import React, {useEffect} from "react";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import history from "@history";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import MultilineTable from "app/main/shared-components/multiline-table/MultilineTable";
import SearchPanel from "../search-panel/SearchPanel";
import PaginationBar from "app/main/shared-components/pagination-bar/PaginationBar";
import {clearfilterFlights, filterFlights,} from "../store/flights-list/filter-flights.slice";
import {fetchAllCurrencies} from "../store/currencies-list.slice";
import {useTranslation} from "react-i18next";

const FlightListPage = () => {
    const dispatch = useDispatch();
    const {allFlights, loading} = useSelector((state: AppState) => state.flights.flightsList.allFlights);
    const filters = useSelector((state: AppState) => state.flights.flightsList.filterFlights);
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(clearfilterFlights());
        dispatch(filterFlights({
            ...filters,
            dayOfWeek: '',
            inFlight: false,
        }));
        dispatch(fetchAllCurrencies());
    }, []);

    return (
        <div>
            <PageHeader
                title={t("app.main.flights.flightList.title", "List of flights")}
                desc={t("app.main.flights.flightList.desc", "If you want to record a new flight, click the Register button")}
                button={t("app.main.agency.agentsList.registerButton", "Register")}
                onClickButton={() => {
                    history.push("/flights/flights-list/create");
                    dispatch(toggleQuickPanel());
                }}
            />
            <SearchPanel/>
            <MultilineTable data={allFlights} loading={loading}/>
            <PaginationBar
                totalPages={allFlights?.totalPages}
                currentPage={(filters.page || 0) + 1}
                onPageChange={(page: number) =>
                    dispatch(filterFlights({...filters, page}))
                }
            />
        </div>
    );
};

export default FlightListPage;
