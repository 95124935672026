import React from 'react';
import {customersFormStyles} from "../customers.styles";
import {Field, Form, Formik} from 'formik';
import history from '@history';
import {Button, TextField} from "@material-ui/core";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch} from "react-redux";
import {createCustomer} from "../store/create-costumer.slice";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const CreateCustomerForm = () => {
  const classes = customersFormStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();

    const phoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/gm

    const validationSchema = yup.object({
        phoneNumber: yup
            .string()
            .matches(phoneRegExp,t('app.main.costumer.createCostumer.registerCostumer.phoneValidation','Phone number must start with + sign')),
        fullName: yup
        .string()
        .required(t("app.main.costumer.createCostumer.registerCostumer.fullName","Please enter the full name"))
        .matches(/^[aA-zZ\s]+$/,t("app.main.costumer.createCostumer.registerCostumer.fullName.title","Only alphabets are allowed for this field ")),
        city: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title","Only alphabets are allowed for this field ")),
        country: yup
        .string()
        .matches(/^[aA-zZ\s]+$/,t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
        email: yup
        .string().email()
        .required(t("app.main.costumer.createCostumer.registerCostumer.email","Please enter the email")),
        password: yup
        .string()
            .required(t("app.main.costumer.createCostumer.registerCostumer.password","Please enter the password"))
});
  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: '#0D3B66'}}>{t("app.main.customer.create.title", "Register Client")}</h1>
        </div>
        <Formik
            initialValues={{
              fullName: '',
              email: '',
              phoneNumber: '',
              address: '',
              password: '',
              city: '',
              zipCode: '',
              country: '',
              dateOfBirth: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              const {
                fullName,
                address,
                phoneNumber,
                email,
                password,
                city,
                zipCode,
                country,
                dateOfBirth
              } = data;
              console.log(data)

              dispatch(createCustomer({
                body: {
                  fullName,
                  address,
                  phoneNumber,
                  email,
                  password,
                  city,
                  zipCode,
                  country,
                  dateOfBirth
                }
              }));
            }}
        >
          {({values, setValues, touched, errors}) => (
              <Form>
                <div className={classes.row}>
                  <Field variant='outlined' value={values.fullName} name='fullName'
                         label={t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell1", "Full name")}
                         as={TextField} required
                         error={Boolean(errors.fullName && touched.fullName)}
                         helperText={touched.fullName ? errors.fullName : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             fullName: e.target.value,
                           });
                         }}/>
                  <Field variant='outlined' value={values.address} name='address'
                         label={t("app.main.customer.customerList.address", "Address")}
                         as={TextField}/>
                  {/*<Field variant='outlined' value={values.lastName} name='lastName' label='Lastname' as={TextField} required />*/}
                </div>
                <div className={classes.row}>
                  <Field variant='outlined' value={values.city} name='city'
                         label={t("app.main.customer.customerList.city", "City")}
                         as={TextField}
                         error={Boolean(errors.city && touched.city)}
                         helperText={touched.city ? errors.city : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             city: e.target.value,
                           });
                         }}/>
                  <Field variant='outlined' value={values.country} name='country'
                         label={t("app.main.customer.customerList.country", "Country")}
                         as={TextField}
                         error={Boolean(errors.country && touched.country)}
                         helperText={touched.country ? errors.country : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             country: e.target.value,
                           });
                         }}/>
                </div>
                <div className={classes.row}>
                  <Field variant='outlined' type='email' value={values.email}
                         label={t("app.main.employees.employeeList.table.email", "Email")}
                         name='email' as={TextField}
                         required
                         error={Boolean(errors.email && touched.email)}
                         helperText={touched.email ? errors.email : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             email: e.target.value,
                           });
                         }}/>
                  <Field variant='outlined' type='password' value={values.password}
                         label={t("app.main.employees.create.password", "Password")}
                         name='password' as={TextField}
                         required
                         error={Boolean(errors.password && touched.password)}
                         helperText={touched.password ? errors.password : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             password: e.target.value,
                           });
                         }}/>
                </div>
                <div className={classes.row} style={{width: '50%'}}>
                  <Field
                      as={TextField}

                      type="text"
                      label={t("app.main.employees.employeeList.table.phoneNumber", "Phone number")}
                      variant="outlined"
                      name={"phoneNumber"}
                      value={values.phoneNumber}
                      error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                      helperText={touched.phoneNumber ? errors.phoneNumber : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          phoneNumber: e.target.value,
                        });
                      }}
                  />
                </div>
                <div className={classes.row}>
                  <LoadingButton
                      type='submit'
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={false}
                  />
                  <Button onClick={(e) => {
                    dispatch(toggleQuickPanel())
                    history.push('/customer/customers-list')
                  }}>
                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                  </Button>
                </div>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default CreateCustomerForm;