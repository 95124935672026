import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AccountServices} from 'app/services';
import {AccountTypes} from 'app/types/service-types/AccountTypes';
import {errorMsg} from "app/utils/MessageUtils";

export const getAccount = () => async (dispatch: Dispatch) => {
  try {
    const response = await AccountServices.fetchAccount();
    dispatch(setAccount(response));
  } catch (err: any) {
    dispatch(errorMsg(err.data?.message));
  }
}

interface GetAccountSlice {
  account: AccountTypes | null;
  loading: boolean;
}

const initialState: GetAccountSlice = {
  account: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'account/get-account',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAccount: (state, action) => {
      state.account = action.payload
    }
  }
});

export const {setLoading, setAccount} = stateSlice.actions;

export default stateSlice.reducer;