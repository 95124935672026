import React from 'react'
import useStyles from './page-header.styles';
import {Button, Icon, IconButton} from "@material-ui/core";
import clsx from 'clsx';
import {PageHeaderTypes} from "app/types/layout-types/PageHeaderTypes";

const PageHeader = (props: PageHeaderTypes) => {
  const classes = useStyles();

  const onClickHandler = () => {
    props.onClickButton && props.onClickButton();
  };

  return (
      <div className={classes.root}>
        {props.backButton && (
            <IconButton style={{color: '#0D3B66', fontWeight: 600, marginRight: '15px'}}
                        onClick={props.backButton}>
              <Icon>arrow_back</Icon>
            </IconButton>
        )}
        <div>
          <h2 style={{color: '#3d3d3d', fontWeight: 500}}>{props.title}</h2>
          <p style={{color: '#adadad', fontWeight: 400}}>{props.desc}</p>
        </div>
        <div className={clsx(classes.menuButtons, 'flex flex-1 justify-end column')}>
          {props.button && <Button onClick={onClickHandler}>
            <div style={{
              color: '#0D3B66',
              background: '#ffffff',
              paddingLeft: '18px',
              paddingRight: '18px',
              paddingTop: '6px',
              paddingBottom: '6px',
              fontWeight: 700,
              borderRadius: '4px'
            }}>{props.button}</div>
          </Button>}
          {props.secButton && <Button onClick={onClickHandler}>
            <div style={{color: '#0D3B66'}}>{props.secButton}</div>
          </Button>}
        </div>
      </div>
  )

}

export default PageHeader;