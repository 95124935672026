import {combineReducers} from '@reduxjs/toolkit';
import country from './country.slice';
import airports from './airports-list.slice';
import airlines from './airlines-list.slice';
import message from './message.slice';

const appReducers = combineReducers({
  country,
  airports,
  airlines,
  message
});

export default appReducers;