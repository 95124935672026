import {makeStyles, Theme} from "@material-ui/core/styles";

export const disquisitionsStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(0, 5)
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    margin: theme.spacing(1.5, 0)
  },
  disquisitionsWrapper: {
    marginTop: '50px',
    marginBottom: '35px',
    '& > div': {
      padding: '20px',
      borderTop: '1px solid rgba(1, 1, 1, .1)'
    },
    '& > div:last-child': {
      borderBottom: '1px solid rgba(1, 1, 1, .1)'
    },
  }
}))