import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../store";
import {WAY_TYPE} from "../../../constants/constants";
import {useTranslation} from "react-i18next";

export interface FlightPricesComponentProps {
  typeOfFlight: string;
  flight?: any,
  returnFlight?: any,
}

const FlightPricesComponent = (props: FlightPricesComponentProps) => {
  const {selectedFlight, selectedReturnFlight}: any = useSelector(
      (state: AppState) => state.schedule.flightResults
  );

  const {wayType} = useSelector(
      (state: AppState) => state.schedule.scheduleForm
  );

  const {adult, infants, children} = useSelector(
      (state: AppState) => state.schedule.scheduleForm.scheduleForm
  );
  const travellers = useSelector(
      (state: AppState) => state.schedule.createSchedule.travellers
  );

  const depTaxFlight = selectedFlight?.taxOfFlight;
  const retTaxFlight =
      wayType === WAY_TYPE.RETURN_FLIGHT ? selectedFlight?.taxOfFlight : null;

  const [onWayPriceInput, setOnWayPriceInput] = useState({
    forAdults: null,
    forChildren: null,
    forInfants: null,
  }) as any;
  const [onReturnPriceInput, setOnReturnPriceInput] = useState({
    forAdults: null,
    forChildren: null,
    forInfants: null,
  }) as any;
  const {t} = useTranslation();

  useEffect(() => {
    getTotalPrice();
  }, [travellers]);

  const getTotalPrice = () => {
    let sum = 0;
    let adultsOnWayPrice = 0;
    let childrenOnWayPrice = 0;
    let infantsOnWayPrice = 0;
    let adultsOnReturnPrice = 0;
    let childrenOnReturnPrice = 0;
    let infantsOnReturnPrice = 0;
    Object.keys(travellers).map((key: any, idx: number) => {
      // @ts-ignore
      const obj = travellers[key];
      // @ts-ignore
      const objKeys = Object.keys(travellers[key]);

      const departureFlightPriceKey = objKeys[6];
      const returningFlightPriceKey = objKeys[7];

      const tax = (depTaxFlight + retTaxFlight!) as any;
      if (wayType === WAY_TYPE.ONE_WAY) {
        if (departureFlightPriceKey.includes("ADULT")) {
          adultsOnWayPrice += +obj[departureFlightPriceKey] || 0;
        }
        if (departureFlightPriceKey.includes("CHILD")) {
          childrenOnWayPrice += +obj[departureFlightPriceKey] || 0;
        }
        if (departureFlightPriceKey.includes("INF")) {
          infantsOnWayPrice += +obj[departureFlightPriceKey] || 0;
        }
        if (isNumeric(obj[departureFlightPriceKey])) {
          sum += +obj[departureFlightPriceKey] + (depTaxFlight as any);
        }
      } else {
        if (departureFlightPriceKey.includes("ADULT")) {
          adultsOnReturnPrice +=
              +obj[returningFlightPriceKey] + +obj[departureFlightPriceKey];
        }
        if (departureFlightPriceKey.includes("CHILD")) {
          childrenOnReturnPrice +=
              +obj[returningFlightPriceKey] + +obj[departureFlightPriceKey];
        }
        if (departureFlightPriceKey.includes("INF")) {
          infantsOnReturnPrice +=
              +obj[returningFlightPriceKey] + +obj[departureFlightPriceKey];
        }
        if (
            isNumeric(obj[departureFlightPriceKey]) &&
            isNumeric(obj[returningFlightPriceKey])
        ) {
          sum +=
              +obj[departureFlightPriceKey] + +obj[returningFlightPriceKey] + tax;
        }
      }
    });
    setOnWayPriceInput({
      forInfants: +infantsOnWayPrice,
      forChildren: +childrenOnWayPrice,
      forAdults: +adultsOnWayPrice,
    });
    setOnReturnPriceInput({
      forInfants: +infantsOnReturnPrice,
      forChildren: +childrenOnReturnPrice,
      forAdults: +adultsOnReturnPrice,
    });
  };
  const passangers = adult + children;

  const isNumeric = (str: string): boolean => {
    return (
        !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  const departureFlight = {
    tax: selectedFlight?.taxOfFlight,
    totalTax: selectedFlight?.taxOfFlight * passangers,
    adultDeparturePrice:
        props.flight?.availableSectorPrice.price.oneWayPrices.forAdults * adult,
    adultReturnPrice:
        props.flight?.availableSectorPrice.price.returnPrices.forAdults * adult,
    childDeparturePrice:
        props.flight?.availableSectorPrice.price.oneWayPrices.forChildren *
        children,
    childReturnPrice:
        props.flight?.availableSectorPrice.price.returnPrices.forChildren *
        children,
    infantDeparturePrice:
        props.flight?.availableSectorPrice.price.oneWayPrices.forInfants * infants,
    infantReturnPrice:
        props.flight?.availableSectorPrice.price.returnPrices.forInfants * infants,
    currencySymbol: selectedFlight?.currency.symbol,
  };

  const returnFlight = {
    tax: selectedReturnFlight?.taxOfFlight,
    totalTax:
        selectedFlight?.taxOfFlight * passangers +
        selectedReturnFlight?.taxOfFlight * passangers,
    adultReturnPrice:
        props.returnFlight?.availableSectorPrice.price.returnPrices.forAdults *
        adult +
        props.flight?.availableSectorPrice.price.returnPrices.forAdults * adult,
    childReturnPrice:
        props.returnFlight?.availableSectorPrice.price.returnPrices
            .forChildren *
        children +
        props.flight?.availableSectorPrice.price.returnPrices.forChildren *
        children,
    infantReturnPrice:
        props.returnFlight?.availableSectorPrice.price.returnPrices
            .forInfants *
        infants +
        props.flight?.availableSectorPrice.price.returnPrices.forInfants *
        infants,
    currencySymbol: selectedReturnFlight?.currency.symbol,
  };

  console.log(props.flight)
  const renderHtml = (
      adultFlightPrice: number,
      childFlightPrice: number,
      infantFlightPrice: number,
      currencySymbol: string,
      totalTaxPrice: number
  ) => {
    return (
        <div style={{display: "flex", marginBottom: "5px"}}>
          <p style={{marginRight: "5px"}}>
            {t("app.main.schedule.scheduleForm.thirdStep.ticketForAdults", "Tickets for adults")} :{" "}
            <b>
              {adultFlightPrice ? adultFlightPrice : '0'} {currencySymbol}
            </b>{" "}
            |
          </p>
          <p style={{marginRight: "5px"}}>
            {t("app.main.schedule.scheduleForm.thirdStep.ticketForChildren", "Tickets for children")} :{" "}
            <b>
              {childFlightPrice ? childFlightPrice : '0'} {currencySymbol}
            </b>{" "}
            |
          </p>
          <p style={{marginRight: "5px"}}>
            {t("app.main.schedule.scheduleForm.thirdStep.ticketForInfants", "Tickets for infants")} :{" "}
            <b>
              {infantFlightPrice ? infantFlightPrice : '0'} {currencySymbol}
            </b>{" "}
            |
          </p>
          <p style={{marginRight: "5px"}}>
            {t("app.main.schedule.scheduleForm.thirdStep.taxes", "Taxes")}:{" "}
            <b>
              {totalTaxPrice} {currencySymbol}
            </b>{" "}
            |
          </p>
          <p>
            {t("app.main.schedule.scheduleForm.thirdStep.total", "Total")}:{" "}
            <b>
              {adultFlightPrice + childFlightPrice + infantFlightPrice + totalTaxPrice || 0}{" "}
              {currencySymbol}
            </b>
          </p>
        </div>
    );
  };
  return props.typeOfFlight === "ONE_WAY" ? (
      <div style={{display: "flex", flexDirection: "column"}}>
        {renderHtml(
            props.flight ? departureFlight.adultDeparturePrice : onWayPriceInput.forAdults,
            props.flight ? departureFlight.childDeparturePrice : onWayPriceInput.forChildren,
            props.flight ? departureFlight.infantDeparturePrice : onWayPriceInput.forInfants,
            departureFlight?.currencySymbol,
            departureFlight?.totalTax || 0
        )}
      </div>
  ) : (
      <div style={{display: "flex", flexDirection: "column"}}>
        {renderHtml(
            props.returnFlight ? returnFlight.adultReturnPrice : onReturnPriceInput.forAdults,
            props.returnFlight ? returnFlight.childReturnPrice : onReturnPriceInput.forChildren,
            props.returnFlight ? returnFlight.infantReturnPrice : onReturnPriceInput.forInfants,
            returnFlight.currencySymbol,
            returnFlight.totalTax || 0
        )}
      </div>
  );
};

export default FlightPricesComponent;
