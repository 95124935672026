import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {CountryRequestBody} from "app/types/service-types/CountryTypes";
import {CountryService} from "app/services";
import {fetchAllCountries} from "./countires-list.slice"
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {errorMsg, successMsg} from "app/utils/MessageUtils";

interface CreateCountryStateParams {
  body: CountryRequestBody;
  onSuccess?: Function;
  onError?: Function;
}

export const createCountry = (params: CreateCountryStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CountryService.createCountry(params.body);
    if (response.status === 201) {
      dispatch(setSuccess(true));
      // dispatch(toggleQuickPanel());
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Country added successfully!' : 'Shteti u shtua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllCountries());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    console.log(err);
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface CreateCountrySlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: CreateCountrySlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/countries',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;