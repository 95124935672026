import useStyles from "../countries-table/countries-table.styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import CollapsibleTableRow from "../countries-table/CountriesTableRow";
import React from "react";
import {CountryTypes} from "../../../types/service-types/CountryTypes";
import {useTranslation} from "react-i18next";

interface IProps {
  data: CountryTypes[] | null,
  loading: boolean
}

const CountriesTable = (props: IProps) => {
  const classes = useStyles();
  const {data, loading} = props;
  const {t} = useTranslation();
  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow5", "Number")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.customer.customerList.country", "Country")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow3", "Code")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.actions", "Actions")}</TableCell>
              {/*<TableCell style={{fontWeight: 'bold', textAlign: 'center'}} >Actions</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <LoadingTableRow colSpan={6}/>
                : !data || data.length === 0 ? <NoDataTableRow colSpan={6}/>
                    : data.map((row) => (
                        <CollapsibleTableRow key={row.id} row={row}/>
                    ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default CountriesTable;