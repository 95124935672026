import {makeStyles, Theme} from "@material-ui/core/styles";

export const createAgentsStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(5, 5)
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    margin: theme.spacing(1.5, 0)
  },
  radioGroupDiv: {
    '& label': {
      fontSize: '1rem',
      margin: theme.spacing(0, 1),
      '& input': {marginRight: '5px'}
    }
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '1rem',
      marginBottom: '10px',
      '& input': {marginRight: '5px'}
    }
  },
  title: {
    fontSize: "15px",
    fontWeight: "bold"
  },
  permissions: {
    marginTop: "1px",
    paddingLeft: "15px",
    paddingRight: "15px",
    backgroundColor: "#F5F5F5",
  }
}))