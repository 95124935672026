import React, {useState} from 'react';
import {employeesFormStyles} from "../employees.styles";
import {Field, Form, Formik} from 'formik';
import history from '@history';
import {Button, MenuItem, TextField} from "@material-ui/core";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useRouteMatch} from "react-router-dom";
import {updateUser} from "../store/update-user.slice";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {updatePassword} from "../store/update-user-password.slice";
import * as yup from "yup";

const UpdateEmployeeForm = () => {
    const classes = employeesFormStyles();
    const dispatch = useDispatch();
    const {params} = useRouteMatch();
    const {t} = useTranslation();
    const allEmpUsers = useSelector((state: AppState) => state.employees.allEmpUsers.allEmpUsers.content);

    const handleKeyDownSpace = (e: KeyboardEvent) => {
        if (e.code === 'Space') {
            e.preventDefault();
        }
    }

    const [changePassword, setChangePassword] = useState(false);
    let validationSchema = null;
    if (changePassword) {
        validationSchema = yup.object().shape({
            name: yup
                .string()
                .required(t("app.main.employee.createEmployee.nameValidation", "Please enter the required field"))
                .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
            surname: yup
                .string()
                .required(t("app.main.employee.createEmployee.surnameValidation", "Please enter the required field"))
                .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
            email: yup
                .string().email()
                .required(t("app.main.costumer.createCostumer.registerCostumer.email", "Please enter the required field")),
            role: yup
                .string()
                .required(t("app.main.employee.createEmployee.roleValidation", "Please enter the required field")),

            newPassword: yup.string().required(t("app.main.updateEmployee.updateEmployee.confirm.password", "This field is required")),
            confirmNewPassword: yup.string().required(t("app.main.updateEmployee.updateEmployee.confirm.password", "This field is required")).when("newPassword", {
                is: (val: any) => (!!(val && val.length > 0)),
                then: yup.string().oneOf(
                    [yup.ref("newPassword")],
                    t("app.main.updateEmployee.updateEmployee.confirm.Samepassword", "Both password need to be the same"
                    ))
            })
        });
    } else {
        validationSchema = yup.object().shape({
            name: yup
                .string()
                .required(t("app.main.employee.createEmployee.nameValidation", "Please enter the required field"))
                .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
            surname: yup
                .string()
                .required(t("app.main.employee.createEmployee.surnameValidation", "Please enter the required field"))
                .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
            email: yup
                .string().email()
                .required(t("app.main.costumer.createCostumer.registerCostumer.email", "Please enter the required field")),
            role: yup
                .string()
                .required(t("app.main.employee.createEmployee.roleValidation", "Please enter the required field"))
        })
    }


    const getSelectedUser = () => {
        let userToReturn = {
            id: '',
            name: '',
            surname: '',
            contactNumber: '',
            address: '',
            email: '',
            newPassword: '',
            confirmNewPassword: '',
            role: '',
            status: false,
            scopes: [],
        }

        if (params) {
            // @ts-ignore
            const foundedUser = allEmpUsers.find((usr) => usr.id === params.id);
            if (foundedUser) {
                const {
                    id,
                    fullName,
                    email,
                    role,
                } = foundedUser;

                const splitFullName = fullName.split(/(\s+)/).filter(e => e.trim().length > 0);

                userToReturn = {
                    id,
                    name: splitFullName[0],
                    surname: splitFullName[1],
                    email,
                    role,
                    contactNumber: '',
                    address: '',
                    newPassword: '',
                    confirmNewPassword: '',
                    scopes: [],
                    status: false,
                }
            }
        }

        return userToReturn;
    }
    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader}>
                <h1 style={{color: '#0D3B66'}}>{t("app.main.employees.update.title", "Change Employee")}</h1>
            </div>
            <Formik
                initialValues={getSelectedUser()}
                validationSchema={validationSchema}
                onSubmit={(data) => {
                    const {
                        id,
                        name,
                        surname,
                        email,
                        newPassword,
                        confirmNewPassword,
                        role,
                        status,
                        scopes,

                    } = data;

                    if (changePassword) {

                        dispatch(updateUser({
                            id,
                            body: {
                                fullName: `${name} ${surname}`, email, role, status, scopes
                            },
                            onSuccess: () => dispatch(toggleQuickPanel())
                        }));
                        dispatch(updatePassword({
                            id,
                            body: {
                                newPassword, confirmNewPassword
                            }
                        }));
                    } else {
                        dispatch(updateUser({
                            id,
                            body: {
                                fullName: `${name} ${surname}`, email, role, status, scopes
                            },
                            onSuccess: () => dispatch(toggleQuickPanel())
                        }));
                    }

                }}
            >
                {({values, touched, errors, setValues}) => (
                    <Form>
                        <div className={classes.row}>
                            <Field variant='outlined' value={values.name} name='name'
                                   label={t("app.main.employees.create.employeeName", "Employee name")}
                                   as={TextField} onKeyDown={handleKeyDownSpace} required
                                   error={Boolean(errors.name && touched.name)}
                                   helperText={touched.name ? errors.name : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           name: e.target.value,
                                       });
                                   }}
                            />
                            <Field variant='outlined' value={values.surname} name='surname'
                                   label={t("app.main.employees.create.employeeLastname", "Employee lastname")}
                                   as={TextField} onKeyDown={handleKeyDownSpace}
                                   required
                                   error={Boolean(errors.surname && touched.surname)}
                                   helperText={touched.surname ? errors.surname : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           surname: e.target.value,
                                       });
                                   }}/>
                        </div>
                        <div className={classes.row}>
                            <Field variant='outlined' type='email' value={values.email} name='email'
                                   label={t("app.main.employees.employeeList.table.email", "Email")}
                                   as={TextField} onKeyDown={handleKeyDownSpace} required
                                   error={Boolean(errors.email && touched.email)}
                                   helperText={touched.email ? errors.email : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           email: e.target.value,
                                       });
                                   }}/>
                            <Field
                                variant="outlined"
                                value={values.role}
                                name="role"
                                required
                                label={t("app.main.employees.create.dropdown.title", "Role")}
                                select
                                as={TextField}
                                error={Boolean(errors.role && touched.role)}
                                helperText={touched.role ? errors.role : ""}
                                onChange={(e: any) => {
                                    setValues({
                                        ...values,
                                        role: e.target.value,
                                    });
                                }}
                            >
                                <MenuItem value="ADMIN">Admin</MenuItem>
                                <MenuItem value="CALL_AGENT">Call Agent</MenuItem>
                            </Field>
                        </div>
                        {changePassword &&
                            <div className={classes.row}>
                                <Field variant='outlined' type='password' value={values.newPassword}
                                       name='newPassword'
                                       error={Boolean(errors.newPassword && touched.newPassword)}
                                       helperText={touched.newPassword ? errors.newPassword : ""}
                                       label='Password' as={TextField} required/>
                                <Field variant='outlined' type='password' value={values.confirmNewPassword}
                                       name='confirmNewPassword'
                                       error={Boolean(errors.confirmNewPassword && touched.confirmNewPassword)}
                                       helperText={touched.confirmNewPassword ? errors.confirmNewPassword : ""}
                                       label='Confirm Password' as={TextField} required/>
                            </div>
                        }
                        <div className={classes.row}>
                            <LoadingButton
                                type='submit'
                                btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                                isLoading={false}
                            />
                            <Button onClick={() => {
                                dispatch(toggleQuickPanel())
                                history.push('/employees/employees-list')
                            }}>
                                {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                            </Button>
                            <div className={clsx(classes.row, "d-flex justify-end")}>
                                <label>
                                    <Field type="checkbox" name="changePassword"
                                           value={changePassword}
                                           checked={changePassword}
                                           onChange={() => setChangePassword(!changePassword)}/>
                                    {t("app.main.employees.update.changePassword", "Change password")}
                                </label>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateEmployeeForm;