import React from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import {useRouteMatch} from 'react-router-dom';

import useStyles from './stepper.styles';
import {StepperComponentTypes, StepTypes} from './stepper.types';

const StepperComponent = (props: StepperComponentTypes) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const getActiveStep = () => {
    for (let i = 0; i < props.steps.length; i++) {
      const step = props.steps[i];

      if (step.path === match.path)
        return i;
    }

    return -1;
  }

  return (
      <div className={classes.root}>
        <Stepper activeStep={getActiveStep()} alternativeLabel>
          {props.steps.map((step: StepTypes) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
          ))}
        </Stepper>
      </div>
  );
};

export default StepperComponent;