import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';

import useStyles from './sub-topbar.styles';
import SubTopbarMenu from 'app/main/layout/sub-topbar-menu/SubTopbarMenu';
import {AppBar} from "@material-ui/core";
import IconNavLink from "app/main/shared-components/icon-nav-link/IconNavLink";
import {ROLES} from 'app/constants/roles';
import {useTranslation} from "react-i18next";

const SubTopbar = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
      <AppBar
          position='relative'
          variant='outlined'
          className={clsx(classes.root, 'p-0')}>
        <div className={classes.subTopBar}>
          <Toolbar style={{padding: 0}}>
            <div className={clsx(classes.menuButtons, 'flex flex-1')}>
              <SubTopbarMenu/>
            </div>
            <div >
              {/*<IconNavLink
                  to='/configurations'
                  title={t("app.main.configuration.sidebar.title5", "Configurations")}
                  iconComponent={<SettingsIcon/>}
                  authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]}
              />*/}
            </div>
          </Toolbar>
        </div>
      </AppBar>
  )
}

export default SubTopbar;
