import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {useTableRowStyles} from "./collapsible-table.styles";
import MultilineTableCell from "./MultilineTableCell";
import {Button, Icon, Tooltip} from "@material-ui/core";
import {ReservationType, TravellerTypes,} from "../../../types/service-types/ReservationTypes";
import {toggleQuickPanel} from "../quick-panel/store";
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import history from "@history";
import ModalDialog from "../modal-dialog/ModalDialog";
import {deleteSchedule} from "../../schedules/store/delete-schedule.slice";
import DateUtils from "../../../utils/DateUtils";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import PrintIcon from "@material-ui/icons/Print";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import {ROLES} from "../../../constants/roles";
import {updateStatusPassenger} from "../../schedules/store/update-passenger.slice";
import {deleteTraveler} from "../../schedules/store/delete-traveler.slice";
import {deleteFlight} from "../../schedules/store/delete-flight-from-traveler.slice";
import {warningMsg} from "../../../utils/MessageUtils";
import {useTranslation} from "react-i18next";
import EditIcon from "../edit-icon/EditIcon";
import {flightShow} from "../../schedules/store/flight-show.slice";
import {disableFlight} from "../../schedules/store/disable-flight-from-traveler.slice";
import {AppState} from "../../../store";
import useAuthority from "../../../hooks/useAuthority";
import ComponentToPrintReservation from "../../PrintInvoice/ComponentToPrintReservation";
import ComponentToPrintTravelers from "../../PrintInvoice/ComponentToPrintTravelers";

interface IProps {
    row: ReservationType;
}

const CollapsibleTableRow = (props: IProps) => {
    const classes = useTableRowStyles();
    const [showPrintInvoice1, setShowPrintInvoice1] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showTravelerDetails, setShowTravelerDetails] = useState(false);
    const [showPrintInvoice1Single, setShowPrintInvoice1Single] = useState(false);
    const dispatch = useDispatch();
    const {hasUserAnyAuthority} = useAuthority();
    let {url} = useRouteMatch();
    const {t} = useTranslation();
    // permissions that admin give the agent
    const scopes = useSelector((state: AppState) => state.account.account.account?.scopes);
    const [modal, setModal] = useState({open: false, deleteId: ""});
    const [reservation, setReservation] = useState();
    const [traveler, setTraveler] = useState();
    const [hasButton, setHasButton] = useState(false);
    const [hasNoteButton, setHasNoteButton] = useState(false);
    const [hasActivitiesButton, setHasActivitiesButton] = useState(false);
    const [hasChangePassengerButton, setHasChangePassengerButton] = useState(false);
    const [hasChangeContactor, setHasChangeContactor] = useState(false);
    const [hasConfirmViber, setHasConfirmViber] = useState(false);
    const [hasPriceButton, setHasPriceButton] = useState(false);
    const [hasShowButton, setHasShowButton] = useState(false);
    const [hasDeactivateButton, setHasDeactivateButton] = useState(false);
    const [hasDeleteFlightButton, setHasDeleteFlightButton] = useState(false);
    const [hasChangeTravelerFlight, setHasChangeTravelerFlight] = useState(false);
    const [hasReturnTravelerFlight, setHasReturnTravelerFlight] = useState(false);
    const [hasSplitButton, setHasSplitButton] = useState(false);
    const [hasCancelButton, setHasCancelButton] = useState(false);

    const printInvoice1 = (reservation: any, showDetails: boolean) => {
        setReservation(reservation);
        setShowPrintInvoice1(true);
        setShowDetails(showDetails);
    };

    const printInvoice1Single = (reservation: any, traveler: any, showDetails: boolean) => {
        setReservation(reservation);
        setTraveler(traveler);
        setShowPrintInvoice1Single(true);
        setShowTravelerDetails(showDetails);
    };

    const showPrint1 = (value: any) => {
        setShowPrintInvoice1(value);
    };

    const showPrint1Single = (value: any) => {
        setShowPrintInvoice1Single(value);
    };

    const disableFlightModalState = {
        open: false, travelerId: '', flight: '', flightName: ''
    };

    const showedFlightModalState = {
        open: false, travelerId: '', status: false, flight: '', flightName: ''
    };

    const [deleteFlightModal, setDeleteFlightModal] = useState({
        open: false, travelerId: "", flight: "", flightName: ""
    });

    const [statusTravelerModal, setStatusTravelerModal] = useState({
        open: false, reservationId: "", travelerId: "", status: false,
    });

    const [deleteTravelerModal, setDeleteTravelerModal] = useState({
        open: false, reservationId: "", travelerId: "", status: false,
    });

    const [disableFlightModal, setDisableFlightModal] = useState(disableFlightModalState);
    const [showedFlightModal, setShowedFlightModal] = useState(showedFlightModalState);

    // HANDLER FOR DISABLE/ENABLE FLIGHT
    const disableFlightHandler = (travelerId: string, flight: string, depFlight: any, arrFlight: any) => {
        setDisableFlightModal({
            open: true, travelerId: travelerId, flight: flight, flightName: `${depFlight} => ${arrFlight}`
        })
    }

    //HANDLER FOR SHOW/NO-SHOW FLIGHT
    const showedFlightHandler = (travelerId: string, status: boolean, flight: string, depFlight?: string, arrFlight?: string) => {
        setShowedFlightModal({
            open: true,
            travelerId: travelerId,
            status: status,
            flight: flight,
            flightName: `${depFlight} => ${arrFlight}`
        })
    }

    const deleteFlightHandler = (travelerId: string, flight: string, depFlight: any, arrFlight: any) => {
        setDeleteFlightModal({
            open: true, travelerId: travelerId, flight: flight, flightName: `${depFlight} - ${arrFlight}`
        });
    };

    const disableTravelerHandler = (reservationId: string, travelerId: string, travelerAgeCategory: string, status: boolean) => {
        const travelers = props.row.travelers;
        let infantCount: number = 0;
        let adultCount: number = 0;

        travelers.forEach(traveler => {
            if (traveler.status) {
                if (traveler.ageCategory === 'INF') {
                    infantCount = infantCount + 1;
                } else if (traveler.ageCategory === 'ADULT') {
                    adultCount = adultCount + 1;
                }
            }
        })

        if (!status && (travelerAgeCategory === 'ADULT' && infantCount >= adultCount)) {
            dispatch(warningMsg('Not allowed to disable adult if there are more infants than adults in the flight'))
        } else {
            setStatusTravelerModal({
                open: true, reservationId: reservationId, travelerId: travelerId, status: status
            })
        }
    }

    const deleteReservationHandler = (id: string) => {
        setModal({open: true, deleteId: id});
    };

    useEffect(() => {
        CheckTooAddPassenger();
    }, [scopes]);

    const {
        id,
        confirmationCode,
        contactNumber,
        secondaryContactNumber,
        createdByRole,
        invoiceInTheNameOf,
        languageOfSupervisor,
        status,
        source,
        supervisor,
        travelers,
        emailOfSupervisor,
        createdDate,
        invoice,
        disquisitions,
        provision,
        smsResponses,
        emailResponses,
        currency,
    } = props.row;
    const [open, setOpen] = React.useState(true);
    let lastDisquisitions = disquisitions[disquisitions.length - 1];
    const allTravellersDisabled = travelers.every(t => !t.status);

    const toggleOpen = () => setOpen(!open);

    const checkAgeAndGender = (age: string, gender: string) => {
        if (age === "ADULT" && gender === "MALE") {
            return "MR";
        } else if (age === "ADULT" && gender !== "MALE") {
            return "MRS";
        } else if (age === "CHILD") {
            return "CHD";
        }
        return age;
    }

    const CheckTooAddPassenger = () => {
        if (scopes && hasUserAnyAuthority([ROLES.ROLE_CALL_AGENT])) {
            scopes.map((x: any) => {
                if (x === "VIBER_CONFIRMATION") {
                    setHasConfirmViber(true);
                }
                if (x === "UPDATE_CONTACT") {
                    setHasChangeContactor(true);
                }
                if (x === "UPDATE_TRAVELERS") {
                    setHasChangePassengerButton(true);
                }
                if (x === "UPDATE_TRAVELER_FLIGHTS") {
                    setHasChangeTravelerFlight(true);
                }
                if (x === "RESERVATION_DISCUSSION") {
                    setHasNoteButton(true);
                }
                if (x === "SPLIT_RESERVATIONS") {
                    setHasSplitButton(true);
                }
            })
        }
        if (scopes && hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
            scopes.forEach((x: any) => {
                switch (x) {
                    case "ADD_TRAVELER":
                        setHasButton(true);
                        break;
                    case "RESERVATION_DISCUSSION":
                        setHasNoteButton(true);
                        break;
                    case "ACTIVITIES":
                        setHasActivitiesButton(true);
                        break;
                    case "UPDATE_TRAVELERS":
                        setHasChangePassengerButton(true);
                        break;
                    case "UPDATE_CONTACT":
                        setHasChangeContactor(true);
                        break;
                    case "CONFIRM_VIBER":
                        setHasConfirmViber(true);
                        break;
                    case "UPDATE_TRAVELER_PRICES":
                        setHasPriceButton(true);
                        break;
                    case "SHOW_TRAVELERS":
                        setHasShowButton(true);
                        break;
                    case "DEACTIVATE_TRAVELER":
                        setHasDeactivateButton(true);
                        break;
                    case "DELETE_TRAVELERS":
                        setHasDeleteFlightButton(true);
                        break;
                    case "UPDATE_TRAVELER_FLIGHTS":
                        setHasChangeTravelerFlight(true);
                        break;
                    case "CANCELLATION_OF_RESERVATION":
                        setHasCancelButton(true);
                        break
                    default:
                        break;
                }
            });
        }
        if (scopes && hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
            setHasButton(true);
            setHasNoteButton(true);
            setHasActivitiesButton(true);
            setHasChangePassengerButton(true);
            setHasChangeContactor(true);
            setHasConfirmViber(true);
            setHasPriceButton(true);
            setHasShowButton(true);
            setHasDeactivateButton(true);
            setHasDeleteFlightButton(true);
            setHasChangeTravelerFlight(true);
            setHasReturnTravelerFlight(true);
            setHasSplitButton(true);
            setHasCancelButton(true);
        }
    }

    const translateDeleteModal = () => {
        let x = t("app.main.schedules.module.title.3", "Are you sure you want to delete flight ");
        return x + deleteFlightModal.flightName;
    }

    const translateDisableModal = () => {
        return t("app.main.schedules.module.title.4", "Are you sure that you want to change status of this booking as no-show");
    }

    const translateShowHideModal = () => {
        let a = t("app.main.schedules.module.title.5", "Are you sure you want to ");
        let b = t("app.main.schedules.module.title.6", "change ");
        let c = t("app.main.schedules.module.title.7", "status to ");
        let d = t("app.main.schedules.module.title.8", "show");
        let e = t("app.main.schedules.module.title.9", "no-show");

        const hideOrShow = showedFlightModal.status ? d : e;

        return a + b + c + hideOrShow;
    }

    return (<React.Fragment>
        {showPrintInvoice1 && (<ComponentToPrintReservation reservation={reservation} showDetails={showDetails}
                                                            showPrint={showPrint1}/>)}

        {showPrintInvoice1Single && (<ComponentToPrintTravelers reservation={reservation} traveler={traveler}
                                                                showPrint={showPrint1Single}/>)}

        {/* DISABLE TRAVELER */}
        <ModalDialog
            mainTitle={t("app.main.schedules.module.title.noshow", 'No show')}
            title={statusTravelerModal.status ? t("app.main.schedules.module.enable.title") : t("app.main.schedules.module.disable.title")}
            onOk={() => {
                dispatch(updateStatusPassenger({
                    reservationId: statusTravelerModal.reservationId,
                    travelerId: statusTravelerModal.travelerId,
                    status: statusTravelerModal.status,
                }));
                setStatusTravelerModal({
                    open: false, reservationId: "", travelerId: "", status: false,
                });
            }}
            onCancel={() => setStatusTravelerModal({
                open: false, reservationId: "", travelerId: "", status: false,
            })}
            open={statusTravelerModal.open}
        />

        <ModalDialog
            title={t("app.main.schedules.module.title.2", "Are you sure you want to delete this?")}
            onOk={() => {
                dispatch(deleteTraveler({
                    reservationId: deleteTravelerModal.reservationId,
                    travelerId: deleteTravelerModal.travelerId,
                    status: deleteTravelerModal.status,
                }));
                setDeleteTravelerModal({
                    open: false, reservationId: "", travelerId: "", status: false,
                });
            }}
            onCancel={() => setDeleteTravelerModal({
                open: false, reservationId: "", travelerId: "", status: false,
            })}
            open={deleteTravelerModal.open}
        />

        <ModalDialog
            title={translateDeleteModal() + ' ?'}
            onOk={() => {
                dispatch(deleteFlight({
                    travelerId: deleteFlightModal.travelerId, flight: deleteFlightModal.flight,
                }));
                setDeleteFlightModal({open: false, travelerId: "", flight: "", flightName: ""});
            }}
            onCancel={() => setDeleteFlightModal({open: false, travelerId: "", flight: "", flightName: ""})}
            open={deleteFlightModal.open}
        />

        {/*DIALOG FOR DISABLE/ENABLE FLIGHT*/}
        <ModalDialog
            title={translateDisableModal() + ' ?'}
            onOk={() => {
                dispatch(disableFlight({
                    travelerId: disableFlightModal.travelerId, flight: disableFlightModal.flight
                }));
                setDisableFlightModal(disableFlightModalState)
            }}
            onCancel={() => setDisableFlightModal(disableFlightModalState)}
            open={disableFlightModal.open}
        />

        <ModalDialog
            title={translateShowHideModal() + ' ?'}
            onOk={() => {
                dispatch(flightShow({
                    travelerId: showedFlightModal.travelerId, flight: showedFlightModal.flight, body: {
                        showed: showedFlightModal.status
                    }
                }));
                setShowedFlightModal(showedFlightModalState);
            }}
            onCancel={() => {
                setShowedFlightModal(showedFlightModalState);
            }}
            open={showedFlightModal.open}
        />

        <ModalDialog
            title={t("app.main.schedules.module.title.1", "This action cancels the reservation!")}
            onOk={() => {
                dispatch(deleteSchedule({id: modal.deleteId}));
                setModal({open: false, deleteId: ""});
            }}
            onCancel={() => setModal({open: false, deleteId: ""})}
            open={modal.open}
        />
        <TableRow className={classes.root}>
            <TableCell align="left">
                <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell align="left">
                <MultilineTableCell
                    {...props}
                    content={[<Box className={classes.textIconWrapper}>
                        <Box fontWeight="fontWeightMedium" mr={2}>
                            #{confirmationCode}
                        </Box>
                        {!allTravellersDisabled && <IconButton size="small" onClick={() => {
                        }}>
                            <Tooltip
                                title={`${t("app.main.schedules.tooltip.print.all.ticket.title", "Print the ticket for all passengers")}`}>
                                <PrintIcon
                                    className={classes.icon}
                                    onClick={() => printInvoice1(props.row, false)}
                                />
                            </Tooltip>
                        </IconButton>}
                        {!allTravellersDisabled && <IconButton size="small" onClick={() => {
                        }}>
                            <Tooltip
                                title={`${t("app.main.schedules.tooltip.print.all.ticket.title", "Print the ticket for all passengers")}`}>
                                <PrintOutlinedIcon
                                    className={classes.icon}
                                    onClick={() => printInvoice1(props.row, true)}
                                />
                            </Tooltip>
                        </IconButton>}
                    </Box>, DateUtils.toHumanDate(DateUtils.convertDate(createdDate), new Date(createdDate).toLocaleTimeString('en-GB')
                        //'en-GB' will ignore browsers time region
                        // and display a 24h zone (14:22 not 2:22)
                    ), <Box fontWeight="fontWeightMedium" display="inline"
                            className={createdByRole === 'AGENT' ? classes.agent : ''}>
                        {createdByRole}: {invoiceInTheNameOf}
                    </Box>,]}
                />
            </TableCell>
            <TableCell align="left">
                <MultilineTableCell
                    {...props}
                    content={[<div style={{wordBreak: "break-word"}}>
                        <Box fontWeight="bold" display="inline">
                            {lastDisquisitions?.fullName ? lastDisquisitions.fullName + ' - ' : ''}
                        </Box>
                        {lastDisquisitions?.message}
                        {hasNoteButton ? (
                            <Tooltip
                                title={`${t("app.main.schedules.tooltip.edit.note.title", "Employee comments about the bill")}`}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        dispatch(toggleQuickPanel());
                                        history.push({
                                            pathname: `${url}/disquisitions/${id}`,
                                            state: {disquisitions: disquisitions},
                                        });
                                    }}
                                >
                                    <Icon className={classes.icon}>
                                        <EditIcon/>
                                    </Icon>
                                </IconButton>
                            </Tooltip>) : false}

                    </div>,]}
                />
            </TableCell>
            <TableCell align="left">
                <MultilineTableCell
                    content={[<div>
                        {supervisor && (<>
                            {hasChangeContactor ? (
                                <Tooltip
                                    title={`${t("app.main.schedules.tooltip.edit.contact.person.title", "Edit the contact person")}`}>
                                    <IconButton
                                        disabled={status != 'ACTIVE'}
                                        size="small"
                                        onClick={() => {
                                            dispatch(toggleQuickPanel());
                                            history.push(`${url}/update-supervisor/${id}`);
                                        }}
                                    >
                                        <Icon
                                            color={status === 'CANCELLED' ? 'disabled' : 'inherit'}
                                            className={classes.icon}>
                                            <EditIcon/>
                                        </Icon>
                                    </IconButton>
                                </Tooltip>) : false}

                            {supervisor} -{" "}
                            <Box fontWeight="fontWeightMedium" display="inline">
                                {languageOfSupervisor ? languageOfSupervisor.toUpperCase() : ""}
                            </Box>
                        </>)}
                    </div>, <div>
                        {contactNumber && (<>
                            {hasConfirmViber ? (
                                <Tooltip
                                    title={`${t("app.main.schedules.tooltip.edit.sms.title", "Send the payment sms to the contact person")}`}>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            dispatch(toggleQuickPanel());
                                            history.push({
                                                pathname: `${url}/sms/${id}`, state: {smsResponses: smsResponses},
                                            });
                                        }}
                                    >
                                        <Icon className={classes.icon}>sms</Icon>
                                    </IconButton>
                                </Tooltip>) : false}
                            {contactNumber} {secondaryContactNumber ? `/ ${secondaryContactNumber}` : ""}
                        </>)}
                    </div>, <div>
                        {emailOfSupervisor && (<>
                            <Tooltip
                                title={`${t("app.main.schedules.tooltip.edit.email.title", "Send the email sms to the contact person")}`}>
                                <IconButton
                                    disabled={allTravellersDisabled}
                                    size='small'
                                    onClick={() => {
                                        dispatch(toggleQuickPanel());
                                        history.push({
                                            pathname: `${url}/email/${id}`, state: {emailResponses: emailResponses},
                                        });
                                    }}
                                >
                                    <Icon className={classes.icon}>email</Icon>
                                </IconButton>
                            </Tooltip>
                            {emailOfSupervisor}
                        </>)}
                    </div>]}
                />
            </TableCell>
            <TableCell align="center">
                <MultilineTableCell content={[`${status} / ${source}`]}/>
            </TableCell>
            <TableCell align="right">
                <MultilineTableCell
                    content={[<div className={classes.textIconWrapper}>
                        <Icon className={classes.icon}>done</Icon>
                        <span>
                  {t("app.main.schedules.sharedComponents.collapsibleTableRow.multilineTableCell.totalInvoice", "Total invoice")}:{" "}
                            <span style={{fontWeight: 500}}>
                    {invoice?.totalAmount} {currency?.symbol}
                  </span>
                </span>
                    </div>, (hasUserAnyAuthority([ROLES.ROLE_ADMIN])) ? (<div className={classes.textIconWrapper}>
                            <Tooltip
                                title={`${t("app.main.schedules.tooltip.edit.payment.title", "Pay the ticket")}`}>
                                <IconButton
                                    size='small'
                                    disabled={status != 'ACTIVE'}
                                    onClick={() => {
                                        dispatch(toggleQuickPanel());
                                        history.push({
                                            pathname: `${url}/payments/${id}`,
                                        });
                                    }}
                                >
                                    <Icon className={classes.icon}>
                                        euro
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                            <span>
                  {t("app.main.schedules.sharedComponents.collapsibleTableRow.multilineTableCell.amountPaid", "Amount paid")}:{" "}
                                <span style={{fontWeight: 500}}>
                    {invoice?.paymentAmount} {currency?.symbol}
                  </span>
                </span>
                        </div>

                    ) : <></>,

                        (hasUserAnyAuthority([ROLES.ROLE_ADMIN]) ? (<>

                            {invoice?.debtAmount > 0 && (<div className={classes.textIconWrapper}>
                                <Icon className={classes.icon} style={{color: "#6c0d20"}}>
                                    warning
                                </Icon>
                                <span style={{color: "#6c0d20", fontWeight: "bold"}}>
                      {t("app.main.schedules.sharedComponents.collapsibleTableRow.multilineTableCell.remainingDebt", "Remaining debt")}:{" "}
                                    <span style={{fontWeight: 'bold'}}>
                        {invoice?.debtAmount} {currency?.symbol}
                      </span>
                    </span>
                            </div>)}
                            {invoice?.debtAmount < 0 && (<div className={classes.textIconWrapper}>
                                <Icon className={classes.icon} style={{color: "#6c0d20"}}>
                                    warning
                                </Icon>
                                <span style={{color: "#6c0d20", fontWeight: "bold"}}>
                      {t("app.main.schedules.sharedComponents.collapsibleTableRow.multilineTableCell.reimbursementAmount", "Reimbursement amount")}:{" "}
                                    <span style={{fontWeight: 'bold'}}>
                        {invoice?.debtAmount} {currency?.symbol}
                      </span>
                    </span>
                            </div>)}
                        </>) : <></>),]}
                />
            </TableCell>
        </TableRow>

        <TableRow>
            <TableCell
                style={{
                    paddingBottom: 0, paddingTop: 0, backgroundColor: "rgba(0,0,0,.03)",
                }}
                colSpan={12}
            >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            {t("app.main.schedules.sharedComponents.collapsibleTableRow.typography", "Passengers")}
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{width: '16rem'}}/>
                                    <TableCell align="left" style={{fontWeight: "bold", width: '16rem'}}>
                                        {t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell1", "Full name")}
                                    </TableCell>
                                    <TableCell align="left" style={{fontWeight: "bold"}}>
                                        {t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell2", "From - To")}
                                    </TableCell>
                                    <TableCell style={{fontWeight: "bold"}} align="right">
                                        {t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell4", "Tickets")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {travelers.map((traveler: TravellerTypes, idx: number) => (<TableRow
                                    key={idx + traveler.name + traveler.dateOfBirth}
                                    className={`${traveler.status ? (traveler.departureFlight?.isDisabled || traveler.returningFlight?.isDisabled) ? classes.isDisabledFlight : classes.passengersActive : classes.passengersDisabled}`}
                                >
                                    <TableCell align="left" component="th" scope="row">
                                        <div style={{display: "flex"}}>
                                            {idx + 1}).
                                            {traveler.departureFlight && <>
                                                <div style={{
                                                    marginLeft: '10px', fontWeight: 'bold',
                                                }}>
                                                    <Icon fontSize='inherit'
                                                          style={{marginRight: '6px'}}>flight</Icon>
                                                    {traveler.departureFlight ? traveler.returningFlight ? 'RT' : 'OW' : ''}
                                                </div>
                                            </>}
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <MultilineTableCell
                                            content={[<>
                                                {hasChangePassengerButton ? (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.edit.title", "Change the data of a passenger")}`}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                dispatch(toggleQuickPanel());
                                                                history.push({
                                                                    pathname: `${url}/update-traveler-flight/${id}`,
                                                                    state: {
                                                                        passenger: traveler,
                                                                        isChangingInfo: true
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <Icon className={classes.icon}>
                                                                <EditIcon/>
                                                            </Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : false}

                                                <Box
                                                    fontWeight="fontWeightMedium"
                                                    display="inline"
                                                >
                                                    {checkAgeAndGender(traveler.ageCategory, traveler.gender)} {traveler.name} {traveler.surname}
                                                </Box>
                                                {hasDeactivateButton ? <>
                                                    {traveler.status ? (
                                                        <Tooltip
                                                            title={`${t("app.main.schedules.tooltip.disable.passenger.title", "Disable passenger")}`}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => disableTravelerHandler(id, traveler.id, traveler.ageCategory, false)}
                                                            >
                                                                <Icon className={classes.icon}>
                                                                    person_off
                                                                </Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip
                                                            title={`${t("app.main.schedules.tooltip.enable.passenger.title", "Enable passenger")}`}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => disableTravelerHandler(id, traveler.id, traveler.ageCategory, true)}
                                                            >
                                                                <Icon
                                                                    className={classes.icon}>person</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </> : false}

                                            </>, <Box className={classes.textIconWrapper}>
                                                <Box
                                                    fontSize="13px"
                                                    display="inline"
                                                    marginRight="10px"
                                                >
                                                    {t("app.main.schedules.sharedComponents.collapsibleTableRow.idPassenger", "Passenger ID")}:{" "}
                                                    <Box
                                                        fontWeight="fontWeightMedium"
                                                        display="inline"
                                                    >
                                                        {traveler.number}
                                                    </Box>
                                                </Box>
                                                {(traveler.status && traveler.departureFlight) && (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.print.ticket.title", "Print")}`}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => printInvoice1Single(props.row, traveler, false)}
                                                        >
                                                            <PrintIcon className={classes.icon}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {(traveler.status && traveler.departureFlight) && (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.print.ticket.title", "Print")}`}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => printInvoice1Single(props.row, traveler, true)}
                                                        >
                                                            <PrintOutlinedIcon className={classes.icon}/>
                                                        </IconButton>
                                                    </Tooltip>)}
                                            </Box>,]}
                                        />
                                    </TableCell>

                                    <TableCell align="left">
                                        {traveler.departureFlight && (<div>
                                            {hasChangeTravelerFlight ? (
                                                <Tooltip
                                                    title={`${t("app.main.schedules.tooltip.edit.one.way.ticket.title", "Edit the departure flight (from - to)")}`}>
                                                    <IconButton
                                                        size="small"
                                                        disabled={status != 'ACTIVE'}
                                                        onClick={() => {
                                                            dispatch(toggleQuickPanel());
                                                            history.push({
                                                                pathname: `${url}/update-passenger-flight/${id}`,
                                                                state: {
                                                                    passenger: traveler,
                                                                    departureFlight: traveler.departureFlight
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Icon className={classes.icon}>
                                                            <EditIcon/>
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>) : false}

                                            <div className={`${classes.inline}
                                               ${traveler.departureFlight.showed ? '' : classes.lineThrough}
                                               ${traveler.departureFlight.isDisabled ? `${classes.textRed} ${classes.lineThrough}` : ''}`}>
                                                  <span className={classes.box}>
                                                    {`${traveler.departureFlight?.departureAirport?.code} -
                                                    ${traveler.departureFlight?.arrivalAirport?.code}`}
                                                  </span>
                                                {DateUtils.toHumanDate(DateUtils.convertDate(traveler.departureFlight?.dateOfDeparture), traveler.departureFlight?.timeOfDeparture?.toString())}
                                            </div>
                                            {hasShowButton && (traveler.departureFlight) ? (
                                                <Tooltip
                                                    title={`${t("app.main.schedules.tooltip.no.show.title", "Change the status of the booking as no-show")}`}>
                                                    <IconButton
                                                        size="small"
                                                        disabled={status != 'ACTIVE'}
                                                        className={classes.marginLeft10}
                                                        onClick={() => {
                                                            showedFlightHandler(traveler.id, !traveler.departureFlight?.showed, 'departureFlight', traveler.departureFlight?.departureAirport?.code, traveler.departureFlight?.arrivalAirport?.code);
                                                        }}
                                                    >
                                                        <Icon className={classes.icon}>
                                                            {traveler.departureFlight.showed ? 'visibility_off' : 'visibility'}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ) : false}


                                            {(hasUserAnyAuthority([ROLES.ROLE_ADMIN]) || hasUserAnyAuthority([ROLES.ROLE_AGENT])) && hasDeleteFlightButton ? (<>
                                                <Tooltip
                                                    title={`${t("app.main.schedules.tooltip.deactivate.title", "Deactivate the booking flight")}`}>
                                                    <IconButton
                                                        size='small'
                                                        disabled={status != 'ACTIVE'}
                                                        onClick={() => {
                                                            if (traveler.departureFlight?.isDisabled) {
                                                                dispatch(toggleQuickPanel());
                                                                history.push({
                                                                    pathname: `${url}/enable-traveler-flight/${id}`,
                                                                    state: {
                                                                        passenger: traveler,
                                                                        flightWay: "ONE_WAY",
                                                                        reActivateflight: traveler.departureFlight
                                                                    },
                                                                });
                                                                return;
                                                            }
                                                            disableFlightHandler(traveler.id, 'departureFlight', traveler.departureFlight?.departureAirport?.code, traveler.departureFlight?.arrivalAirport?.code)
                                                        }}>
                                                        <Icon className={classes.icon}>
                                                            {traveler.departureFlight.isDisabled ? 'done' : 'not_interested'}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                {traveler.returningFlight && (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.delete.title", "Delete the booking flight")}`}>
                                                        <IconButton
                                                            size="small"
                                                            disabled={status != 'ACTIVE'}
                                                            onClick={() => deleteFlightHandler(traveler.id, 'departureFlight', traveler.departureFlight?.departureAirport?.code, traveler.departureFlight?.arrivalAirport?.code)}
                                                        >
                                                            <Icon
                                                                className={classes.icon}>delete_outline</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>) : <></>}

                                        </div>)}

                                        {(!traveler.departureFlight) && (<div>
                                         <span
                                             className={classes.addReturnFlightBtn}
                                             onClick={() => {
                                                 dispatch(toggleQuickPanel());
                                                 history.push({
                                                     pathname: `${url}/add-passenger-return-flight/${id}`, state: {
                                                         passenger: traveler, flightWay: "ONE_WAY", reservationId: id,
                                                     },
                                                 });
                                             }}
                                         >
                                      <Icon className={classes.icon}>add_circle_outline</Icon>
                                             {t("app.main.schedules.sharedComponents.collapsibleTableRow.bookOWFlight", "Book Going Flight")}
                                            </span>
                                        </div>)}

                                        {!traveler.returningFlight && traveler.departureFlight && (

                                            <div>
                                         <span
                                             className={classes.addReturnFlightBtn}
                                             onClick={() => {
                                                 dispatch(toggleQuickPanel());
                                                 history.push({
                                                     pathname: `${url}/add-passenger-return-flight/${id}`, state: {
                                                         passenger: traveler, flightWay: "RETURN_WAY",
                                                     },
                                                 });
                                             }}
                                         >
                                      <Tooltip
                                          title={`${t("app.main.schedules.tooltip.edit.return.way.ticket.title", "Edit the arrival flight (from - to)")}`}>
                                          <Icon className={classes.icon}>add_circle_outline</Icon>
                                      </Tooltip>
                                             {t("app.main.schedules.sharedComponents.collapsibleTableRow.bookReturnFlight", "Book Return Flight")}
                                    </span>
                                            </div>
                                        )}

                                        {traveler.returningFlight && (<div>
                                            {hasReturnTravelerFlight ? (
                                                <Tooltip
                                                    title={`${t("app.main.schedules.tooltip.edit.return.way.ticket.title", "Edit the arrival flight (from - to)")}`}>
                                                    <IconButton
                                                        size="small"
                                                        disabled={status != 'ACTIVE'}
                                                        onClick={() => {
                                                            dispatch(toggleQuickPanel());
                                                            history.push({
                                                                pathname: `${url}/update-passenger-flight/${id}`,
                                                                state: {
                                                                    passenger: traveler,
                                                                    returnFlight: traveler.returningFlight
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Icon className={classes.icon}>
                                                            <EditIcon/>
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>) : false}

                                            <div className={`${classes.inline}
                                               ${traveler.returningFlight.showed ? '' : classes.lineThrough}
                                               ${traveler.returningFlight.isDisabled ? classes.textRed : ''}`}>
                                                  <span className={classes.box}>
                                                    {`${traveler.returningFlight?.departureAirport?.code} -
                                               ${traveler.returningFlight?.arrivalAirport?.code}`}
                                            </span>
                                                {DateUtils.toHumanDate(DateUtils.convertDate(traveler.returningFlight?.dateOfDeparture), traveler.returningFlight?.timeOfDeparture?.toString())}
                                            </div>
                                            {hasShowButton && (traveler.returningFlight) ? (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.no.show.title", "Change the status of the booking as no-show")}`}>
                                                        <IconButton
                                                            size='small'
                                                            disabled={status != 'ACTIVE'}
                                                            className={classes.marginLeft10}
                                                            onClick={() => {
                                                                showedFlightHandler(traveler.id, !traveler.returningFlight?.showed, 'returnFlight', traveler.returningFlight?.departureAirport?.code, traveler.returningFlight?.arrivalAirport?.code)
                                                            }}>
                                                            <Icon className={classes.icon}>
                                                                {traveler.returningFlight.showed ? 'visibility_off' : 'visibility'}
                                                            </Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                                : false}
                                            <Tooltip
                                                title={`${t("app.main.schedules.tooltip.deactivate.title", "Deactivate the booking flight")}`}>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        if (traveler.returningFlight?.isDisabled) {
                                                            dispatch(toggleQuickPanel());
                                                            history.push({
                                                                pathname: `${url}/enable-traveler-flight/${id}`,
                                                                state: {
                                                                    passenger: traveler,
                                                                    flightWay: "RETURN_WAY",
                                                                    reActivateflight: traveler.returningFlight
                                                                },
                                                            });
                                                            return;
                                                        }
                                                        disableFlightHandler(traveler.id, 'returnFlight', traveler.returningFlight?.departureAirport?.code, traveler.returningFlight?.arrivalAirport?.code)
                                                    }}>
                                                    <Icon className={classes.icon}>
                                                        {traveler.returningFlight.isDisabled ? 'done' : 'not_interested'}
                                                    </Icon>
                                                </IconButton>
                                            </Tooltip>
                                            {(hasUserAnyAuthority([ROLES.ROLE_ADMIN]) || hasUserAnyAuthority([ROLES.ROLE_AGENT])) && hasDeleteFlightButton ? <>
                                                {traveler.departureFlight && (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.delete.title", "Delete the booking flight")}`}>
                                                        <IconButton
                                                            size="small"
                                                            disabled={status != 'ACTIVE'}
                                                            onClick={() => deleteFlightHandler(traveler.id, 'returnFlight', traveler.returningFlight?.departureAirport?.code, traveler.returningFlight?.arrivalAirport?.code)}
                                                        >
                                                            <Icon
                                                                className={classes.icon}>delete_outline</Icon>
                                                        </IconButton>
                                                    </Tooltip>)}
                                            </> : false}

                                        </div>)}
                                    </TableCell>

                                    <TableCell align="right">
                                        {!traveler.status ? (<div>
                                            <div>-</div>
                                            {traveler.returningFlight && <div>-</div>}
                                        </div>) : (<div>
                                            <div className={classes.priceFlex}>
                                                {hasPriceButton ? (
                                                    <Tooltip
                                                        title={`${t("app.main.schedules.tooltip.edit.ticket.title", "Edit flight ticket")}`}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                dispatch(toggleQuickPanel());
                                                                history.push({
                                                                    pathname: `${url}/update-traveler-flight/${id}`,
                                                                    state: {
                                                                        passenger: traveler,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <Icon className={classes.icon}>
                                                                <EditIcon/>
                                                            </Icon>
                                                        </IconButton>
                                                    </Tooltip>) : false}
                                                <p>{t("app.main.schedules.sharedComponents.collapsibleTableRow.ticket", "Ticket")}:{" "}
                                                    <span className={classes.textBold}>
                                                        {traveler.price?.departureFlightPrice! + (traveler.price?.returningFlightPrice === null ? 0 : traveler.price?.returningFlightPrice)} {currency?.symbol}
                                                     </span>
                                                </p>
                                            </div>
                                            <p>{t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell3", "Base price")}:{" "}
                                                <span className={classes.textBold}>
                                                     {traveler.price?.departureBaseFlightPrice! + traveler.price?.returningBaseFlightPrice!} {currency?.symbol}
                                                </span>
                                            </p>
                                        </div>)}
                                    </TableCell>
                                </TableRow>))}
                                <TableRow style={{backgroundColor: "#f2f2f2"}}>
                                    <TableCell
                                        colSpan={12}
                                        style={{textAlign: "right", border: "none"}}
                                    >
                                        <p>
                                            {t("app.main.schedules.sharedComponents.collapsibleTableRow.ticket", "Ticket")}:{" "}
                                            {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) ? (<>
                                            <span>
                                               <b>{invoice.amount} {currency?.symbol}</b>
                                            </span>{" "}|{" "}</>) : <>
                                                <span>
                                                    <b>{invoice?.totalAmount} {currency?.symbol}</b>
                                                 </span>{" "}|{" "}
                                            </>
                                            }
                                            {t("app.main.schedules.sharedComponents.collapsibleTableRow.taxes", "Taxes")}:{" "}
                                            <span>
                                              <b>{invoice.tax} {currency?.symbol}</b>
                                            </span>{" "}
                                            | {t("app.main.schedules.sharedComponents.collapsibleTableRow.discount", "Discount")}:{" "}
                                            <span>
                                              <b>{invoice.discount} {currency?.symbol}</b>
                                            </span>{" "}
                                            | {t("app.main.agency.edit.provision.title1", "Provision")}:{" "}
                                            <span>
                                              <b>{provision} {currency?.symbol}</b>
                                            </span>
                                            {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) ? (<>
                                                {" "}
                                                | {t("app.main.schedules.sharedComponents.collapsibleTableRow.realization", "Realization")}:{" "}
                                                <span>
                                              <b>{invoice.paymentAmount} {currency?.symbol}</b>
                                            </span>
                                                {" "}
                                                | {t("app.main.schedules.sharedComponents.collapsibleTableRow.payment", "Payment")}:{" "}
                                                <span>
                                              <b>{invoice.totalAmount} {currency?.symbol}</b>
                                            </span>{" "}
                                                |{" "}
                                                {invoice.debtAmount > 0 ? `${t("app.main.schedules.sharedComponents.collapsibleTableRow.debts", "Debts")}` : `${t("app.main.schedules.sharedComponents.collapsibleTableRow.multilineTableCell.reimbursementAmount", "Reimbursement amount")}`}{" "}
                                                <span>
                                              <b>{invoice.debtAmount} {currency?.symbol}</b>
                                            </span></>) : <></>}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className={classes.rowButtons} colSpan={12}>
                {hasButton ? <Button
                    onClick={() => {
                        dispatch(toggleQuickPanel());
                        history.push({
                            pathname: `${url}/add-passenger/${id}`, state: {reservation: props.row},
                        });
                    }}
                    size="small"
                    variant="outlined"
                    disabled={status != 'ACTIVE'}
                >
                    <PersonAddOutlinedIcon fontSize="small"/>
                    &nbsp; {t("app.main.schedules.sharedComponents.collapsibleTableRow.addPassenger", "Add Passenger")}
                </Button> : false}

                {travelers?.length > 1 && (hasSplitButton ? <>
                    <Button
                        onClick={() => {
                            dispatch(toggleQuickPanel());
                            history.push({
                                pathname: `${url}/split-passengers/${id}`, state: {passengers: travelers},
                            });
                        }}
                        size="small"
                        variant="outlined"
                        disabled={status != 'ACTIVE'}
                    >
                        <Icon fontSize="small">people_outline</Icon>
                        &nbsp; {t("app.main.schedules.sharedComponents.collapsibleTableRow.shareTheReservation", "Split the reservation")}
                    </Button>
                </> : false)}

                {hasActivitiesButton ? <Button
                    size="small"
                    variant="outlined"
                    onClick={() => history.push(`/schedules/${id}/activities`)}
                    disabled={status != 'ACTIVE'}
                >
                    <Icon fontSize="small">history</Icon>
                    &nbsp; {t("app.main.schedules.sharedComponents.collapsibleTableRow.activities", "Historiku")}
                </Button> : false

                }
                {(hasUserAnyAuthority([ROLES.ROLE_ADMIN]) || hasUserAnyAuthority([ROLES.ROLE_AGENT])) && hasCancelButton ? (
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={status != 'ACTIVE'}
                        onClick={() => {
                            deleteReservationHandler(id);
                        }}
                    >
                        <Icon fontSize="small">delete</Icon>
                        {t("app.main.flights.flightList.deletePopup.cancelButton", "Cancel")}
                    </Button>) : <></>}
            </TableCell>
        </TableRow>
    </React.Fragment>);
};

export default CollapsibleTableRow;
