import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {MenuItem, TextField} from "@material-ui/core";
import {clientWebContentStyle} from "./client-web-content.style";
import PhoneNumberService from "../../../services/phone-number.service";
import {useDispatch} from "react-redux";
import {errorMsg, successMsg} from "../../../utils/MessageUtils";
import * as yup from "yup";

const phoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/gm
const validationSchema = yup.object({
  phoneNumber: yup
  .string()
  .matches(phoneRegExp, 'Duhet të jetë një numër i vlefshëm ex +(prefix)XXXXXXXX')
});

const ClientWebContent = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const classes = clientWebContentStyle();
  const phoneNumberService = PhoneNumberService;

  const formDataState = {
    countryName: '',
    phoneNumber: ''
  };

  const [formData, setFormData] = useState(formDataState);
  const [phoneNumbers, setPhoneNumbers] = useState<any>();

  const fetchNumbers = () => {
    phoneNumberService.getAllPhoneNumbers().then((res) => {
      setPhoneNumbers(res);
    }, (error: any) => {
      dispatch(errorMsg(error.data.message));
    });
  }

  useEffect(() => {
    fetchNumbers();
  }, []);

  const onSubmitHandler = (data: any) => {
    phoneNumberService.updatePhoneNumber(data).then(() => {
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'The number added successfully!' : 'Numri u shtua me sukses!'));
      fetchNumbers();
      setFormData(formDataState);
    }, (error: any) => {
      dispatch(errorMsg(error.data.message))
      setFormData(formDataState);
    })
  }

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
      <div className={classes.formWrapper}>
        <div>
          <h1 className={classes.colorBlue}>{t("app.configuration.webEdit", "Client Web Content")}:</h1>
        </div>
        <Formik
            initialValues={{
              countryName: formData.countryName,
              phoneNumber: formData.phoneNumber
            }}
            validationSchema={validationSchema}
            onSubmit={() => {
              onSubmitHandler(formData);
            }}>
          {({errors, touched}) => (
              <Form>
                <Field
                    className={classes.width100}
                    onChange={(e: any) => {
                      const found = phoneNumbers.find(({countryName}: any) => countryName === e.target.value)
                      if (found) {
                        setFormData(found)
                      }
                    }}
                    variant="outlined"
                    name="countryName"
                    value={formData.countryName}
                    label={t("app.configuration.webEdit.countryName", "Country Name")}
                    size='small'
                    select
                    as={TextField}
                    required
                >
                  <MenuItem key={'ger'} value={'GERMANY'}>
                    Germany
                  </MenuItem>
                  <MenuItem key={'kos'} value={'KOSOVO'}>
                    Kosovo
                  </MenuItem>
                  <MenuItem key={'swi'} value={'SWITZERLAND'}>
                    Switzerland
                  </MenuItem>
                </Field>
                <Field
                    className={classes.width100}
                    onChange={(e: any) => handleChange(e)}
                    variant='outlined'
                    name='phoneNumber'
                    value={formData.phoneNumber}
                    label={t("app.configuration.webEdit.phoneNumber", "Phone Number")}
                    error={Boolean(errors?.phoneNumber && touched?.phoneNumber)}
                    helperText={touched?.phoneNumber ? errors?.phoneNumber : ""}
                    size='small'
                    as={TextField}
                    type='text'
                    required
                />
                <LoadingButton
                    type="submit"
                    btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                    isLoading={false}
                />
              </Form>
          )}
        </Formik>
      </div>
  )
}

export default ClientWebContent;
