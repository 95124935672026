import React from 'react';
import {BasePage} from "app/types/PageTypes";
import PageHeader from 'app/main/shared-components/page-header/PageHeader';
import ScheduleForm from "app/main/schedule/ScheduleForm";
import {Redirect, Route, Switch} from "react-router-dom";
import ScheduleResultsPage from "./schedule-results/ScheduleResultsPage";
import StepperComponent from "../shared-components/stepper-component/StepperComponent";
import ScheduleFinal from "./schedule-final-result/ScheduleFinal";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import DateUtils from "../../utils/DateUtils";
import {useTranslation} from "react-i18next";

interface PageWrapperTypes {
    pageHeader: {
        title: string | JSX.Element;
        desc: string;
    }
    children: any;
}

const SchedulePage = (props: BasePage) => {
    const selectedFlight = useSelector((state: AppState) => state.schedule.flightResults.selectedFlight);
    const selectedReturnFlight = useSelector((state: AppState) => state.schedule.flightResults.selectedReturnFlight);
    const {t} = useTranslation();

    const formatFlightDetails = (departure?: any, arrival?:any, date?: any, flightNumber?: any) => (
        <>
            {`${t("app.main.schedules.addPassenger.departureTime", "Nisja")}: ${departure} - ${arrival} - ${DateUtils.toHumanDate(DateUtils.convertDate(date?.dateOfDeparture), date?.timeOfDeparture.toString())} ${t("app.main.flights.create.form.field15", "Numri i fluturimit")} #${flightNumber}`}<br />
        </>
    );

    const formatReturnFlightDetails = (departure?:any, arrival?:any, date?:any, flightNumber?:any) => (
        <>
            {`${t("app.main.schedules.addPassenger.arrivalTime", "Kthimi")}: ${departure} - ${arrival} - ${DateUtils.toHumanDate(DateUtils.convertDate(date?.dateOfDeparture), date?.timeOfDeparture.toString())} ${t("app.main.flights.create.form.field15", "Numri i fluturimit")} #${flightNumber}`}<br />
        </>
    );

    const PageWrapper = (props: PageWrapperTypes) => {
        return (
            <>
                <PageHeader title={props.pageHeader.title} desc={props.pageHeader.desc}/>
                <StepperComponent steps={[
                    {
                        title: `${t("app.main.schedule.step1", "Step 1")}`,
                        desc: `${t("app.main.schedule.desc1", "Description for step 1")}`,
                        path: '/schedule/step1'
                    },
                    {
                        title: `${t("app.main.schedule.step2", "Step 2")}`,
                        desc: `${t("app.main.schedule.desc2", "Description for step 2")}`,
                        path: '/schedule/step2'
                    },
                    {
                        title: `${t("app.main.schedule.step3", "Step 3")}`,
                        desc: `${t("app.main.schedule.desc3", "Description for step 3")}`,
                        path: '/schedule/step3'
                    }
                ]}/>
                {props.children}
            </>
        )
    }

    return (
        <div>
            <Switch>
                <Route path='/schedule' exact render={() => <Redirect to='/schedule/step1'/>}/>
                <Route render={() => (
                    <PageWrapper
                        pageHeader={{
                            title: `${t("app.main.schedule.title1", "Booking flights")}`,
                            desc: `${t("app.main.schedule.description1", "Search for flights by filling out the form below...")}`
                        }}>
                        <ScheduleForm/>
                    </PageWrapper>
                )} path='/schedule/step1'/>

                <Route render={() => (
                    <PageWrapper
                        pageHeader={{
                            title: `${t("app.main.schedule.title2", "Flight results")}`,
                            desc: `${t("app.main.schedule.title2", "Flight results")}...`,
                        }}>
                        <ScheduleResultsPage/>
                    </PageWrapper>
                )} path='/schedule/step2'/>

                <Route render={() => (
                    selectedReturnFlight != null ?
                        <PageWrapper
                            pageHeader={{
                                title: (
                                    <div>
                                        {formatFlightDetails(
                                            selectedFlight?.departureAirport.location,
                                            selectedFlight?.arrivalAirport.location,
                                            selectedFlight,
                                            selectedFlight?.departureAirport.number,
                                        )}
                                        <br />
                                        {formatReturnFlightDetails(
                                            selectedReturnFlight?.departureAirport.location,
                                            selectedReturnFlight?.arrivalAirport.location,
                                            selectedReturnFlight,
                                            selectedFlight?.arrivalAirport.number
                                        )}
                                    </div>
                                ),
                                desc: `${t("app.main.schedule.description3", "Passenger list and ticket prices for booking")}`
                            }}
                        ><ScheduleFinal/></PageWrapper> :
                        <PageWrapper
                            pageHeader={{
                                title: (
                                    <div>
                                        {formatFlightDetails(
                                            selectedFlight?.departureAirport.location,
                                            selectedFlight?.arrivalAirport.location,
                                            selectedFlight,
                                            selectedFlight?.departureAirport.number,
                                        )}
                                    </div>
                                ),
                                desc: `${t("app.main.schedule.description3", "Passenger list and ticket prices for booking")}`
                            }}>
                            <ScheduleFinal/>
                        </PageWrapper>
                )} path='/schedule/step3'/>
            </Switch>
        </div>
    );
};

export default SchedulePage;
