import React from "react";
import SwipeableViews from "react-swipeable-views";
import { activityPageStyles } from "./activities.styles";
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import history from "@history";
import ReservationActivityTab from "./ReservationActivityTab";
import TravelerActivityTab from "./TravelerActivityTab";
import {useTranslation} from "react-i18next";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ActivitiesPage = () => {
  const classes = activityPageStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const {t} = useTranslation();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <PageHeader
        backButton={() => history.goBack()}
        title={t("app.main.schedules.activities.title", "Activities")}
        desc={t("app.main.schedules.activities.desc", "History of activities related to reservations and passengers.")}
      />
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="full width tabs example"
        >
          <Tab label={t("app.main.schedules.activities.tab1", "Reservation")} {...a11yProps(0)} />
          <Tab label={t("app.main.schedules.activities.tab2", "Passengers")} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ReservationActivityTab />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TravelerActivityTab />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default ActivitiesPage;
