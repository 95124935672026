import {PageConfigTypes} from "../../types/PageTypes";
import AgencyReportsPaymentList from "./components/AgencyReportsPaymentList";
import AgencyReportsProvisionList from "./components/AgencyReportsProvisionList";
import AgencyReportsPage from "./AgencyReportsPage";

function AgencyReportsPageConfig(data: any): PageConfigTypes {
    return {
        baseRoute: {
            path: '/agency-reports',
            component: AgencyReportsPage,
            exact: true,
        },
        subRoutes: [
            ...data.map((payment: any) => ({
                path: '/agency-reports/payments/:currencyId',
                component: () => <AgencyReportsPaymentList key={payment.currencyResponse.id} currencyResponse={payment.currencyResponse} />
            })),
            ...data.map((provision: any) => ({
                path: '/agency-reports/provisions/:currencyId',
                component: () => <AgencyReportsProvisionList key={provision.currencyResponse.id} currencyResponse={provision.currencyResponse}  />
            })),
        ],
        settings: {
            navbar: {
                open: true,
                title: 'Agency Reports',
                subtitle: 'Reports that agency have done',
                items: [
                    ...data.map((payment: any) => ({
                        to: "/payments/" + payment.currencyResponse.id,
                        primaryText: "app.main.agency.config.payment.title",
                        secondaryText: payment.currencyResponse.isoCode,
                        icon: "attach_money",
                        exact: true,
                        activeClassName: "",
                        activeStyle: {fontWeight: "bold"},
                    })),
                    ...data.map((provision: any) => ({
                        to: "/provisions/" + provision.currencyResponse.id,
                        primaryText: "app.main.agency.config.provision.title",
                        secondaryText: provision.currencyResponse.isoCode,
                        icon: "payment",
                        exact: true,
                        activeClassName: "",
                    })),
                ]
            }
        }
    };
}

export default AgencyReportsPageConfig;