import React from 'react';
import {BasePage} from "app/types/PageTypes";
import {Redirect, Switch} from "react-router-dom";

const FlightListPage = (props: BasePage) => {
  return (
      <Switch>
        {props.children}
        <Redirect to='/edit'/>
      </Switch>
  );
};

export default FlightListPage;