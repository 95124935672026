import axios from './axios.interceptors';

class CurrenciesServices {
  fetchAll = async (): Promise<any> => {
    try {
      const res = await axios.get('/api/currencies');
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new CurrenciesServices();
