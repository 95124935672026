import axios from './axios.interceptors';

class PriceTemplateServices {
  fetchAll = async (): Promise<any> => {
    try {
      const response = await axios.get('/api/price-templates');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  createPriceTemplate = async (body: any): Promise<any> => {
    try {
      return await axios.post('/api/price-templates', body);
    } catch (err) {
      throw err;
    }
  }

  updatePriceTemplate = async (body: any): Promise<any> => {
    try {
      return await axios.put('/api/price-templates', body);
    } catch (err) {
      throw err;
    }
  }

  deletePriceTemplate = async (id: string): Promise<any> => {
    try {
      return await axios.delete(`/api/price-templates/${id}`);
    } catch (err) {
      throw err;
    }
  }
}

export default new PriceTemplateServices();