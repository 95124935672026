import React, {useEffect, useState} from 'react';
import PageHeader from 'app/main/shared-components/page-header/PageHeader';
import {Column, TableActionsTypes} from "app/types/TableTypes";
import TableActionButtons from "app/main/shared-components/base-table/TableActionButtons";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {fetchAllAirports} from "../store/airport/airports-list.slice";
import history from '@history';
import {deleteAirport} from "../store/airport/delete-airport.slice";
import {toggleQuickPanel} from 'app/main/shared-components/quick-panel/store';
import {useRouteMatch} from "react-router-dom";
import {Cell} from "react-table";
import ModalDialog from "app/main/shared-components/modal-dialog/ModalDialog";
import AirportsTable from "../../shared-components/airports-table/AirportsTable";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import {filterAirports} from "../store/airport/filter-airports.slice";
import {useTranslation} from "react-i18next";

const AirportsListPage = (props: any) => {
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {
    airports,
    loading
  } = useSelector((state: AppState) => state.configurations.airport.airportsList);

  const [modal, setModal] = useState({
    state: false,
    deleteId: ''
  });
  const {t} = useTranslation();
  const tableActions: TableActionsTypes = {
    // onViewRow: (rowId: number) =>  alert('row: ' + rowId),
    onEditRow: (rowId: number) => {
      dispatch(toggleQuickPanel());
      history.push(`${url}/update/${rowId}`);
    },
    onDeleteRow: (rowId: string) => {
      setModal({state: true, deleteId: rowId});
    }
  }

  const columns: Column[] = [
    {
      Header: 'Aeroporti',
      accessor: 'name'
    },
    {
      Header: 'Shteti',
      accessor: 'country',
      Cell: (cell: Cell) => cell.value.name
    },
    {
      Header: 'Lokacioni',
      accessor: 'location'
    },
    {
      Header: 'Kodi',
      accessor: 'code'
    },
    {
      Header: 'Informata tjera',
      accessor: 'additionalInformation'
    },
    {
      Header: 'Valuta',
      accessor: 'currency'
    },
    {
      Header: 'Numri',
      accessor: 'number'
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({cell}: any) => (
          <TableActionButtons {...tableActions} rowId={cell.value}/>
      )
    }
  ];

  useEffect(() => {
    dispatch(fetchAllAirports());
  }, []);

  return (
      <div>
        <PageHeader
            title={t("app.main.configuration.airlineCompanies.title", "Airline Companies")}
            desc={t("app.main.configuration.airlineCompanies.desc", "If you want to add a new airport, click Register")}
            button={t("app.main.agency.agentsList.registerButton", "Register")}
            onClickButton={() => {
              history.push(`${url}/create`)
              dispatch(toggleQuickPanel());
            }}
        />
        <ModalDialog
            open={modal.state}
            title='Are you sure you want to delete this?'
            onOk={() => {
              dispatch(deleteAirport({id: modal.deleteId}));
              setModal({state: false, deleteId: ''});
            }}
            onCancel={() => setModal({state: false, deleteId: ''})}
        />
        <AirportsTable data={airports} loading={loading}/>
        <PaginationBar totalPages={airports?.totalPages}
                       currentPage={(airports?.number! + 1)}
                       onPageChange={(page: number) =>
                           dispatch(filterAirports({...filterAirports, page}))
                       }/>
      </div>
  );

}

export default AirportsListPage;