import React, {useEffect} from "react";
import {MenuItem, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {Field, Form, Formik} from "formik";
import {scheduleFormStyles} from "./schedule.styles";
import {useDispatch, useSelector} from "react-redux";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import {AppState} from "app/store";
import {handleScheduleFormChange, setWayType,} from "app/main/schedule/store/schedule-form.slice";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import LoadingButton from "../shared-components/loading-button/LoadingButton";
import {
    fetchAllFlightResults,
    fetchAllReturnFlightResults,
    setSelectedReturnFlight,
    setSelectedFlight,
} from "./store/flight-results.slice";
import DateUtils from "app/utils/DateUtils";
import clsx from "clsx";
import {fetchAllAirports} from "../configurations/store/airport/airports-list.slice";
import {WAY_TYPE} from "../../constants/constants";
import {fetchAllCurrencies} from "../flights/store/currencies-list.slice";
import {useTranslation} from "react-i18next";
import useAuthority from "../../hooks/useAuthority";
import {ROLES} from "../../constants/roles";

const ScheduleForm = () => {
    const classes = scheduleFormStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const airports = useSelector((state: AppState) => state.configurations.airport.airportsList.airports.content);
    const scheduleForm = useSelector((state: AppState) => state.schedule.scheduleForm.scheduleForm);
    const scheduleWayType = useSelector((state: AppState) => state.schedule.scheduleForm.wayType);
    const maxPeopleInDropdownForAdmin = 10;
    const account = useSelector((state: AppState) => state.account.account);
    const {hasUserAnyAuthority} = useAuthority();

    useEffect(() => {
        dispatch(fetchAllAirports({size: 9999}));
        dispatch(fetchAllCurrencies());
    }, []);

    const today = new Date();

    const onSubmit = (data: any) => {
        dispatch(setWayType(data.wayType));
        dispatch(
            fetchAllFlightResults({
                queryParams: {
                    oneWayPrice: 0,
                    returnPrice: 0,
                    departureAirportId: data.departureAirportId,
                    arrivalAirportId: data.arrivalAirportId,
                    dateOfDeparture: DateUtils.convertDate(data.dateOfDeparture),
                    currencyIsoCode: scheduleForm.currencyIsoCode === 'auto' ? undefined : scheduleForm.currencyIsoCode
                },
            })
        );

        if (data.wayType === WAY_TYPE.RETURN_FLIGHT) {
            dispatch(
                fetchAllReturnFlightResults({
                    queryParams: {
                        oneWayPrice: 0,
                        returnPrice: 0,
                        departureAirportId: data.arrivalAirportId,
                        arrivalAirportId: data.departureAirportId,
                        dateOfDeparture: DateUtils.convertDate(data.dateOfReturnFlight),
                        currencyIsoCode: scheduleForm.currencyIsoCode === 'auto' ? undefined : scheduleForm.currencyIsoCode
                    },
                })
            );
        }
        history.push("/schedule/step2");
    };

    return (
        <div className={classes.formWrapper}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...scheduleForm,
                    wayType: scheduleWayType || WAY_TYPE.ONE_WAY,
                    currencyIsoCode: 'EUR',
                }}
                onSubmit={onSubmit}
            >
                {({values}) => (
                    <Form>
                        <div
                            className={classes.row}
                            style={{borderTop: "1px solid #e7e7e7"}}
                        >
                            <div style={{display: "flex"}}>
                                <Typography
                                    style={{marginBottom: ".2rem", marginRight: "5.6rem", width: "123px"}}
                                >
                                    {t("app.main.schedule.scheduleForm.label1", "Booking tickets")}:
                                </Typography>
                                <div
                                    className={classes.radioGroupDiv}
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                >
                                    <label>
                                        <Field
                                            type="radio"
                                            name="wayType"
                                            value={WAY_TYPE.RETURN_FLIGHT}
                                            checked={values.wayType === WAY_TYPE.RETURN_FLIGHT}
                                            onChange={() => {
                                                dispatch(setWayType(WAY_TYPE.RETURN_FLIGHT));
                                            }}
                                        />
                                        {t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
                                    </label>
                                    <label>
                                        <Field
                                            type="radio"
                                            name="wayType"
                                            value={WAY_TYPE.ONE_WAY}
                                            checked={values.wayType === WAY_TYPE.ONE_WAY}
                                            onChange={(e: any) => {
                                                dispatch(setWayType(e.target.value));
                                                dispatch(setSelectedReturnFlight(null));
                                            }}
                                        />
                                        {t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div
                            className={classes.row}
                            style={{borderTop: "1px solid #e7e7e7"}}
                        >
                            <Typography
                                style={{marginLeft: ".2rem", marginRight: "6.1rem", width: "96px"}}
                            >
                                {t("app.main.schedules.addPassenger.flightFrom", "Flight from")}:
                            </Typography>
                            <Autocomplete
                                size={"small"}
                                options={airports.filter(
                                    (ap: Airport) => ap.id !== values.arrivalAirportId
                                )}
                                getOptionLabel={(option) => option.code + " - " + option.name}
                                value={airports.find(
                                    (ap) => ap.id === values.departureAirportId
                                )}
                                onChange={(e, value) => {
                                    dispatch(setSelectedFlight(null));
                                    dispatch(
                                        handleScheduleFormChange({
                                            field: "departureAirportId",
                                            value: value ? value.id : "",
                                        })
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        style={{width: "60%"}}
                                        size={"small"}
                                        {...params}
                                        variant="outlined"
                                        id="standard-select-currency"
                                        label={t("app.main.schedule.scheduleForm.label5", "Select the airport:")}
                                        name="departureAirportId"
                                        required
                                    />
                                )}
                            />
                            <Typography style={{marginRight: "1rem"}}>
                                {t("app.main.schedules.addPassenger.towardsTheAirport", "Towards the airport")}:
                            </Typography>
                            <Autocomplete
                                size={"small"}
                                options={airports.filter(
                                    (ap: Airport) => ap.id !== values.departureAirportId
                                )}
                                value={airports.find((ap) => ap.id === values.arrivalAirportId)}
                                getOptionLabel={(option) => option.code + " - " + option.name}
                                onChange={(e, value) => {
                                    dispatch(setSelectedFlight(null));
                                    dispatch(
                                        handleScheduleFormChange({
                                            field: "arrivalAirportId",
                                            value: value ? value.id : "",
                                        })
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        style={{width: "60%"}}
                                        size={"small"}
                                        {...params}
                                        variant="outlined"
                                        id="standard-select-currency"
                                        label={t("app.main.schedule.scheduleForm.label5", "Select the airport:")}
                                        name="arrivalAirportId"
                                        required
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.row}>
                            <Grid container>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Typography style={{margin: "0.5rem 6.2rem 0 0", width: "96px"}}>
                                        {t("app.main.schedules.addPassenger.departureTime", "Departure time")}:
                                    </Typography>
                                    <Grid item xs={2}>
                                        <DatePicker
                                            size={"small"}
                                            style={{width: "96%"}}
                                            inputVariant="outlined"
                                            minDate={today}
                                            disableToolbar
                                            autoOk
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            // label="Koha e nisjes"
                                            value={values.dateOfDeparture}
                                            onChange={(date) => {
                                                if (date) {
                                                    dispatch(
                                                        handleScheduleFormChange({
                                                            field: "dateOfDeparture",
                                                            value: date.toISOString(),
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                {values.wayType === WAY_TYPE.RETURN_FLIGHT && (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Typography style={{margin: "0.5rem 1rem 0 2rem"}}>
                                            {t("app.main.schedules.addPassenger.arrivalTime", "Arrival Time")}:
                                        </Typography>
                                        <Grid item xs={2}>
                                            <DatePicker
                                                size={"small"}
                                                style={{width: "96%"}}
                                                inputVariant="outlined"
                                                disableToolbar
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                id="date-picker-inline"
                                                // label="Koha kthyese"
                                                value={values.dateOfReturnFlight}
                                                onChange={(date) => {
                                                    if (date) {
                                                        dispatch(
                                                            handleScheduleFormChange({
                                                                field: "dateOfReturnFlight",
                                                                value: date.toISOString(),
                                                            })
                                                        );
                                                    }
                                                }}
                                            />
                                            {values.dateOfReturnFlight && values.dateOfReturnFlight < values.dateOfDeparture && (
                                                <span
                                                    style={{color: "red"}}>{t("app.main.reserve.step1.arrivalTime.validation",
                                                    "Return date cannot be earlier than departure date")}</span>
                                            )}
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                            </Grid>
                        </div>
                        <div
                            className={classes.row}
                            style={{
                                borderTop: "1px solid #e7e7e7",
                                borderBottom: "1px solid #e7e7e7",
                            }}
                        >
                            <Typography style={{margin: "0.4rem 8.45rem 0 0.2rem", width: "63px"}}>
                                {t("app.main.schedules.addPassenger.adult", "Adult")}:
                            </Typography>
                            <Field
                                size={"small"}
                                variant="outlined"
                                onChange={(e: any) =>
                                    dispatch(
                                        handleScheduleFormChange({
                                            field: "adult",
                                            value: e.target.value,
                                        })
                                    )
                                }
                                value={values.adult}
                                name="adult"
                                label={t("app.main.schedule.scheduleForm.label3", "Quantity:")}
                                select
                                as={TextField}
                            >
                                {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) && (
                                    Array.from(new Array(maxPeopleInDropdownForAdmin)).map((_, idx) => (
                                        <MenuItem key={`adults_${idx + 1}`} value={idx + 1}>
                                            {idx + 1}
                                        </MenuItem>
                                    )))
                                }
                                {hasUserAnyAuthority([ROLES.ROLE_AGENT]) && (
                                    Array.from(new Array((account?.account?.maxAdultAllowed ?? 0) + 1)).map((_, idx) => (
                                        <MenuItem key={`adults_${idx + 1}`} value={idx}>
                                            {idx}
                                        </MenuItem>
                                    )))
                                }
                            </Field>
                            <Typography style={{margin: "0.2rem 1rem 0 2.5rem"}}>
                                {t("app.main.schedules.addPassenger.child", "Child")}:
                            </Typography>
                            <Field
                                size={"small"}
                                variant="outlined"
                                onChange={(e: any) =>
                                    dispatch(
                                        handleScheduleFormChange({
                                            field: "children",
                                            value: e.target.value,
                                        })
                                    )
                                }
                                value={values.children}
                                name="children"
                                label={t("app.main.schedule.scheduleForm.label3", "Quantity:")}
                                select
                                as={TextField}
                            >
                                {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) && (
                                    Array.from(new Array(maxPeopleInDropdownForAdmin + 1)).map((_, idx) => (
                                        <MenuItem key={`adults_${idx}`} value={idx}>
                                            {idx}
                                        </MenuItem>
                                    )))
                                }
                                {hasUserAnyAuthority([ROLES.ROLE_AGENT]) && (
                                    Array.from(new Array((account?.account?.maxChildAllowed ?? 0) + 1)).map((_, idx) => (
                                        <MenuItem key={`adults_${idx + 1}`} value={idx}>
                                            {idx}
                                        </MenuItem>
                                    )))
                                }
                            </Field>
                            <Typography style={{margin: "0.2rem 1rem 0 2.5rem"}}>
                                {t("app.main.schedules.addPassenger.inf", "Infant")}:
                            </Typography>
                            <Field
                                size={"small"}
                                variant="outlined"
                                onChange={(e: any) =>
                                    dispatch(
                                        handleScheduleFormChange({
                                            field: "infants",
                                            value: e.target.value,
                                        })
                                    )
                                }
                                value={values.infants}
                                name="infants"
                                label={t("app.main.schedule.scheduleForm.label3", "Quantity:")}
                                select
                                as={TextField}
                            >
                                {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) && (
                                    Array.from(new Array(maxPeopleInDropdownForAdmin + 1)).map((_, idx) => (
                                        <MenuItem key={`adults_${idx}`} value={idx}>
                                            {idx}
                                        </MenuItem>
                                    )))
                                }
                                {hasUserAnyAuthority([ROLES.ROLE_AGENT]) && (
                                    Array.from(new Array((account?.account?.maxInfantAllowed ?? 0) + 1)).map((_, idx) => (
                                        <MenuItem key={`adults_${idx + 1}`} value={idx}>
                                            {idx}
                                        </MenuItem>
                                    )))
                                }
                            </Field>
                        </div>
                        <div className={clsx(classes.row, "d-flex justify-end")}>
                            <LoadingButton
                                btnTitle={t("app.main.schedules.addPassenger.searchFlights", "Search Flights")}
                                type="submit"
                                isLoading={false}
                                variant="outlined"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ScheduleForm;
