import {createSlice, Dispatch} from "@reduxjs/toolkit";

const getInitialState = () => ({
  scopes: [] as string[],
});

export const setTheAgentScopesForm = (payload: any) => (dispatch: Dispatch) => {
  dispatch(setAgentScopesForm(payload));
};

export const resetTheAgentScopesForm = () => (dispatch: Dispatch) => {
  dispatch(resetAgentScopesForm());
};


const stateSlice = createSlice({
  name: "agents/agent-scopes-form",
  initialState: getInitialState(),
  reducers: {
    setAgentScopesForm: (state, action) => action.payload,
    fillAgentScopesFormField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      return {
        ...state,
        [field]: value
      }
    },
    resetAgentScopesForm: () => getInitialState(),
  },
});

export const {
  setAgentScopesForm,
  fillAgentScopesFormField,
  resetAgentScopesForm,
} = stateSlice.actions;

export default stateSlice.reducer;