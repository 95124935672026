import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import useStyles from './airports-table.styles';
import CollapsibleTableRow from "./AirportsTableRow";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import {PagedAirportsList} from "../../../types/service-types/AirportServiceTypes";
import {useTranslation} from "react-i18next";

interface IProps {
  data: PagedAirportsList | null,
  loading: boolean
}

const AirportsTable = (props: IProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {data, loading} = props;

  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow1", "Airport")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.customer.customerList.country", "Country")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow2", "Location")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow3", "Code")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow4", "Other informations")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.report.search.currency", "Currency")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.configuration.airlineCompanies.table.tableRow5", "Number")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.actions", "Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <LoadingTableRow colSpan={6}/>
                : !data || data.content.length === 0 ? <NoDataTableRow colSpan={6}/>
                    : data.content.map((row) => (
                        <CollapsibleTableRow key={row.id} row={row}/>
                    ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default AirportsTable;