import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AirlineCompany} from "app/types/service-types/AirlineCompaniesTypes";
import {AirlineService} from 'app/services';

export const fetchAllAirlines = () => async (dispatch: Dispatch) => {
  setLoading(true);
  const response = await AirlineService.getAll();
  dispatch(setAirlineCompanies(response));
  setLoading(false);
}

interface AirlineCompaniesSlice {
  airlineCompanies: AirlineCompany[];
  loading: boolean;
}

const initialState: AirlineCompaniesSlice = {
  airlineCompanies: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/airline-companies',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAirlineCompanies: (state, action) => {
      state.airlineCompanies = action.payload
    }
  }
});

export const {setLoading, setAirlineCompanies} = stateSlice.actions;

export default stateSlice.reducer;