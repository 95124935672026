import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PageableTypes} from "app/types/PaginationTypes";
import {WAY_TYPE} from "../../../constants/constants";

interface StepOneTypes {
  oneWayPrice: number;
  returnPrice: number;
  resultNumber: number;
  departureAirportId: string;
  arrivalAirportId: string;
  dateOfDeparture: string;
  pageable?: PageableTypes;
  page?: number;
  size?: number;
  sort?: string[];
  adult: number;
  children: number;
  infants: number;
  additionalSeats: number;
  currency?: string;
  dateOfReturnFlight?: string;
  currencyIsoCode?: string;
}

interface StateTypes {
  scheduleForm: StepOneTypes;
  loading: boolean;
  requested: boolean;
  wayType: string;
}

const initialState: StateTypes = {
  scheduleForm: {
    oneWayPrice: 0,
    returnPrice: 0,
    resultNumber: 20,
    departureAirportId: "",
    arrivalAirportId: "",
    dateOfDeparture: new Date().toISOString(),
    adult: 1,
    children: 0,
    infants: 0,
    additionalSeats: 0,
    currencyIsoCode: "auto",
    dateOfReturnFlight: new Date().toISOString(),
  },
  loading: false,
  requested: false,
  wayType: WAY_TYPE.RETURN_FLIGHT,
};

const setWayTypeFun = (state: any, action: any) => {
  state.wayType = action.payload;
  if (action.payload === WAY_TYPE.ONE_WAY) {
    state.scheduleForm.dateOfReturnFlight = new Date().toISOString();
    state.scheduleForm.returnPrice = 0;
  }
};

const handleChange = (state: any, action: any) => {
  const {field, value} = action.payload;
  state.scheduleForm[field] = value;
};

const stateSlice = createSlice({
  name: "schedule/schedule-form",
  initialState,
  reducers: {
    clearScheduleForm: (state) => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRequested: (state, action) => {
      state.requested = action.payload;
    },
    setScheduleForm: (state, action: PayloadAction<StepOneTypes>) => {
      state.scheduleForm = action.payload;
    },
    handleScheduleFormChange: (state, action) => handleChange(state, action),
    setWayType: (state, action) => setWayTypeFun(state, action),
  },
});

export const {
  setLoading,
  setScheduleForm,
  clearScheduleForm,
  setRequested,
  setWayType,
  handleScheduleFormChange,
} = stateSlice.actions;

export default stateSlice.reducer;
