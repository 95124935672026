import {ROLES} from "../constants/roles";

class ScopesUtils {
  static FlightReservation = (authority: any, scopes: any) => {
    if (scopes && authority([ROLES.ROLE_AGENT])) {
      scopes.map((x: any) => {
        if (x === "FLIGHT_RESERVATION") {
          return true;
        }
      })
    }
    if (scopes && authority([ROLES.ROLE_ADMIN])) {
      return true;
    }
    if (scopes && authority([ROLES.ROLE_CALL_AGENT])) {
      scopes.map((x: any) => {
        if (x === "FLIGHT_RESERVATION") {
          return true
        }
      })
    }
    return false;
  }
}

export default ScopesUtils;

