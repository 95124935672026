import {PageConfigTypes} from "app/types/PageTypes";

class AppUtils {
  findPageConfigsByPathname = (pathname: string, pageConfigs: PageConfigTypes[]): PageConfigTypes | undefined => {
    const currentPathnameSplited = pathname.split('/')[1];
    const currentPathnameFormated = '/' + currentPathnameSplited;
    return pageConfigs.find((config) => config.baseRoute.path.startsWith(currentPathnameFormated));
  }

  formatAndPersistPathname = (basePath: string | undefined, to: string, id: string): string => {
    const splittedPathname = basePath?.split(":id");
    if (splittedPathname !== undefined) {
      if (splittedPathname && splittedPathname?.length === 0) return `${basePath}${to}`;
      return `${splittedPathname[0]}${id}${to}`;
    }
    return "";
  }

  removeFalsyAttributes = (obj: any) => {
    let newObj: any = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };

  parseLanguageLocaleString = (locale: any): string => {
    const defaultLocale = "en";
    if (locale) {
      if (locale.includes("-")) {
        return locale.split("-")[0]
      } else {
        return locale;
      }
    } else {
      return defaultLocale;
    }
  }

  //convert base64 string(which is a file) to File type
  dataUrlToFile = (dataUrl: string, fileName: string): Promise<File> => {
    return fetch(dataUrl)
    .then(res => res.blob())
    .then(blob => {
      return new File([blob], fileName, {type: 'image/png'});
    });
  }
}

export default new AppUtils();
