import React from "react";
import {BasePage} from "app/types/PageTypes";
import {Redirect, Switch} from "react-router-dom";

const AgencyPage = (props: BasePage) => {
  return (
      <Switch>
        {props.children}
        <Redirect to="/agents-agencies/list"/>
      </Switch>
  );
};

export default AgencyPage;
