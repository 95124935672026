import axios from './axios.interceptors';
import {CountryRequestBody, CountryTypes} from "app/types/service-types/CountryTypes";

class CountryServices {
  fetchAll = async (): Promise<CountryTypes[]> => {
    try {
      const response = await axios.get('/api/countries');
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  createCountry = async (body: CountryRequestBody): Promise<any> => {
    try {
      return await axios.post('/api/countries', body);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  updateCountry = async (id: string, body: CountryRequestBody): Promise<any> => {
    return await axios.put(`/api/countries/${id}`, body);
  }

  deleteCountry = async (id: string): Promise<any> => {
    return await axios.delete(`/api/countries/${id}`);
  }


}

export default new CountryServices();