import {makeStyles} from '@material-ui/styles';

export default ({loading}: any) => makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  spinner: {
    color: 'white',
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    // marginLeft: -12,
  },
  title: {
    position: 'relative',
    color: loading ? 'transparent' : '',
  },
  margin: {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 10px 20px -5px rgba(145, 145, 145, 0.4)'
    },
    position: 'relative',
    // margin: '1rem',
    paddingLeft: '40px',
    paddingRight: '40px;',
    backgroundColor: '#45637E',
    boxShadow: '0px 10px 20px -5px rgba(145, 145, 145, 0.4)'
  }
}));