import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {UserService} from "app/services";
import {FilterEmployeeQueryParams, PagedEmployeeList} from "app/types/service-types/EmployeeTypes";
import {errorMsg} from "../../../utils/MessageUtils";

export const fetchAllEmployeeUsers = (queryParams?: FilterEmployeeQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await UserService.filterAllUserEmployee(queryParams);
    } else response = await UserService.fetchAllUserEmployee();
    dispatch(setUsersEmpList(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface UsersEmpListSlice {
  allEmpUsers: PagedEmployeeList;
  loading: boolean;
}

const initialState: UsersEmpListSlice = {
  allEmpUsers: {content: []},
  loading: false
}

const stateSlice = createSlice({
  name: 'users/users-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setUsersEmpList: (state, action) => {
      state.allEmpUsers = action.payload
    }
  }
});

export const {setLoading, setUsersEmpList} = stateSlice.actions;

export default stateSlice.reducer;