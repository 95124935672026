import React, {useState} from "react";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import {useDispatch} from "react-redux";
import {useRouteMatch} from "react-router-dom";

const FlightsDeletePage = (props: any) => {
  // const { allFlights, loading } = useSelector(
  //     (state: AppState) => state.flights.flightsList.allFlights
  // );
  // const filters = useSelector(
  //     (state: AppState) => state.flights.flightsList.filterFlights
  // );
  //
  // useEffect(() => {
  //     dispatch(clearfilterFlights());
  //     dispatch(fetchAllFlights());
  //     dispatch(fetchAllCurrencies());
  // }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {row} = props;
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
      <div>
        <PageHeader
            title={"To be updated"}
            desc={
              "To be updated.."
            }
            // button={"Regjistro"}
            // onClickButton={() => {
            //     history.push("/flights/flights-list/create");
            //     dispatch(toggleQuickPanel());
            // }}
        />
        <div>Under construction..</div>
        {/*<ModalDialog*/}
        {/*    open={modalOpen}*/}
        {/*    title='Are you sure you want to delete this?'*/}
        {/*    onOk={() => {*/}
        {/*        dispatch(deleteFlight({id: row.id}));*/}
        {/*    }}*/}
        {/*    onCancel={() => setModalOpen(false)} />*/}
        {/*<SearchPanel />*/}
        {/*<MultilineTable data={allFlights} loading={loading} />*/}
        {/*<PaginationBar*/}
        {/*    totalPages={allFlights?.totalPages}*/}
        {/*    onPageChange={(page: number) =>*/}
        {/*        dispatch(filterFlights({ ...filters, page }))*/}
        {/*    }*/}
        {/*/>*/}
      </div>
  );
};

export default FlightsDeletePage;