import {
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import {Field} from "formik";
import {
  addNewProvision,
  fillProvisionField,
  removeProvision, resetProvisionForm,
  setTheAgentForm
} from "../store/agent-form.slice";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {createAgentsStyles} from "../create/create-agents.styles";
import {useTranslation} from "react-i18next";


const ProvisionInfo = () => {
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const classes = createAgentsStyles();
  const {t} = useTranslation();
  const {currencies} = useSelector((state: AppState) => state.flights.currenciesList);

  const getDefaultCurrency = () => {
    const defaultCurrency = currencies?.find((item) => item.isoCode === 'EUR')
    return defaultCurrency ? defaultCurrency.id : ''
  }

  useEffect(() => {
    // We are setting default currency
    if (agentForm.provisionForms[0].currencyId === '') {
      dispatch(setTheAgentForm({
        ...agentForm,
        provisionForms: [{
          ...agentForm.provisionForms[0],
          currencyId: getDefaultCurrency()
        }]
      }))
    }
  }, [currencies])

  const handleChange = (e: any, id: string,) => {
    const payload = {
      value: e.target.name === 'currencyId'? e.target.value : e.target.value,
      field: e.target.name,
      id,
    }
    dispatch(fillProvisionField(payload))
  }

  const provisionFormTypes = [
    {
      label: `${t("app.main.agency.create.provisionInfo.label1", "By percentage")}`,
      value: 'PERCENTAGE',
    },
    {
      label: `${t("app.main.agency.create.provisionInfo.label2", "By % and minimum")}`,
      value: 'PERCENTAGE_MINIMUM',
    },

    {
      label: `${t("app.main.agency.create.provisionInfo.label3", "By % and maximum")}`,
      value: 'PERCENTAGE_MAXIMUM',
    },

    {
      label: `${t("app.main.agency.create.provisionInfo.label4", "By % and minimum, maximum")}`,
      value: 'PERCENTAGE_MINIMUM_MAXIMUM',
    },
    {
      label: `${t("app.main.agency.create.provisionInfo.label5", "With FIXED provision")}`,
      value: 'FIXED',
    },


  ];

  const gridSize = agentForm.provisionFormType === 'PERCENTAGE_MINIMUM_MAXIMUM' ? 2 : 3;
  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
      <div>
        <div style={{backgroundColor: '#e9e9e9', marginTop: '16px', padding: '30px 16px'}}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className={classes.radioGroupDiv} role="group"
                   aria-labelledby="my-radio-group">
                <Typography style={{marginBottom: '.5rem'}}>{t("app.main.agency.create.provisionInfo.label6","Form of provision:")}</Typography>
                {
                  provisionFormTypes.map(({label, value}) => {
                    return <label>
                      <Field type="radio" name="radioButton"
                             checked={agentForm.provisionFormType === value}
                             value={value}
                             onChange={(e: any) => {
                               dispatch(setTheAgentForm({
                                 ...agentForm,
                                 provisionFormType: value
                               }))
                               dispatch(resetProvisionForm(getDefaultCurrency()));
                             }}/>
                      {label}
                    </label>
                  })
                }
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{
          backgroundColor: '#f9f9f9',
          marginBottom: '16px',
          padding: '30px 16px'
        }}>

          {agentForm.provisionForms.map(item => {
            return (
                <Grid container spacing={4}>
                  {agentForm.provisionFormType !== 'FIXED' &&
                      <Grid item xs={gridSize}>
                        <Field
                            style={{width: '100%', marginRight: '20px'}}
                            label={t("app.main.agency.create.provisionInfo.label7","Provision from 0% - 20%")}
                            onChange={(e: any) => {
                              handleChange(e, item.id)
                            }}
                            variant='outlined'
                            size='small'
                            name='provisionPercentage'
                            value={item.provisionPercentage}
                            type="number"
                            InputProps={{
                              inputProps: {min: 0, max: 20},
                              endAdornment: (
                                  <InputAdornment position="start">%</InputAdornment>
                              )
                            }}
                            as={TextField}
                            onKeyPress={preventMinus}
                            onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                        />
                      </Grid>}
                  {(agentForm.provisionFormType === 'PERCENTAGE_MINIMUM' || agentForm.provisionFormType === 'PERCENTAGE_MINIMUM_MAXIMUM') &&
                      <Grid item xs={gridSize}>
                        <Field
                            style={{width: '100%', marginRight: '20px'}}
                            label={t("app.main.agency.create.provisionInfo.label8","Minimum provision")}
                            onChange={(e: any) => {
                              handleChange(e, item.id)
                            }}
                            type="number"
                            value={item.minimumProvision}
                            InputProps={{inputProps: {min: 0}}}
                            variant='outlined'
                            size='small'
                            name='minimumProvision'
                            as={TextField}
                        />
                      </Grid>}
                  {(agentForm.provisionFormType === 'PERCENTAGE_MAXIMUM' || agentForm.provisionFormType === 'PERCENTAGE_MINIMUM_MAXIMUM') &&
                      <Grid item xs={gridSize}>
                        <Field
                            style={{width: '100%', marginRight: '20px'}}
                            label={t("app.main.agency.create.provisionInfo.label9","Maximum provision")}
                            onChange={(e: any) => {
                              handleChange(e, item.id)
                            }}
                            type="number"
                            value={item.maximumProvision}
                            InputProps={{inputProps: {min: 0}}}
                            variant='outlined'
                            size='small'
                            name='maximumProvision'
                            as={TextField}
                        />
                      </Grid>}
                  {agentForm.provisionFormType === 'FIXED' &&
                      <Grid item xs={3}>
                        <Field
                            style={{width: '100%', marginRight: '20px'}}
                            label={t("app.main.agency.create.provisionInfo.label10","Provizioni për fluturim")}
                            onChange={(e: any) => {
                              handleChange(e, item.id)
                            }}
                            type="number"
                            value={item.fixedProvision}
                            InputProps={{inputProps: {min: 0}}}
                            variant='outlined'
                            size='small'
                            name='fixedProvision'
                            as={TextField}
                        />
                      </Grid>}
                  {agentForm.provisionFormType !== 'FIXED' &&
                      <Grid item xs={gridSize}>
                        <Field
                            style={{width: '100%', marginRight: '20px'}}
                            label={t("app.main.agency.create.provisionInfo.label11","Llogaritni NETO mbi çmimin")}
                            onChange={(e: any) => {
                              handleChange(e, item.id)
                            }}
                            type="number"
                            value={item.neto}
                            InputProps={{inputProps: {min: 0}}}
                            variant='outlined'
                            size='small'
                            name='neto'
                            as={TextField}
                            onKeyPress={preventMinus}
                            onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                        />
                      </Grid>}
                  <Grid item xs={3}>
                    <div style={{display: 'flex'}}>
                      <Field
                          onChange={(e: any) => {
                            handleChange(e, item.id)
                          }}
                          variant='outlined'
                          value={item.currencyId}
                          name='currencyId'
                          label={t("app.main.report.search.currency", "Currency")}
                          select
                          style={{flex: 1, minWidth: 120}}
                          size='small'
                          as={TextField}
                          required
                      >
                        {currencies && currencies.filter(cr => ['EUR', 'CHF'].includes(cr.isoCode)).map((cr: any, idx) => (
                            <MenuItem key={idx} value={cr.id}>{cr.name} - {cr.isoCode}</MenuItem>
                        ))}
                      </Field>
                      <IconButton

                          style={{margin: '0 10px'}}
                          onClick={() => {
                            dispatch(addNewProvision(getDefaultCurrency()));
                          }} size='small'>
                        <Icon>add</Icon>
                      </IconButton>

                      <IconButton
                          onClick={() => {
                            dispatch(removeProvision(item.id));
                          }}
                          size='small'
                          disabled={agentForm.provisionForms.length === 1}
                      >
                        <Icon color='error'>delete</Icon>
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
            )
          })}
        </div>
      </div>
  )
}


export default ProvisionInfo;