import React, {useEffect, useState} from "react";
import {Button, MenuItem, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {addPassengerReturnFlightStyles} from "./add-passenger-return-flight.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {AppState} from "app/store";
import {
  updatePassengerDepartureFlight,
  updatePassengerReturnFlight,
} from "../store/update-passenger.slice";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import DateUtils from "app/utils/DateUtils";
import {fetchAllAirports} from "../../configurations/store/airport/airports-list.slice";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {Column} from "../../../types/TableTypes";
import {FlightResultTypes} from "../../../types/service-types/ScheduleTypes";
import {
  fetchAllFlightResults,
  fetchAllReturnFlightResults, setReturnFlightResults,
  setSelectedFlight,
  setSelectedReturnFlight,
} from "../../schedule/store/flight-results.slice";
import {warningMsg} from "../../../utils/MessageUtils";
import {useTranslation} from "react-i18next";
import {parse} from "date-fns";
import useAuthority from "../../../hooks/useAuthority";
import {ROLES} from "../../../constants/roles";

const AddPassengerReturnFlight = () => {
  const classes = addPassengerReturnFlightStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<any>();
  const {t} = useTranslation();
  // @ts-ignore
  const [returnPrice, setReturnPrice] = useState(Number);
  const [flightPrice, setFlightPrice] = useState(Number);
  const passenger = location?.state?.passenger;
  const flightWay = location?.state?.flightWay;
  const today = new Date();
  const departureFlight = passenger?.departureFlight;
  const returningFlight = passenger?.returningFlight;
  const {hasUserAnyAuthority} = useAuthority();
  const updateLoading = useSelector(
      (state: AppState) => state.schedules.updateSchedule.loading
  );
  const airports = useSelector(
      (state: AppState) =>
          state.configurations.airport.airportsList.airports.content
  );

  const {
    returnFlightResults,
    returnFlightResultsLoading,
    flightResults,
    loading,
    selectedReturnFlight,
    selectedFlight,
  } = useSelector((state: AppState) => state.schedule.flightResults) as any;

  const adminColumn: Column = {
    Header: `${t("app.main.schedules.addPassenger.freeSeats", "Free seats")}`,
    accessor: "numberOfAvailableSeats",
    Cell: ({ cell }: any) => cell.value,
  };

  const columns: Column[] = [
    {
      Header: `${t("app.main.schedules.addPassenger.departureFrom", "Departure From")}`,
      accessor: "departureAirport",
      Cell: ({ cell }: any) => {
        const { departureAirport } = cell.row.original;
        return `${cell.value.name} (${departureAirport.code})`;
      },
    },
    {
      Header: `${t("app.main.schedules.addPassenger.arrivalTo", "Arrival To")}`,
      accessor: "arrivalAirport",
      Cell: ({ cell }: any) =>
          `${cell.value.name} (${cell.row.original.arrivalAirport.code})`,
    },
    {
      Header: `${t("app.main.schedules.addPassenger.dateOfDeparture", "Date of departure")}`,
      accessor: "dateOfDeparture",
      Cell: ({cell}: any) => {
        const {timeOfDeparture} = cell.row.original;
        const [hours, minutes] = timeOfDeparture.split(':');
        const formattedTime = `${hours}:${minutes}`;
        return cell.value + " " + formattedTime;
      },
    },
    {
      Header: `${t("app.main.schedules.addPassenger.priceWithTax", "Price with tax")}`,
      accessor: "availableSectorPrice.price.returnPrices.forAdults",
      Cell: ({ cell }: any) => {
        const { taxOfFlight, currency } = cell.row.original;
        return cell.value + taxOfFlight + " " + currency.symbol;
      },
    },
  ];

  if (hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
    columns.push(adminColumn);
  }

  useEffect(() => {
    dispatch(setReturnFlightResults(null));

      dispatch(fetchAllAirports({size: 9999}));
    if (flightWay === "ONE_WAY") {
      dispatch(
          fetchAllFlightResults({
            queryParams: {
              oneWayPrice: 0,
              returnPrice: 0,
              departureAirportCode: passenger?.departureFlight?.departureAirport?.code,
              arrivalAirportCode: passenger?.departureFlight?.arrivalAirport?.code,
              dateOfDeparture: DateUtils.convertDate(passenger?.departureFlight?.dateOfDeparture),
            },
          })
      );
    } else {
      dispatch(
          fetchAllReturnFlightResults({
            queryParams: {
              oneWayPrice: 0,
              returnPrice: 0,
              departureAirportCode: passenger?.returningFlight?.departureAirport?.code,
              arrivalAirportCode: passenger?.returningFlight?.arrivalAirport?.code,
              dateOfDeparture: DateUtils.convertDate(passenger?.departureFlight?.dateOfDeparture)
            },
          })
      );
    }
  }, []);

  function parseDateFromString(dateStr: string): Date {
    // Assuming the date format is "DD/MM/YYYY"
    const [day, month, year] = dateStr.split('/').map(Number);
    return parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
  }

  const onReturnFlightSelect = (flight: FlightResultTypes) => {
    dispatch(setSelectedReturnFlight(flight));
    if (passenger?.ageCategory === "ADULT") {
      setReturnPrice(
          parseFloat(`${flight?.sectorPrices?.[0].price?.returnPrices.forAdults}`)
      );
    }
    if (passenger?.ageCategory === "CHILD") {
      setReturnPrice(
          parseFloat(
              `${flight?.sectorPrices?.[0].price?.returnPrices.forChildren}`
          )
      );
    }
    if (passenger?.ageCategory === "INF") {
      setReturnPrice(
          parseFloat(
              `${flight?.sectorPrices?.[0].price?.returnPrices.forInfants}`
          )
      );
    }
  };

  const onDepratureFlightSelect = (flight: FlightResultTypes) => {
    dispatch(setSelectedFlight(flight));
    if (passenger?.ageCategory === "ADULT") {
      setFlightPrice(
          parseFloat(`${flight?.sectorPrices?.[0].price?.oneWayPrices.forAdults}`)
      );
    }
    if (passenger?.ageCategory === "CHILD") {
      setFlightPrice(
          parseFloat(
              `${flight?.sectorPrices?.[0].price?.oneWayPrices.forChildren}`
          )
      );
    }
    if (passenger?.ageCategory === "INF") {
      setFlightPrice(
          parseFloat(
              `${flight?.sectorPrices?.[0].price?.oneWayPrices.forInfants}`
          )
      );
    }
  };

  const getSymbolCurrencyReturnFlight = (flight: any) => {
    return flight?.currency?.symbol;
  };

  const validatePrice = (e: any) => {
    if (e.target.value === "") {
      e.target.setCustomValidity("Shënoni çmimin");
    } else if (e.target.validity.patternMismatch) {
      e.target.setCustomValidity("Çmimi duhet të jetë numër");
    } else if (e.target.value === "0") {
      e.target.setCustomValidity("Çmimi nuk duhet të jetë 0");
    } else {
      e.target.setCustomValidity("");
    }

    return true;
  };

  const onSuccess = () => {
    dispatch(toggleQuickPanel());
    // if (flightWay === "ONE_WAY") {
    //   dispatch(
    //       updateStatusPassenger({
    //         reservationId,
    //         travelerId: passenger.id,
    //         status: true,
    //       })
    //   );
    // }
    history.goBack();
  }

  const addPassengerReturnFlight = () => {
    if (!selectedReturnFlight && flightWay !== "ONE_WAY") {
      dispatch(warningMsg(localStorage.getItem('i18nextLng') === 'en' ? 'You have not selected a flight!' : 'Nuk keni selektuar fluturimin!'));
      return;
    }
    if (!selectedFlight && flightWay === "ONE_WAY") {
      dispatch(warningMsg(localStorage.getItem('i18nextLng') === 'en' ? 'You have not selected a flight!' : 'Nuk keni selektuar fluturimin!'));
      return;
    }

    if (selectedFlight) {
      const params = {
        id: passenger.id,
        body: {
          departureFlightId: selectedFlight.id
        },
        onSuccess
      }
      dispatch(updatePassengerDepartureFlight(params));
      dispatch(setSelectedFlight(null));
    } else if (selectedReturnFlight) {
      const params = {
        id: passenger.id,
        body: {
          returnFlightId: selectedReturnFlight.id
        },
        onSuccess
      }
      dispatch(updatePassengerReturnFlight(params));
      dispatch(setSelectedReturnFlight(null));
    }
  };

  const searchFlights = (data: any) => {
    if (flightWay === "ONE_WAY") {
      dispatch(setSelectedFlight(null));
      dispatch(
          fetchAllFlightResults({
            queryParams: {
              oneWayPrice: 0,
              returnPrice: 0,
              departureAirportId: data.returnFlightDepartureAirportId,
              arrivalAirportId: data.returnFlightArrivalAirportId,
              dateOfDeparture: DateUtils.convertDate(
                  data.returnFlightDateOfDeparture
              ),
              additionalSeats: data.additionalSeats,
              price: {
                departureFlightPrice: passenger?.price?.departureFlightPrice,
                returningFlightPrice: data.price?.returningFlightPrice,
              },
            },
          })
      );
    } else {
      dispatch(setSelectedReturnFlight(null));
      dispatch(
          fetchAllReturnFlightResults({
            queryParams: {
              oneWayPrice: 0,
              returnPrice: 0,
              departureAirportId: data.returnFlightDepartureAirportId,
              arrivalAirportId: data.returnFlightArrivalAirportId,
              dateOfDeparture: DateUtils.convertDate(
                  data.returnFlightDateOfDeparture
              ),
              additionalSeats: data.additionalSeats,
              price: {
                departureFlightPrice: passenger?.price?.departureFlightPrice,
                returningFlightPrice: data.price?.returningFlightPrice,
              },
            },
          })
      );
    }
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "35px"}}>
          <h1 style={{color: "#0D3B66"}}>
            {t("app.main.schedules.addPassengerReturnFlight.title1", "Book a flight")} {flightWay === "ONE_WAY" ? `${t("app.main.schedules.addPassengerReturnFlight.title2", "outgoing")}` : `${t("app.main.schedules.addPassengerReturnFlight.title3", "return")}`} {t("app.main.schedules.addPassengerReturnFlight.title4", "for passenger")}:{" "}
            <span style={{fontWeight: 500}}>
            {passenger?.name} {passenger?.surname}
          </span>
          </h1>
        </div>

        <Formik
            initialValues={{
              additionalSeats: 0,
              returnFlightDepartureAirportId:
              passenger?.departureFlight?.arrivalAirport?.id,
              returnFlightArrivalAirportId:
              passenger?.departureFlight?.departureAirport?.id,
              returnFlightDateOfDeparture: flightWay === "ONE_WAY"
                  ? new Date().toISOString()
                  : DateUtils.toISODate(
                      passenger?.departureFlight?.dateOfDeparture
                  ),
              price: {
                departureFlightPrice: passenger?.price?.departureFlightPrice,
                returningFlightPrice: 0,
              },
            }}
            onSubmit={(data) => {
              searchFlights(data);
            }}
        >
          {({values, setValues}) => (
              <Form>
                <Grid container spacing={4} style={{marginBottom: "30px"}}>
                  <Grid item xs={9}>
                    <Grid container spacing={4}>
                      <Grid item xs={3}>
                        <Field
                            style={{width: "100%"}}
                            variant="outlined"
                            value={values.returnFlightDepartureAirportId}
                            name="returnFlightDepartureAirportId"
                            label={t("app.main.schedules.addPassenger.flightFrom", "Flight from")}
                            select
                            as={TextField}
                        >
                          {airports
                          .filter(
                              (ap: Airport) =>
                                  ap.id !== values.returnFlightArrivalAirportId
                          )
                          .map((ap: Airport, idx) => (
                              <MenuItem
                                  key={ap.id}
                                  id={idx + ap.code}
                                  value={ap.id}
                              >
                                {ap.code} - {ap.name}
                              </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                            style={{width: "100%"}}
                            variant="outlined"
                            value={values.returnFlightArrivalAirportId}
                            name="returnFlightArrivalAirportId"
                            label={t("app.main.schedules.addPassenger.towardsTheAirport", "Towards the airport")}
                            select
                            as={TextField}
                        >
                          {airports
                          .filter(
                              (ap: Airport) =>
                                  ap.id !== values.returnFlightDepartureAirportId
                          )
                          .map((ap: Airport, idx) => (
                              <MenuItem
                                  key={ap.id}
                                  id={ap.code + idx}
                                  value={ap.id}
                              >
                                {ap.code} - {ap.name}
                              </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                              inputVariant="outlined"
                              disableToolbar
                              autoOk
                              variant="inline"
                              minDate={today}
                              format="dd/MM/yyyy"
                              id="date-picker-inline"
                              label={t("app.main.schedules.addPassenger.dateOfDeparture", "Date of departure")}
                              value={values.returnFlightDateOfDeparture}
                              style={{width: "100%"}}
                              onChange={(date) => {
                                if (date) {
                                  setValues({
                                    ...values,
                                    returnFlightDateOfDeparture: date.toISOString(),
                                  });
                                }
                              }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={3}>
                        <Field
                            style={{width: "100%"}}
                            variant="outlined"
                            onChange={(e: any) => {
                              validatePrice(e);
                              flightWay !== "ONE_WAY" ? setReturnPrice(e.target.value) : setFlightPrice(e.target.value)
                            }}
                            value={flightWay !== "ONE_WAY" ? returnPrice : flightPrice}
                            name="price"
                            label={t("app.main.schedules.addPassenger.price", "Price")}
                            as={TextField}
                            inputProps={{pattern: "[0-9]*"}}
                            required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} direction="column">
                    <Field
                        style={{width: "100%"}}
                        variant="outlined"
                        value={values.additionalSeats}
                        name="additionalSeats"
                        label={t("app.main.schedules.addPassengerReturnFlight.additionalSeats", "Additional seats")}
                        select
                        as={TextField}
                    >
                      {Array.from(new Array(10)).map((_, idx) => (
                          <MenuItem key={`additionalSeats_${idx}`} value={idx}>
                            {idx}
                          </MenuItem>
                      ))}
                    </Field>
                    <div style={{marginTop: "14px", float: "right"}}>
                      <LoadingButton
                          btnTitle={t("app.main.schedules.addPassenger.searchFlights", "Search Flight")}
                          type="submit"
                          isLoading={false}
                          variant="outlined"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Form>
          )}
        </Formik>

        <Grid container>
          {flightWay !== "ONE_WAY" ? (
              <BaseTable
                  columns={columns}
                  data={returnFlightResults?.content}
                  onClickTableRow={onReturnFlightSelect}
                  isLoading={returnFlightResultsLoading}
                  disableRow={(row: FlightResultTypes) =>
                      row.numberOfAvailableSeats === 0 || parseDateFromString(row.dateOfDeparture) < parseDateFromString(departureFlight?.dateOfDeparture)
                  }
                  selectedRow={(row: FlightResultTypes) =>
                      row.id === selectedReturnFlight?.id
                  }
                  activeRow={(row: FlightResultTypes) => row.departureAirport?.code === returningFlight?.departureAirport?.code
                      && row.arrivalAirport.code === returningFlight?.arrivalAirport?.code
                      && row.dateOfDeparture === returningFlight?.dateOfDeparture
                      && row.timeOfDeparture === returningFlight?.timeOfDeparture}
              />
          ) : (
              <BaseTable
                  columns={columns}
                  data={flightResults?.content}
                  onClickTableRow={onDepratureFlightSelect}
                  isLoading={loading}
                  selectedRow={(row: FlightResultTypes) =>
                      row.id === selectedFlight?.id
                  }
                  activeRow={(row: FlightResultTypes) => row.departureAirport?.code === departureFlight.departureAirport?.code
                      && row.arrivalAirport?.code === departureFlight.arrivalAirport?.code
                      && row.dateOfDeparture === departureFlight.dateOfDeparture
                      && row.timeOfDeparture === departureFlight.timeOfDeparture}
              />
          )}

          <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                padding: "15px",
                background: "#e7e7e7",
              }}
          >
            {/* <p style={{ marginRight: '5px' }}>Bileta shkuese: <span style={{ fontWeight: 500 }}>24.00 $</span> |</p> */}
            <p style={{marginRight: "5px"}}>
              {t("app.main.schedules.table.result1", "Ticket")}: {" "}
              <span style={{fontWeight: 500}}>
              {flightWay !== "ONE_WAY"
                  ? returnPrice
                  : flightPrice}{" "}
                {getSymbolCurrencyReturnFlight(selectedReturnFlight)}
            </span>{" "}
              |
            </p>
            <p style={{marginRight: "5px"}}>
              {t("app.main.schedules.table.result2", "Taxes")}: {" "}
              <span style={{fontWeight: 500}}>
              {flightWay !== "ONE_WAY"
                  ? selectedReturnFlight?.taxOfFlight || 0
                  : selectedFlight?.taxOfFlight || 0}{" "}
                {getSymbolCurrencyReturnFlight(selectedReturnFlight)}
            </span>{" "}
              |
            </p>
            <p style={{marginRight: "5px"}}>
              {t("app.main.schedules.table.result3", "Total ticket")}: {" "}
              <span style={{fontWeight: 500}}>
              {flightWay === "ONE_WAY" ?
                  flightPrice + selectedFlight?.taxOfFlight
                  : returnPrice + selectedReturnFlight?.taxOfFlight || 0
              }{" "}
                {getSymbolCurrencyReturnFlight(selectedReturnFlight)}
            </span>
            </p>
            {/* <p>Diferenca: <span style={{ fontWeight: 500 }}>0.00 $</span></p> */}
          </div>
        </Grid>

        <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{marginTop: "20px"}}
        >
          <Grid item>
            <LoadingButton
                type="button"
                btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                size="medium"
                isLoading={updateLoading}
                onClick={() => addPassengerReturnFlight()}
            />
            <Button
                onClick={(e) => {
                  dispatch(setSelectedReturnFlight(null));
                  dispatch(toggleQuickPanel());
                  history.goBack();
                }}
            >
              {t("app.main.schedules.splitPassenger.closeButton", "Close")}
            </Button>
          </Grid>
        </Grid>
      </div>
  );
};

export default AddPassengerReturnFlight;
