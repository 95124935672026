import {PageConfigTypes} from "app/types/PageTypes";
import CustomerPage from "./CustomerPage";
import CustomerList from "./customers-list/CustomersList";
import {ROLES} from "app/constants/roles";

const CustomerPageConfig: PageConfigTypes = {
  baseRoute: {
    path: '/customer',
    exact: true,
    component: CustomerPage,
    authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]
  },
  subRoutes: [
    {
      path: '/customer/customers-list',
      component: CustomerList
    }
  ],
  settings: {}
}

export default CustomerPageConfig;