import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import DateUtils from "app/utils/DateUtils";
import {
  FilterPaymentsQueryParams
} from "../../../types/service-types/AgentTypes";
import {fetchAllPayments} from "./payment-list.slice";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] === "ALL") {
      } else newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const filterPayments =
    (id: string, queryParams: FilterPaymentsQueryParams) => async (dispatch: Dispatch) => {
      dispatch(setFilters(queryParams));
      // @ts-ignore
      dispatch(fetchAllPayments(id, {status: queryParams.status,currencyId: queryParams.currencyId, ...removeFalsyAttributes(queryParams)}));
    };

export const clearFilterPayments = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterPaymentsQueryParams = {
  currencyId: "",
  fromDate: "",
  toDate: "",
  status: 'ALL'
};

const stateSlice = createSlice({
  name: "agentedit/payment-filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterPaymentsQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;