import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AgentService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";
import {AgentTypes} from 'app/types/service-types/AgentTypes';

interface GetAgentParams {
  id: string;
  onSuccess?: Function;
  onError?: Function;
}

export const getAgent = (params: GetAgentParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await AgentService.getAgentById(params.id);
    dispatch(setAgent(response));
    if (params.onSuccess) params.onSuccess(response);
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface AgentSlice {
  agent: AgentTypes | null;
  loading: boolean;
}

const initialState: AgentSlice = {
  agent: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'agent/agent',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAgent: (state, action) => {
      state.agent = action.payload
    }
  }
});

export const {setLoading, setAgent} = stateSlice.actions;

export default stateSlice.reducer;