import {makeStyles} from "@material-ui/styles";
import {Theme} from '@material-ui/core';

interface IProps {
  isNavbarOpen: boolean | undefined;
}

const drawerWidth = 420;

export default makeStyles<Theme, IProps>((theme) => ({
  drawerPaper: {
    width: '420px',
    padding: '0 0 0 160px',
    ['@media (max-width:1450px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 0 0 80px;',
      width: '300px',
    },
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 0 0 20px;',
      width: '240px',
    }
  },
  drawerContainer: {
    overflow: 'auto',
  },
  drawer: {
    width: '260px',
    ['@media (max-width:1450px)']: { // eslint-disable-line no-useless-computed-key
      width: '220px',
    },
    ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
      width: '155px',
      padding: '0px',
      margin: '0px'
    },
    ['@media (max-width:375px)']: { // eslint-disable-line no-useless-computed-key
      width: '140px',
      padding: '0px',
      margin: '0px'
    },
    ['@media (max-width:320px)']: { // eslint-disable-line no-useless-computed-key
      width: '114px',
      padding: '0px',
      margin: '0px'
    },
    // ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
    //   width: '180px',
    // },
    position: ({isNavbarOpen}) => isNavbarOpen ? "relative" : "absolute"
  },
  header: {
    padding: theme.spacing(1.5, 2)
  },
  content: {}
}));