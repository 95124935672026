import {AccountTypes} from 'app/types/service-types/AccountTypes';
import axios from './axios.interceptors';

class AccountServices {
  fetchAccount = async (): Promise<AccountTypes> => {
    try {
      const response = await axios.get('/api/account');
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new AccountServices();