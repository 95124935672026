import React from 'react';
import {StylesProvider, ThemeProvider} from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import store from './store';
import {generateClassName, theme} from './theme.config';

import {Router, Switch} from 'react-router-dom';
import history from '@history';
import AppContext from './AppContext';
import AdminLayout from 'app/main/layout/AdminLayout';
import pagesConfigsDefault from 'app/configs/pages.config';


import Auth from "./auth/Auth";
import {PageConfigTypes} from "./types/PageTypes";

const App = () => {
  const [pagesConfigs, setPagesConfigs] = React.useState<PageConfigTypes[]>(pagesConfigsDefault);

  return (
      <AppContext.Provider
          value={{pagesConfigs, setPagesConfigs}}
      >
        <Provider store={store}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <Auth>
                <Router history={history}>
                  <Switch>
                    <AdminLayout/>
                  </Switch>
                </Router>
              </Auth>
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </AppContext.Provider>
  );
}

export default App;
