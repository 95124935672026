import {createGenerateClassName, createMuiTheme} from "@material-ui/core/styles";

export const generateClassName = createGenerateClassName();

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Roboto"',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#0d3b66',
      light: '#12518C',
    },
    secondary: {
      // main: '#ff671e'
      main: '#fff'
    }
  }
});
