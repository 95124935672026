import React, {useEffect, useState} from 'react';
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import history from '@history';
import {toggleQuickPanel} from 'app/main/shared-components/quick-panel/store';
import {useDispatch, useSelector} from "react-redux";
import {Column, TableActionsTypes} from "app/types/TableTypes";
import {AppState} from "app/store";
import {useRouteMatch} from "react-router-dom";
import {deleteUser} from "../store/delete-user.slice";
import TableActionButtons from "app/main/shared-components/base-table/TableActionButtons";
import ModalDialog from "app/main/shared-components/modal-dialog/ModalDialog";
import EmployeeTable from "../../shared-components/employee-table/EmployeeTable";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import {filterEmployee} from "../store/filter-user-employee.slice";
import {fetchAllEmployeeUsers} from "../store/user-employees-list.slice";
import {useTranslation} from "react-i18next";

const EmployeesList = () => {
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {t} = useTranslation();

  const [modal, setModal] = useState({state: false, deleteId: ''});

  const {allEmpUsers, loading} = useSelector((state: AppState) => state.employees.allEmpUsers);

  const tableActions: TableActionsTypes = {
    // onViewRow: (rowId: number) =>  alert('row: ' + rowId),
    onEditRow: (rowId: number) => {
      dispatch(toggleQuickPanel());
      history.push(`${url}/update/${rowId}`);
    },
    onDeleteRow: (rowId: string) => {
      setModal({state: true, deleteId: rowId});
    }
  }

  const columns: Column[] = [
    {
      Header: 'Emri i plotë',
      accessor: 'fullName'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Grupi i punonjësit',
      accessor: 'workerGroup'
    },
    {
      Header: 'Koha e punës',
      accessor: 'workTime'
    },
    {
      Header: 'Lokacioni',
      accessor: 'location'
    },
    {
      Header: 'Adresa e punonjësit',
      accessor: 'address'
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({cell}: any) => (
          <TableActionButtons {...tableActions} rowId={cell.value}/>
      )
    }
  ];

  useEffect(() => {
    dispatch(fetchAllEmployeeUsers());
  }, []);

  return (
      <div>
        <PageHeader
            title={t("app.main.employees.employeeList.title", "All employees")}
            desc={t("app.main.employees.employeeList.desc", "List of employees")}
            button={t("app.main.employees.employeeList.addEmployee", "Add employee")}
            onClickButton={() => {
              history.push('/employees/employees-list/create');
              dispatch(toggleQuickPanel())
            }}
        />
        <ModalDialog
            open={modal.state}
            title='Are you sure you want to delete this?'
            onOk={() => {
              dispatch(deleteUser({id: modal.deleteId}));
              setModal({state: false, deleteId: ''});
            }}
            onCancel={() => setModal({state: false, deleteId: ''})}/>
        <EmployeeTable data={allEmpUsers} loading={loading}/>
        <PaginationBar totalPages={allEmpUsers?.totalPages}
                       onPageChange={(page: number) =>
                           dispatch(filterEmployee({...filterEmployee, page}))
                       }/>
      </div>
  );
};

export default EmployeesList;