import React, {useEffect, useState} from "react";
import {schedulesUpdateFormStyles} from "../schedules.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {addPayment} from "../store/add-payment.slice";
import {fetchReservationPayments} from "../store/payments-list.slice";
import {Column} from "app/types/TableTypes";
import BaseTable from "app/main/shared-components/base-table/BaseTable";
import {Field, Form, Formik} from "formik";
import {Button, MenuItem, TextField} from "@material-ui/core";
import {AppState} from "app/store";
import {ReservationType,} from "app/types/service-types/ReservationTypes";
import {
  fetchAllPaymentMethods
} from "../../configurations/store/paymentmethods/paymentmethods-list.slice";
import DateUtils from "../../../utils/DateUtils";
import {useTranslation} from "react-i18next";

const AddPayment = () => {
  const [deptAmount, setDeptAmount] = useState<any>();
  const classes = schedulesUpdateFormStyles();
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();

  const reservationId = params?.id;

  const {reservationPayments, loading} = useSelector(
      (state: AppState) => state.schedules.reservationPayments
  );
  const {allReservations} = useSelector(
      (state: AppState) => state.schedules.schedulesList
  );
  const {allPaymentMethods} = useSelector(
      (state: AppState) => state.configurations.paymentmethods.paymentmethodsList
  );

  useEffect(() => {
    dispatch(fetchAllPaymentMethods());
  }, []);

  let selectedReservation: ReservationType | undefined;

  useEffect(() => {
    selectedReservation = allReservations?.content.find(
        (res) => res.id === reservationId
    );
    setDeptAmount(selectedReservation?.invoice.debtAmount);
    dispatch(fetchReservationPayments(reservationId));
  }, []);

  const columns: Column[] = [
    {
      Header: `${t("app.main.schedules.payments.transactionId", "Transaction ID")}`,
      accessor: "transactionId",
    },
    {
      Header: `${t("app.main.schedules.payments.paymentMethod", "Payment method")}`,
      accessor: "method.name",
    },
    {
      Header: `${t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell1", "Full name")}`,
      accessor: "fullName",
    },
    {
      Header: `${t("app.main.schedules.payments.notes", "Notes")}`,
      accessor: "notes",
    },
    {
      Header: `${t("app.main.schedules.payments.date", "Date")}`,
      accessor: "date",
      Cell: ({cell}: any) => {
        return DateUtils.toHumanDate(
            DateUtils.convertDate(cell.value),
            new Date(cell.value).toLocaleTimeString())
      },
    },
    {
      Header: `${t("app.main.schedules.payments.type", "Type")}`,
      accessor: "type",
    },
    {
      Header: `${t("app.main.schedules.payments.amount", "Amount")}`,
      accessor: "amount",
    },
  ];
  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: "#0D3B66"}}>{t("app.main.schedules.payments.title", "Invoice payments")}</h1>
        </div>
        <div>
          <BaseTable
              columns={columns}
              data={reservationPayments}
              isLoading={loading}
          />
        </div>
        <Formik
            initialValues={{
              amount: 0,
              paymentMethodId: "",
              notes: "",
            }}
            onSubmit={(data) => {
                const {amount, paymentMethodId, notes} = data;

                dispatch(
                    addPayment({
                      id: reservationId,
                      body: {
                        amount,
                        paymentMethodId,
                        notes,
                      },
                    })
                );
                dispatch(toggleQuickPanel());
                history.goBack();
              }
            }
        >
          {({values}) => (
              <Form>
                <div className={classes.row}>
                  <Field
                      variant="outlined"
                      value={values.paymentMethodId}
                      name="paymentMethodId"
                      label={t("app.main.schedules.payments.paymentMethod", "Payment method")}
                      select
                      as={TextField}
                      required
                      disabled={deptAmount === 0}


                  >
                    {allPaymentMethods
                        .filter(x => x.status === true)
                        .map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                    ))}
                  </Field>
                  <Field
                      variant="outlined"
                      value={values.amount}
                      name="amount"
                      type="number"
                      //InputProps={{inputProps: {min: 1, max: deptAmount}}}
                      label={t("app.main.schedules.sharedComponents.collapsibleTableRow.payment", "Payment")}
                      as={TextField}
                      required
                      disabled={deptAmount === 0}

                  />
                </div>
                <div className={classes.row}>
                  <Field
                      variant="outlined"
                      value={values.notes}
                      name="notes"
                      type="textarea"
                      multiline
                      rows={3}
                      label={t("app.main.schedules.payments.notes", "Notes")}
                      as={TextField}
                      disabled={deptAmount === 0}
                  />
                </div>
                <div className={classes.row}>
                  {(deptAmount > 0 || deptAmount < 0) && <LoadingButton
                      type="submit"
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={false}
                  />}

                  <Button
                      onClick={() => {
                        dispatch(toggleQuickPanel());
                        history.goBack();
                      }}
                  >
                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                  </Button>

                </div>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default AddPayment;
