import React from 'react';
import Grid from "@material-ui/core/Grid";
import {
  Icon,
  IconButton,
  TextField
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useTranslation} from "react-i18next";
import {useStyles} from "../../../schedules/schedules.styles";
import {filterProvisions} from "../../store/filter-provision.slice";
import {useRouteMatch} from "react-router-dom";
import useAuthority from "../../../../hooks/useAuthority";
import {ROLES} from "../../../../constants/roles";

const AgentProvisionSearchPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {params}: any = useRouteMatch();
  const filters = useSelector((state: AppState) => state.agents.filterProvision);
  const {hasUserAnyAuthority} = useAuthority();
  const account = useSelector((state: AppState) => state.account.account);

  const handleChange = (e: any) => {
    if (hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
      dispatch(filterProvisions(params.id, {
        ...filters,
        [e.target.name]: e.target.value
      }));
    }
    if (hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
      dispatch(filterProvisions(account.account!.id, {
        ...filters,
        [e.target.name]: e.target.value
      }));
    }
  }

  return (
      <Grid
          container
          direction="row"
          justify="flex-start"
          className={classes.root}
          style={{margin: '1rem 0', display: 'flex', alignItems: 'center'}}
          alignItems="flex-start"
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          paddingRight: '20px',
          paddingLeft: '20px'
        }}>
          <TextField
              label={t("app.main.schedules.searchPanel.datepicker.from", "From date")}
              type="date"
              name='fromDate'
              value={filters.fromDate ? filters.fromDate : ''}
              onChange={handleChange}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {max: "9999-12-12"}
              }}
          />
          {filters.fromDate && (<>
            &nbsp;
            <IconButton style={{position: 'absolute', right: '1px'}} onClick={() => handleChange({
              target: {name: 'fromDate', value: ''}
            })} size='small'>
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          paddingRight: '20px'
        }}>
          <TextField
              label={t("app.main.schedules.searchPanel.datepicker.to", "To date")}
              type="date"
              name='toDate'
              value={filters.toDate ? filters.toDate : ''}
              onChange={handleChange}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {max: "9999-12-12"}
              }}
          />
          {filters.toDate && (<>
            &nbsp;
            <IconButton style={{position: 'absolute', right: '1px'}} onClick={() => handleChange({
              target: {name: 'toDate', value: ''}
            })} size='small'>
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
      </Grid>
  );
};

export default AgentProvisionSearchPanel;
