import axios from './axios.interceptors';
import {SmsTemplate, SmsTemplateBody} from "../types/SmsTemplateTypes";

class SmsTemplatesServices {
  getAll = async (): Promise<SmsTemplate[]> => {
    try {
      const response = await axios.get('/api/configurations/sms-templates');
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  createSmsTemplate = async (body: SmsTemplateBody): Promise<any> => {
    try {
      return await axios.post('/api/configurations/sms-templates', body);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  updateSmsTemplate = async (id: string, body: SmsTemplateBody): Promise<any> => {
    try {
      return await axios.put(`/api/configurations/sms-templates/${id}`, body);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  deleteSmsTemplate = async (id: string): Promise<any> => {
    try {
      return await axios.delete(`/api/configurations/sms-templates/${id}`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  makeSmsForReservation = async (reservationId: string, smsTemplateId: string): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${reservationId}/sms/${smsTemplateId}`)
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default new SmsTemplatesServices();