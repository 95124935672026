import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import {resetAgentForm} from "../store/agent-form.slice";
import PageHeader from "../../shared-components/page-header/PageHeader";
import {useTranslation} from "react-i18next";
import AgentSearchPanel from "./search-panel/AgentSearchPanel";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import AgentPaymentTable from "./agent-table/AgentPaymentTable";
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import {fetchAllPayments} from "../store/payment-list.slice";
import {clearFilterPayments, filterPayments} from "../store/filter-payment.slice";

export interface PaymentListType {
  currencyResponse: {
    id: string;
    isoCode: string;
    name: string;
    symbol: string;
  }
}

const AgentPaymentGeneric = (props: PaymentListType) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  let {url} = useRouteMatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const {paymentList, loading} = useSelector((state: AppState) => state.agents.paymentList);
  const filterPayment = useSelector((state: AppState) => state.agents.filterPayment);
  const balances = useSelector((state: AppState) => state.agents.agent.agent?.balances);
  const currentBalance = balances?.find((balance) => balance.currencyResponse.id == params.currencyId);

  useEffect(() => {
    dispatch(clearFilterPayments());
    dispatch(filterPayments(params.id, {...filterPayment,currencyId: params.currencyId}));
    dispatch(fetchAllPayments(params.id, {currencyId: params.currencyId, status: 'ALL'}));
  }, [params])

  if (agentLoading) {
    return <LoadingForm/>;
  }

  return (
      <div>
        <PageHeader
            title={`${t("app.main.agency.edit.payment.title1", "Agent Payment")} ${agentForm.fullName} 
            => ${t("app.main.agency.edit.payment.title2", "Credit")}: ${currentBalance?.creditLimit} ${currentBalance?.currencyResponse.isoCode}
             & ${t("app.main.agency.edit.payment.title3", "Current Debt")}: ${paymentList?.total} ${currentBalance?.currencyResponse.isoCode}`}
            desc={t("app.main.agency.edit.payment.desc", "History of payment transactions")}
            backButton={() => {
              dispatch(resetAgentForm());
              history.push("/agents-agencies/list");
            }}
            button={t("app.main.agency.agentsList.registerButton", "Register")}
            onClickButton={() => {
              history.push(`${url}/createPayment`);
              dispatch(toggleQuickPanel());
            }}
        />
        <AgentSearchPanel/>
        <AgentPaymentTable data={paymentList} loading={loading}/>
      </div>
  );
};

export default AgentPaymentGeneric;