import React, {useEffect, useState} from "react";
import {Button, TextareaAutosize} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import Grid from "@material-ui/core/Grid";
import {
  AddDisquisitionsTypes,
  DisquisitionsTypes,
} from "../../../types/service-types/ReservationTypes";
import {disquisitionsStyles} from "./disquisitions.styles";
import {ReservationService} from "app/services";
import {errorMsg, successMsg} from "../../../utils/MessageUtils";
import {filterSchedules} from "../store/filter-schedules.slice";
import {AppState} from "app/store";
import DateUtils from "../../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Icon} from "@material-ui/core";
import EditIcon from "../../shared-components/edit-icon/EditIcon";
import {useTranslation} from "react-i18next";
import useAuthority from "../../../hooks/useAuthority";
import {ROLES} from "../../../constants/roles";

const Disquisitions = () => {
  const classes = disquisitionsStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const location = useLocation<any>();
  const {t} = useTranslation();
  const reservationId = params?.id;
  const disquisitions = location?.state?.disquisitions;
  const {hasUserAnyAuthority} = useAuthority();
  const [addDisquisitionLoading, setAddDisquisitionLoading] = useState(false);
// permissions that admin give the agent
  const scopes = useSelector((state: AppState) => state.account.account.account?.scopes);
  const filters = useSelector(
      (state: AppState) => state.schedules.filterSchedules
  );

  const [editInput, setEditInput] = useState('');
  const [hasAddDisquisition, setHasAddDisquisition] = useState(false);

  const addDisquisition = async (params: any) => {
    try {
      setAddDisquisitionLoading(true);
      const response = await ReservationService.addDisquisition(
          params.id,
          params.body
      );

      if (response.status >= 200 || response.status < 300) {
        dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Comment added successfully!' : 'Komenti u shtua me sukses!'));
        if (params.onSuccess) params.onSuccess();
        dispatch(
            filterSchedules({
              ...filters,
              page: 0,
            })
        );
      }
      setAddDisquisitionLoading(false);
    } catch (err) {
      setAddDisquisitionLoading(false);
      dispatch(errorMsg(err.data.message));
      if (params.onError) params.onError();
    }
  };

  const submit = async (data: AddDisquisitionsTypes) => {
    const params = {
      id: reservationId,
      body: {
        message: data.message,
      },
      onSuccess: () => {
        dispatch(toggleQuickPanel());
        history.goBack();
      },
    };

    await addDisquisition(params);
  };

  useEffect(() => {
    CheckAddDisquisition();
  }, [scopes]);

  const CheckAddDisquisition = () => {
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_CALL_AGENT])) {
      scopes.map((x: any) => {
        if (x === "ADD_DISCUSSION") {
          setHasAddDisquisition(true);
        }
      })
    }
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
      setHasAddDisquisition(true);
    }
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
      setHasAddDisquisition(true);
    }
  }

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "35px"}}>
          <h1 style={{color: "#0D3B66"}}>
            {t("app.main.disquisitions.title", "Employee comments about the bill")}
          </h1>
        </div>

        <div className={classes.disquisitionsWrapper}>
          {disquisitions.map((item: DisquisitionsTypes, index: number) => {
            return (
                <div>
                  {index + 1 + "). "}
                  <span style={{fontWeight: 600}}>{item.fullName}</span>
                  {": "}
                  <span>{item.message}</span>
                  <IconButton size='small'
                              onClick={() => {
                                setEditInput(item.message);
                              }}>
                    <Icon>
                      <EditIcon/>
                    </Icon>
                  </IconButton>
                  <br/>
                  <span style={{fontSize: "11px"}}>
                {DateUtils.toHumanDate(
                    DateUtils.convertDate(item.createdAt),
                    new Date(item.createdAt).toLocaleTimeString()
                )}
              </span>
                </div>
            );
          })}
        </div>

        <Formik
            initialValues={{
              message: "",
            }}
            onSubmit={(data: any) => {
              submit(data);
            }}
        >
          {({values, setValues}) => (
              <Form>
                <Field
                    variant="outlined"
                    name="message"
                    label="Marrësi i pagesës"
                    render={() => (
                        <TextareaAutosize
                            required
                            rowsMin={6}
                            rowsMax={6}
                            style={{
                              border: "1px solid rgb(133, 133, 133)",
                              padding: "10px 20px",
                              width: "100%",
                              marginTop: "25px",
                            }}
                            defaultValue={editInput}
                            value={editInput}
                            onChange={(e: any) => {
                              setEditInput(e.target.value);
                              setValues({
                                ...values,
                                message: e.target.value,
                              })
                            }}
                            aria-label="empty textarea"
                            placeholder={t("app.main.disquisitions.textarea", "Write a memo")}
                        />
                    )}
                />

                <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{marginTop: "20px"}}
                >
                  <Grid item>
                    {hasAddDisquisition ?
                        <LoadingButton
                            type="submit"
                            btnTitle={t("app.main.disquisitions.addMemo", "Add memo")}
                            size="medium"
                            isLoading={addDisquisitionLoading}
                        />
                        :
                        false
                    }

                    <Button
                        onClick={() => {
                          dispatch(toggleQuickPanel());
                          history.goBack();
                        }}
                    >
                      {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default Disquisitions;
