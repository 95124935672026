import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {CountryTypes} from 'app/types/service-types/CountryTypes';
import {CountryService} from 'app/services';

export const fetchAllCountries = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CountryService.fetchAll();
    dispatch(setCountries(response));
    dispatch(setLoading(false));
  } catch (err) {
    console.log(err);
  }
}

interface CountriesListSlice {
  countries: CountryTypes[];
  loading: boolean;
}

const initialState: CountriesListSlice = {
  countries: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'app/country',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCountries: (state, action) => {
      state.countries = action.payload
    }
  }
});

export const {setLoading, setCountries} = stateSlice.actions;

export default stateSlice.reducer;