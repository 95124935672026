import axios from './axios.interceptors';
import {
    FilterReportsQueryParams,
    PaymentMethods,
    PaymentMethodsRequestBoyd,
    PaymentRequestBody
} from "app/types/service-types/PaymentMethodsTypes";

class PaymentMethodsServices {

    fetchAll = async (): Promise<PaymentMethods[]> => {
        try {
            const response = await axios.get('/api/paymentsMethod');
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    createPaymentMethod = async (body: PaymentMethodsRequestBoyd): Promise<any> => {
        try {
            const response = await axios.post('/api/paymentsMethod', body);
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    updatePaymentMethod = async (id: string, body: PaymentMethodsRequestBoyd): Promise<any> => {
        const response = await axios.put(`/api/paymentsMethod/${id}`, body);
        return response;
    }

    deletePaymentMethod = async (id: string): Promise<any> => {
        const response = await axios.delete(`/api/paymentsMethod/${id}`);
        return response;
    }

    fetchAllPayments = async (): Promise<PaymentRequestBody[]> => {
        try {
            const response = await axios.get('/api/payments');
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    filterReports = async (queryParams: FilterReportsQueryParams): Promise<any> => {
        try {
            const response = await axios.get(`/api/payments`, {params: queryParams});
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    deactivatePaymentMethod = async (id: string) => {
        try {
            return await axios.patch(`/api/paymentsMethod/deactivate/${id}`)
        } catch (err) {
            throw err;
        }
    }

    activatePaymentMethod = async (id: string) => {
        try {
            return await axios.patch(`/api/paymentsMethod/activate/${id}`)
        } catch (err) {
            throw err;
        }
    }

}

export default new PaymentMethodsServices();