import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {PaymentMethodsService} from 'app/services';
import {PagedReportsList, PaymentMethods} from "app/types/service-types/PaymentMethodsTypes";

export const fetchAllPaymentMethods = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  const response = await PaymentMethodsService.fetchAll();
  dispatch(setPaymentMethodsList(response));
  dispatch(setLoading(false));
}

interface PaymentMethodsSlice {
  allPaymentMethods: PaymentMethods[];
  loading: boolean;
}

const initialState: PaymentMethodsSlice = {
  allPaymentMethods: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/payment-methods',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setPaymentMethodsList: (state, action) => {
      state.allPaymentMethods = action.payload
    }
  }
});

export const {setLoading, setPaymentMethodsList} = stateSlice.actions;

export default stateSlice.reducer;