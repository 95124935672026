import axios from './axios.interceptors';
import {Airport, AirportRequestBody, FilterAirportsQueryParams} from "app/types/service-types/AirportServiceTypes";

class AirportServices {
  fetchAll = async (): Promise<Airport[]> => {
    try {
      const response = await axios.get('/api/airports');
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  filterAirports = async (queryParams: FilterAirportsQueryParams): Promise<any> => {
    try {
      const response = await axios.get(`/api/airports`, {params: queryParams});
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  createAirport = async (body: AirportRequestBody): Promise<any> => {
    try {
      const response = await axios.post('/api/airports', body);
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  updateAirport = async (id: string, body: AirportRequestBody): Promise<any> => {
    const response = await axios.put(`/api/airports/${id}`, body);
    return response;
  }

  updateAirportImg = async (id: string, backdropImage: any): Promise<any> => {
    let data = new FormData();
    data.append('backdropImage', backdropImage);

    const response = await axios.put(`/api/airports/${id}/backdrop-image`, data);
    return response;
  }

  deleteAirport = async (id: string): Promise<any> => {
    const response = await axios.delete(`/api/airports/${id}`);
    return response;
  }
}

export default new AirportServices();
