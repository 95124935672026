import {makeStyles} from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    width: 'fit-content',
  },
  button: {
    padding: '.5rem 1rem',
    fontSize: '13px',
    fontWeight: 700,
    color: '#555555',
  },
  active: {
    background: '#ffffff'
  }
}));