// @flow
import * as React from 'react';
import '../dashboard-page.css';


export interface Props {
  text: string,
  icon: any,
  data: any,
  iconColor?:any,
}

const DashboardCard = (props: Props) => {

  const Icon = props.icon;
  return (
      <div className="dashboard-card">
        <div className="flex items-center space-between">
          <div className="dashboard-card_text" >{props.text}</div>
          <div className="dashboard-card_icon-circle" >
            <Icon className="dashboard-card_icon" style={{ color: props.iconColor}}/>
          </div>
        </div>
        <div className="dashboard-card_data" style={{ color: props.iconColor}}>{props.data}</div>
      </div>
  );
};

export default DashboardCard;