import React from 'react';
import {Button, CircularProgress} from '@material-ui/core';

import useStyles from './loading-button.styles';
import {LoadingButtonTypes} from 'app/types/ButtonTypes';

const LoadingButton = (props: LoadingButtonTypes) => {
  const classes = useStyles({loading: props.isLoading})();

  return (
      <Button
          disabled={props.disabled}
          type={props.type ? props.type : 'button'}
          onClick={props.onClick && props.onClick}
          size={props.size ? props.size : 'large'}
          className={classes.margin}
          variant='contained'
          color='primary'>
        <span className={classes.title}>
          {props.btnTitle}
        </span>
        {(props.isLoading) && <CircularProgress size={22} className={classes.spinner}/>}
      </Button>
  )
}

export default LoadingButton;
