import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent'
      },
      paper: {
        backgroundColor: 'white',
        borderRadius: '4px',
        outline: 'none',
        boxShadow: '0px 10px 20px -12px rgba(0, 0, 0, 0.3);',
        padding: theme.spacing(2, 5, 2),
      },
      mainTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #EFEFEF',
        '& h3': {
          flex: 1,
          marginLeft: '.5rem'
        },
      },
      title: {
        margin: '1rem 0',
        padding: '2rem',
        backgroundColor: '#FDF3E0',
        color: '#AD3B17',
        fontWeight: 400,
        borderRadius: '4px',
        '& h3': {
          fontWeight: 500,
        },
        '& h4': {
          paddingTop: '1rem'
        }
      },
      buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '1rem',
        '& button.on-confirm' : {
          backgroundColor: '#F55724',
          marginLeft: '1rem'
        }
      }
    }),
);