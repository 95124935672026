import { Grid, Icon, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Field } from "formik";
import { fillAgentFormField } from "../store/agent-form.slice";
import React, { useEffect, useState } from "react";
import { createAgentsStyles } from "../create/create-agents.styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import './agent-form.css';
import { useTranslation } from "react-i18next";
import { setAgentLogoImage } from "../store/agent-logo-image.slice";

const InvoiceInfo = () => {
  const classes = createAgentsStyles();
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const agent = useSelector((state: AppState) => state.agents.agent);
  const [logoType, setLogoType] = useState('operator');
  const [logo, setLogo] = useState();
  const [isOperatorLogo, setIsOperatorLogo] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (agent?.agent?.isOperatorLogo !== undefined) {
      setIsOperatorLogo(agent.agent.isOperatorLogo);
      setLogoType(agent.agent.isOperatorLogo ? 'operator' : 'agency');
    }
  }, [agent?.agent?.isOperatorLogo]);

  const handleChange = (e: any) => {
    const payload = {
      value: e.target.value,
      field: e.target.name
    }

    dispatch(fillAgentFormField(payload));

    if (e.target.name === 'logoImageUrl') {
      let file = e.target.files[0];
      let reader = new FileReader() as any;
      reader.readAsDataURL(file);
      reader.onloadend = function (e: any) {
        setLogo(reader.result);
        dispatch(setAgentLogoImage(reader.result));
      }
    }
  }

  const checkLogoType = (e: any) => {
    const value = e.target.value;
    setLogoType(value);
    setIsOperatorLogo(value === 'operator');
    dispatch(fillAgentFormField({ field: 'isOperatorLogo', value: value === 'operator' }));
  }

  return (
      <div style={{ position: 'relative' }}>
        <Grid container spacing={4} style={{ paddingTop: '15px' }}>
          <Grid item xs={12}>
            <div className={classes.radioGroupDiv} role="group" aria-labelledby="my-radio-group">
              <Typography
                  style={{ marginBottom: '.5rem' }}>{t("app.main.agency.create.logo", "Invoice logo")}:</Typography>
              <label>
                <Field type="radio" name="logoType" checked={isOperatorLogo}
                       value={'operator'} onChange={checkLogoType} />
                {t("app.main.agency.create.secondLogo", "Use the operator logo")}
              </label>
              <label>
                <Field type="radio" name="logoType" checked={!isOperatorLogo} value={'agency'}
                       onChange={checkLogoType} />
                {t("app.main.agency.create.thirdLogo", "Use the agency logo")}
              </label>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {logoType === 'agency' &&
              <Grid item xs={8}>
                <Field
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    type="file"
                    variant='outlined'
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                            <Icon style={{ color: '#45637E' }}>upload_file</Icon>
                          </InputAdornment>
                      )
                    }}
                    size='small'
                    name='logoImageUrl'
                    as={TextField}
                />
              </Grid>}
          <Grid item xs={4}>
            {(logoType === 'agency' && (logo || agent?.agent?.logoImageUrl)) &&
                <img className="invoice-image"
                     style={logo ? { backgroundImage: `url(${logo})` } : { backgroundImage: `url(${agent?.agent?.logoImageUrl})` }} alt="" />}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
                style={{ width: '100%' }}
                label={t("app.main.agency.create.textInvoice", "Text on the invoice")}
                onChange={handleChange}
                variant='outlined'
                value={agentForm.invoiceText}
                size='small'
                name='invoiceText'
                as={TextField}
                multiline
                rows={4}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
                style={{ width: '100%', paddingBottom: '10px' }}
                label={t("app.main.agency.create.textBelowInvoice", "Text below the invoice")}
                onChange={handleChange}
                value={agentForm.invoiceBottomText}
                variant='outlined'
                size='small'
                name='invoiceBottomText'
                as={TextField}
                multiline
                rows={4}
            />
          </Grid>
        </Grid>
      </div>
  )
}

export default InvoiceInfo;
