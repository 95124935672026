import React from "react";
import {BasePage} from "../../types/PageTypes";
import {Redirect, Switch} from "react-router-dom";

const AgencyEditListPage = (props: BasePage) => {
  return (
      <Switch>
        {props.children}
        <Redirect to='/edit'/>
      </Switch>
  )
};

export default AgencyEditListPage;