import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";

export const customersFormStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1)
    }
  },
  tableRow: {
    '& input': {
      width: '80px'
    },
    '& input[name="name"]': {
      width: '100px'
    }
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  row: {
    display: 'flex',
    '& > div': {
      flex: 1,
      margin: theme.spacing(0, .5)
    },
    padding: theme.spacing(2, 0)
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 'auto',
    height: 'auto',
    flex: 1,
    flexBasis: '15%',

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkboxWrapper: {
    padding: theme.spacing(1)
  }
}));