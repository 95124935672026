import React, {useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {TableActionsTypes} from "../../../types/TableTypes";
import {toggleQuickPanel} from "../quick-panel/store";
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom";
import history from '@history';
import ModalDialog from 'app/main/shared-components/modal-dialog/ModalDialog'
import {useTranslation} from "react-i18next";
import {useTableRowStyles} from './payment-method.style';
import PaymentMethodTableCell from "./PaymentMethodTableCell";
import CustomersTableCell from "../customers-table/CustomersTableCell";
import {deactivatePaymentMethod} from "../../configurations/store/paymentmethods/deactivate-paymentMethod.slice";
import {activatePaymentMethod} from "../../configurations/store/paymentmethods/activate-paymentMethod.slice";
import TableActionButtons from "../base-table/TableActionButtons";

const PaymentMethodTableRow = (props: any) => {
    const dispatch = useDispatch();
    const classes = useTableRowStyles();
    let {url} = useRouteMatch();
    const {t} = useTranslation();
    const {row} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const status = row.status ? "Active" : "Deactive";

    const tableActions: TableActionsTypes = {
        onEditRow: (rowId: number) => {
            dispatch(toggleQuickPanel());
            history.push(`${url}/update/${rowId}`);
        },
        [row.status ?  'onDeactivateRow' : 'onActivateRow']: (rowId: string) => {
            setModalOpen(true);
        },
    }

    return (
        <React.Fragment>
            {row.status ? (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle", "Deactivate")} `}
                    title={`${t("app.main.customer.paymentMethod.deactivatePopup.primaryText", "Are you sure you want to deactivate the payment method")} '${row.name}'?`}
                    onOk={() => {
                        dispatch(deactivatePaymentMethod({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            ):(
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle1", "Activate")} `}
                    title={`${t("app.main.customer.paymentMethod.activatePopup.primaryText", "Are you sure you want to activate the payment method")} '${row.name}'?`}
                    onOk={() => {
                        dispatch(activatePaymentMethod({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            )}
            <TableRow className={classes.root}>
                <TableCell style={{textAlign: 'center'}}>
                    <PaymentMethodTableCell {...props} content={[
                        `${row.name}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center',color:row.status?"green":"red", fontWeight:"bold"}}>
                    <CustomersTableCell {...props} content={[
                        `${status}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <TableActionButtons {...tableActions} rowId={row.id}/>
                </TableCell>

            </TableRow>
        </React.Fragment>
    );
};

export default PaymentMethodTableRow;