import React, {useEffect, useState} from "react";
import {Button, MenuItem, TextField,} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import Grid from "@material-ui/core/Grid";
import {SmsTypes} from "../../../types/service-types/ReservationTypes";
import {smsStyles} from "./sms.styles";
import {AppState} from "app/store";
import DateUtils from "../../../utils/DateUtils";
import {useTranslation} from "react-i18next";
import {fetchAllSmsTemplates} from "../../configurations/store/sms-templates/sms-template.slice";
import {addSmsTemplateToReservation} from "../store/sms-template.slice";
import useAuthority from "../../../hooks/useAuthority";
import {ROLES} from "../../../constants/roles";

const Sms = () => {
  const classes = smsStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const location = useLocation<any>();
  const {t} = useTranslation();
  const {hasUserAnyAuthority} = useAuthority();
  // permissions that admin give the agent
  const scopes = useSelector((state: AppState) => state.account.account.account?.scopes);
  const reservationId: string = params?.id;
  const smsResponses = location?.state?.smsResponses;
  const {smsTemplates} = useSelector((state: AppState) => state.configurations.smsTemplates.fetchAllSmsTemplates);

  const [hasSmsButton, setHasSmsButton] = useState(false);

  useEffect(() => {
    dispatch(fetchAllSmsTemplates());
  }, []);

  const onSendSmsHandler = (smsTemplateId: string) => {
    const params = {
      reservationId: reservationId,
      smsTemplateId: smsTemplateId
    }

    dispatch(addSmsTemplateToReservation(params));
  };

  const onCancelHandler = () => {
    dispatch(toggleQuickPanel());
    history.goBack();
  }

  useEffect(() => {
    CheckSendSmsButton()
  }, [scopes]);

  const CheckSendSmsButton = () => {
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
      setHasSmsButton(true);
    }
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
      setHasSmsButton(true);
    }
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_CALL_AGENT])) {
      scopes.map((x: any) => {
        if (x === "SEND_SMS") {
          setHasSmsButton(true);
        }
      })
    }
  }

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "35px"}}>
          <h1 style={{color: "#0D3B66"}}>{t("app.main.schedules.sms.title", "Sending SMS to the client")}</h1>
        </div>

        <div className={classes.disquisitionsWrapper}>
          {smsResponses.map((item: SmsTypes, index: number) => {
            return (
                <div>
                  {index + 1 + "). "}
                  <span style={{fontWeight: 600}}>{item.fullName}</span>
                  {": "}
                  <br/>
                  <span style={{whiteSpace: 'break-spaces'}}>{item.message}</span>
                  <br/>
                  <br/>
                  <span style={{fontSize: "11px"}}>
                    {DateUtils.toHumanDate(
                        DateUtils.convertDate(item.createdAt),
                        new Date(item.createdAt).toLocaleTimeString()
                    )}
                  </span>
                </div>
            );
          })}
        </div>

        <Formik
            initialValues={{
              id: ""
            }}
            onSubmit={(data: any) => {
              onSendSmsHandler(data.id);
            }}
        >
          {({values, setValues}) => (
              <Form>
                <Field
                    variant="outlined"
                    value={values.id}
                    name="id"
                    label={t("app.main.configuration.sidebar.title7", "Sms Template")}
                    select
                    fullWidth
                    as={TextField}
                    onChange={(e: any) => {
                      setValues({
                        id: e.target.value,
                      });
                    }}
                >
                  {smsTemplates.map((sms) => {
                    return (<MenuItem value={sms.id}>{sms.title}</MenuItem>)
                  })}
                </Field>

                <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{marginTop: "20px"}}
                >
                  <Grid item>
                    {hasSmsButton ?
                        <LoadingButton
                            type="submit"
                            btnTitle={t("app.main.schedules.sms.sendSms", "Send SMS")}
                            size="medium"
                        />
                        :
                        false
                    }
                    <Button
                        onClick={onCancelHandler}
                    >
                      {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default Sms;
