import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import useStyles from './collapsible-table.styles';
import CollapsibleTableRow from "./CollapsibleTableRow";
import {PagedReservationsList} from "app/types/service-types/ReservationTypes";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import {useTranslation} from "react-i18next";

interface IProps {
  data: PagedReservationsList | null;
  loading: boolean;
}

const CollapsibleTable = (props: IProps) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {data, loading} = props;

  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell style={{width: '1rem'}}/>
              <TableCell align='left' style={{fontWeight: 'bold', width: '16rem'}}>{t( "app.main.sharedComponents.collapsibleTable.tableRow.tableCell1", "Billing time")}</TableCell>
              <TableCell align='left' style={{fontWeight: 'bold', width: '16rem'}}>{t( "app.main.sharedComponents.collapsibleTable.tableRow.tableCell2", "Note about the bill")}</TableCell>
              <TableCell align='left' style={{fontWeight: 'bold'}}>{t( "app.main.sharedComponents.collapsibleTable.tableRow.tableCell3", "Contact person")}</TableCell>
              <TableCell align='center' style={{fontWeight: 'bold'}}>{t( "app.main.sharedComponents.collapsibleTable.tableRow.tableCell4", "Status / Source")}</TableCell>
              {/*<TableCell align='center' style={{fontWeight: 'bold'}} >Source</TableCell>*/}
              <TableCell align='right' style={{fontWeight: 'bold'}}>{t( "app.main.sharedComponents.collapsibleTable.tableRow.tableCell5", "Total and payment")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <LoadingTableRow colSpan={6}/>
                : !data || data.content.length === 0 ? <NoDataTableRow colSpan={6}/>
                    : data.content.map((row) => (
                        <CollapsibleTableRow key={row.id} row={row}/>
                    ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default CollapsibleTable;
