import {combineReducers} from '@reduxjs/toolkit';
import airport from './airport';
import airlines from './airlines';
import paymentmethods from './paymentmethods';
import countries from './countries';
import smsTemplates from './sms-templates'
import configurationLogoForm from './upload-logo/upload-logo-form.slice'
import getConfigurationLogo from './upload-logo/get-upload-logo.slice'

const reducer = combineReducers({
  airport,
  airlines,
  paymentmethods,
  countries,
  smsTemplates,
  configurationLogoForm,
  getConfigurationLogo
});

export default reducer;