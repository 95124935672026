import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import useStyles from './customers-table.styles';
import CollapsibleTableRow from "./CustomersTableRow";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import {PagedCustomerList} from "../../../types/service-types/CustomerTypes";
import {useTranslation} from "react-i18next";

interface IProps {
  data: PagedCustomerList | null,
  loading: boolean
}

const CustomersTable = (props: IProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {data, loading} = props;

  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell1", "Full name")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.email", "Email")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.phoneNumber", "Phone number")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.customer.customerList.address", "Address")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.customer.customerList.city", "City")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.customer.customerList.country", "Country")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.status", "Status")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.actions", "Actions")}</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <LoadingTableRow colSpan={6}/>
                : !data || data.content.length === 0 ? <NoDataTableRow colSpan={6}/>
                    : data.content.map((row) => (
                        <CollapsibleTableRow key={row.id} row={row}/>
                    ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default CustomersTable;