import React from 'react';
import {customersFormStyles} from "../customers.styles";
import {Field, Form, Formik} from 'formik';
import history from '@history';
import {Button, TextField} from "@material-ui/core";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useRouteMatch} from "react-router-dom";
import {updateCustomer} from "../store/update-customer.slice";
import * as yup from "yup";
import {useTranslation} from "react-i18next";



const UpdateCustomerForm = () => {
  const classes = customersFormStyles();
  const dispatch = useDispatch();
  const {params} = useRouteMatch();
  const {t} = useTranslation();
  const allCustomers = useSelector((state: AppState) => state.customers.customersList.allCustomers!.content);
  const phoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/gm

  const validationSchema = yup.object({
    phoneNumber: yup
        .string()
        .matches(phoneRegExp,t('app.main.costumer.createCostumer.registerCostumer.phoneValidation','Phone number must start with + sign')),
    fullName: yup
        .string()
        .required(t("app.main.costumer.createCostumer.registerCostumer.fullName","Please enter the full name"))
        .matches(/^[aA-zZ\s]+$/,t("app.main.costumer.createCostumer.registerCostumer.fullName.title","Only alphabets are allowed for this field ")),
    city: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title","Only alphabets are allowed for this field ")),
    country: yup
        .string()
        .matches(/^[aA-zZ\s]+$/,t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
    email: yup
        .string().email()
        .required(t("app.main.costumer.createCostumer.registerCostumer.email","Please enter the email"))
  });
  const getSelectedUser = () => {
    let userToReturn = {
      id: '',
      fullName: '',
      phoneNumber: '',
      address: '',
      email: '',
      // password: '',
      city: '',
      country: '',
      zipCode: '',
      dateOfBirth: '',
    }

    if (params) {
      // @ts-ignore
      const foundedCustomer: any = allCustomers.find((usr) => usr.id === params.id);
      if (foundedCustomer) {
        const {
          id,
          fullName,
          phoneNumber,
          address,
          email,
          city,
          country,
          zipCode,
          dateOfBirth,
        } = foundedCustomer;

        // const splitedFullName = fullName ? fullName.split(' ') : ['', ''];

        userToReturn = {
          id,
          fullName,
          phoneNumber,
          address,
          email,
          country,
          city,
          zipCode,
          // password: '',
          dateOfBirth,
        }
      }
    }

    return userToReturn;
  }

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: '#0D3B66'}}>{t("app.main.customer.update.title", "Change Client")}</h1>
        </div>
        <Formik
            initialValues={getSelectedUser()}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              const {
                id,
                fullName,
                address,
                phoneNumber,
                email,
                city,
                zipCode,
                country,
                dateOfBirth
              } = data;

              dispatch(updateCustomer({
                id,
                body: {
                  fullName, address, phoneNumber, email, city, zipCode, country, dateOfBirth
                },
                onSuccess: () => dispatch(toggleQuickPanel())
              }));
            }}
        >
          {({errors, values, setValues, touched}) => (
              <Form>
                <div className={classes.row}>
                  <Field variant='outlined' value={values.fullName} name='fullName'
                         label={t("app.main.schedules.sharedComponents.collapsibleTableRow.tableCell1", "Full name")}
                         as={TextField} required
                         error={Boolean(errors.fullName && touched.fullName)}
                         helperText={touched.fullName ? errors.fullName : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             fullName: e.target.value,
                           });
                         }}/>
                  <Field variant='outlined' value={values.address} name='address'
                         label={t("app.main.customer.customerList.address", "Address")}
                         as={TextField}/>
                  {/*<Field variant='outlined' value={values.lastName} name='lastName' label='Lastname' as={TextField} required/>*/}
                </div>
                <div className={classes.row}>
                  <Field variant='outlined' value={values.city} name='city'
                         label={t("app.main.customer.customerList.city", "City")}
                         as={TextField}
                         error={Boolean(errors.city && touched.city)}
                         helperText={touched.city ? errors.city : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             city: e.target.value,
                           });
                         }}/>
                  <Field variant='outlined' value={values.country} name='country'
                         label={t("app.main.customer.customerList.country", "Country")}
                         as={TextField}
                         error={Boolean(errors.country && touched.country)}
                         helperText={touched.country ? errors.country : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             country: e.target.value,
                           });
                         }}/>
                </div>
                <div className={classes.row}>
                  <Field variant='outlined' type='email' value={values.email} name='email'
                         label={t("app.main.employees.employeeList.table.email", "Email")}
                         as={TextField} required
                         error={Boolean(errors.email && touched.email)}
                         helperText={touched.email ? errors.email : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             email: e.target.value,
                           });
                         }}/>
                  <Field
                      as={TextField}

                      type="text"
                      label={t("app.main.employees.employeeList.table.phoneNumber", "Phone number")}
                      variant="outlined"
                      name={"phoneNumber"}
                      value={values.phoneNumber}
                      error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                      helperText={touched.phoneNumber ? errors.phoneNumber : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          phoneNumber: e.target.value,
                        });
                      }}
                  />
                  {/*<Field variant='outlined' type='password' value={values.password} name='password' label='Password' as={TextField} required/>*/}
                </div>
                <div style={{width: '50%'}} className={classes.row}>
                  {/*<Field variant='outlined' value={values.phoneNumber} inputProps={{ pattern: "[0-9]*" }} name='phoneNumber' label='Phone number' as={TextField} />*/}
                </div>
                <div className={classes.row}>
                  <LoadingButton
                      type='submit'
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={false}
                  />
                  <Button onClick={(e) => {
                    dispatch(toggleQuickPanel())
                    history.push('/customer/customers-list')
                  }}>
                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                  </Button>
                </div>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default UpdateCustomerForm;