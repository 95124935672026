import SchedulePage from './SchedulePage';
import {PageConfigTypes} from "app/types/PageTypes";

const SchedulePageConfig: PageConfigTypes = {
  baseRoute: {
    path: '/schedule',
    exact: true,
    component: SchedulePage
  },
  subRoutes: [],
  settings: {}
}

export default SchedulePageConfig;
