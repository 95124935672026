export default {
  chart: {
    id: "payments-methods",
    events: {
      // beforeMount: function(chartContext, config) {
      //   // ...
      // }
    },
    animations: {
      enabled: true
    }
  },

  noData: {
    text: "No data available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: "#5085A5",
      fontSize: "19px",
      display: "block"
    }
  },
  stroke: {
    curve: "smooth"
  },

  xaxis: {
    categories: [],
    labels: {
      style: {
        colors: "#5085A5"
      },
      rotate: -45
    }
  },
  yaxis: {},
  theme: {
    mode: "light"
  },
  fill: {
    colors: ['#DFFF00','#FFBF00','#FF7F50', '#DE3163', '#9FE2BF', '#40E0D0', '#6495ED', '#CCCCFF',],
    opacity: 0.9,
    type: "solid"
  },
  plotOptions: {
    bar: {
      columnWidth: "30px",
      dataLabels: {
        position: "top",
        offsetY: -20
      }
    }
  },
  colors: ['#DFFF00','#FFBF00','#FF7F50', '#DE3163', '#9FE2BF', '#40E0D0', '#6495ED', '#CCCCFF',],
  dataLabels: {
    enabled: true,
    offsetY: -4,
    style: {
      fontSize: "12px",
      colors: ['#DFFF00','#FFBF00','#FF7F50', '#DE3163', '#9FE2BF', '#40E0D0', '#6495ED', '#CCCCFF',]
    },
    background: {
      enabled: true,
      foreColor: "#fff"
    }
  }
};
