import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {AirlineRequestBody} from "app/types/service-types/AirlineCompaniesTypes"
import {AirlineService} from "app/services"
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {fetchAllAirlines} from "./airline-companies.slice";
import {errorMsg, successMsg} from "app/utils/MessageUtils";

interface CreateAirlineStateParams {
  body: AirlineRequestBody;
  onSuccess?: Function;
  onError?: Function;
}

export const createAirline = (params: CreateAirlineStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AirlineService.createAirline(params.body);
    if (response.status === 201) {
      dispatch(setSuccess(true));
      dispatch(toggleQuickPanel());
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Airline company added successfully!' : 'Kompania ajrore u shtua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllAirlines());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface CreateAirlineSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: CreateAirlineSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/airlines',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;