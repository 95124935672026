import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "app/store";
import {useTranslation} from "react-i18next";
import {flightsFormStyles} from "../../../flights/flights.styles";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import LoadingButton from "../../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "../../../shared-components/quick-panel/store";
import {fillAgentFormField, resetAgentForm} from "../../store/agent-form.slice";
import {createPayment} from "../../store/create-payment.slice";

const CreatePaymentForm = () => {
  const classes = flightsFormStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const createPaymentLoading = useSelector((state: AppState) => state.agents.createPayment.loading);
  const balances = useSelector((state: AppState) => state.agents.agent.agent?.balances);
  const currentBalance = balances?.find((balance) => balance.currencyResponse.id == params.currencyId);

  const formDataState = {
    amount: 0,
    description: "",
    pinCode: "",
    currencyId: currentBalance!.currencyResponse.id
  };

  const [formData, setFormData] = useState(formDataState);

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    dispatch(fillAgentFormField(formData))
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: "#0D3B66"}}>{`${t("app.main.agency.create.payment.title", "Add payments in")} -  ${currentBalance?.currencyResponse.isoCode}`}</h1>
        </div>
        <Formik
            initialValues={formDataState}

            onSubmit={() => {
              dispatch(createPayment({
                id: params.id,
                body: formData,
                onSuccess: () => {
                  history.push("/agents-agencies/list");
                },
              }))
            }}
        >
          <Form>
            <div>
              <div
                  className={classes.row}
                  style={{borderTop: "1px solid #e7e7e7"}}
              >
                <Typography
                    style={{marginLeft: ".2rem", marginRight: "6.1rem", width: "96px"}}
                >
                  {`${t("app.main.agency.create.payment.label1", "Amount")} - ${currentBalance?.currencyResponse.isoCode}`}:
                </Typography>

                <Field
                    variant="outlined"
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    value={formData.amount}
                    className={classes.textField}
                    name="amount"
                    label={`${t("app.main.agency.create.payment.label1", "Amount")}`}
                    as={TextField}
                />
              </div>
              <div
                  className={classes.row}
                  style={{borderTop: "1px solid #e7e7e7"}}
              >
                <Typography
                    style={{marginLeft: ".2rem", marginRight: "6.1rem", width: "96px"}}
                >
                  {t("app.main.agency.create.payment.label2", "Description of payment")}:
                </Typography>

                <Field
                    variant="outlined"
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    value={formData.description}
                    className={classes.textField}
                    name="description"
                    label={`${t("app.main.agency.create.payment.label2", "Description of payment")}`}
                    as={TextField}
                />
              </div>
              <div
                  className={classes.row}
                  style={{borderTop: "1px solid #e7e7e7"}}
              >
                <Typography
                    style={{marginLeft: ".2rem", marginRight: "6.1rem", width: "96px"}}
                >
                  {t("app.main.agency.create.payment.label3", "Pin Code")}:
                </Typography>

                <Field
                    variant="outlined"
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    type="password"
                    value={formData.pinCode}
                    className={classes.textField}
                    name="pinCode"
                    label={`${t("app.main.agency.create.payment.label3", "Pin Code")}`}
                    as={TextField}
                />
              </div>

              <Grid container justify='flex-end' alignItems='center'
                    style={{marginTop: '20px'}}>
                <Grid item>
                  <LoadingButton
                      type='submit'
                      btnTitle={`${t("app.main.agency.create.payment.label4", "Add payment")}`}
                      size='medium'
                      isLoading={createPaymentLoading}
                  />

                  <Button
                      onClick={() => {
                        dispatch(toggleQuickPanel());
                        dispatch(resetAgentForm());
                        history.goBack();
                      }}>{t("app.main.schedules.splitPassenger.closeButton", "Close")}</Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        </Formik>
      </div>
  );
};

export default CreatePaymentForm;
