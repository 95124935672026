import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import {TableCellTypes} from "app/types/TableTypes";
import {useTranslation} from "react-i18next";

const NoDataTableRow = ({colSpan}: TableCellTypes) => {
  const {t} = useTranslation();

  return (
      <TableRow>
        <TableCell align="center" colSpan={colSpan}>
          <Typography variant="subtitle2" color="textSecondary" display="block" gutterBottom>
            {t("app.main.shared-components.base-table.noData", "No data was found!")}
          </Typography>
        </TableCell>
      </TableRow>
  )
}

export default NoDataTableRow;