import axios from "./axios.interceptors";

interface PhoneNumber {
  countryName: 'GERMANY' | 'KOSOVO' | 'SWITZERLAND',
  phoneNumber: string,
}

class PhoneNumberService {
  getAllPhoneNumbers = async (): Promise<PhoneNumber[]> => {
    try {
      const response = await axios.get('/api/public-phone-numbers');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  updatePhoneNumber = async (body: PhoneNumber): Promise<any> => {
    return await axios.put(`/api/public-phone-numbers/`, body);
  }

  getPhoneNumbers = async (): Promise<PhoneNumber[]> => {
    try {
      const response = await axios.get('/api/v1/public-phone-numbers');
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default new PhoneNumberService();