import {combineReducers} from '@reduxjs/toolkit';
import airportsList from './airports-list.slice';
import createAirport from './create-airport.slice';
import deleteAirport from './delete-airport.slice';
import updateAirport from './update-airport.slice';
import timezoneList from './timezone-list.slice';

const reducer = combineReducers({
  airportsList,
  createAirport,
  deleteAirport,
  updateAirport,
  timezoneList
});

export default reducer;
