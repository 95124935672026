import React from 'react';
import {CircularProgress} from "@material-ui/core";

import useStyles from './loading-form.styles';

const LoadingForm = () => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <CircularProgress size={25}/>
      </div>
  );
};

export default LoadingForm;