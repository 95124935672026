import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {UserService} from "app/services";
import {GetEmployeeTypes} from "app/types/service-types/EmployeeTypes";

export const fetchAllUsers = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  const response = await UserService.fetchAll();
  dispatch(setUsersList(response));
  dispatch(setLoading(false));
}

interface UsersListSlice {
  allUsers: GetEmployeeTypes[];
  loading: boolean;
}

const initialState: UsersListSlice = {
  allUsers: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'users/users-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setUsersList: (state, action) => {
      state.allUsers = action.payload
    }
  }
});

export const {setLoading, setUsersList} = stateSlice.actions;

export default stateSlice.reducer;