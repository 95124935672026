import {makeStyles} from '@material-ui/styles';
import {Theme} from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'inherit',
    // background: theme.palette.primary.light
    background: '#fff',
  },
  subTopBar: {
    padding: '0 160px',
    margin: 0,
    ['@media (max-width:1450px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 80px;'
    },
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 20px'
    }
  },
  menuButtons: {
    // border: '1px solid red',
    // margin: '0 2rem',
    color: '#3d3d3d',
  }
}));