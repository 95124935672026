import {createSlice, Dispatch} from "@reduxjs/toolkit";

const getInitialState = () => ({
    scopes: [] as string[],
});

export const setTheEmployeeScopesForm = (payload: any) => (dispatch: Dispatch) => {
    dispatch(setEmployeeScopesForm(payload));
};

export const resetTheEmployeeScopesForm = () => (dispatch: Dispatch) => {
    dispatch(resetEmployeeScopesForm());
};

const stateSlice = createSlice({
    name: "employees/employee-scopes-form",
    initialState: getInitialState(),
    reducers: {
        setEmployeeScopesForm: (state, action) => {
            state.scopes = action.payload.scopes;
        },
        resetEmployeeScopesForm: () => getInitialState(),
    },
});

export const {
    setEmployeeScopesForm,
    resetEmployeeScopesForm,
} = stateSlice.actions;

export default stateSlice.reducer;
