// Helpful links for this hook
// https://tobbelindstrom.com/blog/useMutationObserver
// https://blog.logrocket.com/guide-to-custom-react-hooks-with-mutationobserver

import {RefObject, useEffect, useMemo} from 'react';

const DEFAULT_OPTIONS = {attributes: true, childList: true, subtree: true}

interface UseMutationObserverProps {
  target: RefObject<Element> | Element | Node | null;
  callback: MutationCallback;
  options?: MutationObserverInit;
}

const getRefElement = <T, >(element?: RefObject<Element> | T): Element | T | undefined | null => {
  if (element && 'current' in element) {
    return element.current;
  }

  return element;
}

const useMutationObserver = ({
                               target,
                               callback,
                               options = DEFAULT_OPTIONS
                             }: UseMutationObserverProps): void => {
  const observer = useMemo(
      () => new MutationObserver((mutationRecord, mutationObserver) => {
        callback?.(mutationRecord, mutationObserver);
      }), [callback]
  );

  useEffect(() => {
    if (!observer) return;
    const element = getRefElement(target);

    if (observer && element) {
      observer.observe(element, options);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};

export default useMutationObserver;