import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {fetchAllAgents} from './agents-list.slice';
import DateUtils from "app/utils/DateUtils";
import {FilterAgentsQueryParams} from "../../../types/service-types/AgentTypes";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] === "ALL") {
      } else newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const filterAgents =
    (queryParams: FilterAgentsQueryParams) =>
        async (dispatch: Dispatch) => {
          dispatch(setFilters(queryParams));
          // @ts-ignore
          dispatch(fetchAllAgents({...removeFalsyAttributes(queryParams)}));
        };

export const clearfilterAgents = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterAgentsQueryParams = {};

const stateSlice = createSlice({
  name: "agent/agents-filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterAgentsQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;