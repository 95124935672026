import React, {useEffect, useState} from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FlightRoundedIcon from "@material-ui/icons/FlightRounded";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import Dashboard from '@material-ui/icons/Dashboard';
import {Equalizer} from "@material-ui/icons";
import useStyles from "./sub-topbar-menu.styles";
import IconNavLink from "app/main/shared-components/icon-nav-link/IconNavLink";
import {ROLES} from "app/constants/roles";
import {useTranslation} from "react-i18next";
import SettingsIcon from "@material-ui/icons/Settings";
import useAuthority from "../../../hooks/useAuthority";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";

const SubTopbarMenu = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  // permissions that admin give the agent
  const scopes = useSelector((state: AppState) => state.account.account.account?.scopes);
  const {hasUserAnyAuthority} = useAuthority();
  const [hasButton, setHasButton] = useState(false);

  useEffect(() => {
    FlightReservation()
  }, [scopes]);

  const FlightReservation = () => {
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
      scopes.map((x: any) => {
        if (x === "FLIGHT_RESERVATION") {
          setHasButton(true);
        }
      })
    }
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_CALL_AGENT])) {
      scopes.map((x: any) => {
        if (x === "FLIGHT_RESERVATION") {
          setHasButton(true);
        }
      })
    }
    if (scopes && hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
      setHasButton(true);
    }
  }

  return (
      <div className={classes.root}>
        <div style={{display: "flex"}}>
          <IconNavLink
              to="/dashboard"
              title={t("app.main.layout.subTopBar.title1", "DASHBOARD")}
              iconComponent={<Dashboard/>}
          />
          {hasButton ?
              <IconNavLink
                  to="/schedule"
                  title={t("app.main.layout.subTopBar.title2", "SCHEDULE")}
                  iconComponent={<ScheduleIcon/>}
              />
              : false}

          <IconNavLink
              to="/schedules"
              title={t("app.main.layout.subTopBar.title3", "SCHEDULES")}
              iconComponent={<DescriptionOutlinedIcon/>}
          />
          <IconNavLink
              to="/flights"
              title={t("app.main.layout.subTopBar.title4", "FLIGHTS")}
              iconComponent={<FlightRoundedIcon/>}
          />
          <IconNavLink
              to='/reports'
              title={t("app.main.layout.subTopBar.title5", "REPORTS")}
              iconComponent={<Equalizer/>}
              authorities={[ROLES.ROLE_ADMIN]}
          />
          <IconNavLink
              title='AGENCY REPORTS'
              to='/agency-reports'
              iconComponent={<Equalizer/>}
              authorities={[ROLES.ROLE_AGENT]} />
          <IconNavLink
              to="/employees"
              title={t("app.main.layout.subTopBar.title6", "EMPLOYEES")}
              iconComponent={<PeopleOutlineRoundedIcon/>}
              authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]}
          />
          <IconNavLink
              to="/customer"
              title={t("app.main.layout.subTopBar.title7", "CLIENTS")}
              iconComponent={<PeopleOutlineRoundedIcon/>}
              authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]}
          />
          <IconNavLink
              to="/agents-agencies"
              title={t("app.main.layout.subTopBar.title8", "AGENTS")}
              iconComponent={<PeopleOutlineRoundedIcon/>}
              authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]}
          /></div>
        <div>
          <IconNavLink
              to='/configurations'
              title={t("app.main.configuration.sidebar.title5", "Configurations")}
              iconComponent={<SettingsIcon/>}
              authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]}
          />
        </div>
        {/*<IconNavLink*/}
        {/*  disabled*/}
        {/*  to='/clientele'*/}
        {/*  title='Klientela'*/}
        {/*  iconComponent={<PeopleOutlineRoundedIcon />}*/}
        {/*/>*/}
      </div>
  );
};

export default SubTopbarMenu;
