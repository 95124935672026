import AgencyPage from "./AgencyPage";
import {PageConfigTypes} from "app/types/PageTypes";
import AgentsList from './agents-list/AgentsList';
import {ROLES} from "app/constants/roles";
import AgentsWithDebt from "./agents-list/AgentsWithDebt";
import AgentsWithoutDebt from "./agents-list/AgentsWithoutDebt";
import CreateFlightForm from "../flights/create/CreateFlightForm";
import CreateAgent from "./create/CreateAgent";

const AgencyPageConfig: PageConfigTypes = {
  baseRoute: {
    path: "/agents-agencies",
    component: AgencyPage,
    authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]
  },
  subRoutes: [
    {
      path: "/agents-agencies/list",
      component: AgentsList
    },
    {
      path: "/agents-agencies/agents-debt-list",
      component: AgentsWithDebt
    },
    {
      path: "/agents-agencies/agents-without-debt-list",
      component: AgentsWithoutDebt
    },
  ],
  settings: {
    quickPanel: {
      create: {
        path: "/agents-agencies/list/create",
        component: CreateAgent,
      },
    },
    navbar: {
      open: true,
      title: "app.main.sidebar.agents.title1",
      subtitle: "app.main.sidebar.agents.title2",
      items: [
        {
          to: "/list",
          primaryText: "app.main.sidebar.agents.title3",
          icon: "play_arrow",
          exact: true,
        },
        {
          primaryText: "app.main.sidebar.agents.title4",
          exact: true,
          to: "/agents-debt-list",
          activeClassName: "",
          icon: "play_arrow",
        },
        {
          primaryText: "app.main.sidebar.agents.title5",
          exact: true,
          to: "/agents-without-debt-list",
          activeClassName: "",
          icon: "play_arrow",
        },
      ],
    },
  },
};

export default AgencyPageConfig;
