import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {AgentService} from "app/services";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {fetchAllAgents} from "./agents-list.slice";
import {UpdateAgentAccessibility} from "../../../types/service-types/AgentTypes";

interface UpdateAccessibilityStateParams {
  id: string;
  body: UpdateAgentAccessibility;
  onSuccess?: Function;
  onError?: Function;
}

export const updateAccessibility = (params: UpdateAccessibilityStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AgentService.updateAccessibility(params.id, params.body);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Information changed successfully!' : 'Informatat u ndryshuan me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(""));
      // @ts-ignore
      dispatch(fetchAllAgents());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
};

interface UpdateAccessibilitySlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: UpdateAccessibilitySlice = {
  error: "",
  success: false,
  loading: false,
};

const stateSlice = createSlice({
  name: "agentedit/update-accessibility",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;