import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import NoDataTableRow from "../../../shared-components/base-table/NoDataTableRow";
import LoadingTableRow from "../../../shared-components/base-table/LoadingTableRow";
import {useTableRowStyles} from "../../../shared-components/multiline-table/multiline-table.styles";
import {ProvisionList} from "../../../../types/service-types/AgentTypes";
import MultilineTableCell from "../../../shared-components/multiline-table/MultilineTableCell";
import Grid from "@material-ui/core/Grid";
import {
  updatePassengerFlightStyles
} from "../../../schedules/update-passenger-flight/update-passenger-flight.styles";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {useRouteMatch} from "react-router-dom";
import DateUtils from "../../../../utils/DateUtils";
import useAuthority from "../../../../hooks/useAuthority";
import {ROLES} from "../../../../constants/roles";

interface IProps {
  data?: ProvisionList | null;
  loading: boolean;
}

const AgentProvisionTable = (props: IProps) => {
  const {t} = useTranslation();
  const {data} = props;
  const {hasUserAnyAuthority} = useAuthority();
  const classes = useTableRowStyles();
  const stylesForTotalPrice = updatePassengerFlightStyles();
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open);
  const {params}: any = useRouteMatch();
  const balances = useSelector((state: AppState) => state.agents.agent.agent?.balances);
  const currentBalance = balances?.find((balance) => balance.currencyResponse.id == params.currencyId);
  const account = useSelector((state: AppState) => state.account.account);

  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                {t("app.main.agency.component.agentTable.reservation", "No. Reservation")}
              </TableCell>
              <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                {t("app.main.agency.component.agentTable.cell1", "Time of transaction")}
              </TableCell>
              <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                {t("app.main.report.reportList.responsible", "Përgjegjës")}
              </TableCell>
              <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                {t("app.main.agency.agentsList.agentEdit.accountIdentification.label1", "State")}
              </TableCell>
              <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                {t("app.main.schedules.payments.amount", "Amount")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.loading ? (
                <LoadingTableRow colSpan={6}/>
            ) : !data ? (
                <NoDataTableRow colSpan={6}/>
            ) : (
                data?.payments?.map((row: any) => {
                  const confCode = row.confirmationCode ? row.confirmationCode : "";

                  return (
                      <React.Fragment>
                        <TableRow onClick={toggleOpen} className={classes.root}>
                          <TableCell style={{textAlign: 'center'}}>
                            <MultilineTableCell {...props} content={[confCode]}/>
                          </TableCell>
                          <TableCell style={{textAlign: 'center'}}>
                            <MultilineTableCell {...props} content={[
                              DateUtils.toHumanDate(
                                  DateUtils.convertDate(row.transactionTime),
                                  new Date(row.date).toLocaleTimeString()
                              )
                            ]}/>
                          </TableCell>

                          <TableCell style={{textAlign: 'center'}}>
                            <MultilineTableCell {...props} content={[`${row.fullName}`]}/>
                          </TableCell>
                          <TableCell style={{textAlign: 'center'}}>
                            <MultilineTableCell {...props} content={[`${row.status}`]}/>
                          </TableCell>
                          <TableCell style={{textAlign: 'center'}}>
                            <MultilineTableCell {...props}
                                                content={[`${row.amount} ${row.currency.symbol}`]}/>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                  );
                })
            )}
          </TableBody>

        </Table>
        <Grid container>
          <Grid item style={{flexGrow: 1}}>
            <div className={stylesForTotalPrice.priceRow}>
              <p className={stylesForTotalPrice.price}>
                {t("app.main.schedule.scheduleForm.thirdStep.total", "Totali")}:{" "}
                <span className={stylesForTotalPrice.weight500}>
                      {data?.total}
                  {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) && <>{currentBalance?.currencyResponse.symbol}</>}
                  {hasUserAnyAuthority([ROLES.ROLE_AGENT]) && <>{account.account!.balanceResponse[0].currencyResponse.symbol}</>}
                </span>
              </p>
            </div>
          </Grid>
        </Grid>
      </TableContainer>
  );
};

export default AgentProvisionTable;
