import React from 'react';
import {Pagination} from "@material-ui/lab";
import {Paper} from "@material-ui/core";

import useStyles from './pagination-bar.styles';

interface IProps {
  totalPages?: number;
  onPageChange: Function;
  currentPage?: number;
}

const PaginationBar = (props: IProps) => {
  const classes = useStyles();

  const onChangeHandler = (e: any, value: number) => {
    const page = (value - 1);
    props.onPageChange(page);
  }

  return (
      <Paper className={classes.root}>
        <Pagination
            page={props?.currentPage}
            count={props?.totalPages}
            onChange={onChangeHandler}
            shape="rounded"
        />
      </Paper>
  )
}

export default PaginationBar;
