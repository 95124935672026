import React from 'react';
import {Redirect, Switch} from "react-router-dom";
import {BasePage} from "app/types/PageTypes";

const CustomerPage = (props: BasePage) => {
  return (
      <Switch>
        {props.children}
        <Redirect to='/customer/customers-list'/>
      </Switch>
  );
};

export default CustomerPage;