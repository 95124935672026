import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {MenuItem, TextField} from "@material-ui/core";
import {smsSmtpStyles} from "./smssmtp.style";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {SmsEmailService} from "app/services";
import {successMsg} from "app/utils/MessageUtils";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const SmsSmtp = (props: any) => {
  const classes = smsSmtpStyles();
  const dispatch = useDispatch();

  const [smsFromName, setSmsFromName] = useState("");
  const [smsApiKey, setSmsApiKey] = useState("");
  const [smsGatewayName, setSmsGatewayName] = useState("");
  const [smsBaseUrl, setSmsBaseUrl] = useState("");

  const [emailFromName, setEmailFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [smtpServer, setSmtpServer] = useState("");
  const [secure, setSecure] = useState("");
  const [port, setPort] = useState("");
  const [smtpUsername, setSmtpUsername] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const {t} = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    SmsEmailService.getSmsConfiguration().then((res) => {
      setSmsFromName(res.smsFromName);
      setSmsApiKey(res.smsApiKey);
      setSmsGatewayName(res.smsGatewayName);
      setSmsBaseUrl(res.smsBaseUrl);

      setEmailFromName(res.emailFromName);
      setFromEmail(res.fromEmail);
      setSmtpServer(res.smtpServer);
      setSecure(res.secure);
      setPort(res.port);
      setSmtpUsername(res.smtpUsername);
      setSmtpPassword(res.smtpPassword);

      setShow(true);
    });
  }, []);

  const getSMSconfigurationInitialValues = () => {
    let smsConfiguration = {
      smsFromName: smsFromName,
      smsApiKey: smsApiKey,
      smsGatewayName: smsGatewayName,
      smsBaseUrl: smsBaseUrl,

      emailFromName: emailFromName,
      fromEmail: fromEmail,
      smtpServer: smtpServer,
      secure: secure,
      port: port,
      smtpUsername: smtpUsername,
      smtpPassword: smtpPassword,
    };

    return smsConfiguration;
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: "#0D3B66"}}>{t("app.main.configuration.sms.title", "SMS gateway configuration")}:</h1>
        </div>
        {show ? (
            <Formik
                initialValues={getSMSconfigurationInitialValues()}
                onSubmit={(data) => {
                  const {
                    smsFromName,
                    smsApiKey,
                    smsGatewayName,
                    smsBaseUrl,
                    emailFromName,
                    fromEmail,
                    smtpServer,
                    secure,
                    port,
                    smtpUsername,
                    smtpPassword,
                  } = data;

                  const body = {
                    smsFromName: smsFromName,
                    smsApiKey: smsApiKey,
                    smsGatewayName: smsGatewayName,
                    smsBaseUrl: smsBaseUrl,

                    emailFromName: emailFromName,
                    fromEmail: fromEmail,
                    smtpServer: smtpServer,
                    secure: secure,
                    port: port,
                    smtpUsername: smtpUsername,
                    smtpPassword: smtpPassword,
                  };

                  SmsEmailService.updateSmsConfiguration(body).then((res) => {
                    dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'The configuration for SMS/SMTP was changed successfully!' : 'Konfigurimi per SMS/SMTP u ndryshua me sukses!'));
                  });

                  console.log(data);
                }}
            >
              {({values}) => (
                  <Form>
                    <div className={classes.row}>
                      <Field
                          variant="outlined"
                          value={values.smsFromName}
                          name="smsFromName"
                          label={t("app.main.configuration.sms.input1", "Send SMS on behalf of")}
                          as={TextField}
                          required
                      />
                      <Field
                          variant="outlined"
                          value={values.smsGatewayName}
                          name="smsGatewayName"
                          label={t("app.main.configuration.sms.input2", "Send by SMS company gateway")}
                          select
                          as={TextField}
                          required
                      >
                        <MenuItem value="Infobip.com Gateway">
                          Infobip.com Gateway
                        </MenuItem>
                      </Field>
                    </div>
                    <div className={classes.row}>
                      <Field
                          variant="outlined"
                          value={values.smsApiKey}
                          name="smsApiKey"
                          label={t("app.main.configuration.sms.input3", "Gateway API authentication")}
                          as={TextField}
                      />
                    </div>
                    <div className={classes.formHeader}>
                      <h1 style={{color: "#0D3B66"}}>
                        {t("app.main.configuration.email.title", "E-mail SMPT server configuration")}:
                      </h1>
                    </div>
                    <div className={classes.row}>
                      <Field
                          variant="outlined"
                          value={values.emailFromName}
                          name="emailFromName"
                          label={t("app.main.configuration.email.input1", "Send E-mail on behalf of")}
                          as={TextField}
                      />
                      <Field
                          variant="outlined"
                          value={values.fromEmail}
                          name="fromEmail"
                          label={t("app.main.configuration.email.input2", "Send E-mail from address")}
                          as={TextField}
                          type="email"
                      />
                    </div>
                    <div className={classes.row}>
                      <Field
                          variant="outlined"
                          value={values.smtpServer}
                          name="smtpServer"
                          label="SMTP server:"
                          as={TextField}
                      />
                      <Field
                          variant="outlined"
                          value={values.secure}
                          name="secure"
                          label="Secure:"
                          select
                          as={TextField}
                      >
                        <MenuItem value="TLS">TLS</MenuItem>
                      </Field>
                      <Field
                          variant="outlined"
                          value={values.port}
                          name="port"
                          label="Port:"
                          select
                          as={TextField}
                      >
                        <MenuItem value="587">587</MenuItem>
                      </Field>
                    </div>
                    <div className={classes.row}>
                      <Field
                          variant="outlined"
                          value={values.smtpUsername}
                          name="smtpUsername"
                          label="SMTP username:"
                          as={TextField}
                      />
                      <Field
                          variant="outlined"
                          value={values.smtpPassword}
                          name="smtpPassword"
                          label="SMTP password:"
                          as={TextField}
                          type="password"
                      />
                    </div>
                    <div className={classes.row}>
                      <LoadingButton
                          type="submit"
                          btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                          isLoading={false}
                      />
                    </div>
                  </Form>
              )}
            </Formik>
        ) : (
            <></>
        )}
      </div>
  );
};

export default SmsSmtp;
