import axios from './axios.interceptors';
import {CustomerTypes, FilterCustomersQueryParams, GetCustomerTypes,} from "app/types/service-types/CustomerTypes";

class CustomerServices {
  fetchAll = async (): Promise<GetCustomerTypes> => {
    try {
      const response = await axios.get('/api/customers');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  filterCustomers = async (queryParams: FilterCustomersQueryParams): Promise<any> => {
    try {
      const response = await axios.get(`/api/customers`, {params: queryParams});
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  createCustomer = async (body: CustomerTypes): Promise<any> => {
    try {
      return await axios.post('/api/customers', body);
    } catch (err) {
      throw err;
    }
  }

  activateCustomer=async(id:string)=>{
    try {
      return await axios.patch(`/api/customers/activate/${id}`)
    }catch (err){
      throw err;
    }
  }

  deactivateCustomer=async (id:string)=>{
    try {
     return await axios.patch(`/api/customers/deactivate/${id}`)
    }catch (err){
      throw err;
    }

  }

  updateCustomer = async (id: string, body: CustomerTypes) => {
    try {
      return await axios.put(`/api/customers/${id}`, body);
    } catch (err) {
      throw err;
    }
  }

  deleteCustomer = async (id: string): Promise<any> => {
    try {
      return await axios.delete(`/api/customers/${id}`);
    } catch (err) {
      throw err;
    }
  }

}

export default new CustomerServices();