import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {ReservationService} from "app/services";
import {filterSchedules} from "./filter-schedules.slice";
import store from "../../../store";

interface DeleteTravelerFromReservation {
  reservationId: string;
  travelerId: string;
  status: boolean;
  onSuccess?: Function;
  onError?: Function;
}

export const deleteTraveler =
    (params: DeleteTravelerFromReservation) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.deleteTravelerFromReservation(
            params.reservationId,
            params.travelerId,
            params.status
        );
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Passenger deleted successfully!' : 'Pasagjeri u fshi me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

interface DeleteTravelerFormReservationSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: DeleteTravelerFormReservationSlice = {
  error: "",
  success: false,
  loading: false,
};

const stateSlice = createSlice({
  name: "schedules/traveler/delete",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;
