import {showMessage} from 'app/store/App/message.slice';

const msgConfig = {
  autoHideDuration: 6000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}

export const errorMsg = (msg: string) => {
  return showMessage({
    message: msg,
    variant: 'error',
    ...msgConfig
  });
}

export const successMsg = (msg: string) => {
  return showMessage({
    message: msg,
    variant: 'success',
    ...msgConfig
  });
}

export const warningMsg = (msg: string) => {
  return showMessage({
    message: msg,
    variant: 'warning',
    ...msgConfig
  });
}

export const infoMsg = (msg: string) => {
  return showMessage({
    message: msg,
    variant: 'info',
    ...msgConfig
  });
}