import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {AgentService} from "app/services";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {UpdateAgentBodyTypes} from "app/types/service-types/AgentTypes";
import {fetchAllAgents} from "./agents-list.slice";

interface UpdateAgentStateParams {
  id: string;
  body: UpdateAgentBodyTypes;
  onSuccess?: Function;
  onError?: Function;
}

export const updateAgent = (params: UpdateAgentStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AgentService.updateAgent(params.id, params.body);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Agent changed successfully!' : 'Agenti u ndryshua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(""));
      // @ts-ignore
      dispatch(fetchAllAgents());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
};

interface UpdateAgentSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: UpdateAgentSlice = {
  error: "",
  success: false,
  loading: false,
};

const stateSlice = createSlice({
  name: "agent/update-agent",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;
