import {Grid, MenuItem, TextField} from "@material-ui/core";
import {Field} from "formik";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useTranslation} from "react-i18next";
import {fillEmployeeFormField} from "../store/employee-form.slice";

const EmployeeEditInformation = () => {
    const dispatch = useDispatch();
    const employeeForm = useSelector((state: AppState) => state.employees.employeeForm);
    const {t} = useTranslation();

    const handleChange = (e: any) => {
        const payload = {
            value: e.target.value,
            field: e.target.name
        }
        dispatch(fillEmployeeFormField(payload))
    }

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Field
                        style={{width: '100%'}}
                        variant='outlined'
                        value={employeeForm.fullName}
                        name='fullName'
                        label={t("app.main.employees.create.employeeName", "Employee name")}
                        as={TextField}
                        required
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Field
                        style={{width: '100%'}}
                        variant='outlined'
                        value={employeeForm.email}
                        name='email'
                        label={t("app.main.employees.employeeList.table.email", "Email")}
                        as={TextField}
                        required
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        style={{width: '100%'}}
                        variant="outlined"
                        value={employeeForm.role}
                        name="role"
                        required
                        label={t("app.main.employees.create.dropdown.title", "Role")}
                        select
                        as={TextField}
                        onChange={handleChange}
                    >
                        <MenuItem value="ADMIN">Admin</MenuItem>
                        <MenuItem value="CALL_AGENT">Call Agent</MenuItem>
                    </Field>
                </Grid>
            </Grid>

        </div>
    )
}

export default EmployeeEditInformation;
