import {makeStyles, Theme} from "@material-ui/core/styles";

export const addPassengerReturnFlightStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(0, 5)
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    margin: theme.spacing(1.5, 0)
  }
}))