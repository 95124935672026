import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {FlightResultTypes, ResultFlightsResponse,} from "app/types/service-types/ScheduleTypes";
import {FlightService} from "app/services";
import {FlightSearchRequest} from "app/types/service-types/FlightTypes";

interface FetchAllFlightsStateParams {
  queryParams: FlightSearchRequest;
  onSuccess?: Function;
  onError?: Function;
}

export const fetchAllFlightResults =
    (params: FetchAllFlightsStateParams) => async (dispatch: Dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await FlightService.searchFlights(params.queryParams);
        dispatch(setFlightResults(response));
        if (params.onSuccess) params.onSuccess();
        console.log(response);
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        if (params.onError) params.onError();
      }
    };

export const fetchAllReturnFlightResults =
    (params: FetchAllFlightsStateParams) => async (dispatch: Dispatch) => {
      try {
        dispatch(setReturnFlightResultsLoading(true));
        const response = await FlightService.searchFlights(params.queryParams);
        dispatch(setReturnFlightResults(response));
        if (params.onSuccess) params.onSuccess();
        console.log(response);
        dispatch(setReturnFlightResultsLoading(false));
      } catch (err) {
        dispatch(setReturnFlightResultsLoading(false));
        if (params.onError) params.onError();
      }
    };

interface StateTypes {
  flightResults: ResultFlightsResponse | null;
  selectedFlight: FlightResultTypes | null;
  loading: boolean;
  returnFlightResults: ResultFlightsResponse | null;
  selectedReturnFlight: FlightResultTypes | null;
  returnFlightResultsLoading: boolean;
  selectedFlightResultsLoading: boolean;
}

const initialState: StateTypes = {
  flightResults: null,
  selectedFlight: null,
  loading: false,
  returnFlightResults: null,
  selectedReturnFlight: null,
  returnFlightResultsLoading: false,
  selectedFlightResultsLoading: false,
};

const stateSlice = createSlice({
  name: "schedule/flight-results",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFlightResults: (state, action) => {
      state.flightResults = action.payload;
    },
    setSelectedFlight: (state, action) => {
      state.selectedFlight = action.payload;
    },
    setReturnFlightResultsLoading: (state, action) => {
      state.returnFlightResultsLoading = action.payload;
    },
    setSelectedFlightResultsLoading: (state, action) => {
      state.selectedFlightResultsLoading = action.payload;
    },
    setReturnFlightResults: (state, action) => {
      state.returnFlightResults = action.payload;
    },
    setSelectedReturnFlight: (state, action) => {
      state.selectedReturnFlight = action.payload;
    },
    clearFlightResults: (state) => initialState,
  },
});

export const {
  setLoading,
  setFlightResults,
  setSelectedFlight,
  setReturnFlightResultsLoading,
  setReturnFlightResults,
  setSelectedReturnFlight,
  clearFlightResults,
} = stateSlice.actions;

export default stateSlice.reducer;
