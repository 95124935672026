import axios from './axios.interceptors';
import {AirlineCompany} from "app/types/service-types/AirlineCompaniesTypes";
import {AirlineRequestBody} from "../types/service-types/AirlineCompaniesTypes";

class AirlineCompaniesServices {
  public getAll = async (): Promise<AirlineCompany[]> => {
    try {
      const response = await axios.get('/api/airline-companies');
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  createAirline = async (body: AirlineRequestBody): Promise<any> => {
    try {
      const response = await axios.post('/api/airline-companies', body);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  deleteAirline = async (id: string): Promise<any> => {
    return await axios.delete(`/api/airline-companies/${id}`);
  }

  updateAirline = async (id: string, body: AirlineRequestBody): Promise<any> => {
    return await axios.put(`/api/airline-companies/${id}`, body);
  }
}

export default new AirlineCompaniesServices();