import FlightsPage from "./FlightsPage";
import {PageConfigTypes} from "app/types/PageTypes";
import FlightsListPage from "./flights-list/FlightsListPage";
import CreateFlightForm from "./create/CreateFlightForm";
import FlightsListPageMonday from "./flights-list/FlightsListPageMonday";
import FlightsListPageTuesday from "./flights-list/FlightsListPageTuesday";
import FlightsListPageWednesday from "./flights-list/FlightsListPageWednesday";
import FlightsListPageThursday from "./flights-list/FlightsListPageThursday";
import FlightsListPageFriday from "./flights-list/FlightsListPageFriday";
import FlightsListPageSaturday from "./flights-list/FlightsListPageSaturday";
import FlightsListPageSunday from "./flights-list/FlightsListPageSunday";
import FlightsListPageCurrent from "./flights-list/FlightsListPageCurrent";

const FlightsPageConfig: PageConfigTypes = {
  baseRoute: {
    path: "/flights",
    component: FlightsPage,
  },
  subRoutes: [
    {
      path: "/flights/flights-list",
      component: FlightsListPage,
    },
    {
      path: "/flights/flights-list-monday",
      component: FlightsListPageMonday,
    },
    {
      path: "/flights/flights-list-tuesday",
      component: FlightsListPageTuesday,
    },
    {
      path: "/flights/flights-list-wednesday",
      component: FlightsListPageWednesday,
    },
    {
      path: "/flights/flights-list-thursday",
      component: FlightsListPageThursday,
    },
    {
      path: "/flights/flights-list-friday",
      component: FlightsListPageFriday,
    },
    {
      path: "/flights/flights-list-saturday",
      component: FlightsListPageSaturday,
    },
    {
      path: "/flights/flights-list-sunday",
      component: FlightsListPageSunday,
    },
    {
      path: "/flights/flights-current",
      component: FlightsListPageCurrent
    },
    {
      path: "/flights/sub-path",
      component: () => (
          <div style={{background: "#e92"}}>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>Flights Subpath</p>
            <p>FlightsSubpath</p>
          </div>
      ),
    },
  ],
  settings: {
    quickPanel: {
      create: {
        path: "/flights/flights-list/create",
        component: CreateFlightForm,
      },
    },
    navbar: {
      open: true,
      title: "app.main.sidebar.flight.title1",
      subtitle: "app.main.sidebar.flight.title2",
      items: [
        {
          to: "/flights-list",
          primaryText: "app.main.sidebar.flight.title3",
          activeStyle: {fontWeight: "bold"},
          icon: "flight",
          exact: true,
        },
        {
          primaryText: "app.main.sidebar.flight.title4",
          exact: true,
          to: "/flights-list-monday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title5",
          exact: true,
          to: "/flights-list-tuesday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title6",
          exact: true,
          to: "/flights-list-wednesday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title7",
          exact: true,
          to: "/flights-list-thursday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title8",
          exact: true,
          to: "/flights-list-friday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title9",
          exact: true,
          to: "/flights-list-saturday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title10",
          exact: true,
          to: "/flights-list-sunday",
          activeClassName: "",
          icon: "today",
        },
        {
          primaryText: "app.main.sidebar.flight.title11",
          exact: true,
          to: "/flights-current",
          activeClassName: "",
          icon: "flight",
        },
      ],
    },
  },
};

export default FlightsPageConfig;
