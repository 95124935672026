import React from 'react';
import {Icon, IconButton} from "@material-ui/core";
import {tableActionButtonsStyles} from './table.styles';
import {TableActionsTypes} from 'app/types/TableTypes';

const TableActionButtons = (props: TableActionsTypes) => {
  const classes = tableActionButtonsStyles();

  const renderActionButtons = () => {
    return Object.keys(props).map((propKey: string, idx) => {
      const btnProps = getButtonProps(propKey);

      if (btnProps) {
        return <IconButton
            key={propKey + idx}
            className={classes.icon}
            size='small'
            onClick={() => btnProps?.clickEvt && btnProps.clickEvt(props.rowId, props?.name)}>
          <Icon>{btnProps.icon}</Icon>
        </IconButton>
      }
    });
  }

  const getButtonProps = (propKey: string) => {
    switch (propKey) {
      case 'onDeleteRow':
        return {
          icon: 'delete',
          clickEvt: props[propKey]
        };
      case 'onEditRow':
        return {
          icon: 'edit',
          clickEvt: props[propKey]
        };
      case 'onViewRow':
        return {
          icon: 'visibility',
          clickEvt: props[propKey]
        };
      case 'onShoppingRow':
        return {
          icon: 'shopping_cart',
          clickEvt: props[propKey]
        };
      case 'onDeactivateRow':
        return {
          icon: 'do_disturb_alt',
          clickEvt: props[propKey]
        };
      case 'onActivateRow':
        return {
          icon: 'check',
          clickEvt: props[propKey]
        };


      default:
        return null;
    }
  }

  return (
      <div className={classes.container}>
        {renderActionButtons()}
      </div>
  )
}

export default TableActionButtons;