import {updateAgentsStyles} from "../update/update-agent.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React from "react";
import {resetAgentForm} from "../store/agent-form.slice";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {Field, Form, Formik} from "formik";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {Button, Grid, MenuItem, TextField, Typography} from "@material-ui/core";
import PageHeader from "../../shared-components/page-header/PageHeader";
import {useTranslation} from "react-i18next";
import {updateAccessibility} from "../store/update-accessibility.slice";
import {resetAgentIdentificationForm, setAgentIdentificationForm} from "../store/agent-identification-form.slice";

const AgentIdentification = () => {
    const classes = updateAgentsStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {params}: any = useRouteMatch();
    const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
    const updateAgentLoading = useSelector((state: AppState) => state.agents.updateAgent.loading);
    const agentForm = useSelector((state: AppState) => state.agents.agentForm);
    const identificationForm = useSelector((state: AppState) => state.agents.agentIdentificationForm)

    const handleChange = (e: any) => {
        dispatch(
            setAgentIdentificationForm({
                ...identificationForm,
                [e.target.name]: e.target.value
            })
        )
    };

    if (agentLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`${t("app.main.agency.agentsList.agentEdit.title", "Agent information")} ${agentForm.fullName}`}
                desc={t("app.main.agency.agentsList.agentEdit.desc", "To change the information, change the form below and click Save Changes")}
                backButton={() => {
                    dispatch(resetAgentIdentificationForm());
                    history.push("/agents-agencies/list");
                }}
            />
            <div className={classes.formWrapper}>
                <Formik
                    initialValues={identificationForm}
                    onSubmit={() => {

                        dispatch(updateAccessibility({
                            id: params.id,
                            body: {
                                ...identificationForm
                            },
                            onSuccess: () => {
                                history.push("/agents-agencies/list");
                                dispatch(resetAgentIdentificationForm());
                            },
                        }))
                    }}
                >
                    <Form>
                        <div className={classes.row}
                             style={{
                                 borderTop: "1px solid #e7e7e7",
                                 borderBottom: "1px solid #e7e7e7",
                             }}>
                            <Typography style={{margin: "0.4rem 8.45rem 0 0.2rem", width: "137px"}}>
                                {t("app.main.agency.agentsList.agentEdit.accountIdentification.label1", "State")}:
                            </Typography>
                            <Field
                                onChange={handleChange}
                                variant="outlined"
                                name="status"
                                value={identificationForm.status}
                                size='small'
                                select
                                as={TextField}
                                required
                            >
                                <MenuItem key={'true'} value='true'>
                                    Active
                                </MenuItem>
                                <MenuItem key={'false'} value='false'>
                                    Deactivate
                                </MenuItem>
                            </Field>
                        </div>
                        <div className={classes.row}
                             style={{
                                 borderTop: "1px solid #e7e7e7"
                             }}>
                            <Typography style={{margin: "0.4rem 8.45rem 0 0.2rem", width: "137px"}}>
                                {t("app.main.agency.create.identifyEmail", "Identification (E-mail)")}:
                            </Typography>
                            <Field
                                style={{width: '100%'}}
                                label={t("app.main.agency.create.identifyEmail", "Identification (E-mail)")}
                                onChange={handleChange}
                                value={identificationForm.email}
                                variant='outlined'
                                size='small'
                                name='email'
                                as={TextField}
                                required
                            />
                        </div>
                        <div className={classes.row}>
                            <Typography style={{margin: "0.4rem 8.45rem 0 0.2rem", width: "137px"}}>
                                {t("app.main.employees.create.password", "Password")}:
                            </Typography>
                            <Field
                                type='password'
                                style={{width: '100%'}}
                                label={t("app.main.employees.create.password", "Password")}
                                onChange={handleChange}
                                value={identificationForm.password}
                                variant='outlined'
                                size='small'
                                name='password'
                                as={TextField}
                            />
                        </div>
                        <Grid container justify='flex-end' alignItems='center' style={{marginTop: '20px'}}>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                                    size='medium'
                                    isLoading={updateAgentLoading}
                                />
                                <Button
                                    onClick={() => {
                                        dispatch(resetAgentForm());
                                        history.push('/agents-agencies/list')
                                    }}>
                                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default AgentIdentification;