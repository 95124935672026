import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from './assets/locales/en.json';
import sq from './assets/locales/sq.json';
import dash from './assets/locales/dash.json';
import AppUtils from "./app/utils/AppUtils";

const lng = AppUtils.parseLanguageLocaleString(localStorage.getItem("i18nextLng"));

i18n.use(LanguageDetector).init({
  resources: {
    en: {translations: en},
    sq: {translations: sq},
    ds: {translations: dash}
  },
  lng: lng,
  debug: true,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
});

export default i18n;
