import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import PageHeader from "../../shared-components/page-header/PageHeader";
import React, {useEffect, useState} from "react";
import history from '@history';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router-dom";
import ModalDialog from "../../shared-components/modal-dialog/ModalDialog";
import {Column, TableActionsTypes} from "../../../types/TableTypes";
import TableActionButtons from "../../shared-components/base-table/TableActionButtons";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {AppState} from "../../../store";
import {fetchAllSmsTemplates} from "../store/sms-templates/sms-template.slice";
import {deleteSmsTemplate} from "../store/sms-templates/delete-sms-template.slice";

const SmsTemplates = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  let {url} = useRouteMatch();

  const {
    smsTemplates,
    loading
  } = useSelector((state: AppState) => state.configurations.smsTemplates.fetchAllSmsTemplates);

  const [modal, setModal] = useState({
    state: false,
    deleteId: '',
    smsTitle: ''
  });

  const tableActions: TableActionsTypes = {
    onEditRow: (rowId: number) => {
      dispatch(toggleQuickPanel());
      history.push(`${url}/update/${rowId}`);
    },
    onDeleteRow: (rowId: string, name: string) => {
      setModal({state: true, deleteId: rowId, smsTitle: name});
    }
  }

  const columns: Column[] = [
    {
      Header: `${t("app.main.configuration.smsTemplates.title", "Title")}`,
      accessor: 'title'
    },
    {
      Header: `${t("app.main.configuration.smsTemplates.message", "Message")}`,
      accessor: 'message'
    },
    {
      Header: `${t("app.main.employees.employeeList.table.actions", "Actions")}`,
      accessor: 'id',
      Cell: ({cell}: any) => (
          <TableActionButtons {...tableActions} rowId={cell.value} name={cell.row.original.title}/>
      )
    }
  ];

  useEffect(() => {
    dispatch(fetchAllSmsTemplates());
  }, [])

  const translateModal = () => {
    let title = t("app.main.configuration.sms.module.title", "Are you sure you want to delete sms template ");

    return title + modal.smsTitle + ' ?';
  }

  return (
      <div>
        <PageHeader
            title={t("app.main.configuration.sidebar.title7", "SMS Templates")}
            desc={t("app.main.configuration.smsTemplates.desc", "If you want to add a new template, click Register")}
            button={t("app.main.agency.agentsList.registerButton", "Register")}
            onClickButton={() => {
              history.push(`${url}/create`)
              dispatch(toggleQuickPanel());
            }}
        />
        <ModalDialog
            open={modal.state}
            title={translateModal()}
            onOk={() => {
              dispatch(deleteSmsTemplate({id: modal.deleteId}));
              setModal({state: false, deleteId: '', smsTitle: ''});
            }}
            onCancel={() => setModal({state: false, deleteId: '', smsTitle: ''})}
        />
        <div style={{whiteSpace: 'break-spaces'}}>
          <BaseTable columns={columns} data={smsTemplates} isLoading={loading}/>
        </div>
      </div>)
}

export default SmsTemplates;