import axios from "./axios.interceptors";
import {SmsEmailConfigurations, updateSmsEmailConfigurations,} from "app/types/service-types/SmsEmailTypes";

class SmsEmailMethodsServices {
  getSmsConfiguration = async (): Promise<SmsEmailConfigurations> => {
    try {
      const response = await axios.get("/api/configurations/sms-smtp");
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  updateSmsConfiguration = async (
      body: updateSmsEmailConfigurations
  ): Promise<any> => {
    const response = await axios.put("/api/configurations/sms-smtp", body);
    return response;
  };
}

export default new SmsEmailMethodsServices();
