import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {AgentService} from "../../../services";
import {errorMsg} from "../../../utils/MessageUtils";
import {setLoading} from "./update-agent.slice";

interface AgentLogoImage {
  logoImage: any
}

export const updateAgentImage = (id: string, logoImage: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('logoImage', logoImage);
    const response = await AgentService.uploadAgentLogo(id, data);
    dispatch(setLoading(false));
    dispatch(resetAgentLogoImage());
    return response;
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

const initialState: AgentLogoImage = {
  logoImage: null,
};

const stateSlice = createSlice({
  name: "agent/agent-image-logo",
  initialState,
  reducers: {
    setAgentLogoImage: (state, action) => {
      state.logoImage = action.payload;
    },
    resetAgentLogoImage: (state) => {
      state.logoImage = initialState;
    }
  },
});

export const {setAgentLogoImage, resetAgentLogoImage} = stateSlice.actions;

export default stateSlice.reducer;
