import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import {AppState} from "app/store";
import {useDispatch, useSelector} from 'react-redux';
import {hideMessage} from 'app/store/App/message.slice';

import useStyles from './app-message.styles';

const variantIcon: any = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info'
};

function AppMessage() {
  const dispatch = useDispatch();
  const state = useSelector(({appReducers}: AppState) => appReducers.message.state);
  const options = useSelector(({appReducers}: AppState) => appReducers.message.options);

  const classes: any = useStyles();

  return (
      <Snackbar
          {...options}
          open={state}
          onClose={() => dispatch(hideMessage())}
          classes={{
            root: classes.root
          }}
          ContentProps={{
            // @ts-ignore
            variant: 'body2',
            headlineMapping: {
              body1: 'div',
              body2: 'div'
            }
          }}
      >
        <SnackbarContent
            className={clsx(classes[options.variant])}
            message={
              <div className="flex items-center">
                {variantIcon[options.variant] &&
                    <Icon color="inherit">{variantIcon[options.variant]}</Icon>}
                <Typography className="mx-8">{options.message}</Typography>
              </div>
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit"
                          onClick={() => dispatch(hideMessage())}>
                <Icon>close</Icon>
              </IconButton>
            ]}
        />
      </Snackbar>
  );
}


export default React.memo(AppMessage);