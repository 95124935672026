import {makeStyles, Theme} from "@material-ui/core/styles";

const scheduleDefaults = (theme: Theme) => ({
  row: {
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: 1,
      margin: theme.spacing(0, .5)
    },
    padding: theme.spacing(2.5, 0)
  },
  col: {
    display: 'flex',
    '& > div': {
      flex: 1,
      margin: theme.spacing(2, 0)
    }
  }
})

export const scheduleFormStyles = makeStyles((theme: Theme) => ({
  ...scheduleDefaults(theme),
  formWrapper: {
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1)
    }
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioGroupDiv: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'left',
    // flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    '& label': {
      fontSize: '1rem',
      margin: theme.spacing(0, 1),
      '& input': {marginRight: '5px'}
    }
  },
  alignContentRight: {
    display: 'flex',
    justifyContent: 'right'
  },
  finalFormRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    marginBottom: '25px',
    paddingBottom: '25px',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}));

export const scheduleFinalPageStyles = makeStyles((theme: Theme) => ({
  ...scheduleDefaults(theme)
}))