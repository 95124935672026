import {createSlice, Dispatch} from '@reduxjs/toolkit';
import SmsTemplatesServices from "../../../../services/sms-templates.services";
import {SmsTemplate} from "../../../../types/SmsTemplateTypes";

export const fetchAllSmsTemplates = () => async (dispatch: Dispatch) => {
  setLoading(true);
  const response = await SmsTemplatesServices.getAll();
  dispatch(setSmsTemplates(response));
  setLoading(false);
}

interface SmsTemplatesSlice {
  smsTemplates: SmsTemplate[];
  loading: boolean;
}

const initialState: SmsTemplatesSlice = {
  smsTemplates: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/sms-templates',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSmsTemplates: (state, action) => {
      state.smsTemplates = action.payload
    }
  }
});

export const {setLoading, setSmsTemplates} = stateSlice.actions;

export default stateSlice.reducer;