import {Theme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    '& > div': {
      marginLeft: theme.spacing(2)
    }
  },
  formControl: {
    minWidth: 120,
  },
  totalRes: {
    flex: 'none',
    textAlign: 'right'
  }
}))
