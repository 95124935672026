import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {ReservationService} from 'app/services';
import {ReservationPaymentsTypes} from "app/types/service-types/ReservationTypes";

export const fetchReservationPayments = (id: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  const response = await ReservationService.fetchReservationPayments(id);
  dispatch(setReservationPayments(response));
  dispatch(setLoading(false));
}

interface ReservationPaymentsSlice {
  reservationPayments: ReservationPaymentsTypes[];
  loading: boolean;
}

const initialState: ReservationPaymentsSlice = {
  reservationPayments: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'customer/payments',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setReservationPayments: (state, action) => {
      state.reservationPayments = action.payload
    }
  }
});

export const {setLoading, setReservationPayments} = stateSlice.actions;

export default stateSlice.reducer;