import React from 'react';
import InputBase from "@material-ui/core/InputBase";

import useStyles from './search-input.styles';
import SearchIcon from "@material-ui/icons/Search";
import {useTranslation} from "react-i18next";

interface ISearchInputProps {
  onChange: (value: string) => void;
}

const SearchInput = (props: ISearchInputProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const onSearch = (e: any) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }

  }
  return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon/>
        </div>
        <InputBase
            placeholder={t("app.main.schedules.searchPanel.searchInput", "Search")}
            onChange={onSearch}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{'Montserrat': 'search'}}
        />
      </div>
  );
};

export default SearchInput;