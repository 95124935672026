import React from "react";
import {Redirect} from "react-router-dom";
import {PageConfigTypes} from "app/types/PageTypes";
import DashboardPageConfig from "app/main/dashboard/DashboardPageConfig";
import ConfigurationsPageConfig from "app/main/configurations/ConfigurationsPageConfig";
import SchedulePageConfig from "app/main/schedule/SchedulePageConfig";
import SchedulesPageConfig from "app/main/schedules/SchedulesPageConfig";
import FlightsPageConfig from "app/main/flights/FlightsPageConfig";
import EmployeesPageConfig from "app/main/employees/EmployeesPageConfig";
import CustomersPageConfig from "app/main/customer/CustomerPageConfig";
import AgencyPageConfig from "app/main/agency/AgencyPageConfig";
import FlightsListPageConfig from "../main/flights/FlightsListPageConfig";
import EmployeeEditListPage from "../main/employees/EmployeeEditListPage";
import EmployeeEditPageConfig from "../main/employees/EmployeeEditPageConfig";

const configs: PageConfigTypes[] = [
    DashboardPageConfig,
    ConfigurationsPageConfig,
    SchedulePageConfig,
    SchedulesPageConfig,
    FlightsPageConfig,
    EmployeesPageConfig,
    EmployeeEditPageConfig,
    CustomersPageConfig,
    AgencyPageConfig,
    FlightsListPageConfig,
    {
        baseRoute: {
            path: "/",
            exact: true,
            component: () => <Redirect to="/schedules"/>,
        },
        settings: {},
        subRoutes: [],
    },
];

export default configs;
