import { PageConfigTypes } from "app/types/PageTypes";
import FlightsEditPage from "./flights-list/FlightsEditPage";
import FlightsDeletePage from "./flights-list/FlightsDeletePage";
import FlightsReservationListPage from "./flights-list/FlightsReservationListPage";
import FlightsPassList from "./flights-list/FlightsPassList";
import FlightListPage from "./FlightListPage";
import ActivitiesPage from "../schedules/activities/ActivitiesPage";

const FlightsListPageConfig: PageConfigTypes = {
  baseRoute: {
    path: "/flightsedit/:id",
    component: FlightListPage,
    exact: true,
  },
  subRoutes: [
    {
      path: `/flightsedit/:id/activities`,
      component: ActivitiesPage,
    },
    {
      path: "/flightsedit/:id/activities",
      exact: true,
      component: FlightsDeletePage,
    },
    {
      path: `/flightsedit/:id/edit`,
      component: FlightsEditPage,
    },
    {
      path: "/flightsedit/:id/reservations",
      exact: true,
      component: FlightsReservationListPage,
    },
    {
      path: "/flightsedit/:id/passengers",
      exact: true,
      component: FlightsPassList,
    },
    {
      path: "/flightsedit/sub-path",
      component: () => (
        <div style={{ background: "#e92" }}>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>Flights Subpath</p>
          <p>FlightsSubpath</p>
        </div>
      ),
    },
  ],
  settings: {
    quickPanel: {
      // create: {
      //     path: "/flights/flights-list/create",
      //     component: CreateFlightForm,
      // },
      update: {
        path: "/flightsedit",
        component: () => <p>Update</p>,
      },
    },
    navbar: {
      open: true,
      title: "app.main.schedules.splitPassenger.cell.flight",
      subtitle: "app.main.sidebar.flight.title12",
      items: [
        {
          to: "/edit",
          primaryText: "app.main.sidebar.flight.title13",
          activeStyle: { fontWeight: "bold" },
          icon: "info",
          exact: true,
        },
        {
          primaryText: "app.main.sidebar.flight.title17",
          exact: true,
          to: "/activities",
          activeClassName: "",
          icon: "play_arrow",
        },
        {
          primaryText: "app.main.sidebar.flight.title18",
          exact: true,
          to: "/reservations",
          activeClassName: "",
          icon: "list",
        },
        {
          primaryText: "app.main.sidebar.flight.title19",
          exact: true,
          to: "/passengers",
          activeClassName: "",
          icon: "list",
        },
      ],
    },
  },
};

export default FlightsListPageConfig;
