import {makeStyles} from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // backgroundColor: "url('./landing.jpg')"
  },
  card: {
    padding: '4rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '450px',
    boxShadow: 'none',
    margin: 'auto',
    backgroundColor: 'rgba(255, 255, 255 ,.85)'
  },
  margin: {
    margin: '1rem',
  },
  textField: {
    // width: '25ch',
  },
  logoWrapper: {
    padding: '10px 0 40px 0',
    display: 'block',
    margin: 'auto'
  },
  logo: {
    textAlign: 'center',
    marginBottom: '2rem',
    fontWeight: 'bold',
    color: 'white'
  },
  btnWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

  }
}));