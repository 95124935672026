import React from 'react';

import {Paper, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import NoDataTableRow from './NoDataTableRow';
import {Cell, Column, Row, useTable} from 'react-table';

import {baseTableStyles} from './table.styles';
import LoadingTableRow from "./LoadingTableRow";
import {BaseTableTypes} from "app/types/TableTypes";

const BaseTable = ({
                     columns,
                     data,
                     isLoading,
                     onClickTableRow,
                     disableRow = () => false,
                     activeRow = () => false,
                     selectedRow = () => false
                   }: BaseTableTypes) => {
  const classes = baseTableStyles();

  const {getTableProps, headerGroups, rows, prepareRow} = useTable({
    columns: columns,
    data: data ? data : []
  })

  // const [page, setPage] = React.useState(1);
  // const [rowsPerPage] = React.useState(6);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page - 1) * rowsPerPage);

  const renderCustomHeaderProps = (col: Column) => {
    let width = rows.length > 0 ? 100 : 'auto'

    return {
      style: {
        minWidth: width,
        fontWeight: 600
      },
      align: 'center'
    }
  }

  const renderCustomTDProps = (row: Row, cell: Cell) => {
    if (disableRow(row.original)) {
      return {className: classes.tableCellDisabled}
    }

    return {
      className: classes.center
    }
  }

  const renderCustomTRProps = (row: Row) => {
    if (disableRow(row.original)) {
      return {
        className: classes.tableRowDisabled
      }
    }

    if (activeRow(row.original)) {
      return {
        className: classes.tableRowActive,
        ...(onClickTableRow && {
          onClick: () => {
            onClickTableRow(row.original)
          }
        })
      }
    }

    if (selectedRow(row.original)) {
      return {className: classes.tableRowSelected}
    }

    return onClickTableRow ? {
      hover: true,
      className: classes.tableRowHover,
      onClick: () => onClickTableRow(row.original)
    } : {className: classes.tableRow}
  }

  return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <MaUTable stickyHeader aria-label="sticky table" {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <TableCell
                            className={classes.rowRow} {...column.getHeaderProps(renderCustomHeaderProps(column))}>
                          {column.render('Header')}
                        </TableCell>
                    ))}
                  </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {isLoading ? <LoadingTableRow colSpan={columns.length}/>
                  : rows.length > 0 ? rows.map((row) => {
                        prepareRow(row);
                        return (
                            <TableRow
                                {...renderCustomTRProps(row)}
                                {...row.getRowProps()}
                            >
                              {row.cells.map(cell => {
                                return <TableCell {...cell.getCellProps()}{...renderCustomTDProps(row, cell)}>{cell.render('Cell')}</TableCell>;
                              })}
                            </TableRow>
                        );
                      })
                      : <NoDataTableRow colSpan={columns.length}/>}
            </TableBody>
          </MaUTable>
        </TableContainer>
      </Paper>
  );
};

export default BaseTable;