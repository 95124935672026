import {createSlice, Dispatch} from "@reduxjs/toolkit";
import SmsTemplatesServices from '../../../services/sms-templates.services'
import {errorMsg, successMsg} from '../../../utils/MessageUtils';
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import {filterSchedules} from "./filter-schedules.slice";
import store from "../../../store";

interface MakeSmsForReservationTemplate {
  reservationId: string;
  smsTemplateId: string;
  onSuccess?: Function;
  onError?: Function;
}

export const addSmsTemplateToReservation = (params: MakeSmsForReservationTemplate) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const filters = store.getState().schedules.filterSchedules;
    const response = await SmsTemplatesServices.makeSmsForReservation(params.reservationId, params.smsTemplateId);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'The SMS was sent successfully' : 'SMS u dergua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      dispatch(toggleQuickPanel());
      // @ts-ignore
      dispatch(filterSchedules({
        ...filters
      }));
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface SmsForReservationTemplate {
  success: boolean
  error: string
  loading: boolean
}

const initialState: SmsForReservationTemplate = {
  success: false,
  error: '',
  loading: false
}

const stateSlice = createSlice({
  name: 'schedules/sms-templates',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;