import {makeStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  icon: {
    display: 'flex',
    alignItems: 'center'
  },
  active: {
    background: '#342344',
  },
  listItem: {
    // '&:hover': {
    //   background: '#e7e7e7!important',
    // },
  },
  itemText: {
    '&:active': {
      fontWeight: 700,
      color: '#12518D !important',
      fontSize: '16px !important'
    },
  },
}))