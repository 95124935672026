import React from 'react';
import {BasePage} from "app/types/PageTypes";
import {Redirect, Switch} from "react-router-dom";

const FlightsPage = (props: BasePage) => {
  return (
      <Switch>
        {props.children}
        <Redirect to='/flights/flights-list'/>
      </Switch>
  );
};

export default FlightsPage;