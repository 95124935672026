import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {errorMsg} from "app/utils/MessageUtils";
import {
  FilterProvisionsQueryParams,
  ProvisionList
} from "../../../types/service-types/AgentTypes";
import {AgentService} from "../../../services";

export const fetchAllProvisions = (id: string, queryParams?: FilterProvisionsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await AgentService.filterProvisionsAtAgent(id, queryParams);
    } else response = await AgentService.filterProvisionsAtAgent(id, queryParams);
    dispatch(setProvisionList(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data?.message));
  }
}

interface ProvisionListSlice {
  provisionList: ProvisionList | null;
  loading: boolean;
}

const initialState: ProvisionListSlice = {
  provisionList: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'agentedit/provision-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setProvisionList: (state, action) => {
      state.provisionList = action.payload
    }
  }
});

export const {setLoading, setProvisionList} = stateSlice.actions;

export default stateSlice.reducer;
