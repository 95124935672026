import axios from './axios.interceptors';
import {
  EmployeeTypes,
  FilterEmployeeQueryParams,
  GetEmployeeTypes, UpdateEmployeeScopes,
  UpdateEmployeeTypes
} from "app/types/service-types/EmployeeTypes";
import {UpdateAgentAccessibility} from "../types/service-types/AgentTypes";


class UserServices {
  fetchAll = async (): Promise<GetEmployeeTypes> => {
    try {
      const response = await axios.get('/api/users');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  fetchAllUserEmployee = async (): Promise<GetEmployeeTypes> => {
    try {
      const response = await axios.get('/api/users/employees');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  filterAllUserEmployee = async (queryParams: FilterEmployeeQueryParams): Promise<any> => {
    try {
      const response = await axios.get(`/api/users/employees`, {params: queryParams});
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  createUser = async (body: EmployeeTypes): Promise<any> => {
    try {
      return await axios.post('/api/users', body);
    } catch (err) {
      throw err;
    }
  }

  updateUser = async (id: string, body: EmployeeTypes) => {
    try {
      return await axios.put(`/api/users/${id}`, body);
    } catch (err) {
      throw err;
    }
  }

  updateUserPassword = async (id: string, body: UpdateEmployeeTypes) => {
    try {
      return await axios.put(`/api/users/${id}/password`, body);
    } catch (err) {
      throw err;
    }
  }

  deleteUser = async (id: string): Promise<any> => {
    try {
      return await axios.delete(`/api/users/${id}`);
    } catch (err) {
      throw err;
    }
  }

  updateAccessibility = async (id: string, body: UpdateAgentAccessibility): Promise<any> => {
    try {
      return await axios.put(`/api/users/employees/${id}/accessibility`, body);
    } catch (err) {
      throw err;
    }
  }

  getEmployeeById = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/users/employees/${id}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  activateEmployee = async (id: string) => {
    try {
      return await axios.patch(`/api/users/employees/activate/${id}`)
    } catch (err) {
      throw err;
    }
  }

  deactivateEmployee = async (id: string) => {
    try {
      return await axios.patch(`/api/users/employees/deactivate/${id}`)
    } catch (err) {
      throw err;
    }
  }

  updateScopes = async (id: string, body: UpdateEmployeeScopes): Promise<any> => {
    try {
      return await axios.put(`/api/users/employees/${id}/scopes`, body);
    } catch (err) {
      throw err;
    }
  }

}

export default new UserServices();