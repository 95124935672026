import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {CreateFlightRequestTypes} from "app/types/service-types/FlightTypes"
import {FlightService} from "app/services"
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {fetchAllFlights} from "./flights-list.slice";
import {errorMsg, successMsg} from "app/utils/MessageUtils";

interface CreateFlightStateParams {
  body: CreateFlightRequestTypes;
  onSuccess?: Function;
  onError?: Function;
}

export const createFlight = (params: CreateFlightStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));

    const formatedBodyStructure = {
      ...params.body,
      fareGroupList: [
        {
          // economyWithRestrictions
          seatClass: params.body.economyWithRestrictions.seatClass,
          noOfSeats: params.body.economyWithRestrictions.noOfSeats,
          fareList: [
            {
              fareType: params.body.economyWithRestrictions.fareDefault.fareType,
              amount: params.body.economyWithRestrictions.fareDefault.amount,
              luggage: params.body.economyWithRestrictions.fareDefault.luggage,
            },
            {
              fareType: params.body.economyWithRestrictions.fareOneWay.fareType,
              amount: params.body.economyWithRestrictions.fareOneWay.amount,
              luggage: params.body.economyWithRestrictions.fareOneWay.luggage,
            },
            {
              fareType: params.body.economyWithRestrictions.fareReturn.fareType,
              amount: params.body.economyWithRestrictions.fareReturn.amount,
              luggage: params.body.economyWithRestrictions.fareReturn.luggage,
            },
          ],
        },
        {
          // economyWithoutRestrictions
          seatClass: params.body.economyWithoutRestrictions.seatClass,
          noOfSeats: params.body.economyWithoutRestrictions.noOfSeats,
          fareList: [
            {
              fareType: params.body.economyWithoutRestrictions.fareDefault.fareType,
              amount: params.body.economyWithoutRestrictions.fareDefault.amount,
              luggage: params.body.economyWithoutRestrictions.fareDefault.luggage,
            },
            {
              fareType: params.body.economyWithoutRestrictions.fareOneWay.fareType,
              amount: params.body.economyWithoutRestrictions.fareOneWay.amount,
              luggage: params.body.economyWithoutRestrictions.fareOneWay.luggage,
            },
            {
              fareType: params.body.economyWithoutRestrictions.fareReturn.fareType,
              amount: params.body.economyWithoutRestrictions.fareReturn.amount,
              luggage: params.body.economyWithoutRestrictions.fareReturn.luggage,
            },
          ],
        },
        {
          // economyPremium
          seatClass: params.body.economyPremium.seatClass,
          noOfSeats: params.body.economyPremium.noOfSeats,
          fareList: [
            {
              fareType: params.body.economyPremium.fareDefault.fareType,
              amount: params.body.economyPremium.fareDefault.amount,
              luggage: params.body.economyPremium.fareDefault.luggage,
            },
            {
              fareType: params.body.economyPremium.fareOneWay.fareType,
              amount: params.body.economyPremium.fareOneWay.amount,
              luggage: params.body.economyPremium.fareOneWay.luggage,
            },
            {
              fareType: params.body.economyPremium.fareReturn.fareType,
              amount: params.body.economyPremium.fareReturn.amount,
              luggage: params.body.economyPremium.fareReturn.luggage,
            },
          ],
        },
      ],
    };

    const response = await FlightService.createFlight(formatedBodyStructure);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(toggleQuickPanel());
      dispatch(successMsg(localStorage.getItem("i18nextLng") === "en" ? "Flight added successfully!" : "Fluturimi u shtua me sukses!"));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(""));
      // @ts-ignore
      dispatch(fetchAllFlights());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    const errorMessage = err.data.message.includes("Criteria mismatched in total number of seats between flights and price sectors")
        ? "Criteria mismatched in total number of seats between flights and price sectors"
        : err.data.message;
    dispatch(errorMsg(errorMessage));
    if (params.onError) params.onError();
    dispatch(setError(errorMessage));
  }
};

interface CreateFlightSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: CreateFlightSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'flights/create-flight',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;