import React from 'react';
import clsx from 'clsx';
import useStyles from 'app/main/shared-components/base-dropdown/base-dropdown.styles';
import Button from '@material-ui/core/Button';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {DropdownItemTypes, DropdownTypes} from "app/types/DropdownTypes";
import {Icon} from "@material-ui/core";

const BaseDropdown = (props: DropdownTypes) => {
  const classes = useStyles();

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props: MenuProps) => (
      <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...props}
      />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        minWidth: '60px'
      },
    },
  }))(MenuItem);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
      <>
        <Button className={clsx(classes.boxShadowNone)}
                style={{background: '#45637E'}}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color={props.color}
                onClick={handleClick}
        ><Icon>{props.icon}</Icon>
          {props.title}
          <ExpandMoreIcon/>
        </Button>
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
        >
          {props.items.map((item: DropdownItemTypes, idx: number) => (
              <StyledMenuItem
                  onClick={item.onClick ? item.onClick : () => {
                  }}
                  key={item.toString() + idx}>
                {item.icon && (
                    <ListItemIcon>
                      <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                )}
                <ListItemText>{item.title}</ListItemText>
              </StyledMenuItem>
          ))}
        </StyledMenu>
      </>
  );
}

export default BaseDropdown;