import {combineReducers} from '@reduxjs/toolkit';
import schedulesList from './schedules-list.slice';
import updateSchedule from './update-schedule.slice';
import deleteSchedule from './delete-schedule.slice';
import filterSchedules from './filter-schedules.slice';
import updatePassenger from './update-passenger.slice';
import splitPassengers from './split-passengers.slice';
import reservationPayments from './payments-list.slice';
import addPassenger from './add-passenger.slice';
import addSmsTemplateToReservation from './sms-template.slice';
import phoneNumbers from "./get-phone-numbers.slice";
import dateFilters from './from-to-filter-schedules.slice';

const reducer = combineReducers({
  schedulesList,
  updateSchedule,
  deleteSchedule,
  filterSchedules,
  updatePassenger,
  splitPassengers,
  reservationPayments,
  addPassenger,
  addSmsTemplateToReservation,
  phoneNumbers,
  dateFilters
});

export default reducer;
