import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {Field} from "formik";
import {TextField} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {useDispatch} from "react-redux";
import {setTheSectorPrice} from "../store/flights-list/flight-form.slice";
import {useTranslation} from "react-i18next";

const PricesCurrencyItem = ({price, sectorId}: any) => {
    const dispatch = useDispatch();
    const {oneWayPrices, returnPrices} = price;
    const {t} = useTranslation();

    const handleChange = (e: any) => {
        let value = parseFloat(e.target.value);

        // Round the value to two decimal places
        value = Math.round(value * 100) / 100;

        if (e.target.name === "oneWayForAdults" && value >= 20) {
            dispatch(
                setTheSectorPrice({
                    sectorId,
                    pricePayload: {
                        name: "returnForAdults",
                        value: Math.round((value - 10) * 100) / 100,
                    },
                })
            );
            dispatch(
                setTheSectorPrice({
                    sectorId,
                    pricePayload: {
                        name: "oneWayForChildren",
                        value: Math.round((value - 10) * 100) / 100,
                    },
                })
            );
            dispatch(
                setTheSectorPrice({
                    sectorId,
                    pricePayload: {
                        name: "returnForChildren",
                        value: Math.round((value - 20) * 100) / 100,
                    },
                })
            );
        }
        dispatch(
            setTheSectorPrice({
                sectorId,
                pricePayload: {
                    name: e.target.name,
                    value: value,
                },
            })
        );
        dispatch(
            setTheSectorPrice({
                sectorId,
                pricePayload: {
                    name: "oneWayForInfant",
                    value: 50,
                },
            })
        );
        dispatch(
            setTheSectorPrice({
                sectorId,
                pricePayload: {
                    name: "returnForInfant",
                    value: 40,
                },
            })
        );
    };

    const handleChangeForInfants = (e: any) => {
        let value = parseFloat(e.target.value);

        value = Math.round(value * 100) / 100;

        dispatch(
            setTheSectorPrice({
                sectorId,
                pricePayload: {
                    name: e.target.name,
                    value: value,
                },
            })
        );
    };

    return (
        <TableRow>
            <TableCell style={{borderBottom: "none"}}>
                <Field
                    type="number"
                    step="any"
                    onChange={handleChange}
                    name="oneWayForAdults"
                    label={t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
                    variant="outlined"
                    size="small"
                    value={oneWayPrices.forAdults}
                    style={{margin: "8px"}}
                    as={TextField}
                    required
                />
                <Field
                    type="number"
                    step="any"
                    onChange={handleChange}
                    name="returnForAdults"
                    label={t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
                    variant="outlined"
                    size="small"
                    value={returnPrices.forAdults}
                    style={{margin: "8px"}}
                    as={TextField}
                    required
                />
            </TableCell>
            <TableCell style={{borderBottom: "none"}}>
                <Field
                    type="number"
                    step="any"
                    onChange={handleChange}
                    name="oneWayForChildren"
                    label={t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
                    variant="outlined"
                    size="small"
                    value={oneWayPrices.forChildren}
                    style={{margin: "8px"}}
                    as={TextField}
                    required
                />
                <Field
                    type="number"
                    step="any"
                    onChange={handleChange}
                    name="returnForChildren"
                    label={t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
                    variant="outlined"
                    size="small"
                    value={returnPrices.forChildren}
                    style={{margin: "8px"}}
                    as={TextField}
                    required
                />
            </TableCell>
            <TableCell style={{borderBottom: "none"}}>
                <Field
                    type="number"
                    step="any"
                    onChange={handleChangeForInfants}
                    name="oneWayForInfant"
                    label={t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
                    variant="outlined"
                    size="small"
                    value={oneWayPrices.forInfants}
                    style={{margin: "8px"}}
                    as={TextField}
                    required
                />
                <Field
                    type="number"
                    step="any"
                    onChange={handleChangeForInfants}
                    name="returnForInfant"
                    label={t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
                    variant="outlined"
                    size="small"
                    value={returnPrices.forInfants}
                    style={{margin: "8px"}}
                    as={TextField}
                    required
                />
            </TableCell>
        </TableRow>
    );
};

export default PricesCurrencyItem;
