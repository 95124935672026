import {updateAgentsStyles} from "../update/update-agent.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import {getAgent} from "../store/get-agent.slice";
import {AgentTypes} from "../../../types/service-types/AgentTypes";
import {resetAgentForm, setTheAgentForm} from "../store/agent-form.slice";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {Form, Formik} from "formik";
import {updateAgent} from "../store/update-agent.slice";
import InvoiceInfo from "../components/InvoiceInfo";
import ReservationInfo from "../components/ReservationInfo";
import Credits from "../components/Credits";
import ProvisionInfo from "../components/ProvisionInfo";
import DiscountInfo from "../components/DiscountInfo";
import DebtInfo from "../components/DebtInfo";
import Grid from "@material-ui/core/Grid";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {Button} from "@material-ui/core";
import PageHeader from "../../shared-components/page-header/PageHeader";
import {useTranslation} from "react-i18next";
import AgentEditInformation from "../components/AgentEditInformation";
import {setAgentIdentificationForm} from "../store/agent-identification-form.slice";
import {setAgentScopesForm} from "../store/agent-scopes-form.slice";
import {resetAgentLogoImage, updateAgentImage} from "../store/agent-logo-image.slice";
import AppUtils from "../../../utils/AppUtils";

const AgentsEditPage = () => {
    const classes = updateAgentsStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {params}: any = useRouteMatch();
    const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
    const updateAgentLoading = useSelector((state: AppState) => state.agents.updateAgent.loading);
    const agentForm = useSelector((state: AppState) => state.agents.agentForm);
    const agentLogoImage = useSelector((state: AppState) => state.agents.agentLogoImage);

    useEffect(() => {
        dispatch(getAgent({
            id: params.id,
            onSuccess: (responseData: AgentTypes) => fillFormWithSelectedAgent(responseData),
        }));
        dispatch(resetAgentLogoImage());
    }, []);

    const fillFormWithSelectedAgent = (agentData: AgentTypes) => {
        if (agentData) {
            const formatBalanceRequests = agentData.balances.map((item) => ({
                id: item.id,
                availableCredit: item.availableCredit,
                creditLimit: item.creditLimit,
                neto: item.neto,
                minimumTicketPrice: item.minimumTicketPrice,
                currencyId: item.currencyResponse.id
            }));
            const provisionFormsFormatted = agentData.provisionForms.map(p => {
                return {
                    ...p,
                    currencyId: p.currency.id
                }
            })
            const discountsFormatted = agentData.discounts.map(p => {
                return {
                    ...p,
                    currencyId: p.currency.id
                }
            })
            dispatch(
                setAgentIdentificationForm({
                    email: agentData.email,
                    status: agentData.status
                })
            );
            dispatch(
                setAgentScopesForm({
                    scopes: agentData.scopes,
                })
            )
            dispatch(
                setTheAgentForm({
                    fullName: agentData.fullName,
                    contactEmail: agentData.contactEmail,
                    location: agentData.location,
                    phoneNumber: agentData.phoneNumber,
                    companyName: agentData.companyName,
                    address: agentData.address,
                    invoiceText: agentData.invoiceText,
                    invoiceBottomText: agentData.invoiceBottomText,
                    maxAdultAllowed: agentData.maxAdultAllowed,
                    maxChildAllowed: agentData.maxChildAllowed,
                    maxInfantAllowed: agentData.maxInfantAllowed,
                    allowInfantOnly: agentData.allowInfantOnly,
                    provisionFormType: agentData.provisionFormType,
                    provisionForms: provisionFormsFormatted,
                    provisionPercentage: agentData.provisionPercentage,
                    balanceRequests: formatBalanceRequests,
                    discountType: agentData.discountType,
                    discounts: discountsFormatted,
                    allowedDiscount: agentData.allowedDiscount,
                    actualDebt: agentData.actualDebt,
                    debtBeforeEnding: agentData.debtBeforeEnding,
                    isOperatorLogo: agentData.isOperatorLogo
                })
            );

        }
    };

    if (agentLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`${t("app.main.agency.agentsList.agentEdit.title", "Agent information")} ${" "} ${agentForm.fullName}`}
                desc={t("app.main.agency.agentsList.agentEdit.desc", "To change the information, change the form below and click Save Changes")}
                backButton={() => {
                    dispatch(resetAgentForm());
                    history.push("/agents-agencies/list");
                }}
            />
            <div className={classes.formWrapper}>
                <Formik
                    initialValues={agentForm}
                    onSubmit={() => {
                        const formWithoutBalanceId = {
                            ...agentForm,
                            balanceRequests: agentForm.balanceRequests.map(({id, ...items}) => items)  // We are removing the id from balanceRequests
                        }

                        if (agentLogoImage.logoImage) {
                            AppUtils.dataUrlToFile(agentLogoImage.logoImage, 'logoImage').then((res: File) => {
                                dispatch(updateAgentImage(params.id, res));
                            });
                        }

                        dispatch(updateAgent({
                            id: params.id,
                            body: {...formWithoutBalanceId},
                            onSuccess: () => {
                                history.push("/agents-agencies/list");
                                dispatch(resetAgentForm());
                            },
                        }))
                    }}
                >
                    <Form>

                        <AgentEditInformation/>

                        <InvoiceInfo/>

                        <ReservationInfo/>

                        <Credits/>

                        <ProvisionInfo/>

                        <DiscountInfo/>

                        <DebtInfo/>

                        <Grid container justify='flex-end' alignItems='center'
                              style={{marginTop: '20px'}}>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                                    size='medium'
                                    isLoading={updateAgentLoading}
                                />

                                <Button
                                    onClick={(e) => {
                                        dispatch(resetAgentForm());
                                        history.push('/agents-agencies/list')
                                    }}>
                                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default AgentsEditPage;
