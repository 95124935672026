import React, {useState} from 'react';
import history from '@history';
import PageHeader from 'app/main/shared-components/page-header/PageHeader';
import {useDispatch, useSelector} from "react-redux";
import ModalDialog from "app/main/shared-components/modal-dialog/ModalDialog";
import {useRouteMatch} from "react-router-dom";
import {AppState} from "app/store";
import {toggleQuickPanel} from 'app/main/shared-components/quick-panel/store';
import {deletePaymentMethod} from "../store/paymentmethods/delete-paymentmethod.slice";
import {useTranslation} from "react-i18next";
import PaymentMethodTable from "../../shared-components/payment-method-table/PaymentMethodTable";

const PaymentMethods = (props: any) => {
    const dispatch = useDispatch();
    let {url} = useRouteMatch();
    const {allPaymentMethods, loading} = useSelector((state: AppState) => state.configurations.paymentmethods.paymentmethodsList);
    const [modal, setModal] = useState({
        state: false,
        deleteId: '',
        paymentName: ''
    });
    const {t} = useTranslation();

    const translateModal = () => {
        let title = t("app.main.configuration.payment.module.title", "Are you sure you want to delete payment method ");
        return title + modal.paymentName + ' ?';
    }

    return (
        <div>
            <PageHeader
                title={t("app.main.schedules.payments.paymentMethods", "Payment methods")}
                desc={t("app.main.configuration.paymentMethods.desc", "If you want to add a new method, click Register")}
                button={t("app.main.agency.agentsList.registerButton", "Register")}
                onClickButton={() => {
                    history.push(`${url}/create`)
                    dispatch(toggleQuickPanel());
                }}
            />
            <ModalDialog
                open={modal.state}
                title={translateModal()}
                onOk={() => {
                    dispatch(deletePaymentMethod({id: modal.deleteId}));
                    setModal({state: false, deleteId: '', paymentName: ''});
                }}
                onCancel={() => setModal({state: false, deleteId: '', paymentName: ''})}
            />
            <PaymentMethodTable data={allPaymentMethods} loading={loading} />
        </div>
    );
};

export default PaymentMethods;