import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import DateUtils from "app/utils/DateUtils";
import {FilterFlightsQueryParams} from "app/types/service-types/FlightTypes";
import {fetchAllFlights} from "./flights-list.slice";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const filterFlights =
    (queryParams: FilterFlightsQueryParams) => async (dispatch: Dispatch) => {
      dispatch(setFilters(queryParams));
      // @ts-ignore
      dispatch(fetchAllFlights({...removeFalsyAttributes(queryParams)}));
    };

export const clearfilterFlights = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterFlightsQueryParams = {
/*   departureAirportId: "",
   arrivalAirportId: "",
   code: "",
   fromDate: "",
   toDate: "",*/
};

const stateSlice = createSlice({
  name: "flights/flights-filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterFlightsQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;
