import {combineReducers} from '@reduxjs/toolkit';
import countriesList from './countires-list.slice'
import createCountry from './create-country.slice'
import deleteCountry from './delete-country.slice'
import updateCountry from './update.country.slice'

const reducer = combineReducers({
  countriesList,
  createCountry,
  deleteCountry,
  updateCountry,
});

export default reducer;