import {combineReducers} from '@reduxjs/toolkit';
import airlineCompanies from './airline-companies.slice';
import createAirline from './create-airline.slice';
import deleteAirline from './delete-airline.slice';
import updateAirline from './update-airline.slice';

const reducer = combineReducers({
  airlineCompanies,
  createAirline,
  deleteAirline,
  updateAirline
});

export default reducer;