import {Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import {Field} from "formik";
import {fillAgentFormField} from "../store/agent-form.slice";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {createAgentsStyles} from "../create/create-agents.styles";
import {useTranslation} from "react-i18next";


const DebtInfo = () => {
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const classes = createAgentsStyles();
  const {t} = useTranslation();
  const handleChange = (e:any) => {
    const name = e.target.name;
    const value = name === 'debtBeforeEnding' ? parseInt(e.target.value) : e.target.value;
    const payload = {
      value: value,
      field: name
    }
    dispatch(fillAgentFormField(payload));

}

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };


  return (
      <div style={{margin:'10px 0'}}>
        <Grid container spacing={4}>
          <Grid item xs={7}>
            <div className={classes.radioGroupDiv} role="group"
                 aria-labelledby="my-radio-group">
              <Typography style={{marginBottom: '.5rem'}}>{t("app.main.agency.create.debtInfo.label1","Llogarit borxhin aktual:")}</Typography>
              <label>
                <Field type="radio" name="actualDebt"
                       checked={agentForm.actualDebt === 'ONE SEGMENT'}
                       value={'ONE SEGMENT'} onChange={handleChange}/>
                {t("app.main.agency.create.debtInfo.label2","A segment")}
              </label>
              <label>
                <Field type="radio" name="actualDebt"
                       checked={agentForm.actualDebt === 'TWO SEGMENTS'}
                       value={'TWO SEGMENTS'} onChange={handleChange}/>
                {t("app.main.agency.create.debtInfo.label3","Two segments")}
              </label>
              <label>
                <Field type="radio" name="actualDebt"
                       checked={agentForm.actualDebt === 'TOTAL'} value={'TOTAL'}
                       onChange={handleChange}/>
                {t("app.main.agency.create.debtInfo.label4","Total obligation")}
              </label>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.debtInfo.label5","Calculate the debt before completion")}
                onChange={handleChange}
                value={agentForm.debtBeforeEnding || ''}
                variant='outlined'
                size='small'
                name='debtBeforeEnding'
                type="number"
                as={TextField}
                onKeyPress={preventMinus}
                onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                InputProps={{
                  inputProps: ({min: 0}),
                  endAdornment: (
                      <InputAdornment position="start">Orë</InputAdornment>
                  ),
                }}
            />
          </Grid>
        </Grid>

      </div>
  )
}

export default DebtInfo;