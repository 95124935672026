import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import {resetAgentForm} from "../store/agent-form.slice";
import PageHeader from "../../shared-components/page-header/PageHeader";
import {useTranslation} from "react-i18next";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import AgentProvisionSearchPanel from "./search-panel/AgentProvisionSearchPanel";
import AgentProvisionTable from "./agent-table/AgentProvisionTable";
import {fetchAllProvisions} from "../store/provision-list.slice";
import {clearFilterProvisions, filterProvisions} from "../store/filter-provision.slice";

export interface ProvisionListType {
    currencyResponse: {
        id: string;
        isoCode: string;
        name: string;
        symbol: string;
    }
}

const AgentProvisionGeneric = (props: ProvisionListType) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {params}: any = useRouteMatch();
    const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
    const agentForm = useSelector((state: AppState) => state.agents.agentForm);
    const {provisionList, loading} = useSelector((state: AppState) => state.agents.provisionsList);
    const filtersProvision = useSelector((state: AppState) => state.agents.filterProvision);
    const balances = useSelector((state: AppState) => state.agents.agent.agent?.balances);
    const currentBalance = balances?.find((balance) => balance.currencyResponse.id == params.currencyId);

    useEffect(() => {
        dispatch(clearFilterProvisions());
        dispatch(filterProvisions(params.id, {...filtersProvision, currencyId: params.currencyId}));
        dispatch(fetchAllProvisions(params.id,
            {
                currencyId: params.currencyId
            }));
    }, [params]);

    if (agentLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`${t("app.main.agency.edit.provision.title1", "Provision")} - ${currentBalance?.currencyResponse.isoCode}  ${agentForm.fullName}`}
                desc={t("app.main.agency.edit.provision.desc", "History of provision transactions")}
                backButton={() => {
                    dispatch(resetAgentForm());
                    history.push("/agents-agencies/list");
                }}
            />
            <AgentProvisionSearchPanel/>
            <AgentProvisionTable data={provisionList} loading={loading}/>
        </div>
    );
};

export default AgentProvisionGeneric;