import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import DateUtils from "../../../../utils/DateUtils";

const getInitialState = (): any => ({
  id: Math.random().toString(),
  number: "",
  recurringEndDate: DateUtils.convertDate(new Date().toISOString()),
  dateOfDeparture: DateUtils.convertDate(new Date().toISOString()),
  timeOfDeparture: "",
  timeOfArrival: "",
  gate: "",
  numberOfSeats: 1,
  gepack: 0,
  gepackINF: 5,
  maxOnline: 1,
  taxOfFlight: 0,
  increasePriceOfOnlineBookingFor: 0,
  recurringFrequencyInDays: 0,
  departureAirportId: "",
  arrivalAirportId: "",
  airlineCompanyId: "",
  status: "ACTIVE",
  currencyId: "",
  sectorPrices: [
    {
      id: Math.random().toString(),
      name: "",
      sector: 0,
      numberOfSeats: 1,
      prices: {
        oneWayPrices: {
          forAdults: "",
          forChildren: "",
          forInfants: "",
        },
        returnPrices: {
          forAdults: "",
          forChildren: "",
          forInfants: "",
        },
      },
    },
  ],
  economyWithRestrictions: {
    seatClass: "ECONOMY_WITH_RESTRICTIONS",
    noOfSeats: 0,
    fareDefault: {
      fareType: "DEFAULT",
      amount: 0,
      luggage: "",
    },
    fareOneWay: {
      fareType: "ONE_WAY",
      amount: 0,
      luggage: "",
    },
    fareReturn: {
      fareType: "RETURN",
      amount: 0,
      luggage: "",
    },
  },
  economyWithoutRestrictions: {
    seatClass: "ECONOMY_WITHOUT_RESTRICTIONS",
    noOfSeats: 0,
    fareDefault: {
      fareType: "DEFAULT",
      amount: 0,
      luggage: "",
    },
    fareOneWay: {
      fareType: "ONE_WAY",
      amount: 0,
      luggage: "",
    },
    fareReturn: {
      fareType: "RETURN",
      amount: 0,
      luggage: "",
    },
  },
  economyPremium: {
    seatClass: "ECONOMY_PREM",
    noOfSeats: 0,
    fareDefault: {
      fareType: "DEFAULT",
      amount: 0,
      luggage: "",
    },
    fareOneWay: {
      fareType: "ONE_WAY",
      amount: 0,
      luggage: "",
    },
    fareReturn: {
      fareType: "RETURN",
      amount: 0,
      luggage: "",
    },
  },
});

const newBlankSector = (id: string) => ({
  id,
  name: "",
  sector: 0,
  numberOfSeats: 1,
  prices: {
    oneWayPrices: {
      forAdults: 0,
      forChildren: 0,
      forInfants: 0,
    },
    returnPrices: {
      forAdults: 0,
      forChildren: 0,
      forInfants: 0,
    },
  },
});

const formatTheName = (name: string): string => {
  switch (name) {
    case "oneWayForAdults":
    case "returnForAdults":
      return "forAdults";

    case "oneWayForChildren":
    case "returnForChildren":
      return "forChildren";

    case "oneWayForInfant":
    case "returnForInfant":
      return "forInfants";

    default:
      return "";
  }
};

interface SetSectorProps {
  sectorId: string;
  sectorPayload: any;
}

interface SetSectorPriceProps {
  sectorId: string;
  pricePayload: any;
}

interface RemoveSectorPrice {
  sectorId: string;
  priceId: string;
}

export const setTheFlightForm = (payload: any) => (dispatch: Dispatch) => {
  dispatch(setFlightForm(payload));
};

export const setTheFlightSector =
    (payload: SetSectorProps) => (dispatch: Dispatch) => {
      dispatch(setFlightSector(payload));
    };

export const setTheSectorPrice =
    (payload: SetSectorPriceProps) => (dispatch: Dispatch) => {
      dispatch(setSectorPrice(payload));
    };

export const removeTheSector = (sectorId: string) => (dispatch: Dispatch) => {
  dispatch(removeSector(sectorId));
};

export const addNewBlankSector = (sectorId: string) => (dispatch: Dispatch) => {
  dispatch(addNewSector(sectorId));
};

export const resetTheFlightForm = () => (dispatch: Dispatch) => {
  dispatch(resetFlightForm());
};

const stateSlice = createSlice({
  name: "flights/flight-form",
  initialState: getInitialState(),
  reducers: {
    setFlightForm: (state, action) => action.payload,
    setFlightSector: (state, action: PayloadAction<SetSectorProps>) => {
      const {sectorId, sectorPayload} = action.payload;
      const {name, value} = sectorPayload;

      state.sectorPrices = state.sectorPrices.map((sc: any) => {
        if (sc.id === sectorId)
          return {
            ...sc,
            [name]: value,
          };
        else return sc;
      });
    },
    setSectorPrice: (state, action: PayloadAction<SetSectorPriceProps>) => {
      const {sectorId, pricePayload} = action.payload;
      const sectorIndex = state.sectorPrices.findIndex(
          (sc: any) => sc.id === sectorId
      );

      const {name, value} = pricePayload;
      let formatedName = formatTheName(name);

      console.log("Flight form slice");
      console.log(formatedName);
      console.log(pricePayload);

      // if (name.startsWith("oneWay")) {
      //   state.sectorPrices[sectorIndex].price = {
      //     ...state.sectorPrices[sectorIndex].price,
      //     oneWayPrices: {
      //       ...state.sectorPrices[sectorIndex].price.oneWayPrices,
      //       [formatedName]: value,
      //     },
      //   };
      // } else if (name.startsWith("return")) {
      //   state.sectorPrices[sectorIndex].price = {
      //     ...state.sectorPrices[sectorIndex].price,
      //     returnPrices: {
      //       ...state.sectorPrices[sectorIndex].price.returnPrices,
      //       [formatedName]: value,
      //     },
      //   };
      // }

      if (name.startsWith("oneWay")) {
        state.sectorPrices[sectorIndex].prices = {
          ...state.sectorPrices[sectorIndex].prices,
          oneWayPrices: {
            ...state.sectorPrices[sectorIndex].prices.oneWayPrices,
            [formatedName]: value,
          },
        };
      } else if (name.startsWith("return")) {
        state.sectorPrices[sectorIndex].prices = {
          ...state.sectorPrices[sectorIndex].prices,
          returnPrices: {
            ...state.sectorPrices[sectorIndex].prices.returnPrices,
            [formatedName]: value,
          },
        };
      }
    },
    removeSector: (state, action) => {
      if (state.sectorPrices.length === 1) return;
      state.sectorPrices = state.sectorPrices.filter(
          (sp: any) => sp.id !== action.payload
      );
    },
    addNewSector: (state, action) => {
      if (state.sectorPrices.length === 5) return;
      state.sectorPrices.push(newBlankSector(action.payload));
    },
    removeSectorPrice: (state, action: PayloadAction<RemoveSectorPrice>) => {
      const {priceId, sectorId} = action.payload;
      const sectorIndex = state.sectorPrices.findIndex(
          (sc: any) => sc.id === sectorId
      );

      state.sectorPrices[sectorIndex].prices = state.sectorPrices[
          sectorIndex
          ].prices.filter((p: any) => p.id !== priceId);
    },
    resetFlightForm: () => getInitialState(),
  },
});

export const {
  setFlightForm,
  setFlightSector,
  setSectorPrice,
  removeSector,
  addNewSector,
  removeSectorPrice,
  resetFlightForm,
} = stateSlice.actions;

export default stateSlice.reducer;
