import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import history from "@history";
import {clearfilterFlights, filterFlights} from "../store/flights-list/filter-flights.slice";
import PageHeader from "../../shared-components/page-header/PageHeader";
import MultilineTable from "../../shared-components/multiline-table/MultilineTable";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import SearchPanel from "../search-panel/SearchPanel";
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import {useTranslation} from "react-i18next";

const FlightsListPageCurrent = () => {
  const dispatch = useDispatch();
  const {
    allFlights,
    loading
  } = useSelector((state: AppState) => state.flights.flightsList.allFlights);
  const {t} = useTranslation();
  const filters = useSelector((state: AppState) => state.flights.flightsList.filterFlights);

  useEffect(() => {
    dispatch(clearfilterFlights());
    dispatch(filterFlights({...filters, page: 0, dayOfWeek: '', inFlight: true}));
  }, []);

  return (
      <div>
        <PageHeader
            title={t("app.main.flights.activeFlight.title", "Flights list of 'Active'")}
            desc={t("app.main.flights.flightList.desc", "If you want to record a new flight, click the Register button")}
            button={t("app.main.agency.agentsList.registerButton", "Register")}
            onClickButton={() => {
              history.push("/flights/flights-list/create");
              dispatch(toggleQuickPanel());
            }}/>
        <SearchPanel/>
        <MultilineTable data={allFlights} loading={loading}/>
        <PaginationBar
            totalPages={allFlights?.totalPages}
            currentPage={(filters.page || 0) + 1}
            onPageChange={(page: number) =>
                dispatch(filterFlights({...filters, page}))
            }
        />
      </div>)
}

export default FlightsListPageCurrent;