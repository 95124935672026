import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from "react-i18next";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import useStyles from './payment-method.style';
import PaymentMethodTableRow from "./PaymentMethodTableRow";

interface IProps {
    data: any,
    loading: boolean
}

const PaymentMethodTable = (props: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {data, loading} = props;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow className={classes.root}>
                        <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>Name</TableCell>
                        <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>Status</TableCell>
                        <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.employees.employeeList.table.actions", "Actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <LoadingTableRow colSpan={6}/>
                        : !data || data.length === 0 ? <NoDataTableRow colSpan={6}/>
                            : data.map((row: any) => (
                                <PaymentMethodTableRow key={row.id} row={row}/>
                            ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PaymentMethodTable;