import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {
    Button,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import useStyles from './search-panel.styles';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {clearfilterFlights, filterFlights} from '../store/flights-list/filter-flights.slice';
import {fetchAllAirports} from "../../configurations/store/airport/airports-list.slice";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import {useTranslation} from "react-i18next";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateUtils from "../../../utils/DateUtils";

const SearchPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const filters = useSelector((state: AppState) => state.flights.flightsList.filterFlights);
    const airports = useSelector((state: AppState) => state.configurations.airport.airportsList.airports.content);
    const [fromDate, setSelectedFromDate] = useState<any>(filters.fromDate ? DateUtils.convertDateToIso(filters.fromDate) : null);
    const [toDate, setSelectedToDate] = useState<any>(filters.toDate ? DateUtils.convertDateToIso(filters.toDate) : null);

    const handleChange = (e: any) => {
        dispatch(filterFlights({
            ...filters,
            page: 0,
            [e.target.name]: e.target.value
        }));
    }

    useEffect(() => {
        dispatch(fetchAllAirports({size: 9999}));
    }, []);

    useEffect(() => {
        setSelectedFromDate(filters.fromDate ? DateUtils.convertDateToIso(filters.fromDate) : null);
    }, [filters.fromDate]);

    useEffect(() => {
        setSelectedToDate(filters.toDate ? DateUtils.convertDateToIso(filters.toDate) : null);
    }, [filters.toDate]);

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            className={classes.root}
            style={{margin: '1rem 0', display: 'flex', alignItems: 'center'}}
            alignItems="flex-start"
        >
            <div className={classes.row} style={{position: 'relative'}}>
                <FormControl size='small' variant="outlined" className={classes.formControl}>
                    <InputLabel
                        id="demo-simple-select-outlined-label">{t("app.main.flights.searchPanel.inputLabel1", "Departure from the airport")}:</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        value={filters.departureAirportId ? filters.departureAirportId : ''}
                        name='departureAirportId'
                        onChange={handleChange}
                        label={t("app.main.flights.searchPanel.inputLabel1", "Departure from the airport")}
                    >
                        {airports && airports.filter((a: Airport) => a.id !== filters.arrivalAirportId)
                            .map((a: Airport) => (
                                <MenuItem key={a.id} value={a.id}>{a.code} - {a.name}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {filters.departureAirportId && (<>
                    &nbsp;
                    <IconButton style={{position: 'absolute', right: '24px'}} onClick={() => handleChange({
                        target: {name: 'departureAirportId', value: ''}
                    })} size='small'>
                        <Icon fontSize='small'>close</Icon>
                    </IconButton>
                </>)}
            </div>
            <div className={classes.row} style={{position: 'relative'}}>
                <FormControl size='small' variant="outlined" className={classes.formControl}>
                    <InputLabel
                        id="demo-simple-select-outlined-label2">{t("app.main.flights.searchPanel.inputLabel2", "Arrival at the airport")}:</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label2"
                        value={filters.arrivalAirportId ? filters.arrivalAirportId : ''}
                        name='arrivalAirportId'
                        onChange={handleChange}
                        label={t("app.main.flights.searchPanel.inputLabel2", "Arrival at the airport")}
                    >
                        {airports && airports.filter((a: Airport) => a.id !== filters.departureAirportId)
                            .map((a: Airport, idx: number) => (
                                <MenuItem key={idx} value={a.id}>{a.code} - {a.name}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {filters.arrivalAirportId && (<>
                    &nbsp;
                    <IconButton style={{position: 'absolute', right: '24px'}} onClick={() => handleChange({
                        target: {name: 'arrivalAirportId', value: ''}
                    })} size='small'>
                        <Icon fontSize='small'>close</Icon>
                    </IconButton>
                </>)}
            </div>
            <div className={classes.row} style={{position: 'relative'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker label={t("app.main.schedules.searchPanel.datepicker.from", "From date")}
                                format="dd/MM/yyyy"
                                inputVariant='outlined'
                                size='small'
                                disableToolbar
                                autoOk
                                variant="inline"
                                value={fromDate}
                                onChange={(e) => {
                                    setSelectedFromDate(e);
                                    dispatch(filterFlights({
                                        ...filters,
                                        page: 0,
                                        fromDate: DateUtils.convertDateToIso(e)
                                    }));
                                }}
                    />
                </MuiPickersUtilsProvider>
                {filters.fromDate && (<>
                    &nbsp;
                    <IconButton style={{position: 'absolute', right: '0.7rem'}} onClick={() => {
                        handleChange({
                            target: {name: 'fromDate', value: ''}
                        });
                        setSelectedFromDate(null);
                    }} size='small'>
                        <Icon fontSize='small'>close</Icon>
                    </IconButton>
                </>)}
            </div>
            <div className={classes.row} style={{position: 'relative'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker label={t("app.main.schedules.searchPanel.datepicker.to", "To")}
                                format="dd/MM/yyyy"
                                inputVariant='outlined'
                                size='small'
                                minDate={fromDate}
                                disableToolbar
                                variant="inline"
                                value={toDate}
                                onChange={(e) => {
                                    setSelectedToDate(e);
                                    dispatch(filterFlights({
                                        ...filters,
                                        page: 0,
                                        toDate: DateUtils.convertDateToIso(e)
                                    }));
                                }}
                    />
                </MuiPickersUtilsProvider>
                {filters.toDate && (<>
                    &nbsp;
                    <IconButton style={{position: 'absolute', right: '0.7rem'}} onClick={() => {
                        handleChange({
                            target: {name: 'toDate', value: ''}
                        });
                        setSelectedToDate(null);
                    }} size='small'>
                        <Icon fontSize='small'>close</Icon>
                    </IconButton>
                </>)}
            </div>
            <div className={classes.row} style={{position: 'relative'}}>
                <Button
                    variant='outlined'
                    onClick={(e) => {
                        dispatch(clearfilterFlights());
                        dispatch(filterFlights({page: 0}));
                        dispatch(fetchAllAirports({size: 9999}));
                    }}
                >
                    {t("app.main.schedules.searchPanel.button.clear", "Clear All")}
                </Button>
            </div>
        </Grid>
    );
};

export default SearchPanel;
