import {combineReducers} from '@reduxjs/toolkit';
import paymentmethodsList from './paymentmethods-list.slice';
import createPaymentMethod from './create-paymentmethod.slice'
import updatePaymentMethod from './update-paymentmethod.slice'
import deletePaymentMethod from './delete-paymentmethod.slice'

const reducer = combineReducers({
  paymentmethodsList,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod
});

export default reducer;
