import React, {useEffect} from "react";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import history from "@history";
import CollapsibleTable from "app/main/shared-components/collapsible-table/CollapsibleTable";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import SearchPanel from "../search-panel/SearchPanel";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import {clearfilterSchedules, filterSchedules,} from "../store/filter-schedules.slice";
import {useTranslation} from "react-i18next";
import ScopesUtils from "../../../utils/ScopesUtils";
import useAuthority from "../../../hooks/useAuthority";
import DateUtils from "../../../utils/DateUtils";

const SchedulesListPageFullPaid = (props: any) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {hasUserAnyAuthority} = useAuthority();
  const {allReservations, loading} = useSelector(
      (state: AppState) => state.schedules.schedulesList
  );
  const filters = useSelector(
      (state: AppState) => state.schedules.filterSchedules
  );
  const dateFilters = useSelector((state: AppState) => state.schedules.dateFilters);
  const totalReservations = allReservations?.totalElements;
  const totalTravelers= allReservations?.totalTravelers;
  const scopes = useSelector((state: AppState) => state.account.account.account?.scopes);
  const hasButton = ScopesUtils.FlightReservation(hasUserAnyAuthority, scopes);

  useEffect(() => {
    dispatch(clearfilterSchedules());
    dispatch(
        filterSchedules({
          page: 0,
          isPaid: "false",
          isRefund: '',
          fromDate: dateFilters.fromDate ? DateUtils.convertDateToIso(dateFilters.fromDate) : '',
          toDate: dateFilters.toDate ? DateUtils.convertDateToIso(dateFilters.toDate) : ''
        })
    );
  }, []);

  useEffect(() => {
    ScopesUtils.FlightReservation(hasUserAnyAuthority, scopes);
  }, [scopes]);

  return (
      <div>
        <PageHeader
            title={t("app.main.schedules.schedules-list.notPaid.pageHeader.title", "All unpaid reservations")}
            desc={t("app.main.schedules.schedules-list.pageHeader.description", "List of reservations...")}
            button={hasButton ? t("app.main.schedules.schedules-list.pageHeader.bookButton", "Book") : false}
            onClickButton={() => {
              history.push("/schedule");
            }}
        />
        <SearchPanel totalReservations={totalReservations} totalTravelers={totalTravelers}/>
        <CollapsibleTable data={allReservations} loading={loading}/>
        <PaginationBar
            totalPages={allReservations?.totalPages}
            currentPage={(filters.page || 0) + 1}
            onPageChange={(page: number) =>
                dispatch(filterSchedules({...filters, page}))
            }
        />
      </div>
  );
};

export default SchedulesListPageFullPaid;
