import ReactToPrint from "react-to-print";
import {useEffect, useRef} from "react";
import NewPrintInvoice from "./NewPrintInvoice";

type IProps = {
    reservation: any
    showDetails: boolean
    showPrint: any
}

const ComponentToPrintReservation = ({reservation, showDetails, showPrint}: IProps) => {
    const componentRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        document.getElementById('print').click();
        showPrint(false);
    }, []);

    return (
        <div>
            <ReactToPrint
                trigger={() => <button id='print' style={{display: "none"}}></button>}
                content={() => componentRef.current}/>
            <div style={{display: "none"}}>
                <NewPrintInvoice ref={componentRef} reservation={reservation} />
            </div>
        </div>
    )
}

export default ComponentToPrintReservation;