import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {fetchAllPaymentReports} from "./payment-reports-list.slice";
import DateUtils from "app/utils/DateUtils";
import {FilterReportsQueryParams} from "../../../types/service-types/PaymentMethodsTypes";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] === "ALL") {
      } else newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const filterReports =
    (queryParams: FilterReportsQueryParams) =>
        async (dispatch: Dispatch) => {
          dispatch(setFilters(queryParams));
          // @ts-ignore
          dispatch(fetchAllPaymentReports({...removeFalsyAttributes(queryParams)}));
        };

export const clearfilterReports = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterReportsQueryParams = {
  userId: "",
  customerId: "",
  fromDate: "",
  toDate: "",
  currencyId: "",
  paymentMethodId: "",
};

const stateSlice = createSlice({
  name: "reports/reports-filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterReportsQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;