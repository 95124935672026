import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AgentService} from "app/services";
import {FilterAgentsQueryParams, PagedAgentList} from 'app/types/service-types/AgentTypes';
import {errorMsg} from "../../../utils/MessageUtils";

export const fetchAllAgentsWithoutDept = (queryParams?: FilterAgentsQueryParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await AgentService.filterAgentsWithoutDebt(queryParams || {});
        dispatch(setAgentsList(response));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
    }
}

interface AgentsListSlice {
    allAgents: PagedAgentList | null;
    loading: boolean;
}

const initialState: AgentsListSlice = {
    allAgents: null,
    loading: false
}

const stateSlice = createSlice({
    name: 'agent/agents-without-debt-list',
    initialState,
    reducers: {
        setAgentsList: (state, action) => {
            state.allAgents = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
});

export const {setAgentsList, setLoading} = stateSlice.actions;

export default stateSlice.reducer;