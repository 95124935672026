interface IWayType {
  readonly ONE_WAY: string;
  readonly RETURN_FLIGHT: string;
  readonly MULTI_CITY: string;
}

export const WAY_TYPE: IWayType = {
  ONE_WAY: '0',
  RETURN_FLIGHT: '1',
  MULTI_CITY: '2'
};