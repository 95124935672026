import {makeStyles} from "@material-ui/styles";

export default makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
      padding: '4px',
      fontSize: '12px',
    }
  }
}))

export const useTableRowStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      background: 'rgba(0,0,0,.05)'
    },
    '& > *': {
      borderBottom: 'unset',
      padding: '4px',
      fontSize: '12px',
    }
  },
  priceFlex: {
    display: 'flex',
    justifyContent: "flex-end",
    alignItems: "center"
  },
  passengersActive: {
    backgroundColor: 'rgba(148,217,125,0.35)',
    '&:hover': {
      backgroundColor: 'rgba(107,212,143,1)'
    }
  },
  passengersDisabled: {
    backgroundColor: '#edd8de',
    '&:hover': {
      backgroundColor: 'rgba(255,0,0,0.4)'
    }
  },
  isDisabledFlight: {
    backgroundColor: 'rgba(37,150,190,0.3)',
    '&:hover': {
      backgroundColor: 'rgba(37,150,190,0.6)'
    }
  },
  rowButtons: {
    '& button': {
      marginRight: '.5rem'
    }
  },
  textIconWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: '18px',
    margin: '0 2px',
    color: '#000',
  },
  addReturnFlightBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    marginLeft: '2px',
    textDecoration: 'underline',
    color: '#178cff',
    fontWeight: 500,
    cursor: 'pointer'
  },
  box: {
    fontWeight: 500,
    marginRight: '10px'
  },
  inline: {
    display: 'inline'
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  marginLeft10: {
    marginLeft: '10px'
  },
  textRed: {
    color: 'rgba(108,13,32,1)',
    fontWeight: 500
  },
  agent: {
    backgroundColor: 'rgba(255,255,0,1)'
  },
  textBold: {
    fontWeight: 700
  }
}))