import React from "react";
import {UpdateTravelerStyle} from "./update-traveler.styles";
import {Field, Form, Formik} from "formik";
import {Button, MenuItem, TextField} from "@material-ui/core";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
  updatePassengerBasicInfo,
  updatePassengerDeparturePrice,
  updatePassengerReturnPrice
} from "../store/update-passenger.slice";
import {useTranslation} from "react-i18next";
import DateUtils from "../../../utils/DateUtils";

const UpdateTraveler = () => {
  const classes = UpdateTravelerStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<any>();
  const {t} = useTranslation();
  const passenger = location?.state?.passenger;
  const isChangingInfo = location?.state?.isChangingInfo;

  const submitButton = () => {
    return (
        <div className={classes.row}>
          <LoadingButton
              type="submit"
              btnTitle={`${t("app.main.schedules.update.form.submitButton", "Submit")}`}
              isLoading={false}
          />
          <Button
              onClick={() => {
                dispatch(toggleQuickPanel());
                history.goBack();
              }}>
              {t("app.main.flights.flightList.deletePopup.cancelButton", "Cancel")}
          </Button>
        </div>
    )
  }

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "2rem"}}>
          <h1 style={{color: "#0D3B66"}}>
            {t("app.main.schedules.updateTravelerFlight.title", "Passenger change")}:{" "}
            <span style={{fontWeight: 500}}>
            {passenger?.name} {passenger?.surname}
          </span>
          </h1>
          {passenger?.departureFlight?.id && !isChangingInfo && (
              <div style={{color: "#0D3B66", margin: '1rem 0'}}>
                <div>
                  {`${passenger?.departureFlight.departureAirport.code}
                 => ${passenger?.departureFlight.arrivalAirport.code}`}
                  <p>
                    {DateUtils.toHumanDate(
                        DateUtils.convertDate(
                            passenger?.departureFlight.dateOfDeparture
                        ),
                        passenger?.departureFlight.timeOfDeparture.toString()
                    )}
                  </p>
                </div>
              </div>
          )}
          {passenger?.returningFlight?.id && !isChangingInfo && (
              <div style={{color: "#0D3B66"}}>
                <div>
                  {`${passenger?.returningFlight.departureAirport.code}
                 => ${passenger?.returningFlight.arrivalAirport.code}`}
                  <p>
                    {DateUtils.toHumanDate(
                        DateUtils.convertDate(
                            passenger?.returningFlight.dateOfDeparture
                        ),
                        passenger?.returningFlight.timeOfDeparture.toString()
                    )}
                  </p>
                </div>
              </div>
          )}
        </div>
        {passenger && isChangingInfo && (
            <Formik
                initialValues={{
                  name: passenger?.name,
                  surname: passenger?.surname,
                  dateOfBirth: passenger?.dateOfBirth,
                  nationality: passenger?.nationality,
                  gender: passenger?.gender,
                  ageCategory: passenger?.ageCategory,
                }}
                onSubmit={(data) => {
                  dispatch(
                      updatePassengerBasicInfo({
                        id: passenger?.id,
                        body: data,
                        onSuccess: () => {
                          dispatch(toggleQuickPanel());
                          history.goBack();
                        },
                      })
                  );
                }}
            >
              {({values}) => (
                  <Form>
                    <div>
                      <div className={classes.row}>
                        <Field
                            variant="outlined"
                            size="small"
                            name="gender"
                            required
                            label={t("app.main.schedules.updateTravelerFlight.gender", "Gender")}
                            value={values.gender}
                            select
                            as={TextField}
                        >
                          <MenuItem value="MALE">{t("app.main.schedules.addPassenger.male", "Male")}</MenuItem>
                          <MenuItem value="FEMALE">{t("app.main.schedules.addPassenger.female", "Female")}</MenuItem>
                        </Field>
                        <Field
                            disabled={passenger.ageCategory === 'INF' ? false : true}
                            variant="outlined"
                            size="small"
                            name="ageCategory"
                            required
                            label={t("app.main.schedules.updateTravelerFlight.age", "Age")}
                            value={values.ageCategory}
                            select
                            as={TextField}
                        >
                          <MenuItem
                              value="ADULT">{t("app.main.schedules.addPassenger.adult", "Adult")}</MenuItem>
                          <MenuItem
                              value="CHILD">{t("app.main.schedules.addPassenger.child", "Child")}</MenuItem>
                          <MenuItem
                              value="INF">{t("app.main.schedules.addPassenger.inf", "Infant")}</MenuItem>
                        </Field>
                      </div>
                      <div className={classes.row}>
                        <Field
                            variant="outlined"
                            value={values.name}
                            name="name"
                            label={t("app.main.schedules.addPassenger.passengerName", "Passenger name")}
                            as={TextField}
                            required
                        />
                        <Field
                            variant="outlined"
                            value={values.surname}
                            name="surname"
                            label={t("app.main.schedules.addPassenger.passengerLastname", "Passenger lastname")}
                            as={TextField}
                            required
                        />
                      </div>
                      <div className={classes.row}>
                        <Field
                            variant="outlined"
                            value={values.nationality}
                            name="nationality"
                            label={t("app.main.schedules.addPassenger.citizenship", "Citizenship")}
                            as={TextField}
                        />
                      </div>
                    </div>
                    {submitButton()}
                  </Form>
              )}
            </Formik>
        )}

        {!isChangingInfo && (
            <Formik
                initialValues={{
                  departureFlightPrice: passenger.price.departureFlightPrice,
                  departureBaseFlightPrice: passenger.price.departureBaseFlightPrice,
                  returningFlightPrice: passenger.price.returningFlightPrice,
                  returningBaseFlightPrice: passenger.price.returningBaseFlightPrice
                }}
                onSubmit={(data: any) => {
                  if (passenger.departureFlight.id) {
                    dispatch(updatePassengerDeparturePrice({
                          id: passenger?.id,
                          body: {departureFlightPrice: data.departureFlightPrice}
                        })
                    )
                  }

                  setTimeout(() => {
                    if (passenger.returningFlight?.id) {
                      dispatch(updatePassengerReturnPrice({
                            id: passenger?.id,
                            body: {returnFlightPrice: data.returningFlightPrice}
                          })
                      );
                    }
                    dispatch(toggleQuickPanel());
                    history.goBack();
                  }, 500);
                }}>
              {({values}) => (
                  <Form>
                    {passenger?.departureFlight?.id && (
                        <div className={classes.row} style={{marginTop: "30px"}}>
                          <Field
                              variant="outlined"
                              value={values.departureFlightPrice}
                              name="departureFlightPrice"
                              label={t("app.main.schedules.updateTravelerFlight.outgoingTicket", "Price for outgoing ticket")}
                              as={TextField}
                              required
                              type="number"
                          />
                          <Field
                              variant="outlined"
                              value={values.departureBaseFlightPrice}
                              name="departureBaseFlightPrice"
                              label={t("app.main.schedules.updateTravelerFlight.baseOutgoingPrice", "Base price for outgoing ticket")}
                              as={TextField}
                              required
                              disabled
                          />
                        </div>
                    )}
                    {passenger?.returningFlight?.id && (
                        <div className={classes.row}>
                          <Field
                              variant="outlined"
                              value={values.returningFlightPrice}
                              name="returningFlightPrice"
                              label={t("app.main.schedules.updateTravelerFlight.returnPriceTicket", "Price for return ticket")}
                              as={TextField}
                              required
                              type="number"
                          />
                          <Field
                              variant="outlined"
                              value={passenger?.price.returningBaseFlightPrice}
                              name="returningBaseFlightPrice"
                              label={t("app.main.schedules.updateTravelerFlight.baseReturnPrice", "Base price for the return ticket")}
                              as={TextField}
                              required
                              disabled
                          />
                        </div>
                    )}
                    {submitButton()}
                  </Form>
              )}
            </Formik>
        )}
      </div>
  );
};

export default UpdateTraveler;
