import {createSlice, Dispatch} from "@reduxjs/toolkit";
import SmsTemplatesServices from "../../../../services/sms-templates.services";
import {errorMsg, successMsg} from "../../../../utils/MessageUtils";
import {fetchAllSmsTemplates} from "./sms-template.slice";

interface DeleteSmsTemplateParams {
  id: string
  onSuccess?: Function;
  onError?: Function;
}

export const deleteSmsTemplate = (params: DeleteSmsTemplateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SmsTemplatesServices.deleteSmsTemplate(params.id);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'SMS template deleted successfully!' : 'SMS shablloni u fshi me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllSmsTemplates());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface DeleteSmsTemplate {
  success: boolean
  error: string
  loading: boolean
}

const initialState: DeleteSmsTemplate = {
  success: false,
  error: '',
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/sms-templates',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;