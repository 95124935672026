import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect, useState} from "react";
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import history from '@history';
import PageHeader from "../../shared-components/page-header/PageHeader";
import ModalDialog from "../../shared-components/modal-dialog/ModalDialog";
import {deleteCountry} from "../store/countries/delete-country.slice";
import {fetchAllCountries} from "../store/countries/countires-list.slice";
import CountriesTable from "../../shared-components/countries-table/CountriesTable";
import {useTranslation} from "react-i18next";

const CountriesListPage = (props: any) => {
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {
    countries,
    loading
  } = useSelector((state: AppState) => state.configurations.countries.countriesList);
  const {t} = useTranslation();
  const [modal, setModal] = useState({
    state: false,
    deleteId: ''
  });

  useEffect(() => {
    dispatch(fetchAllCountries());
  }, []);

  return (
      <div>
        <PageHeader
            title={t("app.main.configuration.sidebar.title4", "Countries")}
            desc={t("app.main.configuration.countries.desc", "If you want to add a new country, click Register")}
            button={t("app.main.agency.agentsList.registerButton", "Register")}
            onClickButton={() => {
              history.push(`${url}/create`)
              dispatch(toggleQuickPanel());
            }}
        />
        <ModalDialog
            open={modal.state}
            title='Are you sure you want to delete this?'
            onOk={() => {
              dispatch(deleteCountry({id: modal.deleteId}));
              setModal({state: false, deleteId: ''});
            }}
            onCancel={() => setModal({state: false, deleteId: ''})}
        />
        <CountriesTable data={countries} loading={loading}/>
      </div>
  );

}

export default CountriesListPage;