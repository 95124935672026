import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {PaymentMethodsService} from "app/services";
import {fetchAllPaymentMethods} from "./paymentmethods-list.slice";
import {errorMsg, successMsg} from 'app/utils/MessageUtils';

interface DeletePaymentMethodStateParams {
  id: string;
  onSuccess?: Function;
  onError?: Function;
}

export const deletePaymentMethod = (params: DeletePaymentMethodStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await PaymentMethodsService.deletePaymentMethod(params.id);
    if (response.status >= 200 || response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Payment method successfully deleted!' : 'Metoda për pagesë u fshi me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllPaymentMethods());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface DeletePaymentMethodSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: DeletePaymentMethodSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/airports',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;