import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {PaymentMethodsService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";
import {FilterReportsQueryParams, PagedReportsList} from "../../../types/service-types/PaymentMethodsTypes";

export const fetchAllPaymentReports = (queryParams?: FilterReportsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await PaymentMethodsService.filterReports(queryParams);
    } else response = await PaymentMethodsService.fetchAllPayments();
    dispatch(setReports(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface ReportsListSlice {
  allReports: PagedReportsList | null;
  loading: boolean;
}

const initialState: ReportsListSlice = {
  allReports: null,
  loading: false,
}

const stateSlice = createSlice({
  name: 'report/reports-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setReports: (state, action) => {
      state.allReports = action.payload
    }
  }
});

export const {setLoading, setReports} = stateSlice.actions;

export default stateSlice.reducer;