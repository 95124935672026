import React from 'react';
import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useTranslation} from "react-i18next";
import {useStyles} from "../../../schedules/schedules.styles";
import {filterPayments} from "../../store/filter-payment.slice";
import {useRouteMatch} from "react-router-dom";
import {ROLES} from "../../../../constants/roles";
import useAuthority from "../../../../hooks/useAuthority";

const AgentSearchPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {params}: any = useRouteMatch();
  const filters = useSelector((state: AppState) => state.agents.filterPayment);
  const {hasUserAnyAuthority} = useAuthority();
  const account = useSelector((state: AppState) => state.account.account);

  const handleChange = (e: any) => {
    if (hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
      dispatch(filterPayments(params.id,{
        currencyId: params.currencyId,
        ...filters,
        [e.target.name]: e.target.value
      }));
    }
    if (hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
      dispatch(filterPayments(account.account!.id,{
        currencyId: params.currencyId,
        ...filters,
        [e.target.name]: e.target.value
      }));
    }
  }

  return (
      <Grid
          container
          direction="row"
          justify="flex-start"
          className={classes.root}
          style={{margin: '1rem 0', display: 'flex', alignItems: 'center'}}
          alignItems="flex-start"
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          paddingRight: '20px',
          paddingLeft: '20px'
        }}>
          <TextField
              label={t("app.main.schedules.searchPanel.datepicker.from", "From date")}
              type="date"
              name='fromDate'
              value={filters.fromDate ? filters.fromDate : ''}
              onChange={handleChange}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {max: "9999-12-12"}
              }}
          />
          {filters.fromDate && (<>
            &nbsp;
            <IconButton style={{position: 'absolute', right: '1px'}} onClick={() => handleChange({
              target: {name: 'fromDate', value: ''}
            })} size='small'>
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          paddingRight: '20px'
        }}>
          <TextField
              label={t("app.main.schedules.searchPanel.datepicker.to", "To date")}
              type="date"
              name='toDate'
              value={filters.toDate ? filters.toDate : ''}
              onChange={handleChange}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {max: "9999-12-12"}
              }}
          />
          {filters.toDate && (<>
            &nbsp;
            <IconButton style={{position: 'absolute', right: '1px'}} onClick={() => handleChange({
              target: {name: 'toDate', value: ''}
            })} size='small'>
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        <div>
          <FormControl size='small' variant="outlined" style={{paddingRight: '20px'}}>
            <InputLabel id="demo-simple-select-outlined-label">{t( "app.main.schedules.searchPanel.input.status", "Status")}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                value={filters.status ? filters.status : 'ALL'}
                name='status'
                onChange={handleChange}
                label={t( "app.main.schedules.searchPanel.input.status", "Status")}
            >
              <MenuItem value='ALL'>{t( "app.main.schedules.searchPanel.menuItem1", "All")}</MenuItem>
              <MenuItem value='RESERVATION'>Reservation</MenuItem>
              <MenuItem value='PAYMENT'>Payment</MenuItem>
              <MenuItem value='PROVISION'>Provision</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
  );
};

export default AgentSearchPanel;
