import {PageConfigTypes} from "app/types/PageTypes";
import EmployeesList from "./employees-list/EmployeesList";
import {ROLES} from "app/constants/roles";
import CreateEmployeeForm from "./create/CreateEmployeeForm";
import EmployeesPage from "./EmployeesPage";

const EmployeesPageConfig: PageConfigTypes = {
    baseRoute: {
        path: "/employees",
        component: EmployeesPage,
        authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]
    },
    subRoutes: [
        {
            path: '/employees/employees-list',
            component: EmployeesList
        },
    ],
    settings: {
        quickPanel: {
            create: {
                path: "/employees/list/create",
                component: CreateEmployeeForm,
            },
        },
        navbar: {
            open: true,
            title: "Employees",
            subtitle: "List of employees",
            items: [
                {
                    to: "/list",
                    primaryText: "All employees",
                    icon: "play_arrow",
                    exact: true,
                },
            ],
        },
    },
};


export default EmployeesPageConfig;