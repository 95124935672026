import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {FlightRequestBody} from "app/types/service-types/FlightTypes";
import {FlightService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";
import {setFlightForm} from "./flight-form.slice";

interface GetFlightParams {
  id: string;
  onSuccess?: Function;
  onError?: Function;
}

export const getFlight = (params: GetFlightParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));

    const flightData = await FlightService.getFlightById(params.id);
    if (flightData) {

      const {
        id,
        departureAirport,
        dateOfDeparture,
        timeOfDeparture,
        arrivalAirport,
        gate,
        airlineCompany,
        gepack,
        gepackINF,
        increasePriceOfOnlineBookingFor,
        timeOfArrival,
        taxOfFlight,
        sectorPrices,
        maxOnline,
        numberOfSeats,
        number,
        currency,
        fareGroupList,
        publicAvailability
      } = flightData;

      const formatSectors = sectorPrices.map((item: any) => ({
        ...item,
        prices: item.price
      }));

      const [
        withRestrictions,
        withoutRestrictions,
        premium
      ] = fareGroupList;

      const formattedFlight = {
        id,
        number,
        dateOfDeparture: dateOfDeparture,
        timeOfDeparture,
        timeOfArrival,
        gate,
        numberOfSeats,
        gepack,
        gepackINF,
        maxOnline,
        taxOfFlight,
        increasePriceOfOnlineBookingFor,
        publicAvailability,
        departureAirportId: departureAirport.id,
        arrivalAirportId: arrivalAirport.id,
        departureAirportCode: departureAirport.code,
        arrivalAirportCode: arrivalAirport.code,
        airlineCompanyId: airlineCompany.id,
        sectorPrices: formatSectors,
        currencyId: currency.id,
        economyWithRestrictions: {
          seatClass: 'ECONOMY_WITH_RESTRICTIONS',
          noOfSeats: withRestrictions?.noOfSeats,
          fareDefault: {
            fareType: 'DEFAULT',
            amount: withRestrictions?.fareList[0]?.amount,
            luggage: withRestrictions?.fareList[0]?.luggage,
          },
          fareOneWay: {
            fareType: 'ONE_WAY',
            amount: withRestrictions?.fareList[1]?.amount,
            luggage: withRestrictions?.fareList[1]?.luggage,
          },
          fareReturn: {
            fareType: 'RETURN',
            amount: withRestrictions?.fareList[2]?.amount,
            luggage: withRestrictions?.fareList[2]?.luggage,
          }
        },
        economyWithoutRestrictions: {
          seatClass: 'ECONOMY_WITHOUT_RESTRICTIONS',
          noOfSeats: withoutRestrictions?.noOfSeats,
          fareDefault: {
            fareType: 'DEFAULT',
            amount: withoutRestrictions?.fareList[0]?.amount,
            luggage: withoutRestrictions?.fareList[0]?.luggage,
          },
          fareOneWay: {
            fareType: 'ONE_WAY',
            amount: withoutRestrictions?.fareList[1]?.amount,
            luggage: withoutRestrictions?.fareList[1]?.luggage,
          },
          fareReturn: {
            fareType: 'RETURN',
            amount: withoutRestrictions?.fareList[2]?.amount,
            luggage: withoutRestrictions?.fareList[2]?.luggage,
          }
        },
        economyPremium: {
          seatClass: 'ECONOMY_PREM',
          noOfSeats: premium?.noOfSeats,
          fareDefault: {
            fareType: 'DEFAULT',
            amount: premium?.fareList[0]?.amount,
            luggage: premium?.fareList[0]?.luggage,
          },
          fareOneWay: {
            fareType: 'ONE_WAY',
            amount: premium?.fareList[1]?.amount,
            luggage: premium?.fareList[1]?.luggage,
          },
          fareReturn: {
            fareType: 'RETURN',
            amount: premium?.fareList[2]?.amount,
            luggage: premium?.fareList[2]?.luggage,
          }
        }
      };

      dispatch(setFlightForm(formattedFlight));
      dispatch(setFlight(formattedFlight));
    }
    if (params.onSuccess) params.onSuccess(flightData);
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface FlightSlice {
  flight: FlightRequestBody | null;
  loading: boolean;
}

const initialState: FlightSlice = {
  flight: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'flights/flight',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setFlight: (state, action) => {
      state.flight = action.payload
    }
  }
});

export const {setLoading, setFlight} = stateSlice.actions;

export default stateSlice.reducer;