import {makeStyles} from "@material-ui/styles";
import {fade, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  search: {
    // border: '1px solid red',
    // margin: theme.spacing(2.5, 0),
    border: '5px',
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.20),
    },
    marginLeft: 0,
    width: 'min-content',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      // width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // border: '1px solid red',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('all'),
    // width: '25ch',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      // '&:focus': {
      //   width: '22ch',
      // },
    },
  }
}));