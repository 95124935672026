import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {CreateReservationBodyTypes} from "app/types/service-types/ReservationTypes";
import {ReservationService} from "app/services";
import {errorMsg, successMsg} from "../../../utils/MessageUtils";

interface CreateScheduleStateParams {
    body: CreateReservationBodyTypes;
    onSuccess?: Function;
    onError?: Function;
}

export const createSchedule =
    (params: CreateScheduleStateParams) => async (dispatch: Dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await ReservationService.createReservation(params.body);
            if (response.status >= 200 || response.status < 300) {
                dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Reservation successfully added!' : 'Rezervimi u shtua me sukses!'));
                clearScheduleData();
                if (params.onSuccess) params.onSuccess();
            }
            dispatch(setLoading(false));
        } catch (err) {
            dispatch(setLoading(false));
            dispatch(errorMsg(err.data.message));
            dispatch(setError(err.data.message));
            if (params.onError) params.onError();
        }
    };

const initialState = {
    travellers: {},
    formData: {
        supervisor: "",
        languageOfSupervisor: "sq",
        emailOfSupervisor: "",
        contactNumber: "",
        secondaryContactNumber: "",
        paymentMethodId: "",
        paymentRecipient: "Administrator",
        invoiceInTheNameOf: "Administrator",
        customerId: null,
        source: "ADMIN",
    },
    error: "",
    success: false,
    loading: false,
};

const handleFormDataChange = (state: any, action: any) => {
    const {field, value} = action.payload;

    state.formData[field] = value;
};
const stateSlice = createSlice({
    name: "schedule/create-schedule",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setScheduleTravellers: (state, action) => {
            state.travellers = action.payload;
        },
        setFormData: (state, action) => handleFormDataChange(state, action),
        clearScheduleData: (state) => {
            state.formData = initialState.formData;
            state.travellers = {};
        },
    },
});

export const {
    setLoading,
    setSuccess,
    setError,
    setScheduleTravellers,
    setFormData,
    clearScheduleData,
} = stateSlice.actions;

export default stateSlice.reducer;
