import DashboardPage from './DashboardPage';
import {PageConfigTypes} from "app/types/PageTypes";

const DashboardPageConfig: PageConfigTypes = {
  baseRoute: {
    path: '/dashboard',
    exact: true,
    component: DashboardPage
  },
  subRoutes: [],
  settings: {}
}

export default DashboardPageConfig;