import React, {useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTableRowStyles} from "./employee-table.styles";
import EmployeeTableCell from "./EmployeeTableCell";
import {TableActionsTypes} from "../../../types/TableTypes";
import {useDispatch} from "react-redux"
import history from '@history';
import ModalDialog from 'app/main/shared-components/modal-dialog/ModalDialog'
import {useTranslation} from "react-i18next";
import EmployeTableActionButtons from "./EmployeTableActionButton";
import {deactivateEmployee} from "../../employees/store/deactivate-employee.slice";
import {activateEmployee} from "../../employees/store/activate-employee.slice";

const EmployeeTableRow = (props: any) => {
    const dispatch = useDispatch();
    const classes = useTableRowStyles();
    const {t} = useTranslation();
    const {row} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const status = row.status ? "Active" : "Deactive";

    const tableActions: TableActionsTypes = {
        onEditRow: (rowId: number) => {
            history.push({
                pathname: `/employee-edit/${rowId}/edit`,
                state: {id: rowId},
            });
        },
        [row.status ? 'onDeactivateRow' : 'onActivateRow']: (rowId: string) => {
            setModalOpen(true);
        },
    }

    return (
        <React.Fragment>
            {row.status ? (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle", "Deactivate")} `}
                    title={`${t("app.main.employees.employeesList.deletePopup.primaryText", "Are you sure you want to delete employee")} '${row.fullName}'?`}
                    onOk={() => {
                        dispatch(deactivateEmployee({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            ) : (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle1", "Activate")} `}
                    title={`${t("app.main.employees.employeesList.activatePopup.primaryText", "Are you sure you want to activate employee")} '${row.fullName}'?`}
                    onOk={() => {
                        dispatch(activateEmployee({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            )}
            <TableRow className={classes.root}>
                <TableCell style={{textAlign: 'center'}}>
                    <EmployeeTableCell {...props} content={[
                        `${row.fullName}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <EmployeeTableCell {...props} content={[
                        `${row.email}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center', color: row.status ? "green" : "red", fontWeight: "bold"}}>
                    <EmployeeTableCell {...props} content={[
                        `${status}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <EmployeTableActionButtons {...tableActions} rowId={row.id}/>
                </TableCell>

            </TableRow>
        </React.Fragment>
    );
};

export default EmployeeTableRow;