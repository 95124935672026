import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {ReservationService} from "app/services";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {filterSchedules} from "./filter-schedules.slice";
import store from "../../../store";

interface UpdatePassengerStateParams {
  id: string;
  body: any;
  onSuccess?: Function;
  onError?: Function;
}

interface UpdateStatusPassenger {
  reservationId: string;
  travelerId: string;
  status: boolean;
  onSuccess?: Function;
  onError?: Function;
}

export const updateStatusPassenger =
    (params: UpdateStatusPassenger) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updateStatusPassenger(
            params.reservationId,
            params.travelerId,
            params.status
        );
        console.log(`response update`, response);
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Passenger changed successfully!' : 'Pasagjeri u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

export const updatePassenger =
    (params: UpdatePassengerStateParams) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updatePassenger(
            params.id,
            params.body
        );
        console.log(`response update`, response);
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Reservation changed successfully!' : 'Rezervimi u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

export const updatePassengerBasicInfo =
    (params: UpdatePassengerStateParams) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updatePassengerBasicInfo(
            params.id,
            params.body
        );
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Passenger changed successfully!' : 'Pasagjeri u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

export const updatePassengerDepartureFlight =
    (params: UpdatePassengerStateParams) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updateDepartureFlight(
            params.id,
            params.body
        );
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Flight changed successfully!' : 'Fluturimi u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

export const updatePassengerDeparturePrice =
    (params: UpdatePassengerStateParams) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updateDepartureFlightPrice(
            params.id,
            params.body
        );
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Price changed successfully!' : 'Çmimi u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

export const updatePassengerReturnFlight =
    (params: UpdatePassengerStateParams) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updateReturnFlight(
            params.id,
            params.body
        );
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Flight changed successfully!' : 'Fluturimi u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

export const updatePassengerReturnPrice =
    (params: UpdatePassengerStateParams) => async (dispatch: Dispatch) => {
      const filters = store.getState().schedules.filterSchedules;
      try {
        dispatch(setLoading(true));
        const response = await ReservationService.updateReturnFlightPrice(
            params.id,
            params.body
        );
        if (response.status >= 200 || response.status < 300) {
          dispatch(setSuccess(true));
          dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Price changed successfully!' : 'Çmimi u ndryshua me sukses!'));
          if (params.onSuccess) params.onSuccess();
          dispatch(setError(""));
          // @ts-ignore
          dispatch(
              // @ts-ignore
              filterSchedules({
                ...filters,
                page: 0,
              })
          );
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
      }
    };

interface UpdatePassengerSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: UpdatePassengerSlice = {
  error: "",
  success: false,
  loading: false,
};

const stateSlice = createSlice({
  name: "schedules/updatePassenger ",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;
