import React, {useEffect, useState} from 'react';
import {ReservationService} from "app/services";
import {useRouteMatch} from "react-router-dom";
import LoadingForm from "app/main/shared-components/loading-form/LoadingForm";
import ReservationActivityItem from "./ReservationActivityItem";
import {useTranslation} from "react-i18next";

const ReservationActivityTab = () => {
  const {params} = useRouteMatch();
  const [activityState, setActivityState] = useState({isLoading: false, activities: [], error: ''});
  const {t} = useTranslation();

  const columns = [
    `${t("app.main.schedules.update.form.label1", "Mbikqyrësi")}`,
    `${t("app.main.schedules.update.form.label2", "Email i mbikqyrësit")}`,
    `${t("app.main.schedules.update.form.label3", "Gjuha e mbikqyrësit")}`,
    `${t("app.main.schedules.update.form.label4", "Numri kontaktues")}`,
    `${t("app.main.schedules.payments.paymentMethod", "Mënyra e pagesës")}`,
    `${t("app.main.schedules.update.form.label5", "Marrësi i pagesës")}`,
    `${t("app.main.schedules.update.form.label6", "Fatura në emër të")}`,
    `${t("app.main.schedules.update.form.label7", "Kodi i konfirmimit")}`,
    `${t("app.main.schedules.searchPanel.input.status", "Statusi")}`
  ]

  useEffect(() => {
    setActivityState({...activityState, isLoading: true});
    if (params) {
      // @ts-ignore
      ReservationService.fetchReservationActivities(params.id)
      .then((res) => setActivityState({
        isLoading: false,
        activities: res,
        error: ''
      }))
      .catch((err) => setActivityState({
        ...activityState,
        isLoading: false,
        error:err.data.message
      }));
    }
  }, []);

  return (
      <div>
        {activityState.isLoading ? (
            <LoadingForm/>
        ) : activityState.activities.map((act: any, idx: number) => (
            <ReservationActivityItem key={idx} columns={columns} row={act}/>
        ))}
      </div>
  );
};

export default ReservationActivityTab;