import React, { useEffect, useState } from "react";
import useStyles from "../airports-list/airport.styles";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AppState } from "../../../store";

import * as yup from "yup";
import { CountryRequestBody } from "../../../types/service-types/CountryTypes";
import { Button, TextField } from "@material-ui/core";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import { toggleQuickPanel } from "../../shared-components/quick-panel/store";
import { createCountry } from "../store/countries/create-country.slice";
import { updateCountry } from "../store/countries/update.country.slice";
import { fetchAllCountries } from "../store/countries/countires-list.slice";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
  index: yup.number().required(),
  name: yup.string().required(),
  code: yup.string().required(),
});

function CountryForm(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let { params } = useRouteMatch() as any;
  const {t} = useTranslation();
  const [country, setCountry] = useState<CountryRequestBody>({
    index: 0,
    code: "",
    name: "",
  });

  const countries = useSelector(
    (state: AppState) => state.configurations.countries.countriesList.countries
  );
  const createCountryState = useSelector(
    (state: AppState) => state.configurations.countries.createCountry
  );
  const updateCountryState = useSelector(
    (state: AppState) => state.configurations.countries.updateCountry
  );

  const [componentTitle, setComponentTitle] = useState(`${t("app.main.agency.agentsList.registerButton", "Register")}`);

  useEffect(() => {
    // @ts-ignore
    if (params.id) {
      dispatch(fetchAllCountries());
      // @ts-ignore
      const selectedCountry: CountryRequestBody = countries.find(
        (country) => country.id === params?.id
      );
      setComponentTitle(`${t( "app.main.configuration.airlineCompanies.create.title1", "Change")}`);
      setCountry({
        name: selectedCountry.name,
        index: selectedCountry.index,
        code: selectedCountry.code,
      });
    }
  }, []);

  const closeAndClearData = () => {
    setCountry({
      index: 0,
      code: "",
      name: "",
    });
    dispatch(toggleQuickPanel());
    history.replace("/configurations/countries");
  };

  const onSubmitFormRequest = (data: CountryRequestBody) => {
    console.log(params?.id);
    if (!params?.id) {
      console.log('aaa')
      dispatch(createCountry({ body: data }));
      closeAndClearData();
      // @ts-ignore
    } else {
      dispatch(updateCountry({ id: params.id, body: data }));
      closeAndClearData();
    }
  };
  return (
    <div style={{ padding: "50px" }}>
      <div className={classes.formHeader}>
        <h1 style={{ color: "#0D3B66" }}>{componentTitle} {t("app.main.configuration.countries.create.title", "country")}:</h1>
      </div>
      <Formik
        validateOnChange={true}
        validationSchema={validationSchema}
        initialValues={country}
        onSubmit={onSubmitFormRequest}
        enableReinitialize
      >
        {({ values, setValues }) => (
          <Form>
            <div className={classes.row}>
              <Field
                variant="outlined"
                onChange={(e: any) => {
                  setValues({
                    ...values,
                    index: e.target.value,
                  });
                }}
                value={values.index}
                name="index"
                label={t("app.main.configuration.airlineCompanies.table.tableRow5", "Number")}
                as={TextField}
                required
                onKeyPress={(event:any) => {
                  if (!/[0-9 ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                type='text'
              />
            </div>
            <div className={classes.row}>
              <Field
                variant="outlined"
                value={values.name}
                name="name"
                label={t("app.main.configuration.airlineCompanies.create.input1", "Name")}
                as={TextField}
                required
              />
            </div>
            <div className={classes.row}>
              <Field
                variant="outlined"
                onChange={(e: any) => {
                  setValues({
                    ...values,
                    code: e.target.value.toUpperCase(),
                  });
                }}
                value={values.code}
                name="code"
                label={t("app.main.configuration.airlineCompanies.table.tableRow3", "Code")}
                as={TextField}
                required
              />
            </div>

            <div className={classes.row}>
              <LoadingButton
                type="submit"
                btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                isLoading={
                  createCountryState.loading || updateCountryState.loading
                }
              />
              <Button onClick={closeAndClearData}>{t("app.main.schedules.splitPassenger.closeButton", "Close")}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CountryForm;
