import React from 'react';
import {BasePage} from "app/types/PageTypes";
import {Redirect, Switch} from "react-router-dom";

const ConfigurationsPage = (props: BasePage) => {

  return (<>
    <Switch>
      {props.children}
      <Redirect to='configurations/airports-list'/>
    </Switch>
  </>);
};

export default ConfigurationsPage;