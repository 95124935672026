import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export default makeStyles<Theme>((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 2,
    color: 'inherit',
    background: '#45637E',
  },
  topBar: {
    padding: '0 160px',
    ['@media (max-width:1450px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 80px;'
    },
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 20px',
    },
    ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0px',
    },

  },
  menuButtons: {
    margin: '0 0rem',
    '& *': {
      margin: '0 5px'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    ['@media (max-width:320px)']: {
      marginLeft: theme.spacing(1)
    },
    ['@media (max-width:400px)']: {
      marginLeft: theme.spacing(1)
    }
  },
  dropdownMenu: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  languageId: {
    ['@media (max-width:1900px)']: {
      paddingTop: '7px'
    },
  },
  logoutId: {
    ['@media (max-width:1900px)']: {
      padding: '7px',
      marginLeft: '-20px'
    },
  },
  agentsCredits: {
    marginTop: '3px',
    marginBottom: '3px',
    fontSize: '14px'
  }
}));