import {Grid, TextField} from "@material-ui/core";
import {Field} from "formik";
import {fillAgentFormField} from "../store/agent-form.slice";
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useTranslation} from "react-i18next";

const AgentInformation = () => {
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const {t} = useTranslation();
  const [fullNameError, setFullNameError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [locationError, setLocationError] = useState<string>('');
  const [contactEmailError, setContactEmailError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const handleChange = (e: any) => {
    const payload = {
      value: e.target.value,
      field: e.target.name
    }
    dispatch(fillAgentFormField(payload))
  }

  const re = useMemo(() => {
    return new RegExp("^[a-zA-Z ]*$");
  }, [])

  const validateFullName = (e: any) => {
    setFullNameError('');
    if (!e.target.value) {
      setFullNameError('Please enter the required field');
    } else if (!re.test(e.target.value)) {
      setFullNameError('Only alphabets are allowed');
    }
  }

  const validatePhoneNumber = (e: any) => {
      setPhoneNumberError('');
      if (!e.target.value) {
          setPhoneNumberError('');
      } else if (!(/^\+(?:[0-9]●?){6,14}[0-9]$/).test(e.target.value)) {
      setPhoneNumberError('Contact number must start with +');
    }
  }

  const validateLocation = (e: any) => {
    setLocationError('');
    if (!(/^[a-zA-Z ]*$/).test(e.target.value)) {
      setLocationError('Only alphabets are allowed');
    }
  }

  const validateContactEmail = (e: any) => {
    setContactEmailError('');
    if (!e.target.value) {
      setContactEmailError('Please enter the required field');
    } else if (!(/^\S+@\S+\.\S+$/).test(e.target.value)) {
      setContactEmailError('E-mail address must be a valid email');
    }
  }
  const validateEmail = (e: any) => {
    setEmailError('');
    if (!e.target.value) {
      setEmailError('Please enter the required field');
    } else if (!(/^\S+@\S+\.\S+$/).test(e.target.value)) {
      setEmailError('E-mail address must be a valid email');
    }
  }

  return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.nameLastname", "Agency Name")}
                onChange={(e: any) => {
                  handleChange(e);
                  validateFullName(e);
                }}
                value={agentForm.fullName}
                variant='outlined'
                size='small'
                name='fullName'
                as={TextField}
                required
            />
            {fullNameError && (<span style={{color: 'red', display: 'inline-block'}}>{fullNameError}</span>)}
          </Grid>
          <Grid item xs={4}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.employees.employeeList.table.phoneNumber", "Phone number")}
                onChange={(e: any) => {
                  handleChange(e);
                  validatePhoneNumber(e);
                }}
                value={agentForm.phoneNumber}
                variant='outlined'
                size='small'
                name='phoneNumber'
                as={TextField}
            />
            {phoneNumberError && (
                <span style={{color: 'red', display: 'inline-block'}}>{phoneNumberError}</span>)}
          </Grid>
          <Grid item xs={4}>
            <Field
                type='email'
                style={{width: '100%'}}
                label={t("app.main.agency.create.email", "Contact E-mail")}
                onChange={(e: any) => {
                  handleChange(e);
                  validateContactEmail(e);
                }}
                variant='outlined'
                value={agentForm.contactEmail}
                size='small'
                name='contactEmail'
                as={TextField}
                required

            />
            {contactEmailError && (
                <span style={{color: 'red', display: 'inline-block'}}>{contactEmailError}</span>)}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
            <Grid item xs={4}>
                <Field
                    style={{width: '100%'}}
                    label={t("app.main.agency.create.companyName", "Company Name")}
                    onChange={(e: any) => {
                        handleChange(e);
                    }}
                    value={agentForm.companyName}
                    variant='outlined'
                    size='small'
                    name='companyName'
                    as={TextField}
                />
            </Grid>
          <Grid item xs={4}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.identifyEmail", "Identification (E-mail)")}
                onChange={(e: any) => {
                  handleChange(e);
                  validateEmail(e);
                }}
                value={agentForm.email}
                variant='outlined'
                size='small'
                name='email'
                as={TextField}
                required
            />
            {emailError && (<span style={{color: 'red', display: 'inline-block'}}>{emailError}</span>)}
          </Grid>
          <Grid item xs={4}>
            <Field
                type='password'
                style={{width: '100%'}}
                label={t("app.main.employees.create.password", "Password")}
                onChange={handleChange}
                value={agentForm.password}
                variant='outlined'
                size='small'
                name='password'
                as={TextField}
                required
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.location", "Location")}
                onChange={(e: any) => {
                  handleChange(e);
                  validateLocation(e);
                }}
                value={agentForm.location}
                variant='outlined'
                size='small'
                name='location'
                as={TextField}
            />
            {locationError && (<span style={{color: 'red', display: 'inline-block'}}>{locationError}</span>)}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.customer.customerList.address", "Address")}
                onChange={handleChange}
                value={agentForm.address}
                variant='outlined'
                size='small'
                name='address'
                as={TextField}
                multiline
                rows={4}
            />
          </Grid>
        </Grid>
      </div>
  )
}

export default AgentInformation;
