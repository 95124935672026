import {combineReducers} from '@reduxjs/toolkit';

import scheduleForm from './schedule-form.slice';
import flightResults from './flight-results.slice';
import createSchedule from './create-schedule.slice';

const reducer = combineReducers({
  scheduleForm,
  flightResults,
  createSchedule
});

export default reducer;
