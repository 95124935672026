import {Grid, TextField} from "@material-ui/core";
import {Field} from "formik";
import {fillAgentFormField} from "../store/agent-form.slice";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useTranslation} from "react-i18next";

const AgentEditInformation = () => {
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const {t} = useTranslation();

  const handleChange = (e: any) => {
    const payload = {
      value: e.target.value,
      field: e.target.name
    }
    dispatch(fillAgentFormField(payload))
  }

  return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.nameLastname", "Agency Name")}
                onChange={handleChange}
                value={agentForm.fullName}
                variant='outlined'
                size='small'
                name='fullName'
                as={TextField}
                required
            />
          </Grid>
          <Grid item xs={4}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.employees.employeeList.table.phoneNumber", "Phone number")}
                onChange={handleChange}
                value={agentForm.phoneNumber}
                variant='outlined'
                size='small'
                name='phoneNumber'
                as={TextField}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
                type='email'
                style={{width: '100%'}}
                label={t("app.main.agency.create.email", "Contact E-mail")}
                onChange={handleChange}
                variant='outlined'
                value={agentForm.contactEmail}
                size='small'
                name='contactEmail'
                as={TextField}
                required
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.companyName", "Company Name")}
                onChange={handleChange}
                value={agentForm.companyName}
                variant='outlined'
                size='small'
                name='companyName'
                as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.agency.create.location", "Location")}
                onChange={handleChange}
                value={agentForm.location}
                variant='outlined'
                size='small'
                name='location'
                as={TextField}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
                style={{width: '100%'}}
                label={t("app.main.customer.customerList.address", "Address")}
                onChange={handleChange}
                value={agentForm.address}
                variant='outlined'
                size='small'
                name='address'
                as={TextField}
                multiline
                rows={4}
            />
          </Grid>
        </Grid>
      </div>
  )
}

export default AgentEditInformation;
