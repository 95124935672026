import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {FlightService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";
import {ReservationType} from "../../../../types/service-types/ReservationTypes";

interface GetFlightParams {
  id: string;
  onSuccess?: Function;
  onError?: Function;
}

export const getReservationsFlight = (params: GetFlightParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FlightService.getReservationsFlightById(params.id);
    //mapped response to a new object with 'content' property
    // so that <CollapsibleTable> comp. can accept the data
    let objResponse = {content: response};
    dispatch(setReservationsFlight(objResponse));
    if (params.onSuccess) params.onSuccess(objResponse);
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data?.message));
  }
}

interface ReservationsFlightSlice {
  reservationsFlight: ReservationType[];
  loading: boolean;
}

const initialState: ReservationsFlightSlice = {
  reservationsFlight: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'flightsedit/reservations',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setReservationsFlight: (state, action) => {
      state.reservationsFlight = action.payload
    }
  }
});

export const {setLoading, setReservationsFlight} = stateSlice.actions;

export default stateSlice.reducer;