import {BasePage} from "../../types/PageTypes";
import {Redirect, Switch} from "react-router-dom";
import PageHeader from "../shared-components/page-header/PageHeader";
import React from "react";

const AgencyReportsPage = (props: BasePage) => {
    return (
        <Switch>
            {props.children}
            <PageHeader
                title="Agency Reports"
                desc="History of reports that agents have done - Click the Payment or Provision to see the Reports that has been done."
            />
            <Redirect to='/agency-reports'/>
        </Switch>
    )
}

export default AgencyReportsPage;