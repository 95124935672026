import {createStyles, fade, makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      search: {
        marginTop: '0px',
        border: '5px',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
    }),
);


export const flightsFormStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    padding: '20px',
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1)
    }
  },
  tableRow: {
    '& input': {
      width: '80px'
    },
    '& input[name="name"]': {
      width: '100px'
    }
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(1.5)
  },
  row: {
    display: 'flex',
    '& > div': {
      flex: 1,
      margin: theme.spacing(0, .5)
    },
    padding: theme.spacing(2, 0)
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 'auto',
    height: 'auto',
    flex: 1,
    flexBasis: '15%',

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  }
}));

export const FlightPricesTableStyles = makeStyles((theme: Theme) => ({
  sectorTableHeader: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    // '& > div': {
    //   border: '1px solid red'
    // }
  },
  tableRow: {
    '& input': {
      width: '80px'
    },
    '& input[name="name"]': {
      width: '100px'
    }
  },
  addNewTravelerRow: {
    transition: 'all 150ms ease-in',
    '&:hover': {
      background: 'rgba(0,0,0,.05)',
      cursor: 'pointer'
    },
    '&:active': {
      transition: 'none',
      background: 'rgba(0,0,0,.1)',
    }
  }
}))
