import {createSlice, Dispatch} from "@reduxjs/toolkit";

const getInitialState = () => ({
  id: '',
  amount: '',
  description: '',
  currencyId: '',
});

export const setTheAgentPaymentForm = (payload: any) => (dispatch: Dispatch) => {
  dispatch(setAgentPaymentForm(payload));
};

export const resetTheAgentPaymentForm = () => (dispatch: Dispatch) => {
  dispatch(resetAgentPaymentForm());
};


const stateSlice = createSlice({
  name: "agentedit/payment-form",
  initialState: getInitialState(),
  reducers: {
    setAgentPaymentForm: (state, action) => action.payload,
    fillPaymentFormField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      return {
        ...state,
        [field]: value
      }
    },
    resetAgentPaymentForm: () => getInitialState(),
  },
});

export const {
  setAgentPaymentForm,
  resetAgentPaymentForm,
    fillPaymentFormField
} = stateSlice.actions;

export default stateSlice.reducer;