import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {FilterFlightsQueryParams, PagedFilghtsList} from "app/types/service-types/FlightTypes";
import {FlightService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";

export const fetchAllFlights = (queryParams?: FilterFlightsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;

    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await FlightService.filterFlights(queryParams);
    } else response = await FlightService.getAll();
    dispatch(setFlightsList(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface FlightsListSlice {
  allFlights: PagedFilghtsList | null;
  loading: boolean;
}

const initialState: FlightsListSlice = {
  allFlights: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'flights/flights-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setFlightsList: (state, action) => {
      state.allFlights = action.payload
    }
  }
});

export const {setLoading, setFlightsList} = stateSlice.actions;

export default stateSlice.reducer;
