import React, {useEffect, useState} from 'react';
import {Button, TextField,} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import useStyles from './paymentmethods.style';
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {createPaymentMethod} from "../store/paymentmethods/create-paymentmethod.slice";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {useHistory, useRouteMatch} from "react-router-dom";
import {PaymentMethodsRequestBoyd} from "app/types/service-types/PaymentMethodsTypes";
import LoadingForm from "app/main/shared-components/loading-form/LoadingForm";
import {updatePaymentMethod} from "../store/paymentmethods/update-paymentmethod.slice";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
  name: yup.string().required(),
})

const PaymentMethodsForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let {params} = useRouteMatch();
  const {t} = useTranslation();
  const {allPaymentMethods} = useSelector((state: AppState) => state.configurations.paymentmethods.paymentmethodsList);

  const createPaymentMethodState = useSelector((state: AppState) => state.configurations.paymentmethods.createPaymentMethod);
  const updatePaymentMethodState = useSelector((state: AppState) => state.configurations.paymentmethods.updatePaymentMethod);

  const [loading, setLoading] = useState(true);

  const [componentTitle, setComponentTitle] = useState(`${t("app.main.configuration.paymentMethods.create.title", "Register a payment method")}`);

  const [singlePaymentMethod, setSinglePaymentMethod] = useState<PaymentMethodsRequestBoyd>({
    name: '',
    icon: ''
  });

  useEffect(() => {
    if (params) {
      // @ts-ignore
      const foundedPaymentMethod: any = allPaymentMethods.find(paymentMethod => paymentMethod.id === params.id);

      if (foundedPaymentMethod) {
        setComponentTitle(`${t("app.main.configuration.paymentMethods.create.title2", "Change a payment method")}`);
        setSinglePaymentMethod({
          name: foundedPaymentMethod.name,
          icon: foundedPaymentMethod.icon
        });
      }

      setLoading(false);
    } else setLoading(false);
  }, []);

  if (loading) return <LoadingForm/>

  const onSubmitFormRequest = (data: PaymentMethodsRequestBoyd) => {
    if (params) {
      // @ts-ignore
      const foundedPaymentMethod: any = allPaymentMethods.find(paymentMethod => paymentMethod.id === params.id);
      // update
      if (foundedPaymentMethod) {
        const id = foundedPaymentMethod.id ? foundedPaymentMethod.id : '';
        dispatch(updatePaymentMethod({
          id,
          body: data,
          onSuccess: () => dispatch(toggleQuickPanel())
        }))
      } else {
        dispatch(createPaymentMethod({
          body: data
        }));
      }
    }
  }

  return (

      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: '#0D3B66'}}>{componentTitle}:</h1>
        </div>
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={singlePaymentMethod}
            onSubmit={onSubmitFormRequest}
        >
          {({values, setValues}) => (
              <Form>
                <div className={classes.row} style={{marginTop: '30px'}}>
                  <Field variant='outlined' value={values.name} name='name' label={t("app.main.configuration.airlineCompanies.create.input1", "Name")}
                         as={TextField} required/>
                </div>

                <div className={classes.row}>
                  <LoadingButton
                      type='submit'
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={
                          createPaymentMethodState.loading || updatePaymentMethodState.loading
                      }/>
                  <Button onClick={(e) => {
                    // e.stopPropagation();
                    dispatch(toggleQuickPanel())
                    history.replace('/configurations/payment-methods')
                  }}>{t("app.main.schedules.splitPassenger.closeButton", "Close")}</Button>
                </div>
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default PaymentMethodsForm;
