import React, {useRef, useState} from 'react';

import {NavLink} from 'react-router-dom';
import {IconNavLinkTypes} from 'app/types/NavlinkTypes';

import useStyles from './icon-nav-link.styles';
import {Button} from '@material-ui/core';
import useAuthority from 'app/hooks/useAuthority';
import {useDispatch} from "react-redux";
import {getAccount} from "../../../account/store/get-account.slice";
import {ROLES} from "../../../constants/roles";


const IconNavLink = (props: IconNavLinkTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {hasUserAnyAuthority} = useAuthority();

  const [isActive, setIsActive] = useState(false);

  const navLinkRef = useRef<HTMLAnchorElement>(null);

  const onButtonClickHandler = () => {
    if (navLinkRef && navLinkRef.current) {
      navLinkRef.current.click();
    }

    if (hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
      dispatch(getAccount())
    }
  }

  const {to, title, iconComponent, disabled, exact, authorities, ...other} = props;

  if (!hasUserAnyAuthority(authorities || [])) {
    return null
  }

  return (
      <div className={classes.root}>
        <Button
            disabled={disabled}
            color='inherit'
            className={classes.button}
            style={isActive ? {background: '#e7e7e7'} : {}}
            startIcon={iconComponent}
            onClick={onButtonClickHandler}>
          {title}
        </Button>
        <NavLink
            isActive={(active) => {
              if (active?.url === props.to) setIsActive(true);
              else setIsActive(false);
              return active?.url === props.to;
            }}
            ref={navLinkRef}
            to={to}
            exact={exact}
            {...other}
        />
      </div>
  )
}

export default IconNavLink;
