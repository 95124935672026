import React, {useEffect, useState} from 'react'
import {BasePage} from "app/types/PageTypes";
import DashboardCard from "./components/DashboardCard";
import {EventSeat, Money, People} from '@material-ui/icons';
import './dashboard-page.css';
import Chart from "react-apexcharts";
import paymentMethodsChartOpt from './paymentsMethodsChartOptions'
import revenueChartOpt from './revenueChartOptions'
import {CircularProgress} from "@material-ui/core";
import DashboardService from "../../services/dashboard.service";
import DateUtils from "../../utils/DateUtils";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useTranslation} from "react-i18next";
import useAuthority from "../../hooks/useAuthority";
import {ROLES} from "../../constants/roles";

const DashboardPage = (props: BasePage) => {
    const [paymentMethodsChartOptions, setPaymentMethodsChartOptions] = useState(paymentMethodsChartOpt) as any;
    const [paymentMethodsSeries, setPaymentMethodsSeries] = useState([]) as any;
    const [revenueChartOptions, setRevenueOptions] = useState(revenueChartOpt) as any;
    const [revenueSeries, setRevenueSeries] = useState([]) as any;
    const [reservationsToday, setReservationsToday] = useState('-') as any;
    const [revenueToday, setRevenueToday] = useState('-') as any;
    const [travelers, setTravelers] = useState('-') as any;
    const [disabledRes, setDisabledRes] = useState('-') as any;
    const [revenueLoading, setRevenueLoading] = useState(false);
    const {t} = useTranslation();
    const {hasUserAnyAuthority} = useAuthority();

    const [dates, setDates] = useState({
        fromDate: DateUtils.toUTCDate(DateUtils.convertDate(DateUtils.removeDaysToDate(new Date(), 7))),
        toDate: DateUtils.toUTCDate(DateUtils.convertDate(new Date()))
    });

    useEffect(() => {
        DashboardService.revenueToday().then(res => {
            if (res !== undefined) {
                setRevenueToday(res);
            }
        })
        DashboardService.reservationsToday().then(res => {
            setReservationsToday(res)
        })
        DashboardService.registeredTravelers().then(res => {
            setTravelers(res)
        })
        DashboardService.disabledReservations().then(res => {
            setDisabledRes(res);
        })

        fetchRevenueSummary(
            DateUtils.convertDate(DateUtils.removeDaysToDate(new Date(), 7)),
            DateUtils.convertDate(DateUtils.addDaysToDate(new Date(dates.toDate), 1)))

        DashboardService.paymentMethods().then(res => {
            let valutes = Object.values(res)[0] === 0 && Object.values(res)[1] === 0 && Object.values(res)[2] === 0 && Object.values(res)[3] === 0
                && Object.values(res)[4] === 0 && Object.values(res)[5] === 0 && Object.values(res)[6] === 0;
            if (valutes) {
                setPaymentMethodsSeries([]);
            } else {
                setPaymentMethodsChartOptions({
                    ...paymentMethodsChartOptions,
                    labels: Object.keys(res)
                });
                setPaymentMethodsSeries(Object.values(res));
            }
        });
    }, []);

    const handleChange = (name: string, value: any) => {
        setDates({
            ...dates,
            [name]: value
        })
    }

    useEffect(() => {
        fetchRevenueSummary(
            DateUtils.convertDate(dates.fromDate),
            DateUtils.convertDate(DateUtils.addDaysToDate(new Date(dates.toDate), 1))
        )
    }, [dates]);

    const fetchRevenueSummary = (fromDate: any, toDate: any) => {
        setRevenueLoading(true)
        DashboardService.revenueSummary({
            fromDate,
            toDate
        }).then(res => {
            const series = res.map((currency: any) => {
                const data = currency.revenueDates.map((r: any) => r.amount);
                const isDataNull = data.every((d: any) => !d);
                const currencyName = currency.currencyResponse.name;
                return {
                    name: currencyName,
                    data: !isDataNull ? data : []
                }
            })

            setRevenueSeries(series);
            setRevenueOptions({
                ...revenueChartOptions,
                xaxis: {
                    ...revenueChartOptions.xaxis,
                    categories: res[0].revenueDates.map((r: any) => r.date)
                }
            })
            setRevenueLoading(false)
        })
            .catch(err => {
                setRevenueLoading(false)
            })
    }


    return (
        <div className="dashboard" style={{color: 'black', padding: '20px'}}>
            <div className="dashboard-cards" style={{display: 'flex', alignItems: 'center'}}>
                <DashboardCard text={t("app.main.dashboard.dashboardCard.text2", "Number of pax booked today")}
                               icon={People} data={travelers}
                               iconColor="#F7B835"/>
                <DashboardCard
                    text={t("app.main.dashboard.dashboardCard.text1", "Today's reservations")}
                    icon={EventSeat} data={reservationsToday}
                    iconColor="#1398D3"/>
                {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) && (
                    <>
                        <DashboardCard text={t("app.main.dashboard.dashboardCard.text3", "Today's revenue")}
                                       icon={Money} data={revenueToday}
                                       iconColor="#9635F7"/>
                    </>
                )}
                <DashboardCard
                    text={t("app.main.dashboard.dashboardCard.text5", "Canceled bookings today")}
                    icon={EventSeat} data={disabledRes}
                    iconColor="#FF0000"/>
            </div>
            {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) && (
                <div className="dashboard_charts">
                    <div className="flex flex-col"
                         style={{minWidth: '40%', minHeight: '300px', borderRight: '1px solid lightgray'}}>
                        <h2 style={{
                            marginLeft: '15px',
                            marginBottom: '10px',
                            fontWeight: 500
                        }}>{t("app.main.dashboard.dashboardTitle", "Today's Payment Methods")} :</h2>
                        <Chart
                            width="100%"
                            height="100%"
                            type="pie"
                            options={paymentMethodsChartOptions}
                            series={paymentMethodsSeries}
                        />
                    </div>
                    <div className="flex flex-col" style={{minWidth: '60%', minHeight: '300px'}}>
                        <div className="flex items-center space-between"
                             style={{marginBottom: '10px', fontWeight: 500}}>
                            <h2 style={{marginLeft: '15px',}}>{t("app.main.dashboard.dashboardCard.text4", "Revenue Summary")}:</h2>
                            <div className="flex items-center " style={{flex: 1, justifyContent: 'end'}}>
                                <div style={{
                                    display: 'flex',
                                    marginRight: '20px',
                                    alignItems: 'center',
                                    position: 'relative'
                                }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            inputVariant="outlined"
                                            disableToolbar
                                            autoOk
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            label={t("app.main.schedules.searchPanel.datepicker.from", "From date")}
                                            name='fromDate'
                                            value={dates.fromDate}
                                            style={{width: "100%"}}
                                            onChange={(date) => handleChange('fromDate', date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            inputVariant="outlined"
                                            disableToolbar
                                            autoOk
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            label={t("app.main.schedules.searchPanel.datepicker.to", "To")}
                                            name='toDate'
                                            value={dates.toDate}
                                            style={{width: "100%"}}
                                            onChange={(date) => handleChange('toDate', date)}

                                        />
                                    </MuiPickersUtilsProvider>

                                </div>
                            </div>
                        </div>

                        {revenueLoading && <div style={{
                            width: '100%',
                            height: '100%',
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>

                            <CircularProgress/>
                        </div>}

                        {!revenueLoading && <Chart
                            width="100%"
                            height="100%"
                            type="line"
                            options={revenueChartOptions}
                            series={revenueSeries}/>}
                    </div>
                </div>
            )}
            {props.children}
        </div>
    )
}

export default DashboardPage;
