import React, {useEffect, useState} from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {scheduleFormStyles} from "../schedule.styles";
import {useDispatch, useSelector} from "react-redux";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {Button, Icon, Typography} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import DateUtils from "app/utils/DateUtils";
import {FlightResultTypes, FlightResultTypess,} from "../../../types/service-types/ScheduleTypes";
import {AppState} from "app/store";
import {
    fetchAllFlightResults,
    fetchAllReturnFlightResults,
    setSelectedFlight,
    setSelectedReturnFlight,
} from "../store/flight-results.slice";
import {
    handleScheduleFormChange,
    setScheduleForm,
} from "app/main/schedule/store/schedule-form.slice";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {warningMsg} from "../../../utils/MessageUtils";
import {WAY_TYPE} from "../../../constants/constants";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import FlightPricesComponent from "./FlightPricesComponent";
import {useTranslation} from "react-i18next";
import useAuthority from "../../../hooks/useAuthority";
import {ROLES} from "../../../constants/roles";

const ScheduleResultsPage = () => {
    const classes = scheduleFormStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const flightoffIcon = <FlightTakeoffIcon/>;
    const flightIcon = <FlightLandIcon/>;
    const {hasUserAnyAuthority} = useAuthority();

    const {flightResults, loading} = useSelector(
        (state: AppState) => state.schedule.flightResults
    );
    const {selectedFlight, selectedReturnFlight} = useSelector(
        (state: AppState) => state.schedule.flightResults
    );
    const {returnFlightResults, returnFlightResultsLoading} = useSelector(
        (state: AppState) => state.schedule.flightResults
    );
    const {scheduleForm} = useSelector(
        (state: AppState) => state.schedule.scheduleForm
    );
    const {wayType} = useSelector(
        (state: AppState) => state.schedule.scheduleForm
    );

    const [selectedDate, setSelectedDate] = React.useState(
        scheduleForm.dateOfDeparture
    );
    const [selectedReturnFlightDate, setSelectedReturnFlightDate] =
        React.useState(scheduleForm.dateOfReturnFlight);
    const [selectedTableFlight, setSelectedTableFlight] =
        useState<any>(selectedFlight);
    const [selectedTableReturnFlight, setSelectedTableReturnFlight] =
        useState<any>(selectedReturnFlight);

    const onFlightSelect = (flight: FlightResultTypess) => {
        const totalPrice =
            flight.taxOfFlight +
            flight.availableSectorPrice.price.oneWayPrices.forAdults;
        setSelectedTableFlight({
            ...flight,
            tax: flight.taxOfFlight,
            adultDeparturePrice:
            flight.availableSectorPrice.price.oneWayPrices.forAdults,
            adultReturnPrice:
            flight.availableSectorPrice.price.returnPrices.forAdults,
            currencySymbol: flight.currency.symbol,
            totalPrice,
        });
    };

    const onReturnFlightSelect = (flight: FlightResultTypess) => {
        const totalPrice =
            flight.taxOfFlight +
            flight.availableSectorPrice.price.returnPrices.forAdults;
        setSelectedTableReturnFlight({
            ...flight,
            tax: flight.taxOfFlight,
            adultReturnPrice:
            flight.availableSectorPrice.price.returnPrices.forAdults,
            currencySymbol: flight.currency.symbol,
            totalPrice,
        });
    };

    useEffect(() => {
        if (wayType === WAY_TYPE.RETURN_FLIGHT && selectedTableReturnFlight) {
            dispatch(setSelectedReturnFlight(selectedTableReturnFlight));
        }
    }, [selectedTableReturnFlight]);

    useEffect(() => {
        dispatch(setSelectedFlight(selectedTableFlight));
    }, [selectedTableFlight]);

    const setFlights = () => {
        if (
            !selectedTableFlight ||
            (wayType === WAY_TYPE.RETURN_FLIGHT && !selectedTableReturnFlight)
        ) {
            dispatch(warningMsg(localStorage.getItem('i18nextLng') === 'en' ? 'You have not selected a flight!' : 'Nuk keni selektuar fluturimin!'));
            return;
        }

        history.push("/schedule/step3");
    };

    const canSeeFreeSeats = (arrivalAirportName: string, arrivalAirportCode: any, numberOfSeats: any) => {
        if (hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
            return (
                <>
                    {arrivalAirportName} {" ("}
                    <b>{arrivalAirportCode}</b>
                    {t("app.main.schedule.secondStep.tableRow1", ") only ")} {numberOfSeats} {t("app.main.schedule.secondStep.tableRow2", " free seats")}
                </>
            )
        } else if (hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
            return (
                <>
                    {arrivalAirportName} {"("}<b>{arrivalAirportCode}</b>{")"}
                </>
            )
        }
    }

    const columns = (wayType: string) => {
        return [
            {
                Header: "",
                accessor: "number",
                Cell: ({cell}: any) => {
                    const {
                        number,
                    } = cell.row.original;
                    return "#" + number;
                },
            },
            {
                Header: "",
                accessor: "departureAirport",
                Cell: ({cell}: any) => {
                    const {
                        departureAirport,
                        arrivalAirport,
                        numberOfAvailableSeats,
                    } = cell.row.original;
                    return (
                        <div>
                <span>
                  <Icon
                      fontSize="small"
                      style={{
                          transform: "rotate(45deg)",
                          fontSize: "1.2rem",
                          marginBottom: "-4px",
                          marginRight: "2px",
                      }}
                  >
                    flight
                  </Icon>
                </span>
                            {departureAirport.name} {" ("}
                            <b>{departureAirport.code}</b>
                            {") => "}
                            <span>
                  <Icon
                      fontSize="small"
                      style={{
                          transform: "rotate(135deg)",
                          fontSize: "1.2rem",
                          marginBottom: "-4px",
                          marginRight: "2px",
                      }}
                  >
                    flight
                  </Icon>
                 </span>
                            {canSeeFreeSeats(arrivalAirport.name, arrivalAirport.code, numberOfAvailableSeats)}
                        </div>
                    );
                },
            },
            {
                Header: "",
                accessor: "dateOfDeparture",
                Cell: ({cell}: any) => {
                    const {
                        dateOfDeparture,
                        timeOfDeparture,
                    } = cell.row.original;
                    return DateUtils.toHumanDate(dateOfDeparture, timeOfDeparture);
                },
            },
            {
                Header: "",
                accessor: "availableSectorPrice",
                Cell: ({cell}: any) => {
                    const {
                        currency,
                        availableSectorPrice,
                        taxOfFlight,
                    } = cell.row.original;
                    if (wayType === WAY_TYPE.ONE_WAY) {
                        return (
                            availableSectorPrice.price.oneWayPrices.forAdults +
                            taxOfFlight +
                            " " +
                            currency.symbol +
                            `${t("app.main.schedule.secondStep.tableRow3", " with tax")}`
                        );
                    }
                    return (
                        availableSectorPrice.price.returnPrices.forAdults +
                        taxOfFlight +
                        " " +
                        currency.symbol +
                        `${t("app.main.schedule.secondStep.tableRow3", " with tax")}`
                    );
                },
            },
        ];
    };

    const today = new Date();
    return (
        <div>
            {wayType != WAY_TYPE.RETURN_FLIGHT && (
                <>
                    <div>
                        <Grid
                            container
                            style={{display: "flex", justifyContent: "space-between"}}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <Typography style={{margin: "0.5rem 1.2rem 0 0"}}>
                                            {t("app.main.schedules.addPassenger.departureTime", "Departure time")}:
                                        </Typography>
                                        <DatePicker
                                            disableToolbar
                                            size={"small"}
                                            minDate={today}
                                            inputVariant="outlined"
                                            autoOk
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={selectedDate}
                                            style={{marginBottom: "20px", marginTop: 0}}
                                            onChange={(date) => {
                                                setSelectedFlight(null);
                                                if (date) {
                                                    setSelectedDate(date.toISOString());
                                                    dispatch(
                                                        setScheduleForm({
                                                            ...scheduleForm,
                                                            dateOfDeparture: date.toISOString(),
                                                        })
                                                    );
                                                    dispatch(
                                                        fetchAllFlightResults({
                                                            queryParams: {
                                                                oneWayPrice: scheduleForm.oneWayPrice,
                                                                returnPrice: scheduleForm.returnPrice,
                                                                departureAirportId:
                                                                scheduleForm.departureAirportId,
                                                                arrivalAirportId: scheduleForm.arrivalAirportId,
                                                                dateOfDeparture: DateUtils.convertDate(
                                                                    date.toISOString()
                                                                ),
                                                            },
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>

                                <BaseTable
                                    columns={columns(wayType)}
                                    data={flightResults?.content}
                                    onClickTableRow={onFlightSelect}
                                    isLoading={loading}
                                    disableRow={(row: FlightResultTypes) =>
                                        scheduleForm.adult + scheduleForm.children >
                                        row.numberOfAvailableSeats
                                    }
                                    selectedRow={(row: FlightResultTypes) =>
                                        row.id === selectedTableFlight?.id
                                    }
                                />

                                <div
                                    style={{
                                        marginBottom: "50px",
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-end",
                                        padding: "15px",
                                        background: "#e7e7e7",
                                    }}
                                >
                                    <FlightPricesComponent typeOfFlight={"ONE_WAY"} flight={selectedTableFlight}
                                                           returnFlight={selectedTableReturnFlight}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            )}
            {wayType === WAY_TYPE.RETURN_FLIGHT && (
                <>
                    <div>
                        <Grid
                            container
                            style={{display: "flex", justifyContent: "space-between"}}
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <Typography style={{margin: "0.5rem 1.2rem 0 0"}}>
                                            {t("app.main.schedules.addPassenger.departureTime", "Departure time")}:
                                        </Typography>
                                        <DatePicker
                                            disableToolbar
                                            size={"small"}
                                            inputVariant="outlined"
                                            autoOk
                                            minDate={today}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={selectedDate}
                                            style={{marginBottom: "20px", marginTop: 0}}
                                            onChange={(date) => {
                                                setSelectedFlight(null);
                                                if (date) {
                                                    setSelectedDate(date.toISOString());
                                                    dispatch(
                                                        handleScheduleFormChange({
                                                            field: "dateOfDeparture",
                                                            value: date.toISOString(),
                                                        })
                                                    );
                                                    dispatch(
                                                        fetchAllFlightResults({
                                                            queryParams: {
                                                                oneWayPrice: scheduleForm.oneWayPrice,
                                                                returnPrice: scheduleForm.returnPrice,
                                                                departureAirportId:
                                                                scheduleForm.departureAirportId,
                                                                arrivalAirportId: scheduleForm.arrivalAirportId,
                                                                dateOfDeparture: DateUtils.convertDate(
                                                                    date.toISOString()
                                                                ),
                                                            },
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <BaseTable
                                    columns={columns(wayType)}
                                    data={flightResults?.content}
                                    onClickTableRow={onFlightSelect}
                                    isLoading={loading}
                                    disableRow={(row: FlightResultTypes) =>
                                        scheduleForm.adult + scheduleForm.children >
                                        row.numberOfAvailableSeats
                                    }
                                    selectedRow={(row: FlightResultTypes) =>
                                        row.id === selectedTableFlight?.id
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <Typography style={{margin: "0.5rem 1.2rem 0 0"}}>
                                            {t("app.main.schedules.addPassenger.arrivalTime", "Arrival Time")}:
                                        </Typography>
                                        <DatePicker
                                            size={"small"}
                                            disableToolbar
                                            inputVariant="outlined"
                                            autoOk
                                            minDate={selectedDate}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={selectedReturnFlightDate}
                                            style={{marginBottom: "20px", marginTop: 0}}
                                            onChange={(date) => {
                                                setSelectedReturnFlight(null);
                                                if (date) {
                                                    setSelectedReturnFlightDate(date.toISOString());
                                                    dispatch(
                                                        handleScheduleFormChange({
                                                            field: "dateOfReturnFlight",
                                                            value: date.toISOString(),
                                                        })
                                                    );
                                                    dispatch(
                                                        fetchAllReturnFlightResults({
                                                            queryParams: {
                                                                oneWayPrice: scheduleForm.oneWayPrice,
                                                                returnPrice: scheduleForm.returnPrice,
                                                                departureAirportId:
                                                                scheduleForm.arrivalAirportId,
                                                                arrivalAirportId:
                                                                scheduleForm.departureAirportId,
                                                                dateOfDeparture: DateUtils.convertDate(
                                                                    date.toISOString()
                                                                ),
                                                            },
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <BaseTable
                                    columns={columns(wayType)}
                                    data={returnFlightResults?.content}
                                    onClickTableRow={onReturnFlightSelect}
                                    isLoading={returnFlightResultsLoading}
                                    disableRow={(row: FlightResultTypes) =>
                                        scheduleForm.adult + scheduleForm.children >
                                        row.numberOfAvailableSeats
                                    }
                                    selectedRow={(row: FlightResultTypes) =>
                                        row.id === selectedTableReturnFlight?.id
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                            padding: "15px",
                            background: "#e7e7e7",
                        }}
                    >
                        <FlightPricesComponent typeOfFlight={"RETURN_WAY"} flight={selectedTableFlight}
                                               returnFlight={selectedTableReturnFlight}/>
                    </div>
                </>
            )}

            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{marginTop: "20px", marginBottom: "20px", padding: "5px"}}
            >
                <Link to="/schedule/step1">
                    <Button
                        style={{background: "#e9e9e9"}}>{t("app.main.schedule.scheduleForm.returnBackBtn", "Return Back")}</Button>
                </Link>

                <LoadingButton
                    btnTitle={t("app.main.schedule.scheduleForm.continueBtn", "Continue")}
                    type="button"
                    isLoading={false}
                    variant="outlined"
                    onClick={() => setFlights()}
                />
            </Grid>
        </div>
    );
};

export default ScheduleResultsPage;
