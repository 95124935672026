import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {TimeZoneServices} from 'app/services';
import {TimeZoneTypes} from "../../../../types/service-types/TimeZoneTypes";

export const fetchAllTimeZones = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await TimeZoneServices.fetchAll();
        dispatch(setCountries(response));
        dispatch(setLoading(false));
    } catch (err) {
        console.log(err);
    }
}

interface TimeZoneListSlice {
    timezone: TimeZoneTypes[];
    loading: boolean;
}

const initialState: TimeZoneListSlice = {
    timezone: [],
    loading: false
}

const stateSlice = createSlice({
    name: 'airport/timezone',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setCountries: (state, action) => {
            state.timezone = action.payload
        }
    }
});

export const {setLoading, setCountries} = stateSlice.actions;

export default stateSlice.reducer;