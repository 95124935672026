import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import {Card, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput,} from '@material-ui/core';

import LandingImage from './landing.jpg';
import Logo from './Bemyticket.png';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {submitLogin} from 'app/auth/store/login.slice';

import useStyles from './login-page.styles';
import LoadingButton from 'app/main/shared-components/loading-button/LoadingButton';
import {AppState} from "app/store";

interface State {
  password: string;
  email: string;
  showPassword: boolean;
}

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {isLoading, success, error} = useSelector((state: AppState) => state.auth.login);

  const [values, setValues] = React.useState<State>({
    email: '',
    password: '',
    showPassword: false
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const loginHandler = () => {
    dispatch(submitLogin({
      email: values.email,
      password: values.password
    }));
  }

  const onEnterHandler = (e: any) => {
    if (e.keyCode === 13) {
      loginHandler();
    }
  }

  return (
      <div className={classes.root} style={{
        background: `url(${LandingImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <Card className={classes.card}>
          <div className={classes.logoWrapper}>
            <img src={Logo} alt="logo" width={'260px'}/>
          </div>
          <FormControl size='small' className={clsx(classes.margin, classes.textField)}
                       variant="outlined">
            <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
            <OutlinedInput
                id="outlined-adornment-username"
                value={values.email}
                type='text'
                onKeyUp={onEnterHandler}
                onChange={handleChange('email')}
                labelWidth={70}
            />
          </FormControl>
          <FormControl size='small' className={clsx(classes.margin, classes.textField)}
                       variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                onKeyUp={onEnterHandler}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size='small'
                    >
                      {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
            />
          </FormControl>
          {error && (
              <p style={{textAlign: 'center', color: 'red', marginBottom: '10px'}}>{error}, please
                try again!</p>
          )}
          <FormControl>
            <div className={classes.btnWrapper}>
              <LoadingButton btnTitle='Login' onClick={loginHandler} isLoading={isLoading}/>
            </div>
          </FormControl>
        </Card>
      </div>
  )
}

export default LoginPage;
