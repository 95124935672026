import {PageConfigTypes} from "app/types/PageTypes";
import ReportPage from "./ReportPage";
import {ROLES} from "app/constants/roles";
import ReportsList from "./reports-list/ReportsList";
import ReportsListGeneric from "./reports-list/ReportsListGeneric";

function ReportPageConfig(data: any): PageConfigTypes {
  const config: PageConfigTypes = {
    baseRoute: {
      path: '/reports',
      exact: true,
      component: ReportPage,
      authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER, ROLES.ROLE_AGENT]
    },
    subRoutes: [
      {
        path: '/reports/reports-list',
        component: ReportsList
      },
      ...data.map((method: any) => ({
        path: '/reports/' + method.name.replace(" ", ""),
        component: () => (<ReportsListGeneric title={method.name} methodId={method.id}/>)
      }))
    ],
    settings: {
      navbar: {
        open: true,
        title: "app.main.sidebar.reports.title1",
        subtitle: "app.main.sidebar.reports.title2",
        items: [
          {
            to: "/reports-list",
            primaryText: "app.main.sidebar.reports.title3",
            icon: "list",
            exact: true,
          },
          ...data.map((method: any) => ({
            to: "/" + method.name.replace(" ", ""),
            primaryText: method.name,
            icon: "attach_money",
            exact: true,
            activeClassName: "",
          }))
        ],
      },
    }
  }
  return config;
}

export default ReportPageConfig;