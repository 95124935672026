import {airlineFormStyles} from "../airline-companies/airline-companies.styles";
import {Field, Form, Formik} from "formik";
import {Button, TextField} from "@material-ui/core";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import {updateSmsTemplate} from "../store/sms-templates/update-sms-template.slice";
import {SmsTemplateBody} from "../../../types/SmsTemplateTypes";
import {createSmsTemplate} from "../store/sms-templates/create-sms-template.slice";
import * as yup from "yup";

const SmsTemplatesForm = () => {
  const classes = airlineFormStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  let {params} = useRouteMatch();

  const validationSchema = yup.object({
    title: yup
    .string()
    .required("Please enter the required field"),
    message: yup
    .string()
    .required("Please enter the required field")
  });

  const smsTemplatesState = useSelector((state: AppState) => state.configurations.smsTemplates.fetchAllSmsTemplates);
  const createSmsTemplates = useSelector((state: AppState) => state.configurations.smsTemplates.createSmsTemplate);
  const updateSmsTemplates = useSelector((state: AppState) => state.configurations.smsTemplates.updateSmsTemplate);

  const [smsTemplateToEdit, setSmsTemplateToEdit] = useState<SmsTemplateBody>({
    title: '',
    message: ''
  });

  const [componentTitle, setComponentTitle] = useState(`${t("app.main.agency.agentsList.registerButton", "Register")}`);

  useEffect(() => {
    const {smsTemplates} = smsTemplatesState;
    if (params) {
      // @ts-ignore
      const foundedSmsTemplate = smsTemplates.find(sms => sms.id === params.id);
      if (foundedSmsTemplate) {
        setComponentTitle(`${t("app.main.configuration.airlineCompanies.create.title1", "Change")}`);
        setSmsTemplateToEdit({
          title: foundedSmsTemplate.title,
          message: foundedSmsTemplate.message
        });
      }
    }
  }, []);

  const onSubmitFormRequest = (data: SmsTemplateBody) => {
    const {smsTemplates} = smsTemplatesState;
    if (params) {
      // @ts-ignore
      const foundedSmsTemplate = smsTemplates.find(sms => sms.id === params.id);
      if (foundedSmsTemplate) {
        const id = foundedSmsTemplate.id;
        dispatch(updateSmsTemplate({
          id: id,
          body: data,
          onSuccess: () => dispatch(toggleQuickPanel())
        }))
      } else {
        dispatch(createSmsTemplate({
          body: data,
          onSuccess: () => dispatch(toggleQuickPanel())
        }))
      }
    }
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: '#0D3B66'}}>{componentTitle} {t("app.main.configuration.smsTemplates.create.title", "sms template")}:</h1>
        </div>
        <Formik
            enableReinitialize
            initialValues={smsTemplateToEdit}
            validationSchema={validationSchema}
            onSubmit={onSubmitFormRequest}
        >
          {({values, errors, touched, setValues}) => (
              <Form>
                <div className={classes.row}>
                  <Field variant='outlined'
                         name='title'
                         label={t("app.main.configuration.smsTemplates.title", "Title")}
                         as={TextField}
                         required
                         error={Boolean(errors.title && touched.title)}
                         helperText={touched.title ? errors.title : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             title: e.target.value,
                           });
                         }}/>
                </div>
                <div className={classes.row}>
                  <Field variant='outlined'
                         name='message'
                         label={t("app.main.configuration.smsTemplates.message", "Message")}
                         multiline
                         rows={5}
                         as={TextField}
                         required
                         error={Boolean(errors.message && touched.message)}
                         helperText={touched.message ? errors.message : ""}
                         onChange={(e: any) => {
                           setValues({
                             ...values,
                             message: e.target.value,
                           });
                         }}/>
                </div>
                <div className={classes.row}>
                  <LoadingButton
                      type='submit'
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={
                          createSmsTemplates.loading || updateSmsTemplates.loading
                      }/>
                  <Button onClick={() => {
                    dispatch(toggleQuickPanel());
                    history.goBack();
                  }}>
                    {t("app.main.flights.flightList.deletePopup.cancelButton", "Cancel")}
                  </Button>
                </div>
              </Form>
          )}
        </Formik>
      </div>
  )
}

export default SmsTemplatesForm;