import React from 'react';
import {TableCellBaseProps} from "@material-ui/core";

interface CollapsibleTableCellProps extends TableCellBaseProps {
    content: any[];
}

const PaymentMethodTableCell = (props: CollapsibleTableCellProps) => {

    return (
        <>
            {props.content.map((c, idx: number) => (<p key={idx + c}>{c}</p>))}
        </>
    );
};

export default PaymentMethodTableCell;