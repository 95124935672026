import {ReservationDateFilters} from "../../../types/service-types/ReservationTypes";
import {createSlice} from "@reduxjs/toolkit";

const initialState: ReservationDateFilters = {
  fromDate: new Date().toString(),
  toDate: new Date().toString()
}

const stateSlice = createSlice({
  name: 'schedules/from-to-filters',
  initialState,
  reducers: {
    setFromDateFilter: (state, action) => {
      state.fromDate = action.payload
    },
    setToDateFilter: (state, action) => {
      state.toDate = action.payload
    }
  }
});

export const {setFromDateFilter, setToDateFilter} = stateSlice.actions;

export default stateSlice.reducer;

