import React, {useEffect, useState} from 'react';
import {AuthService} from 'app/services';
import history from '@history';
import {logoutUser, setUserData} from "./store/user.slice";
import {useDispatch, useSelector} from "react-redux";
import LoginPage from "../main/login/LoginPage";
import {AppState} from "../store";
import {getAccount} from 'app/account/store/get-account.slice';

interface AuthProps {
  children?: React.ReactNode;
}

const Auth = ({children}: AuthProps) => {
  const dispatch = useDispatch();
  const account = useSelector((state: AppState) => state.account.account);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  let stateUser = useSelector((state: AppState) => state.auth.user);
  let user = AuthService.handleAuthentication();

  useEffect(() => {
    if (!user) {
      dispatch(logoutUser());
      setAuthenticated(false);
      setWaitAuthCheck(false);
      history.push('/login');
    } else if (!stateUser) {
      dispatch(setUserData({
        user,
        callback: () => {
          setAuthenticated(true);
          setWaitAuthCheck(false);
        }
      }));
      dispatch(getAccount());
    } else {
      setAuthenticated(true);
      setWaitAuthCheck(false);
    }
  }, [user, stateUser]);

  return waitAuthCheck || account.loading ? <p className='p-10'>Loading...</p> : !authenticated ?
      <LoginPage/> : <>{children}</>
}

export default Auth;
