import {createSlice, Dispatch} from "@reduxjs/toolkit";

const getInitialState = () => ({
    email: '',
    password: '',
    status: true
});

export const setTheEmployeeIdentificationForm = (payload: any) => (dispatch: Dispatch) => {
    dispatch(setEmployeeIdentificationForm(payload));
};

export const resetTheEmployeeIdentificationForm = () => (dispatch: Dispatch) => {
    dispatch(resetEmployeeIdentificationForm());
};


const stateSlice = createSlice({
    name: "employee/employee-identification-form",
    initialState: getInitialState(),
    reducers: {
        setEmployeeIdentificationForm: (state, action) => action.payload,
        fillEmployeeIdentificationFormField: (state, action) => {
            const value = action.payload.value;
            const field = action.payload.field;
            return {
                ...state,
                [field]: value
            }
        },
        resetEmployeeIdentificationForm: () => getInitialState(),
    },
});

export const {
    setEmployeeIdentificationForm,
    fillEmployeeIdentificationFormField,
    resetEmployeeIdentificationForm,
} = stateSlice.actions;

export default stateSlice.reducer;