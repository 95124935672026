import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {FilterAirportsQueryParams, PagedAirportsList} from "app/types/service-types/AirportServiceTypes";
import {AirportService} from 'app/services';
import {errorMsg} from "../../../../utils/MessageUtils";

export const fetchAllAirports = (queryParams?: FilterAirportsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await AirportService.filterAirports(queryParams);
    } else response = await AirportService.fetchAll();
    dispatch(setAirports(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface AirportsListSlice {
  airports: PagedAirportsList;
  loading: boolean;
}

const initialState: AirportsListSlice = {
  airports: {
    content: [],
  },
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/airports',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAirports: (state, action) => {
      state.airports = action.payload
    }
  }
});

export const {setLoading, setAirports} = stateSlice.actions;

export default stateSlice.reducer;