import {PageConfigTypes} from "../../types/PageTypes";
import EmployeeEditListPage from "./EmployeeEditListPage";
import EmployeesEditPage from "./employees-list/EmpoyeesEditPage";
import EmployeeIdentification from "./components/EmployeeIdentification";
import EmployeePermissions from "./components/EmployeePermissions";

const EmployeeEditPageConfig: PageConfigTypes = {
    baseRoute: {
        path: "/employee-edit/:id",
        component: EmployeeEditListPage,
        exact: true
    },
    subRoutes: [
        {
            path: "/employee-edit/:id/edit",
            exact: true,
            component: EmployeesEditPage,
        },
        {
            path: "/employee-edit/:id/llogari",
            exact: true,
            component: EmployeeIdentification,
        },
        {
            path: "/employee-edit/:id/privilege",
            exact: true,
            component: EmployeePermissions,
        },
    ],
    settings: {
        navbar: {
            open: true,
            title: "Employee Information",
            subtitle: "Employee Information",
            items: [
                {
                    to: "/edit",
                    primaryText: "Employee information",
                    activeStyle: {fontWeight: "bold"},
                    icon: "info",
                    exact: true,
                },
                {
                    to: "/llogari",
                    primaryText: "app.main.agency.agentsList.agentEdit.sidebar.title1",
                    activeStyle: {fontWeight: "bold"},
                    icon: "key",
                    activeClassName: "",
                    exact: true,
                },
                {
                    to: "/privilege",
                    primaryText: "Employee privileges",
                    activeStyle: {fontWeight: "bold"},
                    icon: "check",
                    activeClassName: "",
                    exact: true,
                },
            ]

        }
    }
}

export default EmployeeEditPageConfig;