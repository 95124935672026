import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {errorMsg} from "../../../../utils/MessageUtils";
import {resetAgentLogoImage} from "../../../agency/store/agent-logo-image.slice";
import ConfigurationServices from "../../../../services/configuration.services";
import {setLoading} from "./get-upload-logo.slice";

interface ConfigLogoImage {
    logoImage: any
}


const initialState: ConfigLogoImage = {
    logoImage: null,
};

const stateSlice = createSlice({
    name: "configuration/configurations-logo-form",
    initialState: initialState,
    reducers: {
        setUploadImageForm: (state, action) => action.payload,
        resetUploadImageForm: () => initialState,
    },
});

export const {
    setUploadImageForm,
    resetUploadImageForm,
} = stateSlice.actions;

export default stateSlice.reducer;
