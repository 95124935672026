import React, {useEffect, useState} from 'react';
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useRouteMatch} from "react-router-dom";
import SearchPanel from "../search-panel/SearchPanel";
import {fetchAllPaymentReports} from "../store/payment-reports-list.slice";
import {clearfilterReports, filterReports} from "../store/filter-reports.slice";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import ReportsTable from "../../shared-components/reports-table/ReportsTable";
import {useTranslation} from "react-i18next";

const ReportsList = () => {
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {t} = useTranslation();
  // const {allPaymentMethods, loading} = useSelector((state: AppState) => state.configurations.paymentmethods.paymentmethodsList);

  const [modal, setModal] = useState({state: false, deleteId: ''});

  const {allReports, loading} = useSelector(
      (state: AppState) => state.reports.paymentReports);

  const filtersRep = useSelector((state: AppState) => state.reports.filReports);
  useEffect(() => {
    dispatch(fetchAllPaymentReports());
    dispatch(clearfilterReports());
  }, []);

  return (
      <div>
        <PageHeader
            title={t("app.main.report.reportList.title", "Booking fees")}
            desc={t("app.main.report.reportList.desc", "Customer payment report")}
        />
        <SearchPanel/>
        <ReportsTable data={allReports} loading={loading}/>
        <PaginationBar totalPages={allReports?.totalPages}
                       onPageChange={(page: number) =>
                           dispatch(filterReports({...filtersRep, page}))
                       }/>
      </div>
  );
};

export default ReportsList;