import React, {useEffect, useState} from 'react';
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import history from '@history';
import {toggleQuickPanel} from 'app/main/shared-components/quick-panel/store';
import {useDispatch, useSelector} from "react-redux";
import {Column, TableActionsTypes} from "app/types/TableTypes";
import {fetchAllCustomers} from "../store/customers-list.slice";
import {AppState} from "app/store";
import {useRouteMatch} from "react-router-dom";
import TableActionButtons from "app/main/shared-components/base-table/TableActionButtons";
import ModalDialog from "app/main/shared-components/modal-dialog/ModalDialog";
import {deleteCustomer} from "../store/delete-customer.slice";
import CustomersTable from "../../shared-components/customers-table/CustomersTable";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import {filterCustomers} from "../store/filter-customers.slice";
import {useTranslation} from "react-i18next";

const EmployeesList = () => {
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {t} = useTranslation();
  const [modal, setModal] = useState({state: false, deleteId: ''});

  const {allCustomers, loading} = useSelector((state: AppState) => state.customers.customersList);

  const tableActions: TableActionsTypes = {
    // onViewRow: (rowId: number) =>  alert('row: ' + rowId),
    onEditRow: (rowId: number) => {
      dispatch(toggleQuickPanel());
      history.push(`${url}/update/${rowId}`);
    },
    onDeleteRow: (rowId: string) => {
      setModal({state: true, deleteId: rowId});
    }
  }

  const columns: Column[] = [
    {
      Header: 'Emri i plotë',
      accessor: 'fullName'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Numri telefonit',
      accessor: 'phoneNumber'
    },
    {
      Header: 'Adresa e punonjësit',
      accessor: 'address'
    },
    {
      Header: 'Qyteti',
      accessor: 'city'
    },
    {
      Header: 'Shteti',
      accessor: 'country'
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({cell}: any) => (
          <TableActionButtons {...tableActions} rowId={cell.value}/>
      )
    }
  ];

  useEffect(() => {
    dispatch(fetchAllCustomers());
  }, []);

  return (
      <div>
        <PageHeader
            title={t("app.main.customer.customerList.title", "All Clients")}
            desc={t("app.main.customer.customerList.desc", "List of clients")}
            button={t("app.main.customer.customerList.addClient", "Add client")}
            onClickButton={() => {
              history.push('/customer/customers-list/create');
              dispatch(toggleQuickPanel())
            }}
        />
        <ModalDialog
            open={modal.state}
            title='Are you sure you want to delete this?'
            onOk={() => {
              dispatch(deleteCustomer({id: modal.deleteId}));
              setModal({state: false, deleteId: ''});
            }}
            onCancel={() => setModal({state: false, deleteId: ''})}
        />
        <CustomersTable data={allCustomers} loading={loading}/>
        <PaginationBar
            totalPages={allCustomers?.totalPages}
            currentPage={(allCustomers?.number! + 1)}
            onPageChange={(page: number) =>
                dispatch(filterCustomers({...filterCustomers, page}))
            }
        />
      </div>
  );
};

export default EmployeesList;