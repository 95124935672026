import {combineReducers} from '@reduxjs/toolkit';
import login from './login.slice';
// import register from './registerSlice';
import user from './user.slice';

const authReducers = combineReducers({
  user,
  login,
  // register
});

export default authReducers;