import {combineReducers} from '@reduxjs/toolkit';

import allEmployees from './employees-list.slice';
import createEmployee from './create-user.slice';
import updateEmployee from './update-user.slice';
import deleteEmployee from './delete-user.slice';
import allEmpUsers from './user-employees-list.slice';
import employeeIdentificationForm from './employee-identification-form.slice';
import empUpdateAccessibility from './update-accessibility.slice';
import getEmployee from './get-employee.slice';
import deactivateEmployee from './deactivate-employee.slice';
import activateEmployee from './activate-employee.slice';
import employeeScopeForm from './employee-scopes-form.slice';
import updateUserScopes from './update-user-scopes.slice';
import employeeForm from './employee-form.slice';

const reducer = combineReducers({
  allEmployees,
  allEmpUsers,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  employeeIdentificationForm,
  empUpdateAccessibility,
  getEmployee,
  deactivateEmployee,
  activateEmployee,
  employeeScopeForm,
  updateUserScopes,
  employeeForm
});

export default reducer;