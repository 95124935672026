import React, {useEffect, useState} from "react";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {getReservationsFlight} from "../store/flights-list/get-reservations.slice";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import SearchInput from "../../shared-components/search-input/SearchInput";
import DateUtils from "../../../utils/DateUtils";
import CollapsibleTable from "../../shared-components/collapsible-table/CollapsibleTable";
import {useTranslation} from "react-i18next";

const FlightsReservationListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {params}: any = useRouteMatch();
  const {reservationsFlight, loading} = useSelector(
      (state: AppState) => state.flights.flightsList.reservationsFlight
  ) as any;
  const [reservations, seReservations] = useState(JSON.parse(JSON.stringify(reservationsFlight)) || []);
  const open = useSelector((state: AppState) => state.quickPanel);

  const flight = useSelector(
      (state: AppState) => state.flights.flight.flight
  ) as any;
  const {t} = useTranslation();

  // const { allFlights, loading } = useSelector(
  //     (state: AppState) => state.flights.flightsList.allFlights
  // );
  // const filters = useSelector(
  //     (state: AppState) => state.flights.flightsList.filterFlights
  // );
  //
  // useEffect(() => {
  //     dispatch(clearfilterFlights());
  //     dispatch(fetchAllFlights());
  //     dispatch(fetchAllCurrencies());
  // }, []);

  useEffect(() => {
    dispatch(getReservationsFlight({
      id: params.id, onSuccess: (res: any) => {
        seReservations(res)
      }
    }));
  }, []);

  useEffect(() => {
    dispatch(getReservationsFlight({
      id: params.id, onSuccess: (res: any) => {
        seReservations(res)
      }
    }));
  }, [open]);

  useEffect(() => {
    if (loading) {
      dispatch(getReservationsFlight({
        id: params.id, onSuccess: (res: any) => {
          seReservations(res)
        }
      }));
    }
  }, [loading]);

  const title = `${t("app.main.sidebar.schedules.desc", "List of reservations")} ( 
  ${flight?.departureAirportCode}->${flight?.arrivalAirportCode} -
  ${DateUtils.toHumanDate(DateUtils.convertDate(flight?.dateOfDeparture))} - ${
      flight?.timeOfDeparture
  })`;

  const searchReservation = (value: string) => {
    if (value) {
      const reservations: any = reservationsFlight.filter(
          (reservation: any) => {
            return reservation.supervisor
            .toLowerCase()
            .match(value.toLowerCase());
          }
      );
      seReservations(reservations);
    } else {
      seReservations(reservationsFlight);
    }
  };

  return (
      <div>
        <PageHeader
            title={title}
            desc={"Rezervimet.."}
            // button={"Regjistro"}
            // onClickButton={() => {
            //     history.push("/flights/flights-list/create");
            //     dispatch(toggleQuickPanel());
            // }}
        />
        <div style={{marginTop: "10px", padding: "10px", marginBottom: "10px"}}>
          <SearchInput onChange={searchReservation}/>
        </div>
        {/*<PassSearchPanel />*/}
        {/*<MultilineTable data={allFlights} loading={loading} />*/}
        {/*<PaginationBar*/}
        {/*    totalPages={allFlights?.totalPages}*/}
        {/*    onPageChange={(page: number) =>*/}
        {/*        dispatch(filterFlights({ ...filters, page }))*/}
        {/*    }*/}
        {/*/>*/}

        {/*Previous table*/}
        {/*<CollFlightTable*/}
        {/*    data={reservations}*/}
        {/*    loading={loading}*/}
        {/*    key={reservations.length}*/}
        {/*/>*/}

        <CollapsibleTable data={reservations} loading={loading}/>
      </div>
  );
};

export default FlightsReservationListPage;
