import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {FilterReservationsQueryParams,} from "app/types/service-types/ReservationTypes";
import {fetchAllSchedules} from "./schedules-list.slice";
import DateUtils from "app/utils/DateUtils";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] === "ALL") {
      } else newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const filterSchedules =
    (queryParams: FilterReservationsQueryParams) =>
        async (dispatch: Dispatch) => {
          dispatch(setFilters(queryParams));
          // @ts-ignore
          dispatch(fetchAllSchedules({...removeFalsyAttributes(queryParams)}));
        };

export const clearfilterSchedules = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterReservationsQueryParams = {
  q: "",
  surname: "",
  fromDate: "",
  toDate: "",
  status: "ALL",
  isPaid: "",
  isRefund: "",
};

const stateSlice = createSlice({
  name: "schedules/schedules-filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterReservationsQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;
