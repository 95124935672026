import React, {useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTableRowStyles} from "./agents-table.styles";
import AgentsTableCell from "./AgentsTableCell";
import {TableActionsTypes} from "../../../types/TableTypes";
import {useDispatch} from "react-redux"
import history from '@history';
import ModalDialog from 'app/main/shared-components/modal-dialog/ModalDialog'
import {useTranslation} from "react-i18next";
import AgentTableActionButton from "./AgentTableActionButton";
import {Balance} from 'app/types/service-types/AgentTypes';
import {deactivateAgent} from "../../agency/store/deactivate-agent.slice";
import {activateAgent} from "../../agency/store/activate-agent.slice";

const AgentsTableRow = (props: any) => {
    const classes = useTableRowStyles();
    const dispatch = useDispatch();
    const {row} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const {t} = useTranslation();
    const status = row.status ? "Active" : "Deactive";

    const tableActions: TableActionsTypes = {
        onEditRow: (rowId: number) => {
            history.push({
                pathname: `/agentedit/${rowId}/edit`,
                state: {id: rowId},
            });
        },
        [row.status ? 'onDeactivateRow' : 'onActivateRow']: (rowId: string) => {
            setModalOpen(true);
        },
    }

    return (
        <React.Fragment>
            {row.status ? (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle", "Deactivate")} `}
                    title={`${t("app.main.customer.agentList.deactivatePopup.primaryText", "Are you sure you want to deactivate agent")} '${row.fullName}'?`}
                    onOk={() => {
                        dispatch(deactivateAgent({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            ) : (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle1", "Activate")} `}
                    title={`${t("app.main.customer.agentList.activatePopup.primaryText1", "Are you sure you want to activate agent")} '${row.fullName}'?`}
                    onOk={() => {
                        dispatch(activateAgent({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            )}
            <TableRow className={classes.root}>
                <TableCell style={{textAlign: 'center'}}>
                    <AgentsTableCell {...props} content={[
                        `${row.fullName}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <AgentsTableCell {...props} content={[
                        `${row.email}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <AgentsTableCell {...props} content={[
                        row.balances?.map((bilanci: Balance) => {
                            return <p>
                                {bilanci.balance} {bilanci.currencyResponse.symbol}
                            </p>
                        })
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <AgentsTableCell {...props} content={[
                        `${row.phoneNumber}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <AgentsTableCell {...props} content={[
                        `${row.address}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center', color: row.status ? "green" : "red", fontWeight: "bold"}}>
                    <AgentsTableCell {...props} content={[
                        `${status}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <AgentTableActionButton {...tableActions} rowId={row.id}/>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default AgentsTableRow;