import {createSlice, Dispatch} from "@reduxjs/toolkit";

const getInitialState = () => ({
  email: '',
  password: '',
  status: true
});

export const setTheAgentIdentificationForm = (payload: any) => (dispatch: Dispatch) => {
  dispatch(setAgentIdentificationForm(payload));
};

export const resetTheAgentIdentificationForm = () => (dispatch: Dispatch) => {
  dispatch(resetAgentIdentificationForm());
};


const stateSlice = createSlice({
  name: "agents/agent-identification-form",
  initialState: getInitialState(),
  reducers: {
    setAgentIdentificationForm: (state, action) => action.payload,
    fillAgentIdentificationFormField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      return {
        ...state,
        [field]: value
      }
    },
    resetAgentIdentificationForm: () => getInitialState(),
  },
});

export const {
  setAgentIdentificationForm,
  fillAgentIdentificationFormField,
  resetAgentIdentificationForm,
} = stateSlice.actions;

export default stateSlice.reducer;