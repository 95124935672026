import React, {useEffect, useState} from "react";
import {Button, MenuItem, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import * as yup from "yup";
import useStyles from "./airport.styles";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {createAirport} from "../store/airport/create-airport.slice";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AirportRequestBody} from "app/types/service-types/AirportServiceTypes";
import LoadingForm from "app/main/shared-components/loading-form/LoadingForm";
import {updateAirport, updateAirportImg,} from "../store/airport/update-airport.slice";
import {CountryTypes} from "../../../types/service-types/CountryTypes";
import {fetchAllCountries} from "../../../store/App/country.slice";
import {fetchAllTimeZones} from "../store/airport/timezone-list.slice";
import {AirportService} from "app/services";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {useTranslation} from "react-i18next";
import {TimeZoneTypes} from "../../../types/service-types/TimeZoneTypes";

const validationSchema = yup.object({
  name: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Please enter the required field"),
  location: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  number: yup.string().matches(/^\d+$/, "Only numbers are allowed for this field "),
  code: yup.string().required("Please enter the required field"),
  currency: yup.string().required("Please enter the required field"),
  countryId: yup.string().required("Please enter the required field"),
});

const AirportForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let {params} = useRouteMatch();
  const {t} = useTranslation();
  const countriesState = useSelector(
      (state: AppState) => state.appReducers.country
  );
  const airports = useSelector(
      (state: AppState) => state.configurations.airport.airportsList.airports.content
  );
  const createAirportState = useSelector(
      (state: AppState) => state.configurations.airport.createAirport
  );
  const updateAirportState = useSelector(
      (state: AppState) => state.configurations.airport.updateAirport
  );

  const timezoneState = useSelector(
      (state: AppState) => state.configurations.airport.timezoneList
  );


  const [loading, setLoading] = useState(true);

  const [img, setImg] = useState(null);
  const [showImg, setShowImg] = useState("");
  const [componentTitle, setComponentTitle] = useState(`${t("app.main.agency.agentsList.registerButton", "Register")}`);

  const uploadImg = (e: any) => {
    const file = e.target.files[0];
    setImg(file);
    setShowImg(URL.createObjectURL(file));
  };

  const [singleAirport, setSingleAirport] = useState<AirportRequestBody>({
    currency: "eur",
    code: "",
    countryId: null,
    name: "",
    location: "",
    additionalInformation: "",
    number: "",
    timeZoneId: ""
  });

  useEffect(() => {
    dispatch(fetchAllCountries());
    dispatch(fetchAllTimeZones());
    if (params) {
      // @ts-ignore
      const foundedAP = airports.find((ap) => ap.id === params.id);
      setShowImg(foundedAP?.backdropImageUrl);
      if (foundedAP) {
        setComponentTitle(`${t( "app.main.configuration.airlineCompanies.create.title1", "Change")}`);
        setSingleAirport({
          location: foundedAP.location,
          additionalInformation: foundedAP.additionalInformation,
          code: foundedAP.code,
          countryId: foundedAP.country.id,
          currency: foundedAP.currency,
          name: foundedAP.name,
          number: foundedAP.number,
          timeZoneId:foundedAP.timeZoneId,
        });
      }
      setLoading(false);
    } else setLoading(false);
  }, []);

  if (loading || countriesState.loading) return <LoadingForm/>;

  const onSubmitFormRequest = (data: AirportRequestBody) => {
    if (params) {
      // @ts-ignore
      const foundedAP = airports.find((ap) => ap.id === params.id);
      // update
      if (foundedAP) {
        const id = foundedAP.id ? foundedAP.id : "";

        if (img) {
          dispatch(
              updateAirportImg({
                id,
                backdropImage: img,
                onSuccess: () => dispatch(updateAirport({
                  id,
                  body: data,
                  onSuccess: () => dispatch(toggleQuickPanel()),
                })),
              })
          );
        } else {
          dispatch(
              updateAirport({
                id,
                body: data,
                onSuccess: () => dispatch(toggleQuickPanel()),
              })
          );
        }
      } else {
        if (img) {
          AirportService.createAirport(data).then((res) => {
            dispatch(
                updateAirportImg({
                  id: res.data.id,
                  backdropImage: img,
                  onSuccess: () => {
                    dispatch(toggleQuickPanel());
                    dispatch(successMsg("Aeroporti u shtua me sukses!"));
                  },
                })
            );
          }).catch((error) => dispatch(errorMsg(error.data.message)));
        } else {
          dispatch(createAirport({
            body: data
          }));
        }
      }
    }
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: "#0D3B66"}}>{componentTitle}:</h1>
        </div>
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={singleAirport}
            onSubmit={onSubmitFormRequest}
        >
          {({values, setValues, errors, touched}) => (
              <Form>
                <div
                    className={classes.row}
                    style={{
                      marginTop: "15px",
                      padding: "20px",
                      border: "1px solid #D2D2D2",
                      borderRadius: "15px",
                    }}
                >
                  <img src={showImg} width="20%"/>
                  <TextField
                      id="outlined-basic"
                      label=""
                      type="file"
                      onChange={uploadImg}
                      variant="outlined"
                  />
                </div>
                <div className={classes.row}>
                  <Field
                      variant="outlined"
                      value={values.name}
                      name="name"
                      label={t("app.main.configuration.airlineCompanies.create.input1", "Name")}
                      as={TextField}
                      required
                      error={Boolean(errors.name && touched.name)}
                      helperText={touched.name ? errors.name : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          name: e.target.value,
                        });
                      }}/>
                  <Field
                      variant="outlined"
                      value={values.location}
                      name="location"
                      label={t("app.main.configuration.airlineCompanies.table.tableRow2", "Location")}
                      as={TextField}
                      error={Boolean(errors.location && touched.location)}
                      helperText={touched.location ? errors.location : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          location: e.target.value,
                        });
                      }}/>
                </div>

                <div className={classes.row}>
                  <Field
                      variant="outlined"
                      value={values.code}
                      name="code"
                      label={t("app.main.configuration.airlineCompanies.table.tableRow3", "Code")}
                      as={TextField}
                      required
                      error={Boolean(errors.code && touched.code)}
                      helperText={touched.code ? errors.code : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          code: e.target.value.toUpperCase(),
                        });
                      }}/>

                  <Field
                      variant="outlined"
                      value={values.timeZoneId}
                      name="timezone"
                      label={t("app.main.configuration.airlineCompanies.table.tableRow7", "Time Zone")}
                      as={TextField}
                      select
                      required
                      error={Boolean(errors.timeZoneId && touched.timeZoneId)}
                      helperText={touched.timeZoneId ? errors.timeZoneId : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          timeZoneId: e.target.value
                        });
                      }}
                  >
                    {timezoneState.timezone.map((tz: TimeZoneTypes) => (
                        <MenuItem key={tz.id} id={tz.id} value={tz.id}>
                          {tz.id} ({tz.displayName})
                        </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.row}>
                  <Field
                      variant="outlined"
                      value={values.number}
                      name="number"
                      label={t("app.main.configuration.airlineCompanies.table.tableRow5", "Number")}
                      as={TextField}
                      error={Boolean(errors.number && touched.number)}
                      helperText={touched.number ? errors.number : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          number: e.target.value,
                        });
                      }}/>
                  <Field
                      variant="outlined"
                      value={values.currency}
                      name="currency"
                      label={t("app.main.report.search.currency", "Currency")}
                      select
                      as={TextField}
                      required
                      error={Boolean(errors.currency && touched.currency)}
                      helperText={touched.currency ? errors.currency : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          currency: e.target.value
                        });
                      }}>
                    <MenuItem value="eur">EUR</MenuItem>
                    <MenuItem value="usd">USD</MenuItem>
                    <MenuItem value="chf">CHF</MenuItem>
                  </Field>
                </div>

                <div className={classes.row}>
                  <Field
                      variant="outlined"
                      value={values.countryId}
                      name="countryId"
                      label={t("app.main.customer.customerList.country", "Country")}
                      select
                      as={TextField}
                      required
                  >
                    {countriesState.countries.map(
                        (c: CountryTypes, index: number) => (
                            <MenuItem key={c.id + index} id={c.id} value={c.id}>
                              {c.name} ({c.code})
                            </MenuItem>
                        )
                    )}
                  </Field>
                  <Field
                      variant="outlined"
                      name="additionalInformation"
                      label={t("app.main.configuration.airlineCompanies.table.tableRow4", "Other informations")}
                      as={TextField}
                  />
                </div>
                <div className={classes.row}>
                  <LoadingButton
                      type="submit"
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={
                        createAirportState.loading || updateAirportState.loading
                      }
                  />
                  <Button
                      onClick={(e) => {
                        // e.stopPropagation();
                        dispatch(toggleQuickPanel());
                        history.replace("/configurations/airports-list");
                      }}
                  >
                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                  </Button>
                </div>
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default AirportForm;
