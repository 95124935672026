import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import useStyles from './modal-dialog.styles'
import {Button, Icon, IconButton} from "@material-ui/core";
import {useTranslation} from "react-i18next";

interface IProps {
  mainTitle?: string;
  title: string;
  onOk: Function;
  onCancel: Function;
  open: boolean;
}

const ModalDialog = (props: IProps) => {
  const classes = useStyles();
  const {open, mainTitle, title, onOk, onCancel} = props;
  const {t} = useTranslation();

  return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.mainTitle}>
              <Icon>menu</Icon>
              <h3>{mainTitle ? mainTitle : `${t("app.main.schedules.module.title.9", "Delete")}`}</h3>
              <IconButton onClick={() => onCancel()}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div style={{borderBottom: '1px solid #EFEFEF'}}>
              <div className={classes.title}>
                <h3>*** {t("app.main.flights.flightList.deletePopup.secondaryTitle", "Warning")} ***</h3>
                <h4>{title}</h4>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button onClick={() => onCancel()}>{t("app.main.flights.flightList.deletePopup.cancelButton", "Cancel")}</Button>
              <Button className='on-confirm' onClick={() => onOk()}>
                <Icon>delete_outline</Icon>
                {t("app.main.flights.flightList.deletePopup.yesButton", "Yes")}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
  );
}

export default ModalDialog;