import React, {useEffect} from 'react'
import {Button, Grid} from '@material-ui/core';
import {Form, Formik} from 'formik';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {createAgentsStyles} from "./create-agents.styles";
import {AppState} from 'app/store';
import {resetAgentForm,} from '../store/agent-form.slice';
import AgentInformation from "../components/AgentInformation";
import InvoiceInfo from "../components/InvoiceInfo";
import ReservationInfo from "../components/ReservationInfo";
import DebtInfo from "../components/DebtInfo";
import DiscountInfo from "../components/DiscountInfo";
import ScopesInfo from "../components/ScopesInfo";
import ProvisionInfo from "../components/ProvisionInfo";
import Credits from "../components/Credits";
import {useTranslation} from "react-i18next";
import {resetAgentLogoImage, updateAgentImage} from "../store/agent-logo-image.slice";
import {AgentService} from "../../../services";
import AppUtils from "../../../utils/AppUtils";
import {fetchAllAgents} from "../store/agents-list.slice";
import {setError} from "../../customer/store/create-costumer.slice";
import {errorMsg, successMsg} from "../../../utils/MessageUtils";
import {setSuccess} from "../store/update-agent.slice";

const CreateAgent = () => {
    const classes = createAgentsStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const agentForm = useSelector((state: AppState) => state.agents.agentForm);
    const createAgentLoading = useSelector((state: AppState) => state.agents.createAgent.loading);
    const agentLogoImage = useSelector((state: AppState) => state.agents.agentLogoImage);

    useEffect(() => {
        dispatch(resetAgentForm());
        dispatch(resetAgentLogoImage());
    }, [])

    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader} style={{marginBottom: '35px'}}>
                <h1 style={{color: '#0D3B66'}}>{t("app.main.agency.create.title", "Register agent")}</h1>
            </div>

            <Formik
                initialValues={agentForm}
                onSubmit={() => {
                    const formWithoutId = {
                        ...agentForm,
                        balanceRequests: agentForm.balanceRequests.map(({id, ...items}) => items),  // We are removing the id from balanceRequests
                        provisionForms: agentForm.provisionForms.map(({id, ...items}) => items),  // We are removing the id from balanceRequests
                        discounts: agentForm.discounts.map(({id, ...items}) => items),  // We are removing the id from balanceRequests
                    }

                    if (agentLogoImage.logoImage) {
                        AppUtils.dataUrlToFile(agentLogoImage.logoImage, 'logoImage').then((res: File) => {
                            const logoImage = res;
                            AgentService.createAgent({...formWithoutId}).then((res: any) => {
                                dispatch(updateAgentImage(res.data.id, logoImage));
                                dispatch(fetchAllAgents());
                                dispatch(toggleQuickPanel());
                                dispatch(setSuccess(true));
                                dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Agent added successfully!' : 'Agenti u shtua me sukses!'));
                            }, error => {
                                dispatch(errorMsg(error.data.message));
                                dispatch(setError(error.data.message));
                            })
                        });
                    }
                }}
            >
                {() => (
                    <Form>
                        <AgentInformation/>

                        <InvoiceInfo/>

                        <ReservationInfo/>

                        <Credits/>

                        <ProvisionInfo/>

                        <DiscountInfo/>

                        <DebtInfo/>

                        <ScopesInfo/>


                        <Grid container justify='flex-end' alignItems='center' style={{marginTop: '20px'}}>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    btnTitle={t("app.main.agency.agentsList.registerButton", "Register")}
                                    size='medium'
                                    isLoading={createAgentLoading}
                                />

                                <Button
                                    onClick={(e) => {
                                        dispatch(toggleQuickPanel())
                                        dispatch(resetAgentForm());
                                        history.push('/agents-agencies/list')
                                    }}>{t("app.main.schedules.splitPassenger.closeButton", "Close")}</Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateAgent;
