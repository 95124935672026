import ConfigurationsPage from "./ConfigurationsPage";
import {PageConfigTypes} from "app/types/PageTypes";
import AirportsListPage from "./airports-list/AirportsListPage";
import AirlineCompaniesPage from "./airline-companies/AirlineCompaniesPage";
import SmssmtpPage from "./sms-smtp/SmsSmtp";
import PaymentMethodsPage from "./payment-methods/PaymentMethods";
import {ROLES} from "app/constants/roles";
import CountriesListPage from "./countries/CountriesListPage";
import ClientWebContent from "./client-web-content/ClientWebContent";
import SmsTemplates from "./sms-templates/SmsTemplates";
import UploadLogoPage from "./upload-logo/UploadLogoPage";

const ConfigurationsPageConfig: PageConfigTypes = {
  baseRoute: {
    path: "/configurations",
    exact: true,
    component: ConfigurationsPage,
    authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER]
  },
  subRoutes: [
    {
      path: "/configurations/airports-list",
      component: AirportsListPage,
    },
    {
      path: "/configurations/airline-companies",
      component: AirlineCompaniesPage,
    },
    {
      path: "/configurations/sms-smtp",
      component: SmssmtpPage,
    },
    {
      path: "/configurations/payment-methods",
      component: PaymentMethodsPage,
    },
    {
      path: "/configurations/countries",
      component: CountriesListPage,
    },
    {
      path: "/configurations/client-web-content",
      component: ClientWebContent,
    },
    {
      path: "/configurations/sms-templates",
      component: SmsTemplates,
    },
    {
      path: "/configurations/upload-logo",
      component: UploadLogoPage,
    },
    {
      path: "/configurations/sub-path",
      component: () => (
          <div style={{background: "#e92"}}>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
            <p>Configurations Subpath</p>
          </div>
      ),
    },
  ],
  settings: {
    navbar: {
      open: true,
      title: "app.main.configuration.sidebar.title5",
      subtitle: "app.main.configuration.sidebar.title6",
      items: [
        {
          to: "/airports-list",
          primaryText: "app.main.configuration.airlineCompanies.title",
          icon: "list",
          exact: true,
        },
        {
          to: "/airline-companies",
          primaryText: "app.main.configuration.airlineCompanies.title1",
          icon: "business",
          exact: true,
        },
        {
          to: "/sms-smtp",
          primaryText: "app.main.configuration.sidebar.title3",
          icon: "sms",
          exact: true,
        },
        {
          to: "/payment-methods",
          primaryText: "app.main.schedules.payments.paymentMethods",
          icon: "payments",
          exact: true,
        },
        {
          to: "/countries",
          primaryText: "app.main.configuration.sidebar.title4",
          icon: "flag",
          exact: true,
        },
        {
          to: "/client-web-content",
          primaryText: "app.configuration.webEdit",
          icon: "settings",
          exact: true,
        },
        {
          to: "/sms-templates",
          primaryText: "app.main.configuration.sidebar.title7",
          icon: "sms",
          exact: true,
        },
        {
          to: "/upload-logo",
          primaryText: "app.main.configurations.upload-logo.title",
          icon: "photo",
          exact: true,
        }
      ],
    },
  },
};

export default ConfigurationsPageConfig;
