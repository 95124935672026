import {PageConfigTypes} from "../../types/PageTypes";
import AgencyEditListPage from "./AgencyEditListPage";
import AgentsEditPage from "./agents-list/AgentsEditPage";
import AgentIdentification from "./components/AgentIdentification";
import AgentPermissions from "./components/AgentPermissions";
import AgentPaymentGeneric from "./components/AgentPaymentGeneric";
import AgentProvisionGeneric from "./components/AgentProvisionGeneric";

function AgencyEditPageConfig(data: any): PageConfigTypes  {
  const config = {
    baseRoute: {
      path: "/agentedit/:id",
      component: AgencyEditListPage,
      exact: true
    },
    subRoutes: [
      {
        path: "/agentedit/:id/edit",
        exact: true,
        component: AgentsEditPage,
      },
      {
        path: "/agentedit/:id/llogari",
        exact: true,
        component: AgentIdentification,
      },
      {
        path: "/agentedit/:id/privilege",
        exact: true,
        component: AgentPermissions,
      },
      ...data.map((payment: any) => ({
        path: '/agentedit/:id/payments/:currencyId',
        component: () => <AgentPaymentGeneric key={payment.currencyResponse.id} currencyResponse={payment.currencyResponse} />
      })),
      ...data.map((provision: any) => ({
        path: '/agentedit/:id/provisions/:currencyId',
        component: () => <AgentProvisionGeneric key={provision.currencyResponse.id} currencyResponse={provision.currencyResponse} />
      })),
      {
        path: "/agentedit/sub-path",
        component: () => (
            <div style={{background: "#e92"}}>
              <p>Flights Subpath</p>
              <p>Flights Subpath</p>
              <p>Flights Subpath</p>
              <p>Flights Subpath</p>
              <p>Flights Subpath</p>
            </div>
        ),
      },
    ],
    settings: {
      navbar: {
        open: true,
        title: "app.main.agency.agentsList.agentEdit.title",
        subtitle: "app.main.agency.agentsList.agentEdit.title",
        items: [
          {
            to: "/edit",
            primaryText: "app.main.agency.agentsList.agentEdit.title",
            activeStyle: {fontWeight: "bold"},
            icon: "info",
            exact: true,
          },
          {
            to: "/llogari",
            primaryText: "app.main.agency.agentsList.agentEdit.sidebar.title1",
            activeStyle: {fontWeight: "bold"},
            icon: "key",
            activeClassName: "",
            exact: true,
          },
          {
            to: "/privilege",
            primaryText: "app.main.agency.agentsList.agentEdit.sidebar.title2",
            activeStyle: {fontWeight: "bold"},
            icon: "check",
            activeClassName: "",
            exact: true,
          },
          ...data.map((payment: any) => ({
            to: "/payments/" + payment.currencyResponse.id,
            primaryText: "app.main.agency.config.payment.title",
            secondaryText: payment.currencyResponse.isoCode,
            icon: "attach_money",
            exact: true,
            activeClassName: "",
          })),
          ...data.map((provision: any) => ({
            to: "/provisions/" + provision.currencyResponse.id,
            primaryText: "app.main.agency.config.provision.title",
            secondaryText: provision.currencyResponse.isoCode,
            icon: "payment",
            exact: true,
            activeClassName: "",
          })),
        ]

      }
    }
  }
  return config;
}

export default AgencyEditPageConfig;