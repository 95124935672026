import {Grid, Icon, IconButton, MenuItem, TextField} from "@material-ui/core";
import {Field} from "formik";
import {
    addNewDiscount,
    fillAgentFormField,
    fillDiscountField,
    removeDiscount,
    resetDiscount,
    setTheAgentForm
} from "../store/agent-form.slice";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useTranslation} from "react-i18next";
import {fetchAllCurrencies} from "../../flights/store/currencies-list.slice";

const DiscountInfo = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const agentForm = useSelector((state: AppState) => state.agents.agentForm);
    const {currencies} = useSelector((state: AppState) => state.flights.currenciesList);

    const getDefaultCurrency = () => {
        const defaultCurrency = currencies?.find((item) => item.isoCode === 'EUR')
        return defaultCurrency ? defaultCurrency.id : ''
    }

    useEffect(() => {
        // We are setting default currency
        if (agentForm.discounts[0].currencyId === '') {
            dispatch(setTheAgentForm({
                ...agentForm,
                discounts: [{
                    ...agentForm.discounts[0],
                    currencyId: getDefaultCurrency()
                }]
            }))
        }
    }, [currencies])

    useEffect(() => {
        dispatch(fetchAllCurrencies());
    }, []);

    const handleChange = (e: any, id?: string) => {
        const payload = {
            value: e.target.name === 'currencyId' ? e.target.value : +e.target.value,
            field: e.target.name,
            id
        }
        dispatch(fillDiscountField(payload))
    }

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Field
                        style={{width: '100%'}}
                        label={t("app.main.agency.create.discountInfo.label1", "Allow discount on ticket (Maximum)")}
                        onChange={(e: any) => {
                            dispatch(fillAgentFormField({field: 'discountType', value: e.target.value}))
                            dispatch(resetDiscount(getDefaultCurrency()));
                        }}
                        value={agentForm.discountType}
                        variant='outlined' size='small'
                        name="discountType"
                        select
                        as={TextField}>
                        <MenuItem
                            value='NO_DISCOUNT'>{t("app.main.agency.create.discountInfo.label2", "Do not allow discount")}</MenuItem>
                        <MenuItem
                            value='ALLOW_DISCOUNT'>{t("app.main.agency.create.discountInfo.label3", "Allow discount")}</MenuItem>
                        <MenuItem
                            value='AUTOMATIC'>{t("app.main.agency.create.discountInfo.label4", "Make automatic discount")}</MenuItem>
                    </Field>
                </Grid>
            </Grid>

            {agentForm.discountType !== 'NO_DISCOUNT' && agentForm.discounts.map(discount => {
                return (
                    <Grid container spacing={4}>
                        {agentForm.discountType === 'ALLOW_DISCOUNT' && <Grid item xs={3}>
                            <Field
                                style={{width: '100%', marginRight: '20px'}}
                                label={t("app.main.agency.create.discountInfo.label5", "Maximum discount")}
                                onChange={(e: any) => {
                                    handleChange(e, discount.id)
                                }}
                                variant='outlined'
                                size='small'
                                name='maxDiscount'
                                value={discount.maxDiscount}
                                type="number"
                                as={TextField}
                            />
                        </Grid>}

                        {agentForm.discountType === 'AUTOMATIC' && <Grid item xs={3}>
                            <Field
                                style={{width: '100%', marginRight: '20px'}}
                                label={t("app.main.agency.create.discountInfo.label6", "Automatic discount")}
                                onChange={(e: any) => {
                                    handleChange(e, discount.id)
                                }}
                                variant='outlined'
                                size='small'
                                name='automaticDiscount'
                                value={discount.automaticDiscount}
                                type="number"
                                as={TextField}
                            />
                        </Grid>}

                        <Grid item xs={3}>
                            <Field
                                style={{width: '100%', marginRight: '20px'}}
                                label={t("app.main.agency.create.discountInfo.label7", "Discount that have a price greater than")}
                                onChange={(e: any) => {
                                    handleChange(e, discount.id)
                                }}
                                variant='outlined'
                                size='small'
                                name='minimumTicketPrice'
                                value={discount.minimumTicketPrice}
                                type="number"
                                as={TextField}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{display: 'flex'}}>
                                <Field
                                    onChange={(e: any) => handleChange(e, discount.id)}
                                    variant='outlined'
                                    value={discount.currencyId}
                                    name='currencyId'
                                    label={t("app.main.report.search.currency", "Currency")}
                                    select
                                    style={{flex: 1, minWidth: 120}}
                                    size='small'
                                    as={TextField}
                                >
                                    {currencies && currencies.filter(cr => ['EUR', 'CHF'].includes(cr.isoCode)).map((cr: any, idx) => (
                                        <MenuItem key={idx} value={cr.id}>{cr.name} - {cr.isoCode}</MenuItem>
                                    ))}
                                </Field>
                                <IconButton
                                    style={{margin: '0 10px'}}
                                    onClick={() => {
                                        dispatch(addNewDiscount(getDefaultCurrency()));
                                    }} size='small'>
                                    <Icon>add</Icon>
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        dispatch(removeDiscount(discount.id));
                                    }}
                                    size='small'
                                    disabled={agentForm.discounts.length === 1}
                                >
                                    <Icon color='error'>delete</Icon>
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>)
            })}
        </div>
    )
}

export default DiscountInfo