import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {Airport} from "app/types/service-types/AirportServiceTypes";
import {AirportService} from 'app/services';

export const fetchAllAirports = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  const response = await AirportService.fetchAll();
  dispatch(setAirports(response));
  dispatch(setLoading(false));
}

interface AirportsListSlice {
  airports: Airport[];
  loading: boolean;
}

const initialState: AirportsListSlice = {
  airports: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'app/airports',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAirports: (state, action) => {
      state.airports = action.payload
    }
  }
});

export const {setLoading, setAirports} = stateSlice.actions;

export default stateSlice.reducer;