import React, {useEffect} from "react";
import {Button, Grid, InputAdornment, MenuItem, TextField,} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {addPassengerStyles} from "./add-passenger.styles";
import {addPassenger} from "../store/add-passenger.slice";
import {AppState} from "app/store";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateUtils from "../../../utils/DateUtils";
import {Column} from "../../../types/TableTypes";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {
  PricesByAgeCategoryTypes,
} from "../../../types/service-types/SectorPricesTypes";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import {fetchAllAirports} from "../../configurations/store/airport/airports-list.slice";
import {FlightResultTypes} from "../../../types/service-types/ScheduleTypes";
import {
  fetchAllFlightResults,
  setFlightResults,
  setSelectedFlight,
} from "../../schedule/store/flight-results.slice";
import {warningMsg} from "app/utils/MessageUtils";
import {useTranslation} from "react-i18next";
import {ROLES} from "../../../constants/roles";
import useAuthority from "../../../hooks/useAuthority";

const AddPassenger = () => {
  const classes = addPassengerStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const {t} = useTranslation();
  const {hasUserAnyAuthority} = useAuthority();

  const adminColumn: Column = {
    Header: `${t("app.main.schedules.addPassenger.freeSeats", "Free seats")}`,
    accessor: "numberOfAvailableSeats",
    Cell: ({ cell }: any) => cell.value,
  };

  const columns: Column[] = [
    {
      Header: `${t("app.main.schedules.addPassenger.departureFrom", "Nisja prej")}`,
      accessor: "departureAirport",
      Cell: ({cell}: any) => {
        const {departureAirport} = cell.row.original;
        return `${cell.value.name} (${departureAirport.code})`;
      },
    },
    {
      Header: `${t("app.main.schedules.addPassenger.arrivalTo", "Zbritja ne")}`,
      accessor: "arrivalAirport",
      Cell: ({cell}: any) =>
          `${cell.value.name} (${cell.row.original.arrivalAirport.code})`,
    },
    {
      Header: `${t("app.main.schedules.addPassenger.dateOfDeparture", "Data e nisjes")}`,
      accessor: "dateOfDeparture",
      Cell: ({cell}: any) => {
        const {timeOfDeparture} = cell.row.original;
        return cell.value + " " + timeOfDeparture;
      },
    },
    {
      Header: `${t("app.main.schedules.addPassenger.priceWithTax", "Çmimi me tax")}`,
      accessor: "lowestPriceWithTax",
      Cell: ({cell}: any) => cell.value,
    }
  ];

  if (hasUserAnyAuthority([ROLES.ROLE_ADMIN])) {
    columns.push(adminColumn);
  }

  const reservationId = params?.id;
  const today = new Date();

  const addPassengerLoading = useSelector(
      (state: AppState) => state.schedules.addPassenger.loading
  );
  const airports = useSelector(
      (state: AppState) =>
          state.configurations.airport.airportsList.airports.content
  );
  const {flightResults, loading, selectedFlight} = useSelector(
      (state: AppState) => state.schedule.flightResults
  );

  const getPriceByAgeCategory = (
      ageCategory: string,
      prices: PricesByAgeCategoryTypes
  ): number => {
    switch (ageCategory) {
      case "ADULT":
        return prices?.forAdults;
      case "CHILD":
        return prices?.forChildren;
      case "INF":
        return prices?.forInfants;
      default:
        return 0;
    }
  };

  const getSymbolCurrencyReturnFlight = (flight: any) => {
    return flight?.currency?.symbol;
  };

  useEffect(() => {
    dispatch(fetchAllAirports({size: 9999}));
  }, []);

  const getDateByYearsAgo = (yearsAgo: number): Date => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - yearsAgo);

    return date;
  };

  const getMinBirthDate = (ageCategory: string) => {
    switch (ageCategory) {
      case "ADULT":
        return new Date("1900-01-01");
      case "CHILD":
        return DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(13), 1);
      case "INF":
        return DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(3), 1);
      default:
        return new Date("1900-01-01");
    }
  };

  const getMaxBirthDate = (ageCategory: string) => {
    switch (ageCategory) {
      case "ADULT":
        return DateUtils.getDateByYearsAgo(13);
      case "CHILD":
        return DateUtils.getDateByYearsAgo(3);
      case "INF":
        return new Date();
      default:
        return new Date();
    }
  }

  const isBirthDateValid = (ageCategory: string, date: Date): Boolean => {

    switch (ageCategory) {
      case "ADULT":
        return date <= DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(13), 0);
      case "CHILD":
        return date <= DateUtils.getDateByYearsAgo(3) && date >= DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(13), 0);
      case "INF":
        return date > DateUtils.removeDaysToDate(DateUtils.getDateByYearsAgo(3), 0) && date <= new Date();
      default:
        return false;
    }
  };

  const submit = async (data: any) => {
    if (!isBirthDateValid(data.ageCategory, data.dateOfBirth)) {
      return;
    }

    if (!selectedFlight) {
      dispatch(warningMsg("Selektoni një fluturim"));
      return;
    }

    const params = {
      id: reservationId,
      body: {
        name: data.name,
        surname: data.surname,
        dateOfBirth: DateUtils.convertDate(data.dateOfBirth),
        nationality: "",
        gender: data.gender,
        ageCategory: data.ageCategory,
        departureFlightId: selectedFlight?.id,
        returnFlightId: null,
        price: {
          departureFlightPrice: parseInt(data.departureFlightPrice),
          returningFlightPrice: 0,
        },
      },
      onSuccess: () => {
        dispatch(setSelectedFlight(null));
        dispatch(setFlightResults(null));
        history.goBack();
        dispatch(toggleQuickPanel());
      },
    };

    dispatch(addPassenger(params));
  };

  const validatePrice = (e: any) => {
    if (e.target.value === "") {
      e.target.setCustomValidity("Shënoni çmimin");
    } else if (e.target.validity.patternMismatch) {
      e.target.setCustomValidity("Çmimi duhet të jetë numër");
    } else {
      e.target.setCustomValidity("");
    }

    return true;
  };

  const onFlightSelect = (flight: FlightResultTypes) => {
    dispatch(setSelectedFlight(flight));
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "35px"}}>
          <h1 style={{color: "#0D3B66"}}>
            {t("app.main.schedules.addPassenger.title","Adding a new passenger to the bill!")}
          </h1>
        </div>

        <Formik
            initialValues={{
              departureAirportId: "",
              arrivalAirportId: "",
              dateOfDeparture: new Date().toISOString(),
            }}
            onSubmit={(data) => {
              dispatch(setSelectedFlight(null));
              dispatch(
                  fetchAllFlightResults({
                    queryParams: {
                      oneWayPrice: 0,
                      returnPrice: 0,
                      departureAirportId: data.departureAirportId,
                      arrivalAirportId: data.arrivalAirportId,
                      dateOfDeparture: DateUtils.convertDate(data.dateOfDeparture),
                    },
                  })
              );
            }}
        >
          {({values, setValues}) => (
              <Form>
                <Grid
                    container
                    alignItems="center"
                    spacing={4}
                    style={{marginBottom: "30px"}}
                >
                  <Grid item xs={3}>
                    <Field
                        style={{width: "100%"}}
                        variant="outlined"
                        value={values.departureAirportId}
                        name="departureAirportId"
                        label={t("app.main.schedules.addPassenger.flightFrom", "Flight from")}
                        select
                        as={TextField}
                    >
                      {airports
                      .filter((ap: Airport) => ap.id !== values.arrivalAirportId)
                      .map((ap: Airport, idx) => (
                          <MenuItem key={ap.id} id={idx + ap.code} value={ap.id}>
                            {ap.code} - {ap.name}
                          </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                        style={{width: "100%"}}
                        variant="outlined"
                        value={values.arrivalAirportId}
                        name="arrivalAirportId"
                        label={t("app.main.schedules.addPassenger.towardsTheAirport", "Towards the airport")}
                        select
                        as={TextField}
                    >
                      {airports
                      .filter(
                          (ap: Airport) => ap.id !== values.departureAirportId
                      )
                      .map((ap: Airport, idx) => (
                          <MenuItem key={ap.id} id={ap.code + idx} value={ap.id}>
                            {ap.code} - {ap.name}
                          </MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                          inputVariant="outlined"
                          disableToolbar
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={t("app.main.schedules.addPassenger.departureTime", "Departure time")}
                          minDate={today}
                          value={values.dateOfDeparture}
                          style={{width: "100%"}}
                          onChange={(date) => {
                            if (date) {
                              setValues({
                                ...values,
                                dateOfDeparture: date.toISOString(),
                              });
                            }
                          }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3}>
                    <LoadingButton
                        btnTitle={t("app.main.schedules.addPassenger.searchFlights", "Search Flights")}
                        type="submit"
                        isLoading={false}
                        variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Form>
          )}
        </Formik>

        <Formik
            initialValues={{
              ageCategory: "ADULT",
              gender: "MALE",
              name: "",
              surname: "",
              dateOfBirth: getDateByYearsAgo(15),
              nationality: "",
              departureFlightPrice: 0,
            }}
            onSubmit={(data: any) => {
              submit(data);
            }}
        >
          {({values, setValues, setFieldValue}) => (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Field
                        style={{width: "100%"}}
                        label={t("app.main.schedules.addPassenger.passengerStatus", "Passenger status")}
                        onChange={(e: any) => {
                          setValues({
                            ...values,
                            ageCategory: e.target.value,
                            dateOfBirth: getMaxBirthDate(e.target.value),
                          });
                          if (
                              selectedFlight?.availableSectorPrice?.price?.oneWayPrices
                          ) {
                            setFieldValue(
                                "departureFlightPrice",
                                getPriceByAgeCategory(
                                    e.target.value,
                                    selectedFlight?.availableSectorPrice?.price
                                        .oneWayPrices
                                )
                            );
                          }
                        }}
                        variant="outlined"
                        size="small"
                        name="ageCategory"
                        required
                        select
                        as={TextField}
                    >
                      <MenuItem value="ADULT">{t("app.main.schedules.addPassenger.adult", "Adult")}</MenuItem>
                      <MenuItem value="CHILD">{t("app.main.schedules.addPassenger.child", "Child")}</MenuItem>
                      <MenuItem value="INF">{t("app.main.schedules.addPassenger.inf", "Infant")}</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                        style={{width: "100%"}}
                        label={t("app.main.schedules.addPassenger.passengerGender", "Passenger gender")}
                        onChange={(e: any) =>
                            setValues({
                              ...values,
                              gender: e.target.value,
                            })
                        }
                        variant="outlined"
                        size="small"
                        name="gender"
                        required
                        select
                        as={TextField}
                    >
                      <MenuItem value="MALE">{t("app.main.schedules.addPassenger.male", "Male")}</MenuItem>
                      <MenuItem value="FEMALE">{t("app.main.schedules.addPassenger.female", "Female")}</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Field
                        style={{width: "100%"}}
                        label={t("app.main.schedules.addPassenger.passengerName", "Passenger name")}
                        onChange={(e: any) =>
                            setValues({
                              ...values,
                              name: e.target.value.toUpperCase(),
                            })
                        }
                        variant="outlined"
                        size="small"
                        name="name"
                        as={TextField}
                        required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                        style={{width: "100%"}}
                        label={t("app.main.schedules.addPassenger.passengerLastname", "Passenger lastname")}
                        onChange={(e: any) =>
                            setValues({
                              ...values,
                              surname: e.target.value.toUpperCase(),
                            })
                        }
                        variant="outlined"
                        size="small"
                        name="surname"
                        as={TextField}
                        required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                          style={{margin: 0, width: "100%"}}
                          minDate={getMinBirthDate(values.ageCategory)}
                          maxDate={getMaxBirthDate(values.ageCategory)}
                          name="dateOfBirth"
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="normal"
                          size="small"
                          label={t("app.main.schedules.addPassenger.passengerBirthday", "Date of birth")}
                          id="date-picker-inline"
                          value={values.dateOfBirth}
                          onChange={(date: any) =>
                              setValues({
                                ...values,
                                dateOfBirth: date,
                              })
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                        style={{width: "100%"}}
                        label={t("app.main.schedules.addPassenger.price", "Price")}
                        onChange={(e: any) => {
                          validatePrice(e);
                          setValues({
                            ...values,
                            departureFlightPrice: e.target.value,
                          });
                          setFieldValue("departureFlightPrice", e.target.value);
                        }}
                        value={selectedFlight ? values.departureFlightPrice : 0}
                        variant="outlined"
                        size="small"
                        name="price"
                        as={TextField}
                        inputProps={{pattern: "[0-9]*"}}
                        required
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="start">EUR</InputAdornment>
                          ),
                        }}
                    />
                  </Grid>
                  {/*<Grid item xs={6}>*/}
                  {/*  <Field*/}
                  {/*      style={{width: "100%"}}*/}
                  {/*      label={t("app.main.schedules.addPassenger.citizenship", "Citizenship")}*/}
                  {/*      onChange={(e: any) =>*/}
                  {/*          setValues({*/}
                  {/*            ...values,*/}
                  {/*            nationality: e.target.value,*/}
                  {/*          })*/}
                  {/*      }*/}
                  {/*      variant="outlined"*/}
                  {/*      size="small"*/}
                  {/*      name="nationality"*/}
                  {/*      as={TextField}*/}
                  {/*      required*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                </Grid>
                <Grid container style={{marginTop: "40px"}}>
                  <Grid xs={12} item style={{flexGrow: 1}}>
                    <BaseTable
                        columns={columns}
                        data={flightResults?.content}
                        onClickTableRow={(row: FlightResultTypes) => {
                          onFlightSelect(row);
                          setValues({
                            ...values,
                            departureFlightPrice: getPriceByAgeCategory(
                                values.ageCategory,
                                row.availableSectorPrice.price.oneWayPrices
                            ),
                          });
                        }}
                        disableRow={(row: FlightResultTypes) =>
                            row.numberOfAvailableSeats === 0
                        }
                        isLoading={loading}
                        selectedRow={(row: FlightResultTypes) =>
                            row.id === selectedFlight?.id
                        }
                    />
                  </Grid>

                  <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        padding: "15px",
                        background: "#e7e7e7",
                      }}
                  >
                    <p style={{marginRight: "5px"}}>
                      {t("app.main.schedules.updatePassengerFlight.outgoingTicket", "Bileta shkuese")}:{" "}
                      <span style={{fontWeight: 500}}>
                    {selectedFlight
                        ? values.departureFlightPrice
                        : ""}{" "}
                        {getSymbolCurrencyReturnFlight(selectedFlight)}
                  </span>{" "}
                      |
                    </p>
                    <p style={{marginRight: "5px"}}>
                      {t("app.main.schedules.updatePassengerFlight.tax", "Taksat")}:{" "}
                      <span style={{fontWeight: 500}}>
                    {selectedFlight?.taxOfFlight}{" "}
                        {getSymbolCurrencyReturnFlight(selectedFlight)}
                  </span>{" "}
                      |
                    </p>
                    <p style={{marginRight: "5px"}}>
                      {t("app.main.schedules.updatePassengerFlight.totalTicket", "Biletat Total")}:{" "}
                      <span style={{fontWeight: 500}}>
                    {selectedFlight
                        ?
                        Number(values.departureFlightPrice)
                        + selectedFlight?.taxOfFlight
                        : ""}{" "}
                        {getSymbolCurrencyReturnFlight(selectedFlight)}
                  </span>{" "}

                    </p>
                  </div>
                </Grid>

                <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{marginTop: "20px"}}
                >
                  <Grid item>
                    <LoadingButton
                        type="submit"
                        btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                        size="medium"
                        isLoading={addPassengerLoading}
                    />
                    <Button
                        onClick={(e) => {
                          dispatch(setSelectedFlight(null));
                          dispatch(setFlightResults(null));
                          dispatch(toggleQuickPanel());
                          history.goBack();
                        }}
                    >
                      {t("app.main.schedules.splitPassenger.closeButton", "Mbylle")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default AddPassenger;
