import React, {useEffect} from "react";
import {MenuItem, TextField, Typography} from "@material-ui/core";
import {Field, Formik} from 'formik';
import {scheduleFormStyles} from "../schedule.styles";
import {useDispatch, useSelector} from "react-redux";
import {setFormData} from "../store/create-schedule.slice";
import {AppState} from "../../../store";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const phoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/gm

const validationSchema = yup.object().shape({
    contactNumber: yup
        .string()
        .matches(phoneRegExp, 'Contact number must start with +'),
    secondaryContactNumber: yup
        .string()
        .matches(phoneRegExp, 'Secondary contact number must start with +'),

    supervisor: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    emailOfSupervisor: yup.string().email('E-mail address must be a valid email'),
});

const ScheduleFinalForm = ({
                               isSubmitting,
                               setFieldValue,
                               setSubmitting,
                           }: any) => {
    const classes = scheduleFormStyles();
    const dispatch = useDispatch();
    const formData = useSelector(
        (state: AppState) => state.schedule.createSchedule.formData
    );
    const {t} = useTranslation();
    const {allPaymentMethods} = useSelector(
        (state: AppState) => state.configurations.paymentmethods.paymentmethodsList);
    const defaultPayment = allPaymentMethods.find(payment => payment.name === 'Cash');

    const handleChange = (e: any) => {
        if (!e.target.value.startsWith(" ")) {
            dispatch(setFormData({field: e.target.name, value: e.target.value}));
        }
    };

    useEffect(() => {
        if (isSubmitting) {
            setFieldValue("formData", {...formData});
            setSubmitting(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (defaultPayment) {
            dispatch(setFormData({
                field: 'paymentMethodId',
                value: defaultPayment.id
            }))
        }
    }, [])

    return (
        <div className={classes.formWrapper}>
            <Formik
                initialValues={{}}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({values, setValues, touched, errors}: any) => (
                    <div>
                        <div
                            className={classes.row}
                            style={{
                                borderTop: "1px solid #e7e7e7",
                            }}
                        >
                            <Typography style={{width: "132px"}}>
                                {t("app.main.reservation.contactPerson", "Contact person")}:
                            </Typography>
                            <Field
                                size={"small"}
                                onChange={(e: any) => {
                                    setValues({
                                        ...values,
                                        supervisor: e.target.value,
                                    });
                                    handleChange(e);
                                }}
                                variant="outlined"
                                value={formData.supervisor}
                                name="supervisor"
                                label={t("app.main.reservation.input1", "Name of the passenger of the contact person...")}
                                error={Boolean(errors.supervisor && touched.supervisor)}
                                helperText={touched.supervisor ? errors.supervisor : ""}
                                as={TextField}
                                style={{
                                    marginRight: "2%",
                                    marginLeft: "2%"
                                }}
                            />

                            <Typography style={{width: "132px"}}>
                                {t("app.main.reservation.language", "Contact language")}:
                            </Typography>
                            <Field
                                size={"small"}
                                error={Boolean(errors.languageOfSupervisor && touched.languageOfSupervisor)}
                                helperText={touched.languageOfSupervisor ? errors.languageOfSupervisor : ""}
                                onChange={(e: any) => {
                                    setValues({
                                        ...values,
                                        languageOfSupervisor: e.target.value,
                                    });
                                    handleChange(e);
                                }}
                                variant="outlined"
                                value={formData.languageOfSupervisor}
                                name="languageOfSupervisor"
                                label={"Gjuha e kontaktuesit"}
                                select
                                as={TextField}
                                style={{
                                    marginRight: "2%",
                                    marginLeft: "2%"
                                }}
                            >
                                <MenuItem
                                    value="sq">{t("app.main.schedules.update.menuItem1", "Albanian")}</MenuItem>
                                <MenuItem
                                    value="en">{t("app.main.schedules.update.menuItem2", "English")}</MenuItem>
                            </Field>

                        </div>

                        <div
                            className={classes.row}
                            style={{
                                borderTop: "1px solid #e7e7e7",
                            }}
                        >
                            <Typography style={{width: "132px"}}>
                                {t("app.main.reservation.contactNumber", "Contact number")}:
                            </Typography>
                            <Field
                                size={"small"}
                                as={TextField}
                                type="text"
                                label={"+"}
                                variant="outlined"
                                name={"contactNumber"}
                                value={values.contactNumber}
                                defaultValue="+"
                                error={Boolean(errors.contactNumber && touched.contactNumber)}
                                helperText={touched.contactNumber ? errors.contactNumber : ""}
                                onChange={(e: any) => {
                                    setValues({
                                        ...values,
                                        contactNumber: e.target.value,
                                    });
                                    handleChange(e);
                                }}
                                style={{
                                    marginRight: "2%",
                                    marginLeft: "2%"
                                }}
                            />


                            <Field
                                size={"small"}
                                as={TextField}
                                type="text"
                                variant="outlined"
                                name={"secondaryContactNumber"}
                                value={values.secondaryContactNumber}
                                onChange={(e: any) => {
                                    setValues({
                                        ...values,
                                        secondaryContactNumber: e.target.value,
                                    });
                                    handleChange(e)
                                }}
                                style={{
                                    marginRight: "2%",
                                    marginLeft: "10%"
                                }}
                            />

                        </div>


                        <div
                            className={classes.row}
                            style={{
                                borderTop: "1px solid #e7e7e7",
                            }}
                        >
                            <Typography style={{width: "132px"}}>
                                {t("app.main.reservation.email", "E-mail address")}:
                            </Typography>
                            <Field
                                size={"small"}
                                type="email"
                                variant="outlined"
                                value={formData.emailOfSupervisor}
                                name="emailOfSupervisor"
                                label={t("app.main.reservation.label", "Example: email@hotmail.com")}
                                error={Boolean(errors.emailOfSupervisor && touched.emailOfSupervisor)}
                                helperText={touched.emailOfSupervisor ? errors.emailOfSupervisor : ""}
                                onChange={(e: any) => {
                                    setValues({
                                        ...values,
                                        emailOfSupervisor: e.target.value,
                                    });
                                    handleChange(e);
                                }}
                                as={TextField}
                                style={{
                                    marginRight: "52%",
                                    marginLeft: "2%"
                                }}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default ScheduleFinalForm;
