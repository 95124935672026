import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {errorMsg} from "app/utils/MessageUtils";
import {EmployeeTypes} from "../../../types/service-types/EmployeeTypes";
import {UserService} from "../../../services";

interface GetEmployeeParams {
    id: string;
    onSuccess?: Function;
    onError?: Function;
}

export const getEmployee = (params: GetEmployeeParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const response = await UserService.getEmployeeById(params.id);
        dispatch(setEmployee(response));
        if (params.onSuccess) params.onSuccess(response);
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
    }
}

interface EmployeeSlice {
    employee: EmployeeTypes | null;
    loading: boolean;
}

const initialState: EmployeeSlice = {
    employee: null,
    loading: false
}

const stateSlice = createSlice({
    name: 'employee/employee',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setEmployee: (state, action) => {
            state.employee = action.payload
        }
    }
});

export const {setLoading, setEmployee} = stateSlice.actions;

export default stateSlice.reducer;