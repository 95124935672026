import React from 'react';
import BaseDropdown from "app/main/shared-components/base-dropdown/BaseDropdown";
import {DropdownItemTypes} from "app/types/DropdownTypes";
import {logoutUser} from "app/auth/store/user.slice";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";

const UserMenu = () => {
  const dispatch = useDispatch();
  const stateUser = useSelector((state: AppState) => state.auth.user);

  const items: DropdownItemTypes[] = [{
    icon: 'exit_to_app',
    title: 'Logout',
    onClick: () => {
      window.location.reload();
      dispatch(logoutUser());
    }
  }];

  // @ts-ignore
  return stateUser && <BaseDropdown color={'primary'} icon='account_circle_outlined' title={stateUser.fullName} items={items}/>

};

export default UserMenu;