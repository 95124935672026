import {combineReducers} from "redux";
import createSmsTemplate from "./create-sms-template.slice";
import fetchAllSmsTemplates from "./sms-template.slice";
import updateSmsTemplate from "./update-sms-template.slice";
import deleteSmsTemplate from "./delete-sms-template.slice";

const reducer = combineReducers({
  fetchAllSmsTemplates,
  createSmsTemplate,
  updateSmsTemplate,
  deleteSmsTemplate
})

export default reducer;