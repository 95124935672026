import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTableRowStyles} from "./reports-table.styles";
import ReportsTableCell from "./ReportsTableCell";
import DateUtils from "../../../utils/DateUtils";

const ReportsTableRow = (props: any) => {
  const classes = useTableRowStyles();
  const {row} = props;

  return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{textAlign: 'center'}}>
            <ReportsTableCell {...props} content={[
              `${row.confirmationCode}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <ReportsTableCell {...props} content={[
              DateUtils.toHumanDate(
                  DateUtils.convertDate(row.date),
                  new Date(row.date).toLocaleTimeString()
              )
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <ReportsTableCell {...props} content={[
              `${row.fullName}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <ReportsTableCell {...props} content={[
              `${row.method.name}`,
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <ReportsTableCell {...props} content={[
              `${row.amount} ${row.currency.symbol}`
            ]}/>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
};

export default ReportsTableRow;