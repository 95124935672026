import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {CurrenciesService} from 'app/services';
import {Currency} from 'app/types/service-types/CurrenciesTypes';
import {errorMsg} from "app/utils/MessageUtils";

export const fetchAllCurrencies = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CurrenciesService.fetchAll();
    dispatch(setCurrencies(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    errorMsg(err.data?.message);
  }
}

interface CurrenciesListSlice {
  loading: boolean;
  currencies: Currency[];
}

const initialState: CurrenciesListSlice = {
  loading: false,
  currencies: []
}

const stateSlice = createSlice({
  name: 'flights/currencies',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload
    }
  }
});

export const {setCurrencies, setLoading} = stateSlice.actions;

export default stateSlice.reducer;
