import React from 'react';
import {BasePage} from "app/types/PageTypes";
import {Redirect, Switch} from "react-router-dom";

const SchedulesPage = (props: BasePage) => {

  return (
      <Switch>
        {props.children}
        <Redirect to='/schedules/schedules-list'/>
      </Switch>
  );
};

export default SchedulesPage;