import React, {useEffect, useState} from "react";

import {Button, MenuItem, TextField} from "@material-ui/core";

import {Field, Form, Formik} from "formik";

import {schedulesFormStyles} from "../schedules.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "app/store";
import {updateSchedule} from "../store/update-schedule.slice";
import {ReservationTypes} from "app/types/service-types/ReservationTypes";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {successMsg} from "../../../utils/MessageUtils";
import {PaymentMethods} from "../../../types/service-types/PaymentMethodsTypes";
import {
  fetchAllPaymentMethods
} from "../../configurations/store/paymentmethods/paymentmethods-list.slice";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const phoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/gm

const validationSchema = yup.object({
  contactNumber: yup
  .string().required('Contact number is required')
  .matches(phoneRegExp, 'Contact number must start with + sign'),
  secondaryContactNumber: yup
  .string()
  .matches(phoneRegExp, 'Secondary contact number must start with + sign')
});

const SupervisorUpdateForm = () => {
  const classes = schedulesFormStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let {params} = useRouteMatch() as any;
  const {t} = useTranslation();

  const {allReservations} = useSelector(
      (state: AppState) => state.schedules.schedulesList
  ) as any;

  const {allPaymentMethods} = useSelector(
      (state: AppState) => state.configurations.paymentmethods.paymentmethodsList);

  const resFlight = useSelector(
      (state: AppState) =>
          state.flights.flightsList.reservationsFlight.reservationsFlight
  );

  const updateScheduleState = useSelector(
      (state: AppState) => state.schedules.updateSchedule
  );

  const flightEditMode = history.location.pathname.includes("flightsedit");
  // @ts-ignore
  const reversations = !flightEditMode ? allReservations.content : resFlight;
  const [formData, setFormData] = useState<ReservationTypes>({
    supervisor: "",
    languageOfSupervisor: "sq",
    emailOfSupervisor: "",
    contactNumber: "",
    secondaryContactNumber: "",
    paymentMethodId: "",
    paymentRecipient: "",
    invoiceInTheNameOf: "",
    source: "ADMIN",
  });

  const handleChange = (e: any) => {
    if (!e.target.value.startsWith(" ")) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getSelectedUser = () => {
    let userToReturn = {
      supervisor: "",
      languageOfSupervisor: "sq",
      emailOfSupervisor: "",
      contactNumber: "",
      secondaryContactNumber: "",
      paymentMethodId: "",
      paymentRecipient: "",
      invoiceInTheNameOf: "",
      source: "ADMIN"
    }

    if (params) {
      const foundedSch = reversations.find((r: any) => r.id === params?.id);
      if (foundedSch) {
        const {
          languageOfSupervisor,
          supervisor,
          emailOfSupervisor,
          contactNumber,
          secondaryContactNumber,
          paymentMethodResponse,
          paymentRecipient,
          invoiceInTheNameOf,
        } = foundedSch;

        const secondaryNumber = secondaryContactNumber ? secondaryContactNumber : "";

        userToReturn = {
          languageOfSupervisor,
          supervisor,
          emailOfSupervisor,
          contactNumber,
          secondaryContactNumber: secondaryNumber,
          paymentMethodId: paymentMethodResponse?.id,
          paymentRecipient,
          invoiceInTheNameOf,
          source: "ADMIN"
        }

      }
    }

    return userToReturn;
  }

  useEffect(() => {
    dispatch(fetchAllPaymentMethods());
    if (params) {
      const foundedSch = reversations.find((r: any) => r.id === params?.id);
      if (foundedSch) {
        const {
          languageOfSupervisor,
          supervisor,
          emailOfSupervisor,
          contactNumber,
          secondaryContactNumber,
          paymentMethodResponse,
          paymentRecipient,
          invoiceInTheNameOf,
        } = foundedSch;

        setFormData({
          languageOfSupervisor,
          supervisor,
          emailOfSupervisor,
          contactNumber,
          secondaryContactNumber,
          paymentMethodId: paymentMethodResponse?.id,
          paymentRecipient,
          invoiceInTheNameOf,
        });
      }
    }
  }, []);

  const onSubmitForm = (travelers: any) => {
    if (formData == null) {
      return;
    }

    if (params) {
      // @ts-ignore
      const foundedSch = reversations.find((r: any) => r.id === params.id);

      if (foundedSch) {
        const id = foundedSch.id;
        dispatch(
            updateSchedule({
              id,
              body: formData,
              onSuccess: () => {
                dispatch(toggleQuickPanel());
                dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Contact person changed successfully!' : 'Personi kontaktues u ndryshua me sukses!'));
                history.goBack();
              },
            })
        );
      }
    }
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "35px"}}>
          <h1 style={{color: "#0D3B66"}}>
            {t("app.main.schedules.update.title", "Change of invoice contact person")}
          </h1>
        </div>

        <Formik
            initialValues={getSelectedUser()}
            validationSchema={validationSchema}
            onSubmit={(data: any) => {
              onSubmitForm(data.travelers);
            }}
        >
          {({errors, values, setValues, touched}) => (
              <Form>
                <div className={classes.row}>
                  <Field
                      onChange={(e: any) => handleChange(e)}
                      variant="outlined"
                      value={formData.supervisor}
                      name="supervisor"
                      label={t("app.main.schedules.update.form.label1", "Supervisor")}
                      as={TextField}
                      required
                  />
                  <Field
                      onChange={(e: any) => handleChange(e)}
                      type="email"
                      variant="outlined"
                      value={formData.emailOfSupervisor}
                      name="emailOfSupervisor"
                      label={t("app.main.schedules.update.form.label2", "Supervisor email")}
                      as={TextField}
                      required
                  />
                  <Field
                      onChange={(e: any) => handleChange(e)}
                      variant="outlined"
                      value={formData.languageOfSupervisor}
                      name="languageOfSupervisor"
                      label={t("app.main.schedules.update.form.label3", "Supervisor language")}
                      select
                      as={TextField}
                      required
                  >
                    <MenuItem
                        value="sq">{t("app.main.schedules.update.menuItem1", "Albanian")}</MenuItem>
                    <MenuItem
                        value="en">{t("app.main.schedules.update.menuItem2", "English")}</MenuItem>
                  </Field>
                </div>

                <div className={classes.row}>
                  <Field
                      as={TextField}
                      type="text"
                      required
                      label={t("app.main.schedules.update.form.label4", "Contact number")}
                      variant="outlined"
                      name="contactNumber"
                      value={formData.contactNumber}
                      error={Boolean(errors?.contactNumber && touched?.contactNumber)}
                      helperText={touched?.contactNumber ? errors?.contactNumber : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          contactNumber: e.target.value,
                        });
                        handleChange(e);
                      }}
                  />
                  <Field
                      as={TextField}
                      type="text"
                      label={t("app.main.reservation.contactNumber2","Secondary Contact number")}
                      variant="outlined"
                      name="secondaryContactNumber"
                      value={formData.secondaryContactNumber}
                      error={Boolean(errors?.secondaryContactNumber && touched?.secondaryContactNumber)}
                      helperText={touched?.secondaryContactNumber ? errors?.secondaryContactNumber : ""}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          secondaryContactNumber: e.target.value,
                        });
                        handleChange(e);
                      }}
                  />
                  <Field
                      onChange={(e: any) => handleChange(e)}
                      variant="outlined"
                      value={formData.paymentMethodId}
                      name="paymentMethodId"
                      label={t("app.main.schedules.payments.paymentMethod", "Payment method")}
                      select
                      as={TextField}
                      required
                  >
                    {allPaymentMethods?.map((payment: PaymentMethods) => {
                      return <MenuItem key={payment.id} value={payment.id}>
                        {payment.name}
                      </MenuItem>
                    })}
                  </Field>
                </div>

                <div className={classes.row}>
                  <Field
                      onChange={(e: any) => handleChange(e)}
                      variant="outlined"
                      value={formData.paymentRecipient}
                      name="paymentRecipient"
                      label={t("app.main.schedules.update.form.label5", "Recipient of payment")}
                      as={TextField}
                      required
                  />
                  <Field
                      onChange={(e: any) => handleChange(e)}
                      variant="outlined"
                      value={formData.invoiceInTheNameOf}
                      name="invoiceInTheNameOf"
                      label={t("app.main.schedules.update.form.label6", "Invoice on behalf of")}
                      as={TextField}
                  />
                </div>

                <div className={classes.row}>
                  <LoadingButton
                      type="submit"
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      size="medium"
                      isLoading={updateScheduleState.loading}
                  />
                  <Button
                      onClick={(e) => {
                        dispatch(toggleQuickPanel());
                        history.goBack();
                      }}
                  >
                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                  </Button>
                </div>
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default SupervisorUpdateForm;
