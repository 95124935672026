import axios from "./axios.interceptors";
import {
  CreateFlightRequestTypes,
  ExportFlightSkyScanner,
  FilterFlightsQueryParams,
  FlightRequestBody,
  FlightSearchRequest
} from "app/types/service-types/FlightTypes";


class FlightServices {

  searchFlights = async (params: FlightSearchRequest): Promise<any> => {
    try {
      const response = await axios.get('/api/flights/search', {params});
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public getAll = async (): Promise<FlightRequestBody[]> => {
    try {
      const response = await axios.get('/api/flights');
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  filterFlights = async (queryParams: FilterFlightsQueryParams): Promise<any> => {
    try {
      const response = await axios.get('/api/flights', {params: queryParams});
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  createFlight = async (body: CreateFlightRequestTypes): Promise<any> => {
    try {
      return await axios.post('/api/flights', body);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  updateFlight = async (id: string, body: any): Promise<any> => {
    const response = await axios.put(`/api/flights/${id}`, body);
    return response;
  }

  deleteFlight = async (id: string): Promise<any> => {
    const response = await axios.delete(`/api/flights/${id}`);
    return response;
  }

  getFlightById = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/flights/${id}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  getPassengersFlightById = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/flights/${id}/travelers`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  getReservationsFlightById = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/flights/${id}/reservations`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  exportFlight = async (body: ExportFlightSkyScanner): Promise<any> => {
    try {
      const response = await axios.post('/api/flights/export', body);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}


export default new FlightServices();
