import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import {Form, Formik} from "formik";
import Grid from "@material-ui/core/Grid";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {Button} from "@material-ui/core";
import PageHeader from "../../shared-components/page-header/PageHeader";
import {useTranslation} from "react-i18next";
import {employeesFormStyles} from "../employees.styles";
import {getEmployee} from "../store/get-employee.slice";
import {EmployeeTypes} from "../../../types/service-types/EmployeeTypes";
import {setEmployeeIdentificationForm} from "../store/employee-identification-form.slice";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {setEmployeeScopesForm} from "../store/employee-scopes-form.slice";
import {resetEmployeeForm, setTheEmployeeForm} from "../store/employee-form.slice";
import {updateUser} from "../store/update-user.slice";
import {resetAgentForm} from "../../agency/store/agent-form.slice";
import EmployeeEditInformation from "../components/EmployeeEditInformation";

const EmployeesEditPage = () => {
    const classes = employeesFormStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const updateEmpLoading = useSelector((state: AppState) => state.employees.updateEmployee.loading);
    const employeeForm = useSelector((state: AppState) => state.employees.employeeForm);
    const {params}: any = useRouteMatch();
    const empLoading = useSelector((state: AppState) => state.employees.allEmployees.loading);

    useEffect(() => {
        dispatch(getEmployee({
            id: params.id,
            onSuccess: (responseData: EmployeeTypes) => fillFormWithSelectedEmployee(responseData),
        }));
    }, []);

    const fillFormWithSelectedEmployee = (empData: EmployeeTypes) => {
        if (empData) {
            dispatch(
                setEmployeeIdentificationForm({
                    email: empData.email,
                    status: empData.status
                })
            );

            dispatch(
                setEmployeeScopesForm({
                    scopes: empData.scopes,
                })
            )

            dispatch(
                setTheEmployeeForm({
                    fullName: empData.fullName,
                    email: empData.email,
                    contactNumber: empData.contactNumber,
                    address: empData.address,
                    role: empData.role,
                })
            );
        }
    };

    if (empLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`Employee information ${" "} ${employeeForm.fullName}`}
                desc={t("app.main.agency.agentsList.agentEdit.desc", "To change the information, change the form below and click Save Changes")}
                backButton={() => {
                    dispatch(resetEmployeeForm());
                    history.push("/employees/employees-list");
                }}
            />
            <div className={classes.formWrapper}>
                <Formik
                    initialValues={employeeForm}
                    onSubmit={() => {

                        const formWithoutBalanceId = {
                            ...employeeForm,
                        }

                        dispatch(updateUser({
                            id: params.id,
                            body: {...formWithoutBalanceId},
                            onSuccess: () => {
                                history.push("/employees/employees-list");
                                dispatch(resetAgentForm());
                            },
                        }))
                    }}
                >
                    <Form>

                        <EmployeeEditInformation />

                        <Grid container justify='flex-end' alignItems='center'
                              style={{marginTop: '20px'}}>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                                    size='medium'
                                    isLoading={updateEmpLoading}
                                />
                                <Button
                                    onClick={(e) => {
                                        dispatch(resetEmployeeForm());
                                        history.push('/employees/employees-list')
                                    }}>
                                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default EmployeesEditPage;
