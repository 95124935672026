import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {UserService} from "../../../services";
import {errorMsg, successMsg} from "../../../utils/MessageUtils";
import {UpdateEmployeeScopes} from "../../../types/service-types/EmployeeTypes";
import {fetchAllEmployeeUsers} from "./user-employees-list.slice";

interface UpdateScopesStateParams {
    id: string;
    body: UpdateEmployeeScopes;
    onSuccess?: Function;
    onError?: Function;
}

export const updateUserScopes = (params: UpdateScopesStateParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await UserService.updateScopes(params.id, params.body);
        if (response.status >= 200 && response.status < 300) {
            dispatch(setSuccess(true));
            dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Privileges changed successfully!' : 'Privilegjet u ndryshuan me sukses!'));
            if (params.onSuccess) params.onSuccess();
            dispatch(setError(""));
            // @ts-ignore
            dispatch(fetchAllEmployeeUsers());
        }
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
    }
};

interface UpdateScopesSlice {
    success: boolean;
    error: string;
    loading: boolean;
}

const initialState: UpdateScopesSlice = {
    error: "",
    success: false,
    loading: false,
};

const stateSlice = createSlice({
    name: "employee-edit/update-user-scopes",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;