import React, {useEffect} from 'react';
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import SearchPanel from "../search-panel/SearchPanel";
import {clearfilterReports, filterReports} from "../store/filter-reports.slice";
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import ReportsTable from "../../shared-components/reports-table/ReportsTable";
import {useTranslation} from "react-i18next";

export interface ReportListType {
  methodId: string;
  title: string;
}

const ReportsListGeneric = (props: ReportListType) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {allReports, loading} = useSelector(
      (state: AppState) => state.reports.paymentReports);

  const filtersRep = useSelector((state: AppState) => state.reports.filReports);

  useEffect(() => {
    dispatch(clearfilterReports());
    dispatch(filterReports({
      page: 0,
      paymentMethodId: props.methodId,
    }))
  }, []);


  return (
      <div>
        <PageHeader
            title={props.title}
            desc={t("app.main.report.reportList.desc", "Customer payment report")}
        />
        <SearchPanel/>
        <ReportsTable data={allReports} loading={loading}/>
        <PaginationBar totalPages={allReports?.totalPages}
                       onPageChange={(page: number) =>
                           dispatch(filterReports({...filtersRep, page}))
                       }/>
      </div>
  );
};

export default ReportsListGeneric;