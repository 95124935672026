import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import DateUtils from "app/utils/DateUtils";
import {fetchAllCustomers} from "../../customer/store/customers-list.slice";
import {FilterCustomersQueryParams} from "../../../types/service-types/CustomerTypes";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] === "ALL") {
      } else newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const filterCustomers =
    (queryParams: FilterCustomersQueryParams) =>
        async (dispatch: Dispatch) => {
          dispatch(setFilters(queryParams));
          // @ts-ignore
          dispatch(fetchAllCustomers({...removeFalsyAttributes(queryParams)}));
        };

export const clearfilterCustomer = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterCustomersQueryParams = {
  q: "",
};

const stateSlice = createSlice({
  name: "customers/customer-filter",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterCustomersQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;