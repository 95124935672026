import React, {useEffect, useState} from 'react'
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import history from "@history";
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from "react-router-dom";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import {fetchAllAgents} from "../store/agents-list.slice";
import {AppState} from "app/store";
import ModalDialog from "app/main/shared-components/modal-dialog/ModalDialog";
import {deleteAgent} from '../store/delete-agent.slice';
import PaginationBar from "../../shared-components/pagination-bar/PaginationBar";
import {clearfilterAgents, filterAgents} from "../store/filter-agents.slice";
import AgentsTable from "../../shared-components/agents-table/AgentsTable";
import {useTranslation} from "react-i18next";

const AgentsList = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [modal, setModal] = useState({state: false, deleteId: ''});

    const {allAgents, loading} = useSelector((state: AppState) => state.agents.allAgents);

    const filtersAgents = useSelector((state: AppState) => state.agents.filAgents);

    useEffect(() => {
        dispatch(fetchAllAgents());
        dispatch(clearfilterAgents());
    }, []);

    return (
        <div>
            <PageHeader
                title={t("app.main.agency.agentsList.title", "Agents")}
                desc={t("app.main.agency.agentsList.desc", "If you want to register a new agent or agency, click the Register button")}
                button={t("app.main.agency.agentsList.registerButton", "Register")}
                onClickButton={() => {
                    history.push("/agents-agencies/list/create");
                    dispatch(toggleQuickPanel());
                }}
            />
            <ModalDialog
                open={modal.state}
                title='Are you sure you want to delete this?'
                onOk={() => {
                    dispatch(deleteAgent({id: modal.deleteId}));
                    setModal({state: false, deleteId: ''});
                }}
                onCancel={() => setModal({state: false, deleteId: ''})}
            />
            <AgentsTable data={allAgents} loading={loading}/>
            <PaginationBar totalPages={allAgents?.totalPages}
                           onPageChange={(page: number) =>
                               dispatch(filterAgents({...filtersAgents, page}))
                           }/>

        </div>
    )
}

export default AgentsList;