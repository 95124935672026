import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Form, Formik} from "formik";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "app/store";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {getAgent} from "../store/get-agent.slice";
import {AgentTypes} from "app/types/service-types/AgentTypes";
import {fetchAllCurrencies} from 'app/main/flights/store/currencies-list.slice';
import {resetAgentForm, setTheAgentForm} from '../store/agent-form.slice';
import {updateAgentsStyles} from "./update-agent.styles";
import {updateAgent} from "../store/update-agent.slice";
import AgentInformation from "../components/AgentInformation";
import InvoiceInfo from "../components/InvoiceInfo";
import ReservationInfo from "../components/ReservationInfo";
import ProvisionInfo from "../components/ProvisionInfo";
import Credits from "../components/Credits";
import DiscountInfo from "../components/DiscountInfo";
import DebtInfo from "../components/DebtInfo";
import ScopesInfo from "../components/ScopesInfo";

const UpdateAgent = () => {
    const classes = updateAgentsStyles();
    const dispatch = useDispatch();

    const history = useHistory();
    const {params}: any = useRouteMatch();

    const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
    const updateAgentLoading = useSelector((state: AppState) => state.agents.updateAgent.loading);
    const agentForm = useSelector((state: AppState) => state.agents.agentForm);

    useEffect(() => {
        dispatch(getAgent({
            id: params.id,
            onSuccess: (responseData: AgentTypes) => fillFormWithSelectedAgent(responseData),
        }));
    }, []);

    const fillFormWithSelectedAgent = (agentData: AgentTypes) => {
        if (agentData) {
            const formatBalanceRequests = agentData.balances.map((item) => ({
                id: item.id,
                availableCredit: item.availableCredit,
                creditLimit: item.creditLimit,
                neto: item.neto,
                minimumTicketPrice: item.minimumTicketPrice,
                currencyId: item.currencyResponse.id
            }));
            const provisionFormsFormatted = agentData.provisionForms.map(p => {
                return {
                    ...p,
                    currencyId: p.currency.id
                }
            })
            const discountsFormatted = agentData.discounts.map(p => {
                return {
                    ...p,
                    currencyId: p.currency.id
                }
            })
            dispatch(
                setTheAgentForm({
                    fullName: agentData.fullName,
                    email: agentData.email,
                    contactEmail: agentData.contactEmail,
                    companyName: agentData.companyName,
                    location: agentData.location,
                    phoneNumber: agentData.phoneNumber,
                    address: agentData.address,
                    invoiceText: agentData.invoiceText,
                    invoiceBottomText: agentData.invoiceBottomText,
                    maxAdultAllowed: agentData.maxAdultAllowed,
                    maxChildAllowed: agentData.maxChildAllowed,
                    maxInfantAllowed: agentData.maxInfantAllowed,
                    allowInfantOnly: agentData.allowInfantOnly,
                    provisionFormType: agentData.provisionFormType,
                    provisionForms: provisionFormsFormatted,
                    provisionPercentage: agentData.provisionPercentage,
                    balanceRequests: formatBalanceRequests,
                    discountType: agentData.discountType,
                    discounts: discountsFormatted,
                    allowedDiscount: agentData.allowedDiscount,
                    actualDebt: agentData.actualDebt,
                    debtBeforeEnding: agentData.debtBeforeEnding,
                    scopes: agentData.scopes,
                    status: agentData.status
                })
            );
        }
    };


    useEffect(() => {
        dispatch(fetchAllCurrencies());
    }, []);


    if (agentLoading) {
        return <LoadingForm/>;
    }

    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader} style={{marginBottom: '35px'}}>
                <h1 style={{color: '#0D3B66'}}>Ndrysho agjentin</h1>
            </div>

            <Formik
                initialValues={agentForm}
                onSubmit={(data) => {
                    const formWithoutBalanceId = {
                        ...agentForm,
                        balanceRequests: agentForm.balanceRequests.map(({id, ...items}) => items)  // We are removing the id from balanceRequests
                    }

                    dispatch(updateAgent({
                        id: params.id,
                        body: {...formWithoutBalanceId},
                        onSuccess: () => {
                            dispatch(toggleQuickPanel());
                            dispatch(resetAgentForm());
                        },
                    }))
                }}
            >
                {({values, setValues, setFieldValue}) => (
                    <Form>
                        <AgentInformation/>

                        <InvoiceInfo/>

                        <ReservationInfo/>

                        <Credits/>

                        <ProvisionInfo/>

                        <DiscountInfo/>

                        <DebtInfo/>

                        <ScopesInfo/>

                        <Grid container justify='flex-end' alignItems='center' style={{marginTop: '20px'}}>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    btnTitle='Ruaj'
                                    size='medium'
                                    isLoading={updateAgentLoading}
                                />

                                <Button
                                    onClick={(e) => {
                                        dispatch(toggleQuickPanel())
                                        dispatch(resetAgentForm());
                                        history.push('/agents-agencies/list')
                                    }}>Mbylle</Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateAgent;