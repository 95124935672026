import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {SmsTemplateBody} from "../../../../types/SmsTemplateTypes";
import SmsTemplatesServices from "../../../../services/sms-templates.services";
import {fetchAllSmsTemplates} from "./sms-template.slice";

interface CreateSmsTemplateParams {
  body: SmsTemplateBody
  onSuccess?: Function;
  onError?: Function;
}

export const createSmsTemplate = (params: CreateSmsTemplateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SmsTemplatesServices.createSmsTemplate(params.body);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'SMS template created successfully!' : 'SMS shablloni u krijua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllSmsTemplates());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface CreateSmsTemplate {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: CreateSmsTemplate = {
  success: false,
  error: '',
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/sms-templates',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;