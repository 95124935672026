import React, {useEffect, useState} from 'react';
import {ReservationService} from "app/services";
import {useRouteMatch} from "react-router-dom";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import TravelerActivityItem from "./TravelerActivityItem";
import {useTranslation} from "react-i18next";

const TravelerActivityTab = () => {
  const {params} = useRouteMatch();
  const [activityState, setActivityState] = useState({isLoading: false, activities: [], error: ''});
  const {t} = useTranslation();

  const columns = [
    `${t("app.main.schedules.updateTravelerFlight.age", "Mosha")}`,
    `${t("app.main.configuration.airlineCompanies.create.input1", "Emri")}`,
    `${t("app.main.configuration.airlineCompanies.create.input2", "Mbiemri")}`,
    `${t("app.main.schedules.addPassenger.passengerBirthday", "Date of birth")}`,
    `${t("app.main.schedules.addPassenger.citizenship", "Shtetësia")}`,
    `${t("app.main.schedules.updateTravelerFlight.gender", "Gjinia")}`,
  ]

  useEffect(() => {
    setActivityState({...activityState, isLoading: true});
    if (params) {
      // @ts-ignore
      ReservationService.fetchTravelersActivities(params.id)
      .then((res) => setActivityState({
        isLoading: false,
        activities: res,
        error: ''
      }))
      .catch((err) => setActivityState({
        ...activityState,
        isLoading: false,
        error: err.data.message
      }));
    }
  }, []);

  return (
      <div>
        {activityState.isLoading ? (
            <LoadingForm/>
        ) : activityState.activities.map((act: any, idx: number) => (
            <TravelerActivityItem key={idx} columns={columns} row={act}/>
        ))}
      </div>
  );
};

export default TravelerActivityTab;