import {createSlice} from '@reduxjs/toolkit';

interface IState {
  state: boolean,
  options: {
    anchorOrigin: {
      vertical: 'top' | 'bottom' | 'left' | 'right',
      horizontal: 'center' | 'left' | 'right'
    },
    autoHideDuration: number,
    message: string,
    variant: string
  }
}

const initialState: IState = {
  state: false,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    autoHideDuration: 6000,
    message: 'Hi',
    variant: 'success'
  }
};
const messageSlice = createSlice({
  name: 'app/message',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        ...action.payload
      };
    },
    hideMessage: (state) => {
      state.state = false;
    }
  }
});

export const {hideMessage, showMessage} = messageSlice.actions;

export default messageSlice.reducer;