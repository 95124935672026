import axios from './axios.interceptors';

class TimeZoneServices {
    fetchAll = async (): Promise<any> => {
        try {
            const res = await axios.get('/api/timezones');
            return res.data;
        } catch (err) {
            throw err;
        }
    }
}

export default new TimeZoneServices();
