import React from 'react';
import {CircularProgress, TableCell, TableRow} from "@material-ui/core";
import {TableCellTypes} from "app/types/TableTypes";

const LoadingTableRow = (props: TableCellTypes) => {
  return (
      <TableRow>
        <TableCell colSpan={props.colSpan} align={props.align || 'center'}>
          <CircularProgress size={24}/>
        </TableCell>
      </TableRow>
  );
};

export default LoadingTableRow;