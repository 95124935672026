import React, {useEffect} from 'react';

import {Button, MenuItem, TextField,} from '@material-ui/core';

import {Field, Form, Formik} from 'formik';

import {schedulesFormStyles} from '../schedules.styles'
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch} from "react-redux";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {TableActionsTypes} from "app/types/TableTypes";

interface Column {
  Header: string;
  accessor: string;
  style?: any;
  Cell?: any;
}

const columns: Column[] = [
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: 'Shkuese',
    accessor: 'shkuese',
    style: {'whiteSpace': 'unset'},
  },
  {
    Header: 'Kthyese',
    accessor: 'kthyese',
    style: {'whiteSpace': 'unset'},
  },
  {
    Header: 'Taksa',
    accessor: 'bill',
  },
  {
    Header: 'Total',
    accessor: 'total',
  }
];

interface Data {
  id: number;
  shkuese: any;
  kthyese: any;
  bill: any;
  total: any;
}

function createData(id: number, shkuese: any, kthyese: any, bill: any, total: any): Data {
  // const density = population / size;
  return {id, shkuese, kthyese, bill, total};
}

const rows = [
  createData(1, '24$', '85$', '70$', '196$'),
  createData(2, 'E premte 12', '220 $', '150$', '195$'),
];

const SchedulesForm = () => {
  const classes = schedulesFormStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      setLoading(false);
      return clearTimeout(timeout);
    }, 1600)
  }, [])

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: '#0D3B66'}}>Ndryshimi i informatave te pasagjerit:</h1>
        </div>
        <Formik
            initialValues={{
              currency: 'eur',
              code: 'prn',
              status: 'i rritur',
              gender: 'mashkull',
            }}
            onSubmit={(data) => {
              console.log(data);
            }}
        >
          {({values}) => (
              <Form>

                <div className={classes.row}>
                  <Field variant='outlined' name='airport' label='Aeroporti' as={TextField}/>
                  <Field variant='outlined' name='location' label='Lokacioni' as={TextField}/>
                </div>

                <div className={classes.row}>
                  <Field variant='outlined' value={values.status} name='status'
                         label='Statusi i pasagjerit' select as={TextField}>
                    <MenuItem value='adult'>I rritur</MenuItem>
                    <MenuItem value='adult'>I rritur</MenuItem>
                    <MenuItem value='adult'>I rritur</MenuItem>
                  </Field>
                  <Field variant='outlined' value={values.gender} name='gender'
                         label='Gjinia e pasagjerit' select as={TextField}>
                    <MenuItem value='Male'>Mashkull</MenuItem>
                    <MenuItem value='Female'>Femer</MenuItem>
                    <MenuItem value='Other'>Tjeter</MenuItem>
                  </Field>
                </div>

                <div className={classes.row}>
                  <Field variant='outlined' name='Firstname' label='Emri i pasagjerit'
                         as={TextField}/>
                  <Field variant='outlined' name='Lastname' label='Mbiemri i pasagjerit'
                         as={TextField}/>
                </div>

                <div className={classes.row}>
                  <Field variant='outlined' name='Birthday' label='Data e lindjes' as={TextField}/>
                  <Field variant='outlined' name='Nationality' label='Shtetesia' as={TextField}/>
                </div>
                <div className={classes.row}>
                  <BaseTable columns={columns} data={rows} isLoading={loading}/>
                </div>

                <div className={classes.row}>
                  <Button style={{background: '#12518D', color: '#ffffff', marginRight: '6px'}}
                          type='submit'>Ruaj Ndryshimet</Button>
                  <Button style={{background: '#12518D', color: '#ffffff'}}
                          onClick={() => dispatch(toggleQuickPanel())}>Mbylle</Button>
                </div>
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default SchedulesForm;