import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React from "react";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {Field, Form, Formik} from "formik";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {Button} from "@material-ui/core";
import PageHeader from "../../shared-components/page-header/PageHeader";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {resetEmployeeScopesForm, setEmployeeScopesForm} from "../store/employee-scopes-form.slice";
import {employeesFormStyles} from "../employees.styles";
import {updateUserScopes} from "../store/update-user-scopes.slice";
import { resetEmployeeForm } from "../store/employee-form.slice";

const EmployeePermissions = () => {
    const classes = employeesFormStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {params}: any = useRouteMatch();
    const empLoading = useSelector((state: AppState) => state.employees.allEmployees.loading);
    const loading = useSelector((state: AppState) => state.employees.updateUserScopes.loading);
    const employeeForm = useSelector((state: AppState) => state.employees.employeeForm);
    const scopesForm = useSelector((state: AppState) => state.employees.employeeScopeForm)

    const handleChange = (value: string) => {
        dispatch(
            setEmployeeScopesForm({
                ...scopesForm,
                scopes: scopesForm.scopes.includes(value) ? scopesForm.scopes.filter(item => item !== value) : [...scopesForm.scopes, value]
            })
        )
    };

    if (empLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`Employee information ${" "} ${employeeForm.fullName}`}
                desc={t("app.main.agency.agentsList.agentEdit.desc", "To change the information, change the form below and click Save Changes")}
                backButton={() => {
                    dispatch(resetEmployeeScopesForm());
                    history.push("/employees/employees-list");
                }}
            />
            <div className={classes.formWrapper}>
                <Formik
                    initialValues={scopesForm}
                    onSubmit={() => {

                        dispatch(updateUserScopes({
                            id: params.id,
                            body: {
                                ...scopesForm
                            },
                            onSuccess: () => {
                                history.push("/employees/employees-list");
                                dispatch(resetEmployeeScopesForm());
                            },
                        }))
                    }}
                >
                    <Form>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <div className={classes.checkboxGroup} role="group">
                                    <label>
                                        <Field type="checkbox" name="FLIGHT_RESERVATION"
                                               checked={scopesForm.scopes.includes('FLIGHT_RESERVATION')}
                                               value='FLIGHT_RESERVATION'
                                               onChange={() => handleChange('FLIGHT_RESERVATION')}/>
                                        {t("app.main.agency.create.scopesInfo.label1", "Booking flights")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="ADD_TRAVELER"
                                               checked={scopesForm.scopes.includes('ADD_TRAVELER')}
                                               value='ADD_TRAVELER'
                                               onChange={() => handleChange('ADD_TRAVELER')}/>
                                        {t("app.main.agency.create.scopesInfo.label2", "Addition of passengers")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="DEACTIVATE_TRAVELER"
                                               checked={scopesForm.scopes.includes('DEACTIVATE_TRAVELER')}
                                               value='DEACTIVATE_TRAVELER'
                                               onChange={() => handleChange('DEACTIVATE_TRAVELER')}/>
                                        {t("app.main.agency.create.scopesInfo.label3", "Cancellation of passengers")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="CANCELLATION_OF_RESERVATION"
                                               checked={scopesForm.scopes.includes('CANCELLATION_OF_RESERVATION')}
                                               value='CANCELLATION_OF_RESERVATION'
                                               onChange={() => handleChange('CANCELLATION_OF_RESERVATION')}/>
                                        {t("app.main.agency.create.scopesInfo.label15", "Cancellation of reservation")}
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.checkboxGroup} role="group">
                                    <label>
                                        <Field type="checkbox" name="RESERVATION_DISCUSSION"
                                               checked={scopesForm.scopes.includes('RESERVATION_DISCUSSION')}
                                               value='RESERVATION_DISCUSSION'
                                               onChange={() => handleChange('RESERVATION_DISCUSSION')}/>
                                        {t("app.main.agency.create.scopesInfo.label5", "Booking note")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="UPDATE_TRAVELERS"
                                               checked={scopesForm.scopes.includes('UPDATE_TRAVELERS')}
                                               value='UPDATE_TRAVELERS'
                                               onChange={() => handleChange('UPDATE_TRAVELERS')}/>
                                        {t("app.main.agency.create.scopesInfo.label6", "Change of passengers")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="ACTIVITIES"
                                               checked={scopesForm.scopes.includes('ACTIVITIES')} value='ACTIVITIES'
                                               onChange={() => handleChange('ACTIVITIES')}/>
                                        {t("app.main.agency.create.scopesInfo.label8", "Activities")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="CONFIRM_VIBER"
                                               checked={scopesForm.scopes.includes('CONFIRM_VIBER')}
                                               value='CONFIRM_VIBER'
                                               onChange={() => handleChange('CONFIRM_VIBER')}/>
                                        {t("app.main.agency.create.scopesInfo.label4", "Viber confirmation")}
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.checkboxGroup} role="group">
                                    <label>
                                        <Field type="checkbox" name="ADD_DISCUSSION"
                                               checked={scopesForm.scopes.includes('ADD_DISCUSSION')}
                                               value='ADD_DISCUSSION'
                                               onChange={() => handleChange('ADD_DISCUSSION')}/>
                                        {t("app.main.agency.create.scopesInfo.label9", "Adding remarks")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="UPDATE_TRAVELER_PRICES"
                                               checked={scopesForm.scopes.includes('UPDATE_TRAVELER_PRICES')}
                                               value='UPDATE_TRAVELER_PRICES'
                                               onChange={() => handleChange('UPDATE_TRAVELER_PRICES')}/>
                                        {t("app.main.agency.create.scopesInfo.label10", "Passenger price change")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="SHOW_TRAVELERS"
                                               checked={scopesForm.scopes.includes('SHOW_TRAVELERS')}
                                               value='SHOW_TRAVELERS'
                                               onChange={() => handleChange('SHOW_TRAVELERS')}/>
                                        {t("app.main.agency.create.scopesInfo.label11", "Show/Don't show passengers")}
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.checkboxGroup} role="group">
                                    <label>
                                        <Field type="checkbox" name="UPDATE_CONTACT"
                                               checked={scopesForm.scopes.includes('UPDATE_CONTACT')}
                                               value='UPDATE_CONTACT'
                                               onChange={() => handleChange('UPDATE_CONTACT')}/>
                                        {t("app.main.agency.create.scopesInfo.label12", "Changing contactor")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="DELETE_TRAVELERS"
                                               checked={scopesForm.scopes.includes('DELETE_TRAVELERS')}
                                               value='DELETE_TRAVELERS'
                                               onChange={() => handleChange('DELETE_TRAVELERS')}/>
                                        {t("app.main.agency.create.scopesInfo.label13", "Deleting a segment")}
                                    </label>
                                    <label>
                                        <Field type="checkbox" name="UPDATE_TRAVELER_FLIGHTS"
                                               checked={scopesForm.scopes.includes('UPDATE_TRAVELER_FLIGHTS')}
                                               value='UPDATE_TRAVELER_FLIGHTS'
                                               onChange={() => handleChange('UPDATE_TRAVELER_FLIGHTS')}/>
                                        {t("app.main.agency.create.scopesInfo.label14", "Change of passenger flights")}
                                    </label>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify='flex-end' alignItems='center'
                              style={{marginTop: '20px'}}>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    btnTitle={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                                    size='medium'
                                    isLoading={loading}
                                />
                                <Button
                                    onClick={(e) => {
                                        dispatch(resetEmployeeForm());
                                        history.push('/employees/employees-list')
                                    }}>
                                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default EmployeePermissions;