import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {AirportService} from "app/services";
import {fetchAllAirports} from "./airports-list.slice";
import {AirportRequestBody} from "app/types/service-types/AirportServiceTypes";
import {errorMsg, successMsg} from "../../../../utils/MessageUtils";

interface UpdateAirportImgParams {
  id: string;
  backdropImage: any;
  onSuccess?: Function;
  onError?: Function;
}

export const updateAirportImg = (params: UpdateAirportImgParams) => async (dispatch: Dispatch) => {
  try {
    console.log(params)
    dispatch(setLoading(true));
    const response = await AirportService.updateAirportImg(params.id, params.backdropImage);
    console.log(response);
    if (response.status >= 200 || response.status < 300) {
      dispatch(setSuccess(true));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllAirports());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Image should not be greater than 1MB!' : 'Imazhi nuk duhet të jetë më i madh se 1MB!'));
    if (params.onError) params.onError();
    dispatch(setError(''));
  }
}

interface UpdateAirportStateParams {
  id: string;
  body: AirportRequestBody;
  onSuccess?: Function;
  onError?: Function;
}

export const updateAirport = (params: UpdateAirportStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AirportService.updateAirport(params.id, params.body);
    console.log(response);
    if (response.status >= 200 || response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Airport changed successfully!' : 'Aeroporti u ndryshua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllAirports());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface UpdateAirportSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: UpdateAirportSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/airports',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;