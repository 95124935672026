import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {AgentService} from "../../../services";
import {fetchAllAgents} from "./agents-list.slice";

interface ActivateAgentStateParams {
    id: string;
    onSuccess?: Function;
    onError?: Function;
}

export const activateAgent = (params: ActivateAgentStateParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await AgentService.activateAgent(params.id);
        if (response.status >= 200 && response.status < 300) {
            dispatch(setSuccess(true));
            dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Agent activated successfully!' : 'Agjenti u aktivizua me sukses!'));
            if (params.onSuccess) params.onSuccess();
            dispatch(setError(""));
            // @ts-ignore
            dispatch(fetchAllAgents());
        }
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
        if (params.onError) params.onError();
        dispatch(setError(err.data.message));
    }
};

interface CreateAgentSlice {
    success: boolean;
    error: string;
    loading: boolean;
}

const initialState: CreateAgentSlice = {
    error: '',
    success: false,
    loading: false
}

const stateSlice = createSlice({
    name: 'agent/activate-agent',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;