import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import DateUtils from "app/utils/DateUtils";
import {FilterProvisionsQueryParams
} from "../../../types/service-types/AgentTypes";
import {fetchAllProvisions} from "./provision-list.slice";

const removeFalsyAttributes = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      if (obj[prop] instanceof Date && !isNaN(Date.parse(obj[prop]))) {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      } else if (obj[prop] instanceof Date && isNaN(Date.parse(obj[prop]))) {
      }
      if (prop === "fromDate" || prop === "toDate") {
        const convertedDate = DateUtils.convertToString(obj[prop]);
        newObj[prop] = convertedDate;
      }
    }
  });
  return newObj;
};

export const filterProvisions =
    (id: string, queryParams: FilterProvisionsQueryParams) => async (dispatch: Dispatch) => {
      dispatch(setFilters(queryParams));

      // @ts-ignore
      dispatch(fetchAllProvisions(id, {currencyId: queryParams.currencyId, ...removeFalsyAttributes(queryParams)}));
    };

export const clearFilterProvisions = () => async (dispatch: Dispatch) => {
  dispatch(setFilters(initialState));
};

const initialState: FilterProvisionsQueryParams = {
  currencyId: "",
  fromDate: "",
  toDate: "",
};

const stateSlice = createSlice({
  name: "agentedit/provision-filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterProvisionsQueryParams>) =>
        action.payload,
  },
});

export const {setFilters} = stateSlice.actions;

export default stateSlice.reducer;