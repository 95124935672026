import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {AirportService} from "app/services";
import {fetchAllAirports} from "./airports-list.slice";
import {errorMsg, successMsg} from 'app/utils/MessageUtils';

interface DeleteAirportStateParams {
  id: string;
  onSuccess?: Function;
  onError?: Function;
}

export const deleteAirport = (params: DeleteAirportStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await AirportService.deleteAirport(params.id);
    if (response.status >= 200 || response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Airport deleted added!' : 'Aeroporti u fshi me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllAirports());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface DeleteAirportSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: DeleteAirportSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/airports',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;