import {combineReducers} from '@reduxjs/toolkit';

import quickPanel from 'app/main/shared-components/quick-panel/store';
import auth from 'app/auth/store';
import configurations from 'app/main/configurations/store';
import schedule from 'app/main/schedule/store';
import flights from 'app/main/flights/store';
import schedules from 'app/main/schedules/store';
import employees from 'app/main/employees/store';
import customers from 'app/main/customer/store';
import agents from 'app/main/agency/store';
import account from 'app/account/store';
import navbar from 'app/main/layout/store';
import reports from 'app/main/report/store';
import appReducers from "./App";

const createReducer = combineReducers({
  auth,
  quickPanel,
  configurations,
  appReducers,
  schedule,
  flights,
  schedules,
  employees,
  customers,
  agents,
  account,
  navbar,
  reports,
});

export default createReducer;