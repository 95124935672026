import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import useStyles from './reports-table.styles';
import CollapsibleTableRow from "./ReportsTableRow";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import {PagedReportsList} from "../../../types/service-types/PaymentMethodsTypes";
import {useTranslation} from "react-i18next";

interface IProps {
  data: PagedReportsList | null,
  loading: boolean
}

const ReportsTable = (props: IProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {data, loading} = props;

  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell
                  style={{fontWeight: 'bold', textAlign: 'center', width: '10px'}}>#####</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.report.reportList.date", "Date")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.report.reportList.responsible", "Responsible")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.schedules.payments.paymentMethod", "Payment method")}</TableCell>
              <TableCell style={{fontWeight: 'bold', textAlign: 'center'}}>{t("app.main.schedules.payments.amount", "Amount")}</TableCell>
              {/*<TableCell style={{fontWeight: 'bold', textAlign: 'center'}} >Actions</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <LoadingTableRow colSpan={6}/>
                : !data || data.content.length === 0 ? <NoDataTableRow colSpan={6}/>
                    : data.content.map((row) => (
                        <CollapsibleTableRow key={row.confirmationCode} row={row}/>
                    ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default ReportsTable;