import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";

export const smsSmtpStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1)
    }
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(1.5),
    margin: theme.spacing(1.5, 0)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: 1,
      margin: theme.spacing(0, .5)
    },
    padding: theme.spacing(2, 0)
  },
  checkboxWrapper: {
    padding: theme.spacing(1)
  }
}));