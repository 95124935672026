import axios from './axios.interceptors';
import {
  AddPassengerTypes,
  AddPaymentsTypes,
  AddSmsTypes, BasicTravellerInfo,
  CreateReservationBodyTypes,
  DisquisitionsTypes,
  EmailTypes,
  FilterReservationsQueryParams, FlightShowTypes,
  SplitPassengersTypes,
  TravellerTypes,
  UpdateReservationBodyTypes
} from "../types/service-types/ReservationTypes";

class ReservationServices {

  fetchAll = async (): Promise<any> => {
    try {
      const response = await axios.get('/api/reservations');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  fetchReservationActivities = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/reservations/${id}/activities`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  fetchTravelersActivities = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/reservations/${id}/travelers/activities`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  searchReservations = async (queryParams: FilterReservationsQueryParams): Promise<any> => {
    try {
      const response = await axios.get(`/api/reservations`, {params: queryParams});
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  updateReservation = async (id: string, body: UpdateReservationBodyTypes): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/${id}`, body);
    } catch (err) {
      throw err;
    }
  }

  deleteReservation = async (id: string, status: 'ACTIVE' | 'PENDING' | 'CANCELLED'): Promise<any> => {
    try {
      return await axios.patch(`/api/reservations/${id}`, {status});
    } catch (err) {
      throw err;
    }
  }

  cancelReservation = async (reservationId: string): Promise<any> => {
    try {
      return await axios.delete(`/api/reservations/${reservationId}`)
    } catch (err) {
      throw err;
    }
  }

  createReservation = async (body: CreateReservationBodyTypes): Promise<any> => {
    try {
      return await axios.post('/api/reservations', body);
    } catch (err) {
      throw err;
    }
  }

  updateStatusPassenger = async (reservationId: string, travelerId: string, status: boolean): Promise<any> => {
    try {
      return await axios.patch(`/api/reservations/${reservationId}/travelers/${travelerId}?status=${status}`);
    } catch (err) {
      throw err;
    }
  }

  updatePassenger = async (id: string, body: TravellerTypes): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/travelers/${id}`, body);
    } catch (err) {
      throw err;
    }
  }

  updatePassengerBasicInfo = async (id: string, body: BasicTravellerInfo): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/travelers/${id}`, body);
    } catch (err) {
      throw err;
    }
  }

  updateDepartureFlight = async (id: string, departureFlight: string): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/travelers/${id}/departureFlight`, departureFlight);
    } catch (err) {
      throw err;
    }
  }

  updateDepartureFlightPrice = async (id: string, departureFlightPrice: number): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/travelers/${id}/departurePrice`, departureFlightPrice);
    } catch (err) {
      throw err;
    }
  }

  updateReturnFlight = async (id: string, returnFlight: string): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/travelers/${id}/returnFlight`, returnFlight);
    } catch (err) {
      throw err;
    }
  }

  updateReturnFlightPrice = async (id: string, returnFlightPrice: number): Promise<any> => {
    try {
      return await axios.put(`/api/reservations/travelers/${id}/returnPrice`, returnFlightPrice);
    } catch (err) {
      throw err;
    }
  }

  splitPassengers = async (id: string, body: SplitPassengersTypes): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${id}/split`, body)
    } catch (err) {
      throw err;
    }
  }

  addPayment = async (id: string, body: AddPaymentsTypes): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${id}/payments`, body)
    } catch (err) {
      throw err;
    }
  }

  addDisquisition = async (id: string, body: DisquisitionsTypes): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${id}/disquisitions`, body)
    } catch (err) {
      throw err;
    }
  }

  fetchReservationPayments = async (id: string): Promise<any> => {
    try {
      const response = await axios.get(`/api/reservations/${id}/payments`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  addPassenger = async (id: string, body: AddPassengerTypes): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${id}/travelers`, body)
    } catch (err) {
      throw err;
    }
  }

  deleteTravelerFromReservation = async (reservationId: string, travelerId: string, status: boolean): Promise<any> => {
    try {
      return await axios.patch(`/api/reservations/${reservationId}/travelers/${travelerId}?status=${status}`);
    } catch (err) {
      throw err;
    }
  }

  deleteFlight = async (travelerId: string, flight: string): Promise<any> => {
    try {
      return await axios.delete(`/api/reservations/${travelerId}/flights/${flight}`)
    } catch (err) {
      throw err;
    }
  }

  disableFlightFromTraveler = async (travelerId: string, flight: string) => {
    try {
      return await axios.patch(`/api/reservations/travelers/${travelerId}/flights/${flight}/status/disable`)
    } catch (err) {
      throw err;
    }
  }

  editDepartureFlightShow = async (travelerId: string, body: FlightShowTypes) => {
    try {
      return await axios.patch(`/api/reservations/travelers/${travelerId}/flights/departureFlight/showed`, body)
    } catch (err) {
      throw err;
    }
  }

  editFlightShow = async (travelerId: string, flight: string, body: FlightShowTypes) => {
    try {
      return await axios.patch(`/api/reservations/travelers/${travelerId}/flights/${flight}/showed`, body)
    } catch (err) {
      throw err;
    }
  }

  addSms = async (id: string, body: AddSmsTypes): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${id}/sms`, body)
    } catch (err) {
      throw err;
    }
  }

  addEmail = async (id: string, body: EmailTypes): Promise<any> => {
    try {
      return await axios.post(`/api/reservations/${id}/email`, body)
    } catch (err) {
      throw err;
    }
  }
}

export default new ReservationServices();
