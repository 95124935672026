import React from "react";
import {Drawer} from "@material-ui/core";
import useStyles from "./quick-panel.styles";
import {useSelector} from "react-redux";
import {AppState} from "app/store";
import AirportForm from "app/main/configurations/airports-list/AirportForm";
import PaymentMethodsForm from "app/main/configurations/payment-methods/PaymentMethodsForm";
import {Switch} from "react-router-dom";
import {Route} from "react-router";
import AirlineCompaniesForm from "app/main/configurations/airline-companies/AirlineCompaniesForm";
import SchedulesForm from "app/main/schedules/schedules-list/SchedulesForm";
import CreateFlightForm from "app/main/flights/create/CreateFlightForm";
import SupervisorUpdateForm from "app/main/schedules/update/SupervisorUpdateForm";
import CreateEmployeeForm from "app/main/employees/create/CreateEmployeeForm";
import UpdateEmployeeForm from "app/main/employees/update/UpdateEmployeeForm";
import CreateCustomerForm from "app/main/customer/create/CreateCustomerForm";
import UpdateCustomerForm from "app/main/customer/update/UpdateCustomerForm";
import UpdateFlightForm from "../../flights/update/UpdateFlightForm";
import UpdatePassengerFlight
  from "app/main/schedules/update-passenger-flight/UpdatePassengerFlight";
import UpdateTravelerFlight from "app/main/schedules/update-traveler-flight/UpdateTravelerFlight";
import SplitPassengers from "app/main/schedules/split-passengers/SplitPassengers";
import AddPassengerReturnFlight
  from "app/main/schedules/add-passenger-return-flight/AddPassengerReturnFlight";
import EnableTravelerFlight from "app/main/schedules/enable-traveler-flight/EnableTravelerFlight";
import Disquisitions from "app/main/schedules/disquisitions/Disquisitions";
import AddPassenger from "app/main/schedules/add-passenger/AddPassenger";
import Sms from "app/main/schedules/sms/Sms";
import Email from "app/main/schedules/email/Email";
import CreateAgent from "app/main/agency/create/CreateAgent";

import AddPayment from "app/main/schedules/payments/AddPayment";
import UpdateAgent from "app/main/agency/update/UpdateAgent";
import CountryForm from "../../configurations/countries/CountryForm";
import CreatePaymentForm from "../../agency/components/create-payment/CreatePaymentForm";
import UpdatePaymentForm from "../../agency/components/update-payment/UpdatePaymentForm";
import SmsTemplatesForm from "../../configurations/sms-templates/SmsTemplatesForm";

const QuickPanel = () => {
  const classes = useStyles();
  const open = useSelector((state: AppState) => state.quickPanel);

  const pathParts = window.location.pathname.split("/");
  const urlResult = `${pathParts[2]}`;
  return (
      <Drawer classes={{paper: classes.root}} open={open} anchor="right">
        <Switch>
          <Route
              path="/configurations/payment-methods/create"
              component={PaymentMethodsForm}
          />
          <Route
              path="/configurations/payment-methods/update/:id"
              component={PaymentMethodsForm}
          />
          <Route
              path="/configurations/airports-list/create"
              component={AirportForm}
          />
          <Route
              path="/configurations/airports-list/update/:id"
              component={AirportForm}
          />
          <Route
              path="/configurations/airline-companies/create"
              component={AirlineCompaniesForm}
          />
          <Route
              path="/configurations/airline-companies/update/:id"
              component={AirlineCompaniesForm}
          />
          <Route
              path={`/schedules/${urlResult}/create`}
              component={SchedulesForm}
          />
          <Route
              path={`/schedules/${urlResult}/update-supervisor/:id`}
              component={SupervisorUpdateForm}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/update-supervisor/:id`}
              component={SupervisorUpdateForm}
          />
          <Route
              path={`/schedules/${urlResult}/update-passenger-flight/:id`}
              component={UpdatePassengerFlight}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/update-passenger-flight/:id`}
              component={UpdatePassengerFlight}
          />
          <Route
              path={`/schedules/${urlResult}/update-traveler-flight/:id`}
              component={UpdateTravelerFlight}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/update-traveler-flight/:id`}
              component={UpdateTravelerFlight}
          />

          <Route
              path={`/schedules/${urlResult}/split-passengers/:id`}
              component={SplitPassengers}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/split-passengers/:id`}
              component={SplitPassengers}
          />
          <Route
              path={`/schedules/${urlResult}/add-passenger-return-flight/:id`}
              component={AddPassengerReturnFlight}
          />
          <Route
              path={`/schedules/${urlResult}/enable-traveler-flight/:id`}
              component={EnableTravelerFlight}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/add-passenger-return-flight/:id`}
              component={AddPassengerReturnFlight}
          />
          <Route
              path={`/schedules/${urlResult}/add-passenger/:id`}
              component={AddPassenger}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/add-passenger/:id`}
              component={AddPassenger}
          />
          <Route
              path={`/schedules/${urlResult}/disquisitions/:id`}
              component={Disquisitions}
          />
          <Route
              path={`/flightsedit/${urlResult}/reservations/disquisitions/:id`}
              component={Disquisitions}
          />
          <Route path={`/schedules/${urlResult}/sms/:id`} component={Sms}/>
          <Route
              path={`/flightsedit/${urlResult}/reservations/sms/:id`}
              component={Sms}
          />
          <Route path={`/schedules/${urlResult}/email/:id`} component={Email}/>
          <Route
              path={`/flightsedit/${urlResult}/reservations/email/:id`}
              component={Email}
          />
          <Route
              path={`/flights/${urlResult}/create`}
              component={CreateFlightForm}
          />
          <Route
              path={`/flights/${urlResult}/update/:id`}
              component={UpdateFlightForm}
          />
          <Route
              path="/employees/employees-list/create"
              component={CreateEmployeeForm}
          />
          <Route
              path="/employees/employees-list/update/:id"
              component={UpdateEmployeeForm}
          />
          <Route
              path="/customer/customers-list/create"
              component={CreateCustomerForm}
          />
          <Route
              path="/customer/customers-list/update/:id"
              component={UpdateCustomerForm}
          />

          <Route
              path={`/schedules/${urlResult}/payments/:id`}
              component={AddPayment}
          />

          <Route
              path={`/flightsedit/${urlResult}/reservations/payments/:id`}
              component={AddPayment}
          />

          <Route path="/agents-agencies/list/create" component={CreateAgent}/>

          <Route
              path="/agents-agencies/list/update/:id"
              component={UpdateAgent}
          />
          <Route
              path="/configurations/countries/create"
              component={CountryForm}
          />

          <Route
              path="/configurations/countries/update/:id"
              component={CountryForm}
          />
          <Route
              path="/configurations/sms-templates/create"
              component={SmsTemplatesForm}
          />
          <Route
              path="/configurations/sms-templates/update/:id"
              component={SmsTemplatesForm}
          />
          <Route
              path="/agentedit/:id/payments/:currencyId/createPayment"
              component={CreatePaymentForm}
          />

          <Route
              path="/agentedit/:id/payments/:currencyId/updatePayment/:rowId"
              component={UpdatePaymentForm}
          />
        </Switch>
      </Drawer>
  );
};

export default QuickPanel;
