import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import {clearFilterProvisions, filterProvisions} from "../../agency/store/filter-provision.slice";
import {fetchAllProvisions} from "../../agency/store/provision-list.slice";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import PageHeader from "../../shared-components/page-header/PageHeader";
import AgentProvisionSearchPanel from "../../agency/components/search-panel/AgentProvisionSearchPanel";
import AgentProvisionTable from "../../agency/components/agent-table/AgentProvisionTable";

export interface ProvisionListType {
    currencyResponse: {
        id: string;
        isoCode: string;
        name: string;
        symbol: string;
    }
}

const AgencyReportsProvisionList = (prop: ProvisionListType) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {params}: any = useRouteMatch();
    const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
    const {provisionList, loading} = useSelector((state: AppState) => state.agents.provisionsList);
    const filtersProvision = useSelector((state: AppState) => state.agents.filterProvision);
    const account = useSelector((state: AppState) => state.account.account);

    useEffect(() => {
        console.log('filter', filtersProvision)
        dispatch(clearFilterProvisions());
        dispatch(filterProvisions(account.account!.id, {...filtersProvision, currencyId: params.currencyId}));
        dispatch(fetchAllProvisions(account.account!.id, {currencyId: params.currencyId}));
    }, [params]);

    if (agentLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`${t("app.main.agency.edit.provision.title1", "Provision")} - ${account.account!.balanceResponse[0].currencyResponse?.isoCode} ${account.account!.fullName} `}
                desc={t("app.main.agency.edit.provision.desc", "History of provision transactions")}
                backButton={() => {
                    history.push("/agency-reports");
                }}
            />
            <AgentProvisionSearchPanel/>
            <AgentProvisionTable data={provisionList} loading={loading}/>
        </div>
    );
};

export default AgencyReportsProvisionList;