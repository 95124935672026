import React, {useEffect, useState} from "react";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import history from "@history";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useRouteMatch} from "react-router-dom";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {Column} from "../../../types/TableTypes";
import {getPassengersFlight} from "../store/flights-list/get-passengers.slice";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactToPrint from "react-to-print";
import {getReservationsFlight} from "../store/flights-list/get-reservations.slice";
import {useTranslation} from "react-i18next";

const FlightsPassList = (props: any) => {
  const dispatch = useDispatch();
  const {params}: any = useRouteMatch();
  const {
    passengersFlight,
    loading
  } = useSelector((state: AppState) => state.flights.flightsList.passengersFlight);
  const resFlight = useSelector((state: AppState) => state.flights.flightsList.reservationsFlight.reservationsFlight);
  const flight = useSelector((state: AppState) => state.flights.flight.flight);
  const [modal, setModal] = useState({
    state: false,
    deleteId: ''
  });
  const {t} = useTranslation();
  const columns: Column[] = [
    {
      Header: '#',
      Cell: ({row}: { row: any }) => (row.index + 1 + ').'),
      accessor: 'index',
    },
    // {
    //     Header: '#',
    //     accessor: 'id'
    // },
    {
      Header: `${t( "app.main.schedules.splitPassenger.cell.passenger", "Passenger")}`,
      accessor: 'name',
      Cell: ({cell}: any) => {
        const {name, surname} = cell.row.original;
        return <div><b>{name} {surname}</b></div>
      }
    },
    // {
    //     Header: 'Mbiemri',
    //     accessor: 'surname',
    // },
    {
      Header: `${t( "app.main.schedules.updateTravelerFlight.age", "Age")}`,
      accessor: 'ageCategory',
    },
    // {
    //     Header: 'Actions',
    //     accessor: 'id',
    //     Cell: ({ cell }: any) => (
    //         <TableActionButtons {...tableActions} rowId={cell.value} />
    //     )
    // }
  ];


  useEffect(() => {
    dispatch(getReservationsFlight({id: params.id}));
    dispatch(getPassengersFlight({id: params.id}));
    if (!flight) {
      history.push({
        pathname: `/flightsedit/${params.id}/edit`,
        state: {
          id: params.id
        }
      })
    }
  }, []);


  const input = document.getElementById('divToPrint');

  // @ts-ignore
  // @ts-ignore
  return (
      <div>
        <PageHeader
            title={t("app.main.sidebar.flight.title19", "List of passengers")}
            desc={t("app.main.sidebar.flight.title19", "List of passengers")}
            secButton={<ReactToPrint
                trigger={() => {
                  return <PictureAsPdfIcon style={{fontSize: '2.5rem'}}/>
                }}
                content={() => input}/>}
        />
        <div id="divToPrint">
          {resFlight[0]?.travelers[0] &&
              <div>
                <h2 style={{textAlign: 'center', marginTop: '2rem'}}><span>Fluturimi: </span>
                  <span><b>{flight?.departureAirportCode}</b></span> <span>{'->'} </span>
                  <span><b>{flight?.arrivalAirportCode}</b></span></h2>
                <h3 style={{textAlign: 'center', margin: '0.5rem 0 1rem 0'}}>{'Numri i fluturimit:'}
                  <b>{'#'}{flight?.number}</b></h3></div>}
          <BaseTable columns={columns} data={passengersFlight} isLoading={loading}/>
        </div>
      </div>
  );
};

export default FlightsPassList;