import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {DecodedTokenType} from "app/types/UserTypes";
import {setIsLoading} from './login.slice';
import AuthService from 'app/services/auth.service';
import {setAccount} from "app/account/store/get-account.slice";

interface SetUserDataParams {
  user: DecodedTokenType | null;
  callback?: Function;
}

export const setUserData = (params: SetUserDataParams) => async (dispatch: Dispatch) => {
  dispatch(setUser(params.user));
  if (params.callback) params.callback();
}

export const logoutUser = () => async (dispatch: Dispatch) => {
  dispatch(userLoggedOut());
  dispatch(setIsLoading(false));
  AuthService.logout();
  // @ts-ignore
  dispatch(setUserData({user: null}));
  dispatch(setAccount(null))
}

const initialState: DecodedTokenType | null = null;

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state) => initialState
  },
  extraReducers: {}
});

export const {setUser, userLoggedOut} = userSlice.actions;

export default userSlice.reducer;