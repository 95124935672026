import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {setUserData} from "./user.slice";
import {UserLoginTypes} from "app/types/UserTypes";
import AuthService from 'app/services/auth.service';
import {getAccount} from 'app/account/store/get-account.slice';

export const submitLogin = ({email, password}: UserLoginTypes) => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const user = await AuthService.signInWithEmailAndPassword({email, password});
    // @ts-ignore
    const isCustomer = user?.authorities.every((auth, index, array) => {
      return auth === 'ROLE_CUSTOMER' && array.length === 1
    });
    if (user && !isCustomer) {
      AuthService.setSession(user.access_token, user.refresh_token);
      // @ts-ignore
      dispatch(setUserData({user}));
      // @ts-ignore
      dispatch(getAccount())
      return dispatch(loginSuccess());
    } else {
      dispatch(setIsLoading(false));
      return dispatch(loginError('Error while loggin'));
    }
  } catch (error) {
    dispatch(setIsLoading(false));
    return dispatch(loginError(error.data.error_description));
  }
}

const initialState = {
  isLoading: false,
  success: false,
  error: ''
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state) => {
      state.success = true;
      state.error = ''
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    loginError: (state, action) => {
      state.success = false;
      state.error = action.payload;
    }
  },
  extraReducers: {}
});

export const {loginSuccess, loginError, setIsLoading} = loginSlice.actions;

export default loginSlice.reducer;