import React, {useEffect, useState} from 'react';

import {Button, TextField,} from '@material-ui/core';

import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';

import {airlineFormStyles} from './airline-companies.styles';
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {createAirline} from "../store/airlines/create-airline.slice";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AirlineRequestBody} from "../../../types/service-types/AirlineCompaniesTypes";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {updateAirline} from "../store/airlines/update-airline.slice";
import {useTranslation} from "react-i18next";


const validationSchema = yup.object({
  name: yup.string().required(),
  additionalInformation: yup.string(),
})

const AirlineCompaniesForm = () => {
  const classes = airlineFormStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  let {params} = useRouteMatch();

  const airlineCompaniesState = useSelector((state: AppState) => state.configurations.airlines.airlineCompanies);
  const createAirlinesState = useSelector((state: AppState) => state.configurations.airlines.createAirline);
  const updateAirlinesState = useSelector((state: AppState) => state.configurations.airlines.updateAirline);

  const [loading, setLoading] = useState(true);

  const [singleAirline, setSingleAirline] = useState<AirlineRequestBody>({
    name: '',
    additionalInformation: '',
  });

  const [componentTitle, setComponentTitle] = useState(`${t("app.main.agency.agentsList.registerButton", "Register")}`);

  useEffect(() => {
    const {airlineCompanies} = airlineCompaniesState;

    if (params) {
      // @ts-ignore
      const foundedAP = airlineCompanies.find(ap => ap.id === params.id);
      if (foundedAP) {
        setComponentTitle(`${t( "app.main.configuration.airlineCompanies.create.title1", "Change")}`);

        setSingleAirline({
          name: foundedAP.name,
          additionalInformation: foundedAP.additionalInformation
        });
      }
      setLoading(false);
    } else setLoading(false);
  }, []);

  if (loading) return <LoadingForm/>

  const onSubmitFormRequest = (data: AirlineRequestBody) => {

    const {airlineCompanies} = airlineCompaniesState;

    if (params) {
      // @ts-ignore
      const foundedAP = airlineCompanies.find(ap => ap.id === params.id);
      // update
      if (foundedAP) {
        const id = foundedAP.id ? foundedAP.id : '';
        dispatch(updateAirline({
          id,
          body: data,
          onSuccess: () => dispatch(toggleQuickPanel())
        }))
      } else {
        dispatch(createAirline({
          body: data
        }));
      }
    }
  }


  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader}>
          <h1 style={{color: '#0D3B66'}}>{componentTitle} {t("app.main.configuration.airlineCompanies.create.label.title", "airline company")}:</h1>
        </div>
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={singleAirline}
            onSubmit={onSubmitFormRequest}
        >
          {({values}) => (
              <Form>

                <div className={classes.row}>
                  <Field variant='outlined'
                         name='name'
                         label={t("app.main.configuration.airlineCompanies.create.input.companyName", "Name of the Company")}
                         as={TextField}
                         required/>
                </div>

                <div className={classes.row}>
                  <Field variant='outlined'
                         name='additionalInformation'
                         label={t("app.main.configuration.airlineCompanies.table.tableRow4", "Other informations")}
                         multiline
                         rows={5}
                         as={TextField}/>
                </div>

                <div className={classes.row}>
                  <LoadingButton
                      type='submit'
                      btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                      isLoading={
                          createAirlinesState.loading || updateAirlinesState.loading
                      }/>
                  <Button onClick={(e) => {
                    // e.stopPropagation();
                    dispatch(toggleQuickPanel())
                    history.replace('/configurations/airline-companies')
                  }}>
                    {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                  </Button>
                </div>
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default AirlineCompaniesForm;
