import {combineReducers} from '@reduxjs/toolkit';
import allFlights from './flights-list.slice'
import createFlight from './create-flight.slice'
import deleteFlight from './delete-flight.slice';
import updateFlight from './update-flight.slice';
import filterFlights from './filter-flights.slice';
import flightForm from './flight-form.slice';
import passengersFlight from './get-passengers.slice';
import reservationsFlight from './get-reservations.slice';
import exportFlight from './export-flight.slice';

const reducer = combineReducers({
  allFlights,
  createFlight,
  deleteFlight,
  updateFlight,
  filterFlights,
  flightForm,
  passengersFlight,
  reservationsFlight,
  exportFlight
});

export default reducer;
