import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Grid, Icon, InputAdornment, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import PageHeader from "../../shared-components/page-header/PageHeader";
import ConfigurationServices from "../../../services/configuration.services";
import {setSuccess} from "../../agency/store/update-agent.slice";
import {errorMsg, successMsg} from "../../../utils/MessageUtils";
import {setError} from "../../customer/store/create-costumer.slice";
import {useDispatch} from "react-redux";
import {setAgentLogoImage} from "../../agency/store/agent-logo-image.slice";
import history from "../../../../@history/@history";
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";

const UploadLogoPage = () => {
    const {t} = useTranslation();
    const [logoImage, setLogoImage] = useState<FormData | null>(null);
    const [initialImageUrl, setInitialImageUrl] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        ConfigurationServices.getConfigurationLogo()
            .then((response) => {
                setInitialImageUrl(response.logoImageUrl);
                setLogoImage(response.logoImageUrl);
            })
            .catch((error) => {
                console.error("Error fetching initial image:", error);
            });

    }, []);

    const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'logoImageUrl') {
            const file = e.target.files?.[0];
            let reader = new FileReader() as any;
            let url = reader.readAsDataURL(file);
            reader.onloadend = function (e: any) {
                setInitialImageUrl(reader.result);
            }
            if (file) {
                const data = new FormData();
                data.append('logoImage', file);
                setLogoImage(data);
            }
        }
    };

    const handleSaveButtonClick = () => {
        if (logoImage) {
            ConfigurationServices.uploadConfigurationLogo(logoImage)
                .then((response) => {
                    dispatch(setSuccess(true));
                    dispatch(successMsg("Logo u ngarkua me sukses"));
                })
                .catch((error) => {
                    dispatch(errorMsg(error.data.message));
                    dispatch(setError(error.data.message));
                });
        }
    };

    return (
        <div>
            <PageHeader
                title={t("app.main.configurations.upload-logo.title", "Upload Logo")}
                desc={t("app.main.configurations.upload-logo.desc", "If you want to add a new logo, click Choose File")}
                button={t("app.main.flights.flightList.editPage.saveChanges", "Save Changes")}
                onClickButton={() => {
                    handleSaveButtonClick()
                }}
            />
            <div style={{whiteSpace: 'break-spaces', marginTop: '100px'}}>
                <div style={{position: 'relative'}}>
                    <Formik
                        initialValues={
                            {}
                        }
                        onSubmit={(data) => {
                        }}
                    >
                        {({values, setFieldValue}) => (
                            <Form>
                                <Grid container spacing={2} style={{justifyContent: 'end'}}>
                                    <Grid item xs={4}>
                                        <Field
                                            style={{width: '100%'}}
                                            onChange={handleLogoImageChange}
                                            type="file"
                                            variant='outlined'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <Icon style={{color: '#45637E'}}>upload_file</Icon>
                                                    </InputAdornment>
                                                )
                                            }}
                                            size='small'
                                            name='logoImageUrl'
                                            as={TextField}
                                        />
                                    </Grid>
                                    <Grid item xs={4} style={{backgroundColor: 'rgb(231, 231, 231)'}}>
                                        {(initialImageUrl) && (
                                            <img
                                                className="logo-image"
                                                src={initialImageUrl}
                                                alt="Uploaded Logo"
                                                style={{maxWidth: '100%', maxHeight: '200px'}}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default UploadLogoPage;
