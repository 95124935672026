import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AgentService} from "app/services";
import {FilterAgentsQueryParams, PagedAgentList} from 'app/types/service-types/AgentTypes';
import {errorMsg} from "../../../utils/MessageUtils";

export const fetchAllAgents = (queryParams?: FilterAgentsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await AgentService.filterAgents(queryParams);
    } else response = await AgentService.fetchAll();
    dispatch(setAgentsList(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface AgentsListSlice {
  allAgents: PagedAgentList | null;
  loading: boolean;
}

const initialState: AgentsListSlice = {
  allAgents: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'agent/agent-list',
  initialState,
  reducers: {
    setAgentsList: (state, action) => {
      state.allAgents = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
});

export const {setAgentsList, setLoading} = stateSlice.actions;

export default stateSlice.reducer;