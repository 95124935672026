import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";

const getInitialState = () => ({
  id: '',
  fullName: '',
  email: '',
  contactEmail: '',
  location: '',
  companyName: '',
  phoneNumber: '',
  logoImageUrl: "",
  password: '',
  address: '',
  invoiceText: '',
  invoiceBottomText: '',
  maxAdultAllowed: 0,
  maxChildAllowed: 0,
  maxInfantAllowed: 0,
  allowInfantOnly: false,
  discountType:'NO_DISCOUNT',
  provisionForms: [
    {
      id: Math.random().toString(),
      provisionPercentage: 0,
      neto: 0,
      minimumProvision: 0,
      maximumProvision: 0,
      fixedProvision: 0,
      minPrice: 0,
      currencyId: ""
    }
  ],
  provisionPercentage: 0,
  provisionFormType: 'PERCENTAGE',
  balanceRequests: [
    {
      id: Math.random().toString(),
      availableCredit: 0,
      creditLimit: 0,
      neto: 0,
      minimumTicketPrice: 0,
      currencyId: ''
    }
  ],
  allowedDiscount: 0,
  actualDebt: '',
  debtBeforeEnding: 0,
  discounts: [
    {
      id: Math.random().toString(),
      maxDiscount: 0,
      automaticDiscount: 0,
      minimumTicketPrice: 0,
      currencyId: ""
    }
  ],
  scopes: [] as string[],
  status: true,
  isOperatorLogo: true
});

const newBalanceRequest = (defaultCurrencyId: string = '') => ({
  id: Math.random().toString(),
  creditLimit: 0,
  availableCredit: 0,
  neto: 0,
  minimumTicketPrice: 0,
  currencyId: defaultCurrencyId
});
const newProvisionRequest = (defaultCurrencyId: string = '') => ({
  id: Math.random().toString(),
  provisionPercentage: 0,
  neto: 0,
  minimumProvision: 0,
  maximumProvision: 0,
  fixedProvision: 0,
  minPrice: 0,
  currencyId: defaultCurrencyId
});
const newDiscountRequest = (defaultCurrencyId: string = '') => ({
  id: Math.random().toString(),
  maxDiscount: 0,
  automaticDiscount: 0,
  minimumTicketPrice: 0,
  currencyId: defaultCurrencyId
});

interface SetAgentBalanceRequest {
  id: string;
  availableCredit: number,
  creditLimit: number;
  neto: number;
  minimumTicketPrice: number;
  currencyId: string;
  onSuccess?: Function;
}

export const setTheAgentForm = (payload: any) => (dispatch: Dispatch) => {
  dispatch(setAgentForm(payload));
};

export const setTheAgentBalanceRequest = (payload: SetAgentBalanceRequest) => (dispatch: Dispatch) => {
  dispatch(setAgentBalanceRequest(payload))
}

export const removeTheBalanceRequest = (id: string) => (dispatch: Dispatch) => {
  dispatch(removeBalanceRequest(id));
};

export const addNewBlankBalanceRequest = (defaultCurrencyId: string = '') => (dispatch: Dispatch) => {
  dispatch(addNewBalanceRequest(defaultCurrencyId));
};

export const resetTheAgentForm = () => (dispatch: Dispatch) => {
  dispatch(resetAgentForm());
};


const stateSlice = createSlice({
  name: "agents/agent-form",
  initialState: getInitialState(),
  reducers: {
    setAgentForm: (state, action) => action.payload,
    fillAgentFormField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      return {
        ...state,
        [field]: value
      }
    },
    fillProvisionField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      const id = action.payload.id;
      // @ts-ignore
      state.provisionForms = state.provisionForms.map((provision => {
        if (provision.id === id) {
          return {
            ...provision,
            [field]: value
          }
        } else return provision;
      }))
    },
    removeProvision: (state, action) => {
      if (state.provisionForms.length === 1) return;
      // @ts-ignore
      state.provisionForms = state.provisionForms.filter((item) => item.id !== action.payload);
    },
    addNewProvision: (state, action) => {
      state.provisionForms.push(newProvisionRequest(action.payload));
    },
    resetProvisionForm: (state, action) => {
      state.provisionForms = [newProvisionRequest(action.payload)];
    },
    fillDiscountField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      const id = action.payload.id;
      // @ts-ignore
      state.discounts = state.discounts.map((discount => {
        if (discount.id === id) {
          return {
            ...discount,
            [field]: value
          }
        } else return discount;
      }))
    },
    removeDiscount: (state, action) => {
      if (state.discounts.length === 1) return;
      // @ts-ignore
      state.discounts = state.discounts.filter((item) => item.id !== action.payload);
    },
    addNewDiscount: (state, action) => {
      state.discounts.push(newDiscountRequest(action.payload));
    },
    resetDiscount: (state, action) => {
      state.discounts = [newDiscountRequest(action.payload)];
    },
    setAgentBalanceRequest: (state, action: PayloadAction<SetAgentBalanceRequest>) => {
      state.balanceRequests = state.balanceRequests.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload,
          }
        } else return item;
      })
    },
    fillBalanceField: (state, action) => {
      const value = action.payload.value;
      const field = action.payload.field;
      const id = action.payload.id;
      // @ts-ignore
      state.balanceRequests = state.balanceRequests.map((balance => {
        if (balance.id === id) {
          return {
            ...balance,
            [field]: value
          }
        } else return balance;
      }))
    },

    removeBalanceRequest: (state, action) => {
      if (state.balanceRequests.length === 1) return;
      state.balanceRequests = state.balanceRequests.filter((item) => item.id !== action.payload);
    },
    addNewBalanceRequest: (state, action) => {
      state.balanceRequests.push(newBalanceRequest(action.payload));
    },

    resetAgentForm: () => getInitialState(),
  },
});

export const {
  setAgentForm,
  setAgentBalanceRequest,
  removeBalanceRequest,
  addNewBalanceRequest,
  fillBalanceField,
  resetAgentForm,
  fillAgentFormField,
  fillProvisionField,
  removeProvision,
  addNewProvision,
  resetProvisionForm,
  fillDiscountField,
  removeDiscount,
  addNewDiscount,
  resetDiscount
} = stateSlice.actions;

export default stateSlice.reducer;
