import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {ExportFlightSkyScanner} from "app/types/service-types/FlightTypes"
import {FlightService} from "app/services"
import {errorMsg} from "app/utils/MessageUtils";
import FileSaver from "file-saver";

interface ExportFlightStateParams {
  body: ExportFlightSkyScanner;
  onSuccess?: Function;
  onError?: Function;
}

export const exportFlight = (params: ExportFlightStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FlightService.exportFlight(params.body);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
    }
    dispatch(setLoading(false));
    var blob = new Blob([JSON.stringify(response)], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, 'flights.json');
    console.log(response, 'response')
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface ExportFlightSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: ExportFlightSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'flights/create-flight',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;