import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    width: '84%'
  },
  scroll: {
    height: '100%'
  },
  extendedWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {flex: 1}
  }
}));