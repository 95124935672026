import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const baseTableStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    'rt-td': {
      whiteSpace: 'normal',
    }
  },
  container: {
    // height: 500,
    maxHeight: '100%',
  },
  rowRow: {
    padding: '4px',
    fontSize: '12px',
  },
  tableRowHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,.2)'
    }
  },
  tableRow:

      {
        '& > *': {
          padding: '4px',
          fontSize: '12px',
        },
        '&:hover':
            {
              cursor: 'default'
            }
      },
  tableRowDisabled: {
    backgroundColor: '#ececec',
    textAlign: 'center'
  },
  tableCellDisabled: {
    color: '#bcbbb9',
    textAlign: 'center'
  },
  tableRowActive: {
    backgroundColor: '#dde8d7',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tableRowSelected: {backgroundColor: 'rgba(148,217,125,0.5)'},
  center: {
    textAlign: 'center'
  }
}));

export const tableActionButtonsStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    margin: theme.spacing(0, .8),
    backgroundColor:'rgb(224,224,224)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:'#bcbbb9'
    }
  },

}));