import SchedulesPage from './SchedulesPage';
import {PageConfigTypes} from "app/types/PageTypes";
import SchedulesListPage from "app/main/schedules/schedules-list/SchedulesListPage";
import SchedulesListPageFullPaid from "app/main/schedules/schedules-list/SchedulesListPageFullPaid";
import SchedulesListPageDept from "app/main/schedules/schedules-list/SchedulesListPageDept";
import ScheduleListPageRefund from "app/main/schedules/schedules-list/ScheduleListPageRefund";
import ScheduleListPageNotRefund from "app/main/schedules/schedules-list/ScheduleListPageNotRefund";
import ActivitiesPage from "./activities/ActivitiesPage";
import ScheduleListPageDisabled from "./schedules-list/ScheduleListPageDisabled";
import ScheduleListPageAnyDisabled from "./schedules-list/ScheduleListPageAnyDisabled";
import ScheduleListRecentlyUpdated from "./schedules-list/ScheduleListRecentlyUpdated";
import {ROLES} from "../../constants/roles";

const SchedulesPageConfig: PageConfigTypes = {
  baseRoute: {
    path: '/schedules',
    exact: true,
    component: SchedulesPage
  },
  subRoutes: [
    {
      path: '/schedules/schedules-list',
      component: SchedulesListPage,
      authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_AGENT]
    },
    {
      path: `/schedules/:id/activities`,
      component: ActivitiesPage,
      authorities: [ROLES.ROLE_ADMIN]
    },
    {
      path: '/schedules/schedules-list-paid',
      component: SchedulesListPageFullPaid,
      authorities: [ROLES.ROLE_ADMIN]
    },
    {
      path: '/schedules/schedules-list-dept',
      component: SchedulesListPageDept,
      authorities: [ROLES.ROLE_ADMIN]
    },
    {
      path: '/schedules/schedules-list-refund',
      component: ScheduleListPageRefund,
      authorities: [ROLES.ROLE_ADMIN]
    },
    {
      path: '/schedules/schedules-list-notrefund',
      component: ScheduleListPageNotRefund,
      authorities: [ROLES.ROLE_ADMIN]
    },
    {
      path: '/schedules/schedules-list-disabled',
      component: ScheduleListPageDisabled,
      authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_AGENT]
    },
    {
      path: '/schedules/schedules-list-any-disabled',
      component: ScheduleListPageAnyDisabled,
      authorities: [ROLES.ROLE_ADMIN]
    },
    {
      path: '/schedules/schedules-list-updated',
      component: ScheduleListRecentlyUpdated,
      authorities: [ROLES.ROLE_ADMIN, ROLES.ROLE_AGENT]
    }
  ],
  settings: {
    navbar: {
      open: true,
      title: "app.main.sidebar.schedules.title",
      subtitle: "app.main.sidebar.schedules.desc",
      items: [
        {
          primaryText: "app.main.schedules.schedules-list.pageHeader.title",
          exact: true,
          to: '/schedules-list',
          icon: 'list'
        },
        {
          primaryText: "app.main.sidebar.schedules.list7",
          exact: true,
          to: '/schedules-list-updated',
          icon: 'list'
        },
        {
          primaryText: "app.main.sidebar.schedules.list1",
          exact: true,
          to: '/schedules-list-paid',
          icon: 'attach_money'
        },
        {
          primaryText: "app.main.sidebar.schedules.list2",
          exact: true,
          to: '/schedules-list-dept',
          icon: 'money_off'
        },
        {
          primaryText: "app.main.sidebar.schedules.list3",
          exact: true,
          to: '/schedules-list-refund',
          icon: 'money_off'
        },
        {
          primaryText: "app.main.sidebar.schedules.list4",
          exact: true,
          to: '/schedules-list-notrefund',
          icon: 'money_off'
        },
        {
          primaryText: "app.main.sidebar.schedules.list5",
          exact: true,
          to: '/schedules-list-disabled',
          icon: 'money_off'
        },
        {
          primaryText: "app.main.sidebar.schedules.list6",
          exact: true,
          to: '/schedules-list-any-disabled',
          icon: 'money_off'
        },
      ]
    }
  }
}

export default SchedulesPageConfig;