import {
  addNewBalanceRequest,
  fillBalanceField,
  removeBalanceRequest,
  setTheAgentForm
} from "../store/agent-form.slice";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {Grid, Icon, IconButton, MenuItem, TextField} from "@material-ui/core";
import {Field} from "formik";
import {useTranslation} from "react-i18next";
import {fetchAllCurrencies} from "../../flights/store/currencies-list.slice";

const Credits = () => {
  const {currencies} = useSelector((state: AppState) => state.flights.currenciesList);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);

  const getDefaultCurrency = () => {
    const defaultCurrency = currencies?.find((item) => item.isoCode === 'EUR')
    return defaultCurrency ? defaultCurrency.id : ''
  }

  useEffect(() => {
    // We are setting default currency
    if (agentForm.balanceRequests[0].currencyId === '') {
      dispatch(setTheAgentForm({
        ...agentForm,
        balanceRequests: [{
          ...agentForm.balanceRequests[0],
          currencyId: getDefaultCurrency()
        }]
      }))
    }
  }, [currencies])

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const handleChange = (e: any, id?: string) => {
    const payload = {
      value: e.target.name === 'currencyId'? e.target.value : e.target.value,
      field: e.target.name,
      id
    }
    dispatch(fillBalanceField(payload))
  }

  useEffect(() => {
    dispatch(fetchAllCurrencies());
  }, []);

  return (
      <div style={{margin: '0px 0px'}}>
        {agentForm?.balanceRequests?.map(balance => {
          return (<Grid container spacing={4}>
            <Grid item xs={3}>
              <Field
                  style={{width: '100%', marginRight: '20px'}}
                  label={t("app.main.agency.create.credit.input1","Maximum credit")}
                  onChange={(e: any) => {
                    handleChange(e, balance.id)
                  }}
                  variant='outlined'
                  size='small'
                  name='creditLimit'
                  value={balance.creditLimit}
                  type="number"
                  as={TextField}
                  InputProps={{inputProps: {min: 0}}}
                  onKeyPress={preventMinus}
                  onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                  required
              />
            </Grid>
            <Grid item xs={3}>
              <div style={{display: 'flex'}}>
                <Field
                    onChange={(e: any) => handleChange(e, balance.id)}
                    variant='outlined'
                    value={balance.currencyId}
                    name='currencyId'
                    label={t("app.main.report.search.currency", "Valuta")}
                    select
                    style={{flex: 1, minWidth: 120}}
                    size='small'
                    as={TextField}
                    required
                >
                  {currencies && currencies.filter(cr => ['EUR', 'CHF'].includes(cr.isoCode)).map((cr: any, idx) => (
                      <MenuItem key={idx} value={cr.id}>{cr.name} - {cr.isoCode}</MenuItem>
                  ))}
                </Field>
                <IconButton
                    style={{margin: '0 10px'}}
                    onClick={() => {
                      dispatch(addNewBalanceRequest(getDefaultCurrency()));
                    }} size='small'>
                  <Icon>add</Icon>
                </IconButton>

                <IconButton
                    onClick={() => {
                      dispatch(removeBalanceRequest(balance.id));
                    }}
                    size='small'
                    disabled={agentForm.balanceRequests.length === 1}
                >
                  <Icon color='error'>delete</Icon>
                </IconButton>
              </div>
            </Grid>
          </Grid>)
        })}
      </div>
  )
}

export default Credits