import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {UserService} from "app/services";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import {CreateEmployeeTypes} from "app/types/service-types/EmployeeTypes";
import {fetchAllEmployeeUsers} from "./user-employees-list.slice";

interface CreateUserStateParams {
  body: CreateEmployeeTypes;
  onSuccess?: Function;
  onError?: Function;
}

export const createUser = (params: CreateUserStateParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await UserService.createUser(params.body);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(toggleQuickPanel());
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Employee added successfully!' : 'Punetori u shtua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(''));
      // @ts-ignore
      dispatch(fetchAllEmployeeUsers());
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
}

interface CreateUserSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: CreateUserSlice = {
  error: '',
  success: false,
  loading: false
}

const stateSlice = createSlice({
  name: 'users/create-user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
})

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;