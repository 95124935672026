import {combineReducers} from '@reduxjs/toolkit';
import flightsList from './flights-list';
import currenciesList from './currencies-list.slice';
import flight from './flights-list/get-flight.slice';

const reducer = combineReducers({
  flightsList,
  currenciesList,
  flight
});

export default reducer;
