import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  }
}))

export const airlineFormStyles = makeStyles((theme: Theme) => ({
  formWrapper: {

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1)
    }
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(1.5)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: 1,
      margin: theme.spacing(0, .5)
    },
    padding: theme.spacing(2, 0)
  }
}))
