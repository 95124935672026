import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {PassengersFlightRequest} from "app/types/service-types/FlightTypes";
import {FlightService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";

interface GetFlightParams {
  id: string;
  onSuccess?: Function;
  onError?: Function;
}

export const getPassengersFlight = (params: GetFlightParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await FlightService.getPassengersFlightById(params.id);
    dispatch(setPassengersFlight(response));
    if (params.onSuccess) params.onSuccess(response);
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface PassengersFlightSlice {
  passengersFlight: PassengersFlightRequest[];
  loading: boolean;
}

const initialState: PassengersFlightSlice = {
  passengersFlight: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'flightsedit/passengers',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setPassengersFlight: (state, action) => {
      state.passengersFlight = action.payload
    }
  }
});

export const {setLoading, setPassengersFlight} = stateSlice.actions;

export default stateSlice.reducer;