import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {FlightService} from "app/services";
import {filterFlights} from "./filter-flights.slice";
import {errorMsg, successMsg} from "app/utils/MessageUtils";
import store from "../../../../store";

interface UpdateFlightStateParams {
  id: string;
  body: any;
  onSuccess?: Function;
  onError?: Function;
}

export const updateFlight = (params: UpdateFlightStateParams) => async (dispatch: Dispatch) => {
  const filters = store.getState().flights.flightsList.filterFlights;
  try {
    dispatch(setLoading(true));
    const {
      id,
      economyPremium,
      economyWithRestrictions,
      economyWithoutRestrictions,
      sectorPrices,
      ...others
    } = params.body;

    const formatSectors = sectorPrices.map((item: any) => {
      const {id, prices, ...others} = item;
      const {id: id2, ...others2} = prices;
      return {
        ...others,
        prices: others2
      }
    });


    const formatedBodyStructure = {
      ...others,
      sectorPrices: formatSectors,
      recurringEndDate: "03/12/2021",
      recurringFrequencyInDays: 0,
      fareGroupList: [
        { // economyWithRestrictions
          seatClass: economyWithRestrictions.seatClass,
          noOfSeats: economyWithRestrictions.noOfSeats,
          fareList: [
            {
              fareType: economyWithRestrictions.fareDefault.fareType,
              amount: economyWithRestrictions.fareDefault.amount,
              luggage: economyWithRestrictions.fareDefault.luggage
            },
            {
              fareType: economyWithRestrictions.fareOneWay.fareType,
              amount: economyWithRestrictions.fareOneWay.amount,
              luggage: economyWithRestrictions.fareOneWay.luggage
            },
            {
              fareType: economyWithRestrictions.fareReturn.fareType,
              amount: economyWithRestrictions.fareReturn.amount,
              luggage: economyWithRestrictions.fareReturn.luggage
            }
          ]
        },
        { // economyWithoutRestrictions
          seatClass: economyWithoutRestrictions.seatClass,
          noOfSeats: economyWithoutRestrictions.noOfSeats,
          fareList: [
            {
              fareType: economyWithoutRestrictions.fareDefault.fareType,
              amount: economyWithoutRestrictions.fareDefault.amount,
              luggage: economyWithoutRestrictions.fareDefault.luggage
            },
            {
              fareType: economyWithoutRestrictions.fareOneWay.fareType,
              amount: economyWithoutRestrictions.fareOneWay.amount,
              luggage: economyWithoutRestrictions.fareOneWay.luggage
            },
            {
              fareType: economyWithoutRestrictions.fareReturn.fareType,
              amount: economyWithoutRestrictions.fareReturn.amount,
              luggage: economyWithoutRestrictions.fareReturn.luggage
            }
          ]
        },
        { // economyWithoutRestrictions
          seatClass: economyPremium.seatClass,
          noOfSeats: economyPremium.noOfSeats,
          fareList: [
            {
              fareType: economyPremium.fareDefault.fareType,
              amount: economyPremium.fareDefault.amount,
              luggage: economyPremium.fareDefault.luggage
            },
            {
              fareType: economyPremium.fareOneWay.fareType,
              amount: economyPremium.fareOneWay.amount,
              luggage: economyPremium.fareOneWay.luggage
            },
            {
              fareType: economyPremium.fareReturn.fareType,
              amount: economyPremium.fareReturn.amount,
              luggage: economyPremium.fareReturn.luggage
            }
          ]
        },
      ],
    }
    const response = await FlightService.updateFlight(params.id, formatedBodyStructure);

    if (response.status >= 200 && response.status < 300) {
      dispatch(setSuccess(true));
      dispatch(successMsg(localStorage.getItem('i18nextLng') === 'en' ? 'Flight changed successfully!' : 'Fluturimi u ndryshua me sukses!'));
      if (params.onSuccess) params.onSuccess();
      dispatch(setError(""));
      // @ts-ignore
      dispatch(
          // @ts-ignore
          filterFlights({
            ...filters,
            arrivalAirportId: filters.arrivalAirportId,
            departureAirportId: filters.departureAirportId,
            page: filters.page,
          })
      );
    }
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
    if (params.onError) params.onError();
    dispatch(setError(err.data.message));
  }
};

interface UpdateFlightSlice {
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: UpdateFlightSlice = {
  error: "",
  success: false,
  loading: false,
};

const stateSlice = createSlice({
  name: "flights/update-flight",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;
