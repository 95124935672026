import {CountryTypes} from "../../../../types/service-types/CountryTypes";
import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {CountryService} from "../../../../services";
import {errorMsg} from "../../../../utils/MessageUtils";

export const fetchAllCountries = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CountryService.fetchAll();
    dispatch(setCountries(response));
    dispatch(setLoading(false));

  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface CountriesListSlice {
  countries: CountryTypes[];
  loading: boolean;
}

const initialState: CountriesListSlice = {
  countries: [],
  loading: false
}

const stateSlice = createSlice({
  name: 'configurations/countries',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCountries: (state, action) => {
      state.countries = action.payload
    }
  }
});

export const {setLoading, setCountries} = stateSlice.actions;

export default stateSlice.reducer;