import React from 'react';
import {employeesFormStyles} from "../employees.styles";
import {Field, Form, Formik} from 'formik';
import history from '@history';
import {Button, Grid, MenuItem, TextField} from "@material-ui/core";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import {createUser} from "../store/create-user.slice";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {AppState} from "../../../store";
import {setEmployeeScopesForm} from "../store/employee-scopes-form.slice";

const handleKeyDownSpace = (e: KeyboardEvent) => {
    if (e.code === 'Space') {
        e.preventDefault();
    }
}

const CreateEmployeeForm = () => {
    const classes = employeesFormStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const scopesForm = useSelector((state: AppState) => state.employees.employeeScopeForm)

    const handleChange = (value: string) => {
        const newScopes = scopesForm.scopes.includes(value)
            ? scopesForm.scopes.filter(item => item !== value)
            : [...scopesForm.scopes, value];

        dispatch(setEmployeeScopesForm({scopes: newScopes}));
    };

    const validationSchema = yup.object({
        name: yup
            .string()
            .required(t("app.main.employee.createEmployee.nameValidation", "Please enter the required field"))
            .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
        surname: yup
            .string()
            .required(t("app.main.employee.createEmployee.surnameValidation", "Please enter the required field"))
            .matches(/^[aA-zZ\s]+$/, t("app.main.costumer.createCostumer.registerCostumer.fullName.title", "Only alphabets are allowed for this field ")),
        email: yup
            .string().email()
            .required(t("app.main.costumer.createCostumer.registerCostumer.email", "Please enter the required field")),
        password: yup
            .string()
            .required(t("app.main.costumer.createCostumer.registerCostumer.password", "Please enter the required field")),
        role: yup
            .string()
            .required(t("app.main.employee.createEmployee.roleValidation", "Please enter the required field"))
    });

    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader}>
                <h1 style={{color: '#0D3B66'}}>{t("app.main.employees.create.title", "Register Employee")}</h1>
            </div>
            <Formik
                initialValues={{
                    name: '',
                    surname: '',
                    email: '',
                    contactNumber: '',
                    address: '',
                    password: '',
                    role: '',
                    status: false,
                    scopes: [],
                }}
                validationSchema={validationSchema}
                onSubmit={(data) => {
                    const {name, surname, email, password, role} = data;
                    const scopes = scopesForm.scopes;

                    dispatch(createUser({
                        body: {
                            fullName: `${name} ${surname}`,
                            email, password, role, status: true, scopes
                        }
                    }));
                }}
            >
                {({values, errors, touched, setValues}) => (
                    <Form>
                        <div className={classes.row}>
                            <Field variant='outlined' value={values.name} name='name'
                                   label={t("app.main.employees.create.employeeName", "Employee name")}
                                   as={TextField} onKeyDown={handleKeyDownSpace}
                                   required
                                   error={Boolean(errors.name && touched.name)}
                                   helperText={touched.name ? errors.name : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           name: e.target.value,
                                       });
                                   }}/>
                            <Field variant='outlined' value={values.surname} name='surname'
                                   label={t("app.main.employees.create.employeeLastname", "Employee lastname")}
                                   as={TextField} onKeyDown={handleKeyDownSpace}
                                   required
                                   error={Boolean(errors.surname && touched.surname)}
                                   helperText={touched.surname ? errors.surname : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           surname: e.target.value,
                                       });
                                   }}/>
                        </div>
                        <div className={classes.row}>
                            <Field variant='outlined' type='email' value={values.email}
                                   onKeyDown={handleKeyDownSpace} name='email'
                                   label={t("app.main.employees.employeeList.table.email", "Email")}
                                   as={TextField} required
                                   error={Boolean(errors.email && touched.email)}
                                   helperText={touched.email ? errors.email : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           email: e.target.value,
                                       });
                                   }}/>
                            <Field variant='outlined' type='password' value={values.password} name='password'
                                   label={t("app.main.employees.create.password", "Password")}
                                   as={TextField} required
                                   error={Boolean(errors.password && touched.password)}
                                   helperText={touched.password ? errors.password : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           password: e.target.value,
                                       });
                                   }}/>
                        </div>
                        <div className={classes.row} style={{width: '50%'}}>
                            <Field variant="outlined" value={values.role}
                                   name="role" required
                                   label={t("app.main.employees.create.dropdown.title", "Role")}
                                   select as={TextField}
                                   error={Boolean(errors.role && touched.role)}
                                   helperText={touched.role ? errors.role : ""}
                                   onChange={(e: any) => {
                                       setValues({
                                           ...values,
                                           role: e.target.value,
                                       });
                                   }}
                            >
                                <MenuItem value="ADMIN">Admin</MenuItem>
                                <MenuItem value="CALL_AGENT">Call Agent</MenuItem>
                            </Field>
                        </div>
                        <div className={classes.row} style={{width: '100%'}}>
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <div className={classes.checkboxGroup} role="group">
                                        <label>
                                            <Field type="checkbox" name="FLIGHT_RESERVATION"
                                                   checked={scopesForm.scopes.includes('FLIGHT_RESERVATION')}
                                                   value='FLIGHT_RESERVATION'
                                                   onChange={() => handleChange('FLIGHT_RESERVATION')}/>
                                            {t("app.main.agency.create.scopesInfo.label1", "Booking flights")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="ADD_TRAVELER"
                                                   checked={scopesForm.scopes.includes('ADD_TRAVELER')}
                                                   value='ADD_TRAVELER'
                                                   onChange={() => handleChange('ADD_TRAVELER')}/>
                                            {t("app.main.agency.create.scopesInfo.label2", "Addition of passengers")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="DEACTIVATE_TRAVELER"
                                                   checked={scopesForm.scopes.includes('DEACTIVATE_TRAVELER')}
                                                   value='DEACTIVATE_TRAVELER'
                                                   onChange={() => handleChange('DEACTIVATE_TRAVELER')}/>
                                            {t("app.main.agency.create.scopesInfo.label3", "Cancellation of passengers")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="CANCELLATION_OF_RESERVATION"
                                                   checked={scopesForm.scopes.includes('CANCELLATION_OF_RESERVATION')}
                                                   value='CANCELLATION_OF_RESERVATION'
                                                   onChange={() => handleChange('CANCELLATION_OF_RESERVATION')}/>
                                            {t("app.main.agency.create.scopesInfo.label15", "Cancellation of reservation")}
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.checkboxGroup} role="group">
                                        <label>
                                            <Field type="checkbox" name="RESERVATION_DISCUSSION"
                                                   checked={scopesForm.scopes.includes('RESERVATION_DISCUSSION')}
                                                   value='RESERVATION_DISCUSSION'
                                                   onChange={() => handleChange('RESERVATION_DISCUSSION')}/>
                                            {t("app.main.agency.create.scopesInfo.label5", "Booking note")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="UPDATE_TRAVELERS"
                                                   checked={scopesForm.scopes.includes('UPDATE_TRAVELERS')}
                                                   value='UPDATE_TRAVELERS'
                                                   onChange={() => handleChange('UPDATE_TRAVELERS')}/>
                                            {t("app.main.agency.create.scopesInfo.label6", "Change of passengers")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="ACTIVITIES"
                                                   checked={scopesForm.scopes.includes('ACTIVITIES')} value='ACTIVITIES'
                                                   onChange={() => handleChange('ACTIVITIES')}/>
                                            {t("app.main.agency.create.scopesInfo.label8", "Activities")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="CONFIRM_VIBER"
                                                   checked={scopesForm.scopes.includes('CONFIRM_VIBER')}
                                                   value='CONFIRM_VIBER'
                                                   onChange={() => handleChange('CONFIRM_VIBER')}/>
                                            {t("app.main.agency.create.scopesInfo.label4", "Viber confirmation")}
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.checkboxGroup} role="group">
                                        <label>
                                            <Field type="checkbox" name="ADD_DISCUSSION"
                                                   checked={scopesForm.scopes.includes('ADD_DISCUSSION')}
                                                   value='ADD_DISCUSSION'
                                                   onChange={() => handleChange('ADD_DISCUSSION')}/>
                                            {t("app.main.agency.create.scopesInfo.label9", "Adding remarks")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="UPDATE_TRAVELER_PRICES"
                                                   checked={scopesForm.scopes.includes('UPDATE_TRAVELER_PRICES')}
                                                   value='UPDATE_TRAVELER_PRICES'
                                                   onChange={() => handleChange('UPDATE_TRAVELER_PRICES')}/>
                                            {t("app.main.agency.create.scopesInfo.label10", "Passenger price change")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="SHOW_TRAVELERS"
                                                   checked={scopesForm.scopes.includes('SHOW_TRAVELERS')}
                                                   value='SHOW_TRAVELERS'
                                                   onChange={() => handleChange('SHOW_TRAVELERS')}/>
                                            {t("app.main.agency.create.scopesInfo.label11", "Show/Don't show passengers")}
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.checkboxGroup} role="group">
                                        <label>
                                            <Field type="checkbox" name="UPDATE_CONTACT"
                                                   checked={scopesForm.scopes.includes('UPDATE_CONTACT')}
                                                   value='UPDATE_CONTACT'
                                                   onChange={() => handleChange('UPDATE_CONTACT')}/>
                                            {t("app.main.agency.create.scopesInfo.label12", "Changing contactor")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="DELETE_TRAVELERS"
                                                   checked={scopesForm.scopes.includes('DELETE_TRAVELERS')}
                                                   value='DELETE_TRAVELERS'
                                                   onChange={() => handleChange('DELETE_TRAVELERS')}/>
                                            {t("app.main.agency.create.scopesInfo.label13", "Deleting a segment")}
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="UPDATE_TRAVELER_FLIGHTS"
                                                   checked={scopesForm.scopes.includes('UPDATE_TRAVELER_FLIGHTS')}
                                                   value='UPDATE_TRAVELER_FLIGHTS'
                                                   onChange={() => handleChange('UPDATE_TRAVELER_FLIGHTS')}/>
                                            {t("app.main.agency.create.scopesInfo.label14", "Change of passenger flights")}
                                        </label>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.row}>
                            <LoadingButton
                                type='submit'
                                btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                                isLoading={false}
                            />
                            <Button onClick={() => {
                                dispatch(toggleQuickPanel())
                                history.push('/employees/employees-list')
                            }}>
                                {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateEmployeeForm;