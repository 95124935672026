import {combineReducers} from '@reduxjs/toolkit';
import allAgents from './agents-list.slice'
import createAgent from './create-agent.slice'
import agentForm from './agent-form.slice';
import agent from './get-agent.slice';
import updateAgent from './update-agent.slice';
import filAgents from './filter-agents.slice';
import paymentForm from './payment-form.slice';
import createPayment from './create-payment.slice';
import paymentList from './payment-list.slice';
import provisionsList from './provision-list.slice';
import filterPayment from './filter-payment.slice';
import updateAccessibility from "./update-accessibility.slice";
import updateScopes from "./update-scopes.slice";
import filterProvision from "./filter-provision.slice";
import updatePayment from "./update-payment.slice";
import agentIdentificationForm from "./agent-identification-form.slice";
import agentScopesForm from "./agent-scopes-form.slice";
import agentLogoImage from "./agent-logo-image.slice";
import allAgentsWithDebt from "./agent-with-debt-list.slice";
import allAgentsWithoutDebt from "./agent-without-debt-list.slice";
import filterAgentsWithDebt from "./filter-agents-with-debt.slice";
import filterAgentsWithoutDebt from "./filter-agents-without-debt.slice";

const reducer = combineReducers({
  allAgents,
  createAgent,
  createPayment,
  updatePayment,
  paymentForm,
  paymentList,
  provisionsList,
  filterPayment,
  filterProvision,
  agentForm,
  agentIdentificationForm,
  agentScopesForm,
  agent,
  updateAgent,
  updateAccessibility,
  updateScopes,
  filAgents,
  filterAgentsWithDebt,
  filterAgentsWithoutDebt,
  agentLogoImage,
  allAgentsWithDebt,
  allAgentsWithoutDebt
});

export default reducer;
