import React, {useState} from 'react';
import {activityItemStyles} from "./activities.styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import DateUtils from "../../../utils/DateUtils";
import {useTranslation} from "react-i18next";

interface IProps {
  columns: string[];
  row: any;
}

const TravelerActivityItem = (props: IProps) => {
  const classes = activityItemStyles();
  const {columns, row} = props;
  const {t} = useTranslation();
  const [more, setMore] = useState(false);

  const {
    id,
    ageCategory,
    gender,
    name,
    surname,
    dateOfBirth,
    nationality,
    revisionInfo
  } = row;

  return (
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {t("app.main.schedules.activities.reservationTypography", "Change by date")}:
            </Typography>
            <Typography variant="h5" component="h2">
              {DateUtils.toHumanDate(DateUtils.convertDate(revisionInfo.timestamp))}
            </Typography>
            <br/>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((col: any, idx: number) => (
                        <TableCell key={idx}>{col}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/*<TableCell>{id}</TableCell>*/}
                    <TableCell>{ageCategory}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{surname}</TableCell>
                    <TableCell>{DateUtils.toHumanDate(dateOfBirth)}</TableCell>
                    <TableCell>{nationality}</TableCell>
                    <TableCell>{gender}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>

          </CardActions>
          <CardActions>
            {more
                ? (
                    <Typography variant="body2" component="p" className={classes.cardFooter}>
                      {t("app.main.schedules.activities.reservationChanged", "Changed by")}: <strong>{revisionInfo.username}</strong>
                      <br/>
                      {t("app.main.schedules.activities.reservationFrom", "From")}: <strong>{revisionInfo.ipAddress}</strong>
                    </Typography>
                )
                : <Button onClick={() => setMore(!more)} size="small">{t("app.main.schedules.activities.reservationMore", "Read more")}</Button>
            }
          </CardActions>
        </Card>
      </div>
  );
};

export default TravelerActivityItem;