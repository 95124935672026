import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {FormControl, Icon, IconButton, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import useStyles from './search-panel.styles';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {filterReports} from "../store/filter-reports.slice";
import {GetEmployeeTypes} from "../../../types/service-types/EmployeeTypes";
import {fetchAllCurrencies} from "../../flights/store/currencies-list.slice";
import {Currency} from "../../../types/service-types/CurrenciesTypes";
import {fetchAllEmployeeUsers} from "../../employees/store/user-employees-list.slice";
import {Autocomplete} from "@material-ui/lab";
import {fetchAllCustomers} from "../../customer/store/customers-list.slice";
import {useTranslation} from "react-i18next";
import {ROLES} from "../../../constants/roles";
import useAuthority from "../../../hooks/useAuthority";


const SearchPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let debounce: any = null;
  const {t} = useTranslation();
  const filters = useSelector((state: AppState) => state.reports.filReports);
  const reports = useSelector((state: AppState) => state.reports.paymentReports.allReports);
  const users = useSelector((state: AppState) => state.employees.allEmployees.allUsers);
  const currencies = useSelector((state: AppState) => state.flights.currenciesList.currencies);
  const usersEmployee = useSelector((state: AppState) => state.employees.allEmpUsers.allEmpUsers.content);
  // const customers = useSelector((state: AppState) => state.customers.customersList.allCustomers);
  const customers = useSelector((state: AppState) => state.customers.customersList.allCustomers.content);
  const { hasUserAnyAuthority } = useAuthority();

  const handleChange = (e: any) => {
    dispatch(filterReports({
      ...filters,
      page: 0,
      [e.target.name]: e.target.value
    }));
  }

  useEffect(() => {
    // dispatch(fetchAllPaymentReports());
    dispatch(fetchAllEmployeeUsers({size: 9999}));
    dispatch(fetchAllCurrencies());
    dispatch(fetchAllCustomers({size: 9999}));
  }, []);

  const [q, setQ] = useState('');

  const searchByQuery = (q: any) => {
    // setQ(q.target.value)
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      dispatch(filterReports({
        ...filters,
        customerId: q,
        page: 0,
        // [e.target.name]: e.target.value
      }));
    }, 500);
  }

  // console.log('blend', allCustomers!.content);

  return (
      <Grid
          container
          direction="row"
          justify="flex-start"
          className={classes.root}
          style={{margin: '1rem 0', display: 'flex', alignItems: 'center'}}
          alignItems="flex-start"
      >
        {/*<div>*/}
        {/*  <TextField style={{maxWidth: '100px'}} name='code' label="Kodi" size='small' value={filters.code} onChange={handleChange} variant="outlined"/>*/}
        {/*</div>*/}
        <div className={classes.row} style={{position: 'relative'}}
        >
          <TextField
              label={t( "app.main.schedules.searchPanel.datepicker.from", "From date")}
              type="date"
              name='fromDate'
              value={filters.fromDate ? filters.fromDate : ''}
              onChange={handleChange}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {max: "9999-12-12"}
              }}
          />
          {filters.fromDate && (<>
            &nbsp;
            <IconButton
                style={{position: 'absolute', right: '1px'}}
                onClick={() => handleChange({
                  target: {name: 'fromDate', value: ''}
                })} size='small'
            >
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        <div className={classes.row} style={{position: 'relative'}}>
          <TextField
              label={t( "app.main.schedules.searchPanel.datepicker.to", "To")}
              type="date"
              name='toDate'

              value={filters.toDate ? filters.toDate : ''}
              onChange={handleChange}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {max: "9999-12-12"}
              }}
          />
          {filters.toDate && (<>
            &nbsp;
            <IconButton
                style={{position: 'absolute', right: '1px'}}
                onClick={() => handleChange({
                  target: {name: 'toDate', value: ''}
                })} size='small'
            >
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        <Autocomplete
            size={'small'}
            options={customers}
            getOptionLabel={(option) =>
                option.fullName
            }
            onChange={(e, value) => {
              if (value) {
                searchByQuery(value.id)
              } else {
                searchByQuery(null)
              }
            }}
            renderInput={(params) => <TextField
                style={{width: '200px'}}
                size={'small'}
                {...params}
                variant='outlined'
                id="standard-select-currency"
                label={t("app.main.customer.customerList.title", "All clients")}
                name="customerId"
                value={q}
                required
            />}
        />
        {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) &&
            <div className={classes.row} style={{position: 'relative'}}>
          <FormControl size='small' variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{t("app.main.employees.employeeList.title", "All employees")}</InputLabel>
            <Select
                style={{width: '220px'}}
                labelId="demo-simple-select-outlined-label"
                value={filters.userId ? filters.userId : ''}
                name='userId'
                onChange={handleChange}
                label={t("app.main.employees.employeeList.title", "All employees")}
            >
              {usersEmployee && usersEmployee.map((e: GetEmployeeTypes) => (
                  <MenuItem key={e.id} value={e.id}>{e.fullName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {filters.userId && (<>
            &nbsp;
            <IconButton
                style={{position: 'absolute', right: '25px'}}
                onClick={() => handleChange({
                  target: {name: 'userId', value: ''}
                })} size='small'
            >
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        }
        <div className={classes.row} style={{position: 'relative'}}>
          <FormControl style={{minWidth: '100px'}} size='small' variant="outlined"
                       className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label2">{t("app.main.report.search.currency", "Currency")}</InputLabel>
            <Select
                style={{width: '120px'}}
                labelId="demo-simple-select-outlined-label2"
                value={filters.currencyId ? filters.currencyId : ''}
                name='currencyId'
                onChange={handleChange}
                label="Valuta:"
            >
              {currencies && currencies.map((c: Currency) => (
                  <MenuItem key={c.id} value={c.id}>{c.name} - {(c.symbol)}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {filters.currencyId && (<>
            &nbsp;
            <IconButton
                style={{position: 'absolute', right: '21px'}}
                onClick={() => handleChange({
                  target: {name: 'currencyId', value: ''}
                })} size='small'
            >
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </>)}
        </div>
        {/*<div>*/}
        {/*    <button style={{border: '1px solid #e1e1e1', width: '70px', height: '38px'}}><SearchIcon style={{fontSize: '26px', marginTop: '4px'}}/></button>*/}
        {/*</div>*/}
      </Grid>
  );
};

export default SearchPanel;