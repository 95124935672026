import {createSlice} from '@reduxjs/toolkit';

interface Navbar {
  visibility: boolean;
}

const initialState: Navbar = {
  visibility: false,
}

const stateSlice = createSlice({
  name: 'navbar/show-navbar',
  initialState,
  reducers: {
    showNavbar: (state, action) => {
      state.visibility = action.payload
    }
  }
});

export const {showNavbar} = stateSlice.actions;

export default stateSlice.reducer;