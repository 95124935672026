import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AgentService} from 'app/services';
import {errorMsg} from "app/utils/MessageUtils";
import ConfigurationServices from "../../../../services/configuration.services";

interface GetConfigLogoParams {
    onSuccess?: Function;
    onError?: Function;
}

export const getUploadImageFromConfig = (params?: GetConfigLogoParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const response = await ConfigurationServices.getConfigurationLogo();
        dispatch(setLogoImage(response));
        if (params?.onSuccess) params?.onSuccess(response);
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(errorMsg(err.data.message));
    }
}

interface ConfigImageSlice {
    logoImageUrl: string;
    loading: boolean;
}

const initialState: ConfigImageSlice = {
    logoImageUrl: "",
    loading: false
}

const stateSlice = createSlice({
    name: 'agent/agent',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLogoImage: (state, action) => {
            state.logoImageUrl = action.payload
        }
    }
});

export const {setLoading, setLogoImage} = stateSlice.actions;

export default stateSlice.reducer;