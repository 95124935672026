import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import PricesCurrencyItem from "./PricesCurrencyItem";
import {useTranslation} from "react-i18next";

const PricesTable = ({prices, sectorId}: any) => {
  const {t} = useTranslation();
  return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight: 'bold', borderBottom: 'none'}}>{t("app.main.schedules.addPassenger.adult", "Adult")}</TableCell>
            <TableCell style={{fontWeight: 'bold', borderBottom: 'none'}}>{t("app.main.schedules.addPassenger.child", "Child")}</TableCell>
            <TableCell style={{fontWeight: 'bold', borderBottom: 'none'}}>{t("app.main.schedules.addPassenger.inf", "Infant")}</TableCell>
            <TableCell style={{borderBottom: 'none'}}/>
          </TableRow>
        </TableHead>
        <TableBody>
          <PricesCurrencyItem price={prices} sectorId={sectorId}
                              disabledRemoveBtn={prices.length === 1}/>
        </TableBody>
      </Table>
  )
}

export default PricesTable;
