import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "../../../store";
import React, {useEffect} from "react";
import {clearFilterPayments, filterPayments} from "../../agency/store/filter-payment.slice";
import {fetchAllPayments} from "../../agency/store/payment-list.slice";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import PageHeader from "../../shared-components/page-header/PageHeader";
import AgentSearchPanel from "../../agency/components/search-panel/AgentSearchPanel";
import AgentPaymentTable from "../../agency/components/agent-table/AgentPaymentTable";

export interface PaymentListType {
    currencyResponse: {
        id: string;
        isoCode: string;
        name: string;
        symbol: string;
    }
}

const AgencyReportsPaymentList = (props: PaymentListType) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {params}: any = useRouteMatch();
    const agentLoading = useSelector((state: AppState) => state.agents.agent.loading);
    const {paymentList, loading} = useSelector((state: AppState) => state.agents.paymentList);
    const filterPayment = useSelector((state: AppState) => state.agents.filterPayment);
    const account = useSelector((state: AppState) => state.account.account);

    useEffect(() => {
        dispatch(clearFilterPayments());
        dispatch(filterPayments(account.account!.id, {...filterPayment, currencyId: params.currencyId}));
        dispatch(fetchAllPayments(account.account!.id, {currencyId: params.currencyId, status: 'ALL'}));
    }, [params])

    if (agentLoading) {
        return <LoadingForm/>;
    }

    return (
        <div>
            <PageHeader
                title={`${t("app.main.agency.edit.payment.title1", "Agent Payment")} ${account.account!.fullName} 
            => ${t("app.main.agency.edit.payment.title2", "Credit")}: ${account.account!.balanceResponse[0].creditLimit} ${account.account!.balanceResponse[0].currencyResponse?.isoCode}
             & ${t("app.main.agency.edit.payment.title3", "Current Debt")}: ${paymentList?.total} ${account.account!.balanceResponse[0].currencyResponse?.isoCode}`}
                desc={t("app.main.agency.edit.payment.desc", "History of payment transactions")}
                backButton={() => {
                    history.push("/agency-reports");
                }}
            />
            <AgentSearchPanel/>
            <AgentPaymentTable data={paymentList} loading={loading}/>
        </div>
    );
};

export default AgencyReportsPaymentList;