import {makeStyles} from "@material-ui/styles";

export default makeStyles(() => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
            padding: '4px',
            fontSize: '12px',
        }
    }
}))

export const useTableRowStyles = makeStyles(() => ({
    root: {
        '& > *': {
            padding: '4px',
            fontSize: '12px',
        }
    },
    rowButtons: {
        '& button': {
            marginRight: '.5rem'
        }
    }
}))