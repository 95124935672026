import {makeStyles} from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    background: '#f9f9f9',
    display: 'flex',
    paddingLeft: '15px',
    paddingTop: '20px',
    paddingBottom: '20px',
    ['@media (max-width:425px)']: {
      overflowX: 'scroll'
    },
    ['@media (max-width:375px)']: {
      overflowX: 'scroll'
    },
  },
  menuButtons: {
    margin: '0 2rem'
  }

}));

