import React, {useEffect, useState} from 'react';
import {AppBar, Divider, Drawer, Icon, IconButton, Toolbar,} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './topbar.styles';
import UserMenu from "app/main/shared-components/UserMenu/UserMenu";
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'app/store';
import {ROLES} from 'app/constants/roles';
import useAuthority from 'app/hooks/useAuthority';
import {Link} from 'react-router-dom';
import {getAccount} from "../../../account/store/get-account.slice";
import {useTranslation} from "react-i18next";
import BaseDropdown from "../../shared-components/base-dropdown/BaseDropdown";
import {DropdownItemTypes} from "../../../types/DropdownTypes";
import MenuIcon from "@material-ui/icons/Menu";
import {showNavbar} from "../store/navbar.slice";
import AppUtils from "../../../utils/AppUtils";
import {useMediaQuery} from 'react-responsive';
import ConfigurationServices from "../../../services/configuration.services";

const TopbarLayout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const account = useSelector((state: AppState) => state.account.account);
    const {hasUserAnyAuthority} = useAuthority();
    const [showAllBalances, setShowAllBalances] = useState(false);
    const refreshBalance = () => {dispatch(getAccount());};
    const {i18n, t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [initialImageUrl, setInitialImageUrl] = useState("");
    const isTabletDevice = useMediaQuery({query: "(min-device-width: 850px)"});

    const toggleDrawer = (event: any) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        dispatch(showNavbar(true));
        setOpen(!open);
    };

    const toggleLanguageDrawer = (event: any) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpenLanguage(!openLanguage);
    };

    const items: DropdownItemTypes[] = [
        {
            icon: 'language_outlined',
            title: 'English',
            onClick: () => {
                onLanguageChange('en');
                setOpenLanguage(!openLanguage);
            }
        },
        {
            icon: 'language_outlined',
            title: 'Shqip',
            onClick: () => {
                onLanguageChange('sq');
                setOpenLanguage(!openLanguage);
            }
        },
        // {
        //     icon: 'language_outlined',
        //     title: 'Dash',
        //     onClick: () => {
        //         onLanguageChange('ds');
        //         setOpenLanguage(!openLanguage);
        //     }
        // }
    ];

    const onLanguageChange = (lang: any) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
    };

    const titleOfLanguage = (language: any) => {
        if (language === 'en') {
            return "English"
        }
        if (language === 'sq') {
            return "Shqip"
        }
        // if (language === 'ds') {
        //     return "Dash"
        // }
    };

    const renderBalances = () => {
        const balances = showAllBalances && account?.account?.balanceResponse && account?.account?.balanceResponse.length > 2 ?
            account?.account?.balanceResponse : account?.account?.balanceResponse.slice(0, 2)

        return <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '55px',
                paddingTop: '10px',
                paddingBottom: '10px'
            }}>
                <table style={{display: 'table-cell'}}>
                    {balances?.map(item => <tr className={classes.agentsCredits}>
                        <td style={{paddingRight: '40px'}}><span
                            style={{marginRight: '12px'}}>{t("app.main.layout.topBar.TopBarLayout.credit", "Credit:")}</span>{item?.creditLimit} {item?.currencyResponse?.isoCode}
                        </td>
                        <td style={{paddingRight: '40px'}}><span
                            style={{marginRight: '12px'}}>{t("app.main.layout.topBar.TopBarLayout.actualCredit", "Actual Credit:")}</span>{item?.availableCredit} {item?.currencyResponse?.isoCode}
                        </td>
                        <td style={{paddingRight: '40px'}}><span
                            style={{marginRight: '12px'}}>{t("app.main.layout.topBar.TopBarLayout.balance", "Balance:")}</span>{item?.balance} {item?.currencyResponse?.isoCode}
                        </td>
                    </tr>)}
                </table>

                {(!showAllBalances && account?.account?.balanceResponse && account?.account?.balanceResponse.length > 2)
                    && <div className="text-center" style={{cursor: 'pointer'}}
                            onClick={() => setShowAllBalances(true)}>Shfaqë të gjitha</div>}
            </div>
            <div>
                <IconButton style={{color: '#ffffff'}}
                            onClick={refreshBalance}>
                    <Icon>refresh</Icon>
                </IconButton>
            </div>
        </div>
    };

    useEffect(() => {
        ConfigurationServices.getConfigurationLogo()
            .then((response) => {
                setInitialImageUrl(response.logoImageUrl);
            })
            .catch((error) => {
                console.error("Error fetching initial image:", error);
            });

    }, [initialImageUrl]);

    const returnLogoImage = () => {
        if (initialImageUrl) {
            return <div>
                <Link to={'/schedule'}><img src={initialImageUrl} width={"210"} height={"38"}/></Link>
            </div>
        }
    };

    return (
        <AppBar
            id="topbar"
            className={clsx(classes.root, 'flex relative z-10')}
            color="primary"
            position='relative'
            elevation={2}
        >
            <div className={classes.topBar}>
                <Toolbar className="p-0" style={{padding: 0}}>
                    {isTabletDevice ? null : (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={toggleDrawer}
                            className={classes.menuButton}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                    {returnLogoImage()}
                    {hasUserAnyAuthority([ROLES.ROLE_AGENT]) && renderBalances()}
                    <div className={clsx(classes.menuButtons, 'flex flex-1 justify-end')}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={toggleLanguageDrawer}
                            className={classes.dropdownMenu}
                        >
                            <MenuIcon/>
                        </IconButton>
                        {isTabletDevice ?
                            <>
                                <div className={classes.languageId}>
                                    <BaseDropdown color={'primary'} icon='language_outlined'
                                                  title={titleOfLanguage(AppUtils.parseLanguageLocaleString(i18n.language)) as any}
                                                  items={items}/>
                                </div>
                                <div className={classes.logoutId}>
                                    <UserMenu/>
                                </div>
                            </>
                            :
                            <Drawer
                                className={classes.drawer}
                                variant={isTabletDevice ? "permanent" : "temporary"}
                                classes={{
                                    paper: classes.drawerPaper
                                }}
                                anchor="right"
                                open={openLanguage}
                                onClose={toggleLanguageDrawer}
                            >
                                <div className={classes.toolbar}/>
                                <Divider/>
                                <div style={{width: "140px", marginLeft: "25px", marginTop: "24px"}}>
                                    <div style={{marginBottom: "10px", display: "grid", marginRight: "15px"}}>
                                        <BaseDropdown color={'primary'} icon='language_outlined'
                                                      title={titleOfLanguage(i18n.language) as any}
                                                      items={items}/>
                                    </div>
                                    <div>
                                        <UserMenu/>
                                    </div>
                                </div>
                            </Drawer>
                        }
                    </div>
                </Toolbar>
            </div>
        </AppBar>
    )
}

export default TopbarLayout;
