import React, {useEffect, useState} from "react";
import {Button, Icon} from "@material-ui/core";
import {schedulesUpdateFormStyles} from "../schedules.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {AppState} from "app/store";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {Column} from "../../../types/TableTypes";
import Grid from "@material-ui/core/Grid";
import {TravellerTypes} from "../../../types/service-types/ReservationTypes";
import {warningMsg} from "../../../utils/MessageUtils";
import {splitPassengers} from "../store/split-passengers.slice";
import {useTranslation} from "react-i18next";

interface TravellerTypesWithCheckbox extends TravellerTypes {
  checked: boolean;
}

const SplitPassengers = () => {
  const classes = schedulesUpdateFormStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {params}: any = useRouteMatch();
  const location = useLocation<any>();
  const {t} = useTranslation();

  const reservationId = params?.id;

  const splitPassengersLoading = useSelector(
      (state: AppState) => state.schedules.splitPassengers.loading
  );

  const [passengers, setPassengers] = useState<TravellerTypesWithCheckbox[]>(
      []
  );

  useEffect(() => {
    setPassengers(unSelectAllPassengers(location?.state?.passengers));
  }, []);

  const unSelectAllPassengers = (passengers: TravellerTypes[]) => {
    return passengers.map((item: TravellerTypes) => ({
      ...item,
      checked: false,
    }));
  };

  const handlePassengerChange = (passengerId: string, checked: boolean) => {
    const changedPassengers = passengers.map(
        (item: TravellerTypesWithCheckbox) =>
            item.id === passengerId
                ? {
                  ...item,
                  checked,
                }
                : item
    );

    setPassengers(changedPassengers);
  };

  const passengerAgeCategory = (
      ageCategory: string,
      gender: string
  ): string => {
    switch (ageCategory) {
      case "ADULT":
        return gender === "MALE" ? "MR" : "MRS";
      case "CHILD":
        return "CHD";
      case "INF":
        return "INF";
      default:
        return "";
    }
  };

  const columns: Column[] = [
    {
      Header: "",
      accessor: "id",
      Cell: ({cell}: any) => {
        const {id, checked, returningFlight} = cell.row.original;
        return (
            <div style={{display: "flex", alignItems: "center"}}>
              <input
                  style={{verticalAlign: "middle"}}
                  type="checkbox"
                  name={id}
                  checked={checked}
                  value={id}
                  onChange={(e) =>
                      handlePassengerChange(e.target.value, e.target.checked)
                  }
              />
              <span style={{marginLeft: "50px"}}>
              <Icon
                  fontSize="small"
                  style={{
                    verticalAlign: "middle",
                    transform: "rotate(45deg)",
                  }}
              >
                flight
              </Icon>
                {returningFlight ? "RT" : "OW"}
            </span>
            </div>
        );
      },
    },
    {
      Header: `${t("app.main.schedules.splitPassenger.cell.passenger", "Passenger")}`,
      accessor: "name",
      Cell: ({cell}: any) => {
        const {name, surname, ageCategory, gender} = cell.row.original;
        return (
            <>
              {passengerAgeCategory(ageCategory, gender)}.{" "}
              <span style={{fontWeight: 600}}>
              {name} {surname}
            </span>
            </>
        );
      },
    },
    {
      Header: `${t("app.main.schedules.splitPassenger.cell.flight", "Flight")}`,
      accessor: "departureFlight",
      Cell: ({cell}: any) => {
        const {departureFlight, returningFlight} = cell.row.original;
        return (
            <>
            <span style={{fontWeight: 600}}>
              {departureFlight?.departureAirport?.code} -{" "}
              {departureFlight?.arrivalAirport?.code}
            </span>
              {returningFlight ? (
                  <>
                    {" >> "}{" "}
                    <span style={{fontWeight: 600}}>
                  {returningFlight?.departureAirport?.code} -{" "}
                      {returningFlight?.arrivalAirport?.code}
                </span>
                  </>
              ) : (
                  ""
              )}
            </>
        );
      },
    },
    {
      Header: `${t("app.main.schedules.addPassenger.price", "Price")}`,
      accessor:
          "departureFlight.availableSectorPrice.price.returnPrices.forAdults",
      Cell: ({cell}: any) => {
        const {departureFlight, returningFlight, ageCategory, price} =
            cell.row.original;
        if (returningFlight) {
          if (ageCategory === "ADULT") {
            return (
                <span style={{fontWeight: 600}}>
                {price?.departureBaseFlightPrice + price?.returningBaseFlightPrice}{" "}
                  {departureFlight?.currency?.symbol}
              </span>
            );
          }
          if (ageCategory === "CHILD") {
            return (
                <span style={{fontWeight: 600}}>
                {price?.departureBaseFlightPrice + price?.returningBaseFlightPrice}{" "}
                  {departureFlight?.currency?.symbol}
              </span>
            );
          } else {
            return (
                <span style={{fontWeight: 600}}>
                {price?.departureBaseFlightPrice + price?.returningBaseFlightPrice}{" "}
                  {departureFlight?.currency?.symbol}
              </span>
            );
          }
        } else {
          if (ageCategory == "ADULT") {
            return (
                <span style={{fontWeight: 600}}>
                {
                  price?.departureBaseFlightPrice
                }{" "}
                  {departureFlight?.currency?.symbol}
              </span>
            );
          }
          if (ageCategory == "CHILD") {
            return (
                <span style={{fontWeight: 600}}>
                {
                  price?.departureBaseFlightPrice
                }{" "}
                  {departureFlight?.currency?.symbol}
              </span>
            );
          } else {
            return (
                <span style={{fontWeight: 600}}>
                {
                  price?.departureBaseFlightPrice
                }{" "}
                  {departureFlight?.currency?.symbol}
              </span>
            );
          }
        }
      },
    },
  ];

  const submit = () => {
    const selectedPassengers = passengers.filter(
        (item: TravellerTypesWithCheckbox) => item.checked
    );
    const selectedPassengersIds = selectedPassengers.map(
        (item: TravellerTypesWithCheckbox) => item.id
    );

    if (!selectedPassengersIds.length) {
      dispatch(warningMsg(localStorage.getItem('i18nextLng') === 'en' ? 'You have not selected any passengers!' : 'Nuk keni selektuar asnjë pasagjerë!'));
      return;
    }

    dispatch(
        splitPassengers({
          id: reservationId,
          body: {
            travelersIds: selectedPassengersIds,
          },
          onSuccess: () => {
            dispatch(toggleQuickPanel());
            history.goBack();
          },
        })
    );
  };

  return (
      <div className={classes.formWrapper}>
        <div className={classes.formHeader} style={{marginBottom: "35px"}}>
          <h1 style={{color: "#0D3B66"}}>
            {t("app.main.schedules.splitPassenger.title", "Select the passengers you want to separate from this bill!")}
          </h1>
        </div>

        {passengers?.length && (
            <BaseTable columns={columns} data={passengers} isLoading={false}/>
        )}

        <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{marginTop: "20px"}}
        >
          <Grid item>
            <LoadingButton
                type="button"
                btnTitle={t("app.main.schedules.splitPassenger.splitButton", "Split Passengers")}
                size="medium"
                onClick={() => submit()}
                isLoading={splitPassengersLoading}
            />
            <Button
                onClick={() => {
                  dispatch(toggleQuickPanel());
                  history.goBack();
                }}
            >
              {t("app.main.schedules.splitPassenger.closeButton", "Close")}
            </Button>
          </Grid>
        </Grid>
      </div>
  );
};

export default SplitPassengers;
