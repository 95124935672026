import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {errorMsg} from "app/utils/MessageUtils";
import {
  FilterPaymentsQueryParams,
  PaymentList
} from "../../../types/service-types/AgentTypes";
import {AgentService} from "../../../services";

export const fetchAllPayments = (id: string, queryParams?: FilterPaymentsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await AgentService.filterPaymentsAtAgent(id, queryParams);
    } else response = await AgentService.filterPaymentsAtAgent(id);
    dispatch(setPaymentList(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data?.message));
  }
}

interface PaymentListSlice {
  paymentList: PaymentList | null;
  loading: boolean;
}

const initialState: PaymentListSlice = {
  paymentList: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'agentedit/payment-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setPaymentList: (state, action) => {
      state.paymentList = action.payload
    }
  }
});

export const {setLoading, setPaymentList} = stateSlice.actions;

export default stateSlice.reducer;
