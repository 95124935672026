import {createSlice, Dispatch} from "@reduxjs/toolkit";

const getInitialState = () => ({
    id: '',
    fullName: '',
    email: '',
    contactNumber: '',
    address: '',
    scopes: [] as string[],
    status: true,
    role: '',
});

export const setTheEmployeeForm = (payload: any) => (dispatch: Dispatch) => {
    dispatch(setEmployeeForm(payload));
};
export const resetTheEmployeeForm = () => (dispatch: Dispatch) => {
    dispatch(resetEmployeeForm());
};

const stateSlice = createSlice({
    name: "employee/employee-form",
    initialState: getInitialState(),
    reducers: {
        setEmployeeForm: (state, action) => action.payload,
        fillEmployeeFormField: (state, action) => {
            const value = action.payload.value;
            const field = action.payload.field;
            return {
                ...state,
                [field]: value
            }
        },
        resetEmployeeForm: () => getInitialState(),
    },
});

export const {
    setEmployeeForm,
    resetEmployeeForm,
    fillEmployeeFormField
} = stateSlice.actions;

export default stateSlice.reducer;
