import {Grid, Switch, TextField} from "@material-ui/core";
import {Field} from "formik";
import {fillAgentFormField, setTheAgentForm} from "../store/agent-form.slice";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useTranslation} from "react-i18next";


const ReservationInfo = () => {
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const {t} = useTranslation();
  const handleChange = (e:any) => {
    const payload = {
      value:e.target.value,
      field:e.target.name
    }
    dispatch(fillAgentFormField(payload))
  }
    const preventMinus = (e: any) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

 return (
     <div>
       <Grid container spacing={4}>
         <Grid item xs={3}>
           <Field
               style={{width: '100%'}}
               label={t("app.main.agency.create.reservationMaxAdult", "Reservation Max. Adult")}
               onChange={handleChange}
               value={agentForm.maxAdultAllowed}
               InputProps={{inputProps: {min: 0}}}
               variant='outlined'
               size='small'
               name='maxAdultAllowed'
               type="number"
               as={TextField}
               onKeyPress={preventMinus}
               onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
           />
         </Grid>
         <Grid item xs={3}>
           <Field
               style={{width: '100%'}}
               label={t("app.main.agency.create.reservationMaxChild", "Reservation Max. Child")}
               onChange={handleChange}
               value={agentForm.maxChildAllowed}
               InputProps={{inputProps: {min: 0}}}
               variant='outlined'
               size='small'
               name='maxChildAllowed'
               type="number"
               as={TextField}
               onKeyPress={preventMinus}
               onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
           />
         </Grid>
         <Grid item xs={3}>
           <Field
               style={{width: '100%'}}
               label={t("app.main.agency.create.reservationMaxInf", "Reservation Max. Inf")}
               onChange={handleChange}
               variant='outlined'
               value={agentForm.maxInfantAllowed}
               InputProps={{inputProps: {min: 0}}}
               size='small'
               name='maxInfantAllowed'
               type="number"
               as={TextField}
               onKeyPress={preventMinus}
               onKeyDown={ (evt: any) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
           />
         </Grid>
         <Grid item xs={3}>
           <div>
             {t("app.main.agency.create.allowReservationInf", "Allow reservations only with Inf")}:
           </div>
           <Switch
               checked={agentForm.allowInfantOnly}
               onChange={(e: any) => dispatch(setTheAgentForm({
                 ...agentForm,
                 allowInfantOnly: e.target.checked
               }))}
               value={agentForm.allowInfantOnly}
               color="primary"
               name="switch"
               inputProps={{'aria-label': 'primary checkbox'}}
           />
         </Grid>
       </Grid>
     </div>
 )
}


export default ReservationInfo;