import {makeStyles} from '@material-ui/styles';
import {Theme} from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: '100%',
    overflow: 'hidden',
    // border: '2px solid green'
  },
  pagesWrapper: {
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0),
    flex: 1,
    // border: '1px solid red',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },

  pageLayoutWrapper: {
    display: 'flex',
    height: '100%',
    padding: '0 160px',
    ['@media (max-width:1450px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 80px;'
    },
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      padding: '0 20px'
    }
  },
}))