import React, {useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useAuthority from "../../../hooks/useAuthority";
import {useTableRowStyles} from "./multiline-table.styles";
import {FlightRequestBody, PagedFilghtsList,} from "app/types/service-types/FlightTypes";
import LoadingTableRow from "../base-table/LoadingTableRow";
import NoDataTableRow from "../base-table/NoDataTableRow";
import {useDispatch, useSelector} from "react-redux";
import ModalDialog from "../modal-dialog/ModalDialog";
import {deleteFlight} from "../../flights/store/flights-list/delete-flight.slice";
import MultilineTableCell from "./MultilineTableCell";
import DateUtils from "../../../utils/DateUtils";
import TableActionButtons from "../base-table/TableActionButtons";
import {TableActionsTypes} from "../../../types/TableTypes";
import history from "@history";
import {useTranslation} from "react-i18next";
import {ROLES} from "../../../constants/roles";
import {filterFlights} from "../../flights/store/flights-list/filter-flights.slice";
import {AppState} from "../../../store";

interface IProps {
    data: PagedFilghtsList | null;
    loading: boolean;
}

const MultilineTable = (props: IProps) => {
    const classes = useTableRowStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {data} = props;
    const {hasUserAnyAuthority} = useAuthority();
    const [open, setOpen] = React.useState(false);
    const filters = useSelector((state: AppState) => state.flights.flightsList.filterFlights);
    const [modalOpen, setModalOpen] = useState<{
        state: boolean,
        deleteId: string,
        flightName?: string
    }>({
        state: false,
        deleteId: "",
        flightName: ""
    });

    const toggleOpen = () => setOpen(!open);

    const tableActions: TableActionsTypes = {
        onEditRow: (rowId: number) => {
            history.push({
                pathname: `/flightsedit/${rowId}/edit`,
                state: {id: rowId},
            });
        },
        onDeleteRow: (rowId: string, name: string) => {
            setModalOpen({state: true, deleteId: rowId, flightName: name});
        },
    };

    const onOkModal = () => {
        dispatch(deleteFlight({id: modalOpen.deleteId}));
        setModalOpen({state: false, deleteId: ""});
        dispatch(filterFlights({
            ...filters,
            dayOfWeek: '',
            inFlight: false,
        }));
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow className={classes.root}>
                        <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                            {t("app.main.flight.multiTable.cell.flightNumber", "Flight Number")}
                        </TableCell>
                        <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                            {t("app.main.schedules.splitPassenger.cell.flight", "Flight")}
                        </TableCell>
                        <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                            {t("app.main.schedules.addPassenger.departureTime", "Departure time")}
                        </TableCell>
                        {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) ? (
                            <>
                                <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                                    {t("app.main.flights.multilineTable.tableRow1", "Reserved seats / INF")}
                                </TableCell>
                                <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                                    {t("app.main.flights.multilineTable.tableRow2", "Prices for adults")}
                                </TableCell>
                                <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                                    {t("app.main.schedules.sharedComponents.collapsibleTableRow.realization", "Realization")}
                                </TableCell>
                                <TableCell style={{fontWeight: "bold", textAlign: "center"}}>
                                    {t("app.main.employees.employeeList.table.actions", "Actions")}
                                </TableCell>
                            </>) : <></>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.loading ? (
                        <LoadingTableRow colSpan={6}/>
                    ) : !data || data.content.length === 0 ? (
                        <NoDataTableRow colSpan={6}/>
                    ) : (
                        data.content.map((row: FlightRequestBody) => {
                            return (
                                <React.Fragment>
                                    <TableRow onClick={toggleOpen} className={classes.root}>
                                        <TableCell style={{textAlign: "center"}}>
                                            <MultilineTableCell
                                                {...props}
                                                content={[
                                                    `#${row.number}`,
                                                ]}
                                            />
                                        </TableCell>
                                        <TableCell style={{textAlign: "center"}}>
                                            <MultilineTableCell
                                                {...props}
                                                content={[
                                                    `${row.departureAirport.code} => ${row.arrivalAirport.code}`,
                                                ]}
                                            />
                                        </TableCell>
                                        <TableCell style={{textAlign: "center"}}>
                                            <MultilineTableCell
                                                {...props}
                                                content={[
                                                    DateUtils.toHumanDate(
                                                        row.dateOfDeparture,
                                                        row.timeOfDeparture
                                                    ),
                                                ]}
                                            />
                                        </TableCell>

                                        {hasUserAnyAuthority([ROLES.ROLE_ADMIN]) ? (
                                            <>
                                                <TableCell style={{textAlign: "center"}}>
                                                    <MultilineTableCell
                                                        {...props}
                                                        content={[
                                                            `${row.numberOfSeats} total / ${row.numberOfAvailableSeats} ${t("app.main.flights.listOfFlights.reservedSeats.freeSeats", "free seats")} `
                                                        ]}
                                                    />
                                                </TableCell>
                                                <TableCell style={{textAlign: "center"}}>
                                                    <MultilineTableCell
                                                        {...props}
                                                        content={[`${row.lowestPriceWithTax}`]}
                                                    />
                                                </TableCell>
                                                <TableCell style={{textAlign: "center"}}>
                                                    <MultilineTableCell
                                                        {...props}
                                                        content={[`${row.realization} ${row.currency.symbol}`]}
                                                    />
                                                </TableCell>
                                                <TableCell style={{textAlign: "center"}}>
                                                    <TableActionButtons {...tableActions} rowId={row.id}
                                                                        name={
                                                                            `${row.departureAirport.name}(${row.departureAirport.code})
                                                  - ${row.arrivalAirport.name}(${row.arrivalAirport.code})`
                                                                        }/>
                                                </TableCell> </>) : <></>}
                                    </TableRow>
                                </React.Fragment>
                            );
                        })
                    )}
                </TableBody>
            </Table>

            <ModalDialog
                open={modalOpen.state}
                title={`${t("app.main.flights.flightList.deletePopup.primaryText", "Are you sure you want to delete flight")} '${modalOpen.flightName}'?`}
                onOk={onOkModal}
                onCancel={() => setModalOpen({state: false, deleteId: "", flightName: ""})}
            />
        </TableContainer>
    );
};

export default MultilineTable;
