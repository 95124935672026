import React, {useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTableRowStyles} from "./customers-table.styles";
import CustomersTableCell from "./CustomersTableCell";
import TableActionButtons from "../base-table/TableActionButtons";
import {TableActionsTypes} from "../../../types/TableTypes";
import {useDispatch} from "react-redux"
import ModalDialog from 'app/main/shared-components/modal-dialog/ModalDialog'
import {useTranslation} from "react-i18next";
import {deactivateCustomer} from "../../customer/store/deactivate-costumer.slice";
import {activateCustomer} from "../../customer/store/activate-costumer.slice";

const CustomersTableRow = (props: any) => {
    const {t} = useTranslation();
    const classes = useTableRowStyles();
    const dispatch = useDispatch();
    const {row} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const checkPhoneNumber = row.phoneNumber ? row.phoneNumber : "";
    const checkAddress = row.address ? row.address : "";
    const checkCity = row.city ? row.city : "";
    const checkCountry = row.country ? row.country : "";
    const status = row.status ? "Active" : "Deactive";

    const tableActions: TableActionsTypes = {
        [row.status ? 'onDeactivateRow' : 'onActivateRow']: (rowId: string) => {
            setModalOpen(true);
        },
    }

    return (
        <React.Fragment>
            {row.status ? (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle", "Deactivate")} `}
                    title={`${t("app.main.customer.customersList.deactivatePopup.primaryText", "Are you sure you want to deactivate customer")} '${row.fullName}'?`}
                    onOk={() => {
                        dispatch(deactivateCustomer({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            ) : (
                <ModalDialog
                    open={modalOpen}
                    mainTitle={`${t("app.main.customer.customersList.deactivatePopup.mainTitle1", "Activate")} `}
                    title={`${t("app.main.customer.customersList.activatePopup.primaryText1", "Are you sure you want to activate customer")} '${row.fullName}'?`}
                    onOk={() => {
                        dispatch(activateCustomer({id: row.id}));
                    }}
                    onCancel={() => setModalOpen(false)}/>
            )}

            <TableRow className={classes.root}>
                <TableCell style={{textAlign: 'center'}}>
                    <CustomersTableCell {...props} content={[
                        `${row.fullName}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <CustomersTableCell {...props} content={[
                        `${row.email}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <CustomersTableCell {...props} content={[
                        `${checkPhoneNumber}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <CustomersTableCell {...props} content={[
                        `${checkAddress}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <CustomersTableCell {...props} content={[
                        `${checkCity}`,
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <CustomersTableCell {...props} content={[
                        `${checkCountry}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center', color: row.status ? "green" : "red", fontWeight: "bold"}}>
                    <CustomersTableCell {...props} content={[
                        `${status}`
                    ]}/>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <TableActionButtons {...tableActions} rowId={row.id}/>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default CustomersTableRow;