import React, {useContext, useEffect} from 'react';
import {Divider, Drawer, List, useMediaQuery} from '@material-ui/core';
import AppContext from "app/AppContext";
import useStyles from './navbar.styles';
import history from '@history';
import NavbarListItem from 'app/main/layout/navbar-list-item/NavbarListItem';
import AppUtils from "app/utils/AppUtils";
import {useTranslation} from "react-i18next";
import {theme} from "../../../theme.config";
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from "../../../store";
import {showNavbar} from "../store/navbar.slice";

const Navbar = ({headerHeight = 0}) => {
  const {pagesConfigs} = useContext(AppContext);
  const {pathname, state}: any = history.location;
  const pageConfig = AppUtils.findPageConfigsByPathname(pathname, pagesConfigs);
  const nav = pageConfig?.settings?.navbar;
  const classes = useStyles({isNavbarOpen: nav?.open});
  const basePath = pageConfig?.baseRoute.path;
  const {t} = useTranslation();
  // @ts-ignore
  const navTitle = `${t(nav?.title)}`;
  // @ts-ignore
  const navSubtitle = `${t(nav?.subtitle)}`;

  const dispatch = useDispatch();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navbarState = useSelector((state: AppState) => state.navbar.navbar);

  return (
      <Drawer
          className={classes.drawer}
          variant={isMdUp ? ((navTitle && navSubtitle) ? "permanent" : "persistent") : "temporary"}
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
          open={isMdUp ? nav && nav.open : nav && nav.open && navbarState.visibility}
          onClose={() => dispatch(showNavbar(false))}
      >
        <div className={classes.toolbar} />
        <div className={classes.drawerContainer} style={{paddingTop: `${headerHeight}px`}}>
          <div className={classes.header}>
            <h2>{nav && navTitle}</h2>
            <h5 style={{color: 'gray'}}>{nav && navSubtitle}</h5>
          </div>
          <Divider/>
          <div className={classes.content}>
            <List onClick={() => dispatch(showNavbar(false))}>
              {nav && nav?.items?.map((navItem, idx) => {
                const {to, primaryText, icon,secondaryText, ...otherProps} = navItem;
                let formatedPath = "";
                let hasState = state && state.id;

                if (hasState) {
                  formatedPath = AppUtils.formatAndPersistPathname(basePath, to, state.id);
                } else {
                  formatedPath = `${basePath}${to}`;
                }
                const secondaryTextExist = secondaryText ? t(primaryText) + " " + secondaryText : t(primaryText)
                return (

                    <NavbarListItem
                        icon={icon}
                        to={{
                          pathname: formatedPath,
                          state: {
                            id: hasState && state.id
                          }
                        }}
                        primaryText={secondaryTextExist}
                        key={to + idx}
                        {...otherProps}
                    />
                )
              })}
            </List>
            <Divider/>
          </div>
        </div>
      </Drawer>
  );
};

export default Navbar;