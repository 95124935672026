import React from 'react';
import Grid from "@material-ui/core/Grid";
import {
  Button,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import useStyles from './search-panel.styles';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {clearfilterSchedules, filterSchedules} from '../store/filter-schedules.slice';
import {useTranslation} from "react-i18next";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateUtils from "../../../utils/DateUtils";

const SearchPanel = (props?: any) => {
  const {totalReservations, totalTravelers} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const filters = useSelector((state: AppState) => state.schedules.filterSchedules);

  const handleChange = (e: any) => {
    dispatch(filterSchedules({
      ...filters,
      page: 0,
      [e.target.name]: e.target.value
    }));
  }

  return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Grid
              container
              direction="row"
              justify="flex-start"
              className={classes.root}
              style={{margin: '1rem 0', display: 'flex', alignItems: 'center'}}
              alignItems="flex-start"
          >
            <div>
              <FormControl size='small' variant="outlined" className={classes.formControl}>
                <InputLabel
                    id="demo-simple-select-outlined-label">{t("app.main.schedules.searchPanel.input.status", "Status")}</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    value={filters.status ? filters.status : 'ALL'}
                    name='status'
                    onChange={handleChange}
                    label={t("app.main.schedules.searchPanel.input.status", "Status")}
                >
                  <MenuItem
                      value='ALL'>{t("app.main.schedules.searchPanel.menuItem1", "All")}</MenuItem>
                  <MenuItem
                      value='ACTIVE'>{t("app.main.schedules.searchPanel.menuItem2", "Active")}</MenuItem>
                  <MenuItem
                      value='DISABLED'>{t("app.main.schedules.searchPanel.menuItem3", "Canceled")}</MenuItem>
                  <MenuItem
                      value='ANY_DISABLED'>{t("app.main.schedules.searchPanel.menuItem4", "Canceled passenger")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField name='q' label={t("app.main.schedules.searchPanel.searchInput", "Search")}
                         size='small' value={filters.q} onChange={handleChange}
                         variant="outlined"/>
            </div>

            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker label={t("app.main.schedules.searchPanel.datepicker.from", "From date")}
                            format="dd/MM/yyyy"
                            inputVariant='outlined'
                            size='small'
                            disableToolbar
                            autoOk
                            variant="inline"
                            value={filters.fromDate}
                            onChange={(e) => {
                              dispatch(filterSchedules({
                                ...filters,
                                page: 0,
                                fromDate: DateUtils.convertDateToIso(e)
                              }));
                            }}
                />
              </MuiPickersUtilsProvider>
              {filters.fromDate && (<>
                &nbsp;
                <IconButton style={{position: 'absolute', right: '0.7rem'}} onClick={() => {
                  handleChange({
                    target: {name: 'fromDate', value: null}
                  });
                }} size='small'>
                  <Icon fontSize='small'>close</Icon>
                </IconButton>
              </>)}
            </div>
            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker label={t("app.main.schedules.searchPanel.datepicker.to", "To")}
                            format="dd/MM/yyyy"
                            inputVariant='outlined'
                            size='small'
                            disableToolbar
                            autoOk
                            variant="inline"
                            value={filters.toDate}
                            minDate={filters.fromDate}
                            onChange={(e) => {
                              dispatch(filterSchedules({
                                ...filters,
                                page: 0,
                                toDate: DateUtils.convertDateToIso(e)
                              }));
                            }}
                />
              </MuiPickersUtilsProvider>
              {filters.toDate && (<>
                &nbsp;
                <IconButton style={{position: 'absolute', right: '0.7rem'}} onClick={() => {
                  handleChange({
                    target: {name: 'toDate', value: null}
                  });
                }} size='small'>
                  <Icon fontSize='small'>close</Icon>
                </IconButton>
              </>)}
            </div>
            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
              <Button
                  variant='outlined'
                  onClick={() => {
                    dispatch(clearfilterSchedules());
                    dispatch(filterSchedules({
                      ...filters,
                      page: 0,
                      status: 'ALL',
                      q: '',
                      fromDate: DateUtils.convertDateToIso(new Date()),
                      toDate: DateUtils.convertDateToIso(new Date())
                    }))
                  }}
              >
                {t("app.main.schedules.searchPanel.button.clear", "Reset")}
              </Button>
            </div>
          </Grid>
          <div className={classes.totalRes}>
            <p>{t("app.main.schedules.searchPanel.totalReservation", "Total reservations")}: <b>{totalReservations}</b></p>
            <p>{t("app.main.schedules.searchPanel.totalTravelers", "Total travelers")}: <b>{totalTravelers}</b></p>

          </div>
        </div>
      </>
  );
};

export default SearchPanel;
