import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {FilterReservationsQueryParams, PagedReservationsList} from "app/types/service-types/ReservationTypes";
import {ReservationService} from "app/services";
import {errorMsg} from "app/utils/MessageUtils";

export const fetchAllSchedules = (queryParams?: FilterReservationsQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await ReservationService.searchReservations(queryParams);
    } else response = await ReservationService.fetchAll();

    dispatch(setAllReservations(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface SchedulesListSlice {
  allReservations: PagedReservationsList | null;
  loading: boolean;
}

const initialState: SchedulesListSlice = {
  allReservations: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'schedules/schedules-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAllReservations: (state, action) => {
      state.allReservations = action.payload
    }
  }
});

export const {setLoading, setAllReservations} = stateSlice.actions;

export default stateSlice.reducer;
