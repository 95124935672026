import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export default makeStyles<Theme>((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 2,
    color: 'inherit',
    background: 'theme.palette.primary.main'
  },
  menuButtons: {
    margin: '0 0rem',
    '& *': {
      margin: '0 5px'
    }
  },
}));