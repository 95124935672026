import axios from './axios.interceptors';

class ConfigurationServices {
    getConfigurationLogo = async (): Promise<any> => {
        try {
            const response = await axios.get('/api/configurations/logo');
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    uploadConfigurationLogo = async (logoImage: any): Promise<any> => {
        return await axios.put(`/api/configurations/logo`, logoImage);
    }
}

export default new ConfigurationServices();