import React, {useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTableRowStyles} from "./airports-table.styles";
import AirportsTableCell from "./AirportsTableCell";
import TableActionButtons from "../base-table/TableActionButtons";
import {TableActionsTypes} from "../../../types/TableTypes";
import {toggleQuickPanel} from "../quick-panel/store";
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom";
import history from '@history';
import ModalDialog from 'app/main/shared-components/modal-dialog/ModalDialog'
import {deleteAirport} from "../../configurations/store/airport/delete-airport.slice";
import {useTranslation} from "react-i18next";

const AirportsTableRow = (props: any) => {
  const classes = useTableRowStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {row} = props;
  const [modalOpen, setModalOpen] = useState(false);

  const tableActions: TableActionsTypes = {
    // onViewRow: (rowId: number) =>  alert('row: ' + rowId),
    onEditRow: (rowId: number) => {
      dispatch(toggleQuickPanel());
      history.push(`${url}/update/${rowId}`);
    },
    onDeleteRow: (rowId: string) => {
      setModalOpen(true);
    }
  }

  const translateModal = () => {
    let title = t("app.main.configuration.airport.module.title", "Are you sure you want to delete airport ");

    return title + row.name + ' ?';
  }

  return (
      <React.Fragment>
        <ModalDialog
            open={modalOpen}
            title={translateModal()}
            onOk={() => {
              dispatch(deleteAirport({id: row.id}));
            }}
            onCancel={() => setModalOpen(false)}/>
        <TableRow className={classes.root}>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              // `${row.departureAirport.name} => ${row.arrivalAirport.name}`,
              `${row.name}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              `${row.country.name}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              `${row.location}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              `${row.code}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              `${row.additionalInformation}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              `${row.currency}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <AirportsTableCell {...props} content={[
              `${row.number}`
            ]}/>
          </TableCell>
          <TableCell style={{textAlign: 'center'}}>
            <TableActionButtons {...tableActions} rowId={row.id}/>
          </TableCell>

        </TableRow>
      </React.Fragment>
  );
};

export default AirportsTableRow;