class DateUtils {

  static convertDateToIso(date: any) {
    const dateObj = new Date(date);
    let day = (dateObj.getDate().toString());
    let month = (dateObj.getMonth() + 1).toString();

    if (month.length === 1) {
      month = `0${month}`
    }
    if (day.length === 1) {
      day = `0${day}`
    }

    return [dateObj.getFullYear(), month, day].join("-");
  }

  static convertDate(inputFormat: string | Date | undefined) {
    function pad(s: any) {
      return (s < 10) ? '0' + s : s;
    }

    if (inputFormat === undefined) return '';
    if (typeof inputFormat === 'string' && inputFormat.includes('/')) {
      return inputFormat;
    }
    let d = (typeof inputFormat === 'string') ? new Date(inputFormat) : inputFormat;

    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
  }

  static convertToString(date: string) {
    if (!date) return '';

    const splitedDate = date.split('-');
    return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
  }

  static toISODate(date: string): string {
    if (!date) return '';
    if (!date.includes('/')) return date;

    // 18/03/2021
    const dateSplit = date.split('/');
    // @ts-ignore
    return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]).toISOString();
  }

  static toUTCDate(date: string): string {
    if (!date) return '';
    if (!date.includes('/')) return date;

    const dateSplit = date.split('/');
    const GMTHours = new Date().getHours() - new Date().getUTCHours();

    // @ts-ignore
    return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0], GMTHours, 0).toISOString();
  }

  static getDateBy(date: any) {
    // 18/03/2021
    if (!date || ((typeof date) === 'object')) return date;
    else if (date.includes('/')) {
      const splitedDate = date.split('/');
      const [day, month, year] = splitedDate;
      return new Date(`${month}/${day}/${year}`);
    } else return date;
  }

  static getCurrentDate() {
    const date = new Date();
    return new Date(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
  }

  static getCurrentNewDate() {
    const date = new Date();
    let day = (date.getDate().toString());
    let month = (date.getMonth() + 1).toString();
    let hours = (date.getHours()).toString();
    let minutes = (date.getMinutes()).toString();
    let seconds = (date.getSeconds()).toString();

    if (month.length === 1) {
      month = `0${month}`
    }
    if (day.length === 1) {
      day = `0${day}`
    }
    if (hours.length === 1) {
      hours = `0${hours}`
    }
    if (minutes.length === 1) {
      minutes = `0${minutes}`
    }
    if (seconds.length === 1) {
      seconds = `0${seconds}`
    }

    return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
  }

  static isEqualDate(date: Date): boolean {
    return date.getTime() === this.getCurrentDate().getTime()
  }

  static toDateObject(stringDate: string, stringTime: string) {
    // stringDate format example: "19/11/2021"
    const [day, month, year] = stringDate.split("/");
    const date = new Date(Number(year), Number(month), Number(day));

    if (stringTime) {
      const [hours, minutes] = stringTime.split(":");
      // date.setMinutes(Number(minutes));
      date.setHours(Number(hours));
      date.setMinutes(Number(minutes))
    }

    return date;
  }

  static toHumanDate(date: string, time?: string): string {
    if (!date) return '';
    // 18/03/2021, 12:41:00
    const dateSplit = date.split('/');
    // @ts-ignore
    const dateString = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]).toDateString();
    // Ex:: Sun Apr 18 2021
    const splitedDate = dateString.split(' ');

    const dayStr = splitedDate[0];
    const month = splitedDate[1];
    const dayNum = splitedDate[2];
    const year = splitedDate[3];

    let formatedTime = '';
    if (time) {
      const splitedTime = time.split(':');
      formatedTime = `${splitedTime[0]}:${splitedTime[1]}`;
    }

    return `${this.getALStringDay(dayStr)}, ${dayNum} ${this.getALStringMonth(month)} ${year} ${formatedTime}`;
  }

  static getALStringMonth = (month: string) => {
    switch (month.toLowerCase()) {
      case 'jan':
        return localStorage.getItem('i18nextLng') === 'en' ? 'January' : 'Janar';
      case 'feb':
        return localStorage.getItem('i18nextLng') === 'en' ? 'February' : 'Shkurt';
      case 'mar':
        return localStorage.getItem('i18nextLng') === 'en' ? 'March' : 'Mars';
      case 'apr':
        return localStorage.getItem('i18nextLng') === 'en' ? 'April' : 'Prill';
      case 'may':
        return localStorage.getItem('i18nextLng') === 'en' ? 'May' : 'Maj';
      case 'jun':
        return localStorage.getItem('i18nextLng') === 'en' ? 'June' : 'Qershor';
      case 'jul':
        return localStorage.getItem('i18nextLng') === 'en' ? 'July' : 'Korrik';
      case 'aug':
        return localStorage.getItem('i18nextLng') === 'en' ? 'August' : 'Gusht';
      case 'sep':
        return localStorage.getItem('i18nextLng') === 'en' ? 'September' : 'Shtator';
      case 'oct':
        return localStorage.getItem('i18nextLng') === 'en' ? 'October' : 'Tetor';
      case 'nov':
        return localStorage.getItem('i18nextLng') === 'en' ? 'November' : 'Nëntor';
      case 'dec':
        return localStorage.getItem('i18nextLng') === 'en' ? 'December' : 'Dhjetor';
    }
  }

  static getALStringDay = (day: string) => {
    switch (day.toLowerCase()) {
      case 'mon':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Monday' : 'E Hënë';
      case 'tue':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Tuesday' : 'E Martë';
      case 'wed':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Wednesday' : 'E Mërkurë';
      case 'thu':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Thursday' : 'E Enjte';
      case 'fri':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Friday' : 'E Premte';
      case 'sat':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Saturday' : 'E Shtunë';
      case 'sun':
        return localStorage.getItem('i18nextLng') === 'en' ? 'Sunday' : 'E Dielë';
    }
  }

  static getDateByYearsAgo = (yearsAgo: number): Date => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - yearsAgo);

    return date;
  }

  static addDaysToDate = (date: Date, days: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);

    return newDate;
  }

  static removeDaysToDate = (date: Date, days: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);

    return newDate;
  }

  static checkMinDateAtFlight(stringDate: string, stringTime: string) {
    const [day, month, year] = stringDate.split("/");
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    if (stringTime) {
      const [hours, minutes] = stringTime.split(":");
      date.setHours(Number(hours));
      date.setMinutes(Number(minutes))
    }

    return date;
  }

}

export default DateUtils;
