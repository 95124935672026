import React, {useEffect, useState} from "react";
import {Button, MenuItem, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {updatePassengerFlightStyles} from "./update-passenger-flight.styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {AppState} from "app/store";
import {
    updatePassengerDepartureFlight,
    updatePassengerReturnFlight
} from "../store/update-passenger.slice";
import LoadingButton from "../../shared-components/loading-button/LoadingButton";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import DateUtils from "app/utils/DateUtils";
import {fetchAllAirports} from "../../configurations/store/airport/airports-list.slice";
import BaseTable from "../../shared-components/base-table/BaseTable";
import {Column} from "../../../types/TableTypes";
import {FlightResultTypes} from "../../../types/service-types/ScheduleTypes";
import {
    fetchAllFlightResults,
    fetchAllReturnFlightResults,
    setSelectedFlight,
    setSelectedReturnFlight,
} from "../../schedule/store/flight-results.slice";
import {warningMsg} from "app/utils/MessageUtils";
import {PricesByAgeCategoryTypes} from "../../../types/service-types/SectorPricesTypes";
import {useTranslation} from "react-i18next";

const UpdatePassengerFlight = () => {
    const classes = updatePassengerFlightStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<any>();
    const today = new Date;
    const {t} = useTranslation();

    const passenger = location?.state?.passenger;
    const departureFlight = location?.state?.departureFlight;
    const returnFlight = location?.state?.returnFlight;

    const updateLoading = useSelector(
        (state: AppState) => state.schedules.updateSchedule.loading
    );
    const airports = useSelector(
        (state: AppState) =>
            state.configurations.airport.airportsList.airports.content
    );

    const [activeRow, setActiveRow] = useState<FlightResultTypes | null>(
        passenger?.departureFlight
    );
    const [activeReturnRow, setReturnActiveRow] =
        useState<FlightResultTypes | null>(passenger?.returningFlight);

    const checkFlightPrices = () => {
        if (departureFlight) {
            return "availableSectorPrice.price.oneWayPrices.forAdults";
        }
        return "availableSectorPrice.price.returnPrices.forAdults";
    }

    const calcDifference = () => {
        if (departureFlight) {
            const x: any = (selectedFlight!.availableSectorPrice?.price.oneWayPrices.forAdults + selectedFlight!.taxOfFlight);

            if ((selectedFlight!.availableSectorPrice?.price.oneWayPrices.forAdults + selectedFlight!.taxOfFlight) > departureFlight.lowestPriceWithTax.split(".00")[0]) {
                return (x - departureFlight.lowestPriceWithTax.split(".00")[0]);
            } else if ((selectedFlight!.availableSectorPrice?.price.oneWayPrices.forAdults + selectedFlight!.taxOfFlight) < departureFlight.lowestPriceWithTax.split(".00")[0]) {
                return (departureFlight.lowestPriceWithTax.split(".00")[0] - x);
            }

            return 0;
        } else {
            const y: any = (selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults + selectedReturnFlight!.taxOfFlight);

            if ((selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults + selectedReturnFlight!.taxOfFlight) > returnFlight.lowestPriceWithTax.split(".00")[0]) {
                const returnPrice: any = selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults + selectedReturnFlight!.taxOfFlight
                return (y - returnPrice);
            } else if ((selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults + selectedReturnFlight!.taxOfFlight) < returnFlight.lowestPriceWithTax.split(".00")[0]) {
                const returnPrice: any = selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults + selectedReturnFlight!.taxOfFlight
                return (returnPrice - y);
            }

            return 0;
        }
    }

    const columns: Column[] = [
        {
            Header: `${t("app.main.schedules.addPassenger.departureFrom", "Departure From")}`,
            accessor: "departureAirport",
            Cell: ({cell}: any) => {
                const {departureAirport} = cell.row.original;
                return `${cell.value.name} (${departureAirport.code})`;
            },
        },
        {
            Header: `${t("app.main.schedules.addPassenger.arrivalTo", "Arrival To")}`,
            accessor: "arrivalAirport",
            Cell: ({cell}: any) =>
                `${cell.value.name} (${cell.row.original.arrivalAirport.code})`,
        },
        {
            Header: `${t("app.main.schedules.addPassenger.dateOfDeparture", "Date of departure")}`,
            accessor: "dateOfDeparture",
            Cell: ({cell}: any) => {
                const {timeOfDeparture} = cell.row.original;
                const [hours, minutes] = timeOfDeparture.split(':');
                const formattedTime = `${hours}:${minutes}`;
                return cell.value + " " + formattedTime;
            },
        },
        {
            Header: `${t("app.main.schedules.addPassenger.priceWithTax", "Price with tax")}`,
            accessor: (checkFlightPrices()),
            Cell: ({cell}: any) => {
                const {taxOfFlight, currency} = cell.row.original;
                return cell.value + taxOfFlight + " " + currency.symbol;
            },
        },
        {
            Header: `${t("app.main.schedules.addPassenger.freeSeats", "Free seats")}`,
            accessor: "numberOfAvailableSeats",
            Cell: ({cell}: any) => cell.value,
        },
    ];

    const {
        flightResults,
        loading,
        returnFlightResults,
        returnFlightResultsLoading,
        selectedFlight,
        selectedReturnFlight,
    } = useSelector((state: AppState) => state.schedule.flightResults);

    useEffect(() => {
        dispatch(fetchAllAirports({size: 999999}));

        if (departureFlight) {
            dispatch(
                fetchAllFlightResults({
                    queryParams: {
                        oneWayPrice: 0,
                        returnPrice: 0,
                        departureAirportId: departureFlight.departureAirport?.id,
                        arrivalAirportId: departureFlight.arrivalAirport?.id,
                        dateOfDeparture: DateUtils.convertDate(departureFlight.dateOfDeparture),
                    },
                })
            );
        }

        if (returnFlight) {
            dispatch(
                fetchAllReturnFlightResults({
                    queryParams: {
                        oneWayPrice: 0,
                        returnPrice: 0,
                        departureAirportId: returnFlight.departureAirport?.id,
                        arrivalAirportId: returnFlight.arrivalAirport?.id,
                        dateOfDeparture: DateUtils.convertDate(returnFlight.dateOfDeparture),
                    },
                })
            );
        }
    }, []);

    const isReturnFlightDateValid = (returnFlightDate: string): boolean => {
        if (!returnFlightDate) {
            return false;
        }

        if (selectedFlight?.dateOfDeparture) {
            return !(
                new Date(DateUtils.toISODate(returnFlightDate)) >
                new Date(DateUtils.toISODate(selectedFlight?.dateOfDeparture))
            );
        }

        return false;
    };

    const onFlightSelect = (flight: FlightResultTypes) => {
        dispatch(setSelectedFlight(flight));
    };

    const onReturnFlightSelect = (flight: FlightResultTypes) => {
        dispatch(setSelectedReturnFlight(flight));
    };

    // const getPriceByAgeCategory = (
    //     ageCategory: string,
    //     prices: PricesByAgeCategoryTypes
    // ): number => {
    //     switch (ageCategory) {
    //         case "ADULT":
    //             return prices?.forAdults;
    //         case "CHILD":
    //             return prices?.forChildren;
    //         case "INF":
    //             return prices?.forInfants;
    //         default:
    //             return 0;
    //     }
    // };

    const onSuccess = () => {
        dispatch(setSelectedFlight(null));
        if (selectedReturnFlight) {
            dispatch(setSelectedReturnFlight(null));
        }
        dispatch(toggleQuickPanel());
        history.goBack();
    }

    const infoMsg: string = 'Ky fluturim është tashmë i zgjedhur';

    const updatePassengerFlight = () => {
        if (selectedFlight) {
            if (selectedFlight?.id !== activeRow?.id) {
                const params = {
                    id: passenger.id,
                    body: {
                        departureFlightId: selectedFlight.id
                    },
                    onSuccess
                }
                dispatch(updatePassengerDepartureFlight(params));
            } else {
                dispatch(warningMsg(infoMsg))
            }
        }
        if (selectedReturnFlight) {
            if (selectedReturnFlight.id !== activeReturnRow?.id) {
                const params = {
                    id: passenger.id,
                    body: {
                        returnFlightId: selectedReturnFlight.id
                    },
                    onSuccess
                }
                dispatch(updatePassengerReturnFlight(params));
            } else {
                dispatch(warningMsg(infoMsg));
            }
        }
    };

    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader} style={{marginBottom: "35px"}}>
                <h1 style={{color: "#0D3B66"}}>
                    {departureFlight ?
                        `${t("app.main.schedules.updatePassengerFlight.title1", `Change of passenger departure flight`)}` :
                        `${t("app.main.schedules.updatePassengerFlight.title2", `Change of passenger return flight`)}`
                    }
                    <span style={{fontWeight: 500}}>
            {passenger?.name} {passenger?.surname}
          </span>
                </h1>
            </div>

            {departureFlight && (
                <Formik
                    initialValues={{
                        departureAirportId: departureFlight?.departureAirport?.id,
                        arrivalAirportId: departureFlight?.arrivalAirport?.id,
                        dateOfDeparture: DateUtils.toISODate(
                            departureFlight?.dateOfDeparture
                        ),
                    }}
                    onSubmit={(data) => {
                        dispatch(
                            fetchAllFlightResults({
                                queryParams: {
                                    oneWayPrice: 0,
                                    returnPrice: 0,
                                    departureAirportId: data.departureAirportId,
                                    arrivalAirportId: data.arrivalAirportId,
                                    dateOfDeparture: DateUtils.convertDate(data.dateOfDeparture),
                                },
                            })
                        );
                    }}
                >
                    {({values, setValues}) => (
                        <Form>
                            <Grid container spacing={4} style={{marginBottom: "30px"}}>
                                <Grid item xs={9}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={4}>
                                            <Field
                                                style={{width: "100%"}}
                                                variant="outlined"
                                                value={values.departureAirportId}
                                                name="departureAirportId"
                                                label={t("app.main.schedules.addPassenger.flightFrom", "Flight from")}
                                                select
                                                as={TextField}
                                            >
                                                {airports
                                                    .filter(
                                                        (ap: Airport) => ap.id !== values.arrivalAirportId
                                                    )
                                                    .map((ap: Airport, idx) => (
                                                        <MenuItem
                                                            key={ap.id}
                                                            id={idx + ap.code}
                                                            value={ap.id}
                                                        >
                                                            {ap.code} - {ap.name}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Field
                                                style={{width: "100%"}}
                                                variant="outlined"
                                                value={values.arrivalAirportId}
                                                name="arrivalAirportId"
                                                label={t("app.main.schedules.addPassenger.towardsTheAirport", "Towards the airport")}
                                                select
                                                as={TextField}
                                            >
                                                {airports
                                                    .filter(
                                                        (ap: Airport) => ap.id !== values.departureAirportId
                                                    )
                                                    .map((ap: Airport, idx) => (
                                                        <MenuItem
                                                            key={ap.id}
                                                            id={ap.code + idx}
                                                            value={ap.id}
                                                        >
                                                            {ap.code} - {ap.name}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    inputVariant="outlined"
                                                    disableToolbar
                                                    autoOk
                                                    variant="inline"
                                                    minDate={today}
                                                    format="dd/MM/yyyy"
                                                    id="date-picker-inline"
                                                    label={t("app.main.schedules.addPassenger.dateOfDeparture", "Date of departure")}
                                                    value={values.dateOfDeparture}
                                                    style={{width: "100%"}}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setValues({
                                                                ...values,
                                                                dateOfDeparture: date.toISOString(),
                                                            });
                                                        }
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} direction="column">
                                    <div style={{marginTop: "16px", float: "right"}}>
                                        <LoadingButton
                                            btnTitle={t("app.main.schedules.addPassenger.searchFlights", "Search Flight")}
                                            type="submit"
                                            isLoading={false}
                                            variant="outlined"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
            {returnFlight && (
                <Formik initialValues={{
                    returnFlightDepartureAirportId: returnFlight.departureAirport?.id,
                    returnFlightArrivalAirportId: returnFlight.arrivalAirport?.id,
                    returnFlightDateOfDeparture: DateUtils.toISODate(
                        returnFlight?.dateOfDeparture
                    ),
                }} onSubmit={(data) => {
                    dispatch(
                        fetchAllReturnFlightResults({
                            queryParams: {
                                oneWayPrice: 0,
                                returnPrice: 0,
                                departureAirportId: data.returnFlightDepartureAirportId,
                                arrivalAirportId: data.returnFlightArrivalAirportId,
                                dateOfDeparture: DateUtils.convertDate(
                                    data.returnFlightDateOfDeparture
                                ),
                            },
                        })
                    );
                }}>
                    {({values, setValues}) => (
                        <Form>
                            <Grid container spacing={4} style={{marginBottom: "30px"}}>
                                <Grid item xs={9}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={4}>
                                            <Field
                                                style={{width: "100%"}}
                                                variant="outlined"
                                                value={values.returnFlightDepartureAirportId}
                                                name="returnFlightDepartureAirportId"
                                                label={t("app.main.schedules.addPassenger.flightFrom", "Flight from")}
                                                select
                                                as={TextField}
                                            >
                                                {airports
                                                    .filter(
                                                        (ap: Airport) =>
                                                            ap.id !== values.returnFlightArrivalAirportId
                                                    )
                                                    .map((ap: Airport, idx) => (
                                                        <MenuItem
                                                            key={ap.id}
                                                            id={idx + ap.code}
                                                            value={ap.id}
                                                        >
                                                            {ap.code} - {ap.name}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Field
                                                style={{width: "100%"}}
                                                variant="outlined"
                                                value={values.returnFlightArrivalAirportId}
                                                name="returnFlightArrivalAirportId"
                                                label={t("app.main.schedules.addPassenger.towardsTheAirport", "Towards the airport")}
                                                select
                                                as={TextField}
                                            >
                                                {airports
                                                    .filter(
                                                        (ap: Airport) =>
                                                            ap.id !== values.returnFlightDepartureAirportId
                                                    )
                                                    .map((ap: Airport, idx) => (
                                                        <MenuItem
                                                            key={ap.id}
                                                            id={ap.code + idx}
                                                            value={ap.id}
                                                        >
                                                            {ap.code} - {ap.name}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    inputVariant="outlined"
                                                    disableToolbar
                                                    autoOk
                                                    variant="inline"
                                                    minDate={today}
                                                    format="dd/MM/yyyy"
                                                    id="date-picker-inline"
                                                    label={t("app.main.schedules.addPassenger.dateOfDeparture", "Date of departure")}
                                                    value={values.returnFlightDateOfDeparture}
                                                    style={{width: "100%"}}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setValues({
                                                                ...values,
                                                                returnFlightDateOfDeparture: date.toISOString(),
                                                            });
                                                        }
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} direction="column">
                                    <div style={{marginTop: "16px", float: "right"}}>
                                        <LoadingButton
                                            btnTitle={t("app.main.schedules.addPassenger.searchFlights", "Search Flight")}
                                            type="submit"
                                            isLoading={false}
                                            variant="outlined"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}

            <Grid container>
                {departureFlight && (
                    <Grid item style={{flexGrow: 1}}>
                        <BaseTable
                            columns={columns}
                            data={flightResults?.content}
                            onClickTableRow={onFlightSelect}
                            isLoading={loading}
                            activeRow={(row: FlightResultTypes) => row.id === activeRow?.id}
                            disableRow={(row: FlightResultTypes) =>
                                row.numberOfAvailableSeats === 0
                            }
                            selectedRow={(row: FlightResultTypes) =>
                                row.id === selectedFlight?.id
                            }
                        />
                        {selectedFlight && (
                            <div className={classes.priceRow}>
                                <p className={classes.price}>
                                    {t("app.main.schedules.updatePassengerFlight.outgoingTicket", "Outgoing ticket")}:{" "}
                                    <span className={classes.weight500}>
                      {selectedFlight!.availableSectorPrice?.price.oneWayPrices.forAdults}{" "}
                                        {selectedFlight!.currency?.symbol}</span>{" "}|
                                </p>
                                <p className={classes.price}>
                                    {t("app.main.schedules.updatePassengerFlight.tax", "Taxes")}:{" "}
                                    <span className={classes.weight500}>
                      {selectedFlight!.taxOfFlight} {selectedFlight!.currency?.symbol}
                    </span>{" "}|
                                </p>
                                <p className={classes.price}>
                                    {t("app.main.schedules.updatePassengerFlight.totalTicket", "Total Ticket")}:{" "}
                                    <span className={classes.weight500}>
                      {selectedFlight!.availableSectorPrice?.price.oneWayPrices.forAdults + selectedFlight!.taxOfFlight}{" "}
                                        {selectedFlight!.currency?.symbol}
                    </span>{" "}|
                                </p>
                                <p>{t("app.main.schedules.updatePassengerFlight.difference", "Difference")}:{" "}
                                    <span className={classes.weight500}>
                      {calcDifference()}
                                        {selectedFlight!.currency?.symbol}</span>
                                </p>
                            </div>
                        )}
                    </Grid>
                )}
                {returnFlight && (
                    <Grid item style={{flexGrow: 1}}>
                        <BaseTable
                            columns={columns}
                            data={returnFlightResults?.content}
                            onClickTableRow={onReturnFlightSelect}
                            isLoading={returnFlightResultsLoading}
                            activeRow={(row: FlightResultTypes) =>
                                row.id === activeReturnRow?.id
                            }
                            selectedRow={(row: FlightResultTypes) =>
                                row.id === selectedReturnFlight?.id
                            }
                            disableRow={(row: FlightResultTypes) =>
                                isReturnFlightDateValid(row.dateOfDeparture) || row.numberOfAvailableSeats === 0
                            }
                        />
                        {selectedReturnFlight && (
                            <div className={classes.priceRow}>
                                <p className={classes.price}>
                                    {t("app.main.schedules.updatePassengerFlight.outgoingTicket", "Outgoing ticket")}:{" "}
                                    <span className={classes.weight500}>
                      {selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults}{" "}
                                        {selectedReturnFlight!.currency?.symbol}</span>{" "}|
                                </p>
                                <p className={classes.price}>
                                    {t("app.main.schedules.updatePassengerFlight.tax", "Taxes")}:{" "}
                                    <span className={classes.weight500}>
                      {selectedReturnFlight!.taxOfFlight} {selectedReturnFlight!.currency?.symbol}
                    </span>{" "}|
                                </p>
                                <p className={classes.price}>
                                    {t("app.main.schedules.updatePassengerFlight.totalTicket", "Total Ticket")}:{" "}
                                    <span className={classes.weight500}>
                      {selectedReturnFlight!.availableSectorPrice?.price.returnPrices.forAdults +
                          selectedReturnFlight!.taxOfFlight}{" "}
                                        {selectedReturnFlight!.currency?.symbol}
                    </span>{" "}|
                                </p>
                                <p>{t("app.main.schedules.updatePassengerFlight.difference", "Difference")}:{" "}
                                    <span className={classes.weight500}>
                      {calcDifference()}
                                        {selectedReturnFlight!.currency?.symbol}</span>
                                </p>
                            </div>
                        )}
                    </Grid>
                )}
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{marginTop: "20px"}}
            >
                <Grid item>
                    <LoadingButton
                        type="submit"
                        btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                        size="medium"
                        isLoading={updateLoading}
                        onClick={() => updatePassengerFlight()}
                    />
                    <Button
                        onClick={(e) => {
                            dispatch(toggleQuickPanel());
                            history.goBack();
                        }}
                    >
                        {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default UpdatePassengerFlight;
