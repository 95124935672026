import {makeStyles} from "@material-ui/core/styles";
import {amber, blue, green} from "@material-ui/core/colors";

export default makeStyles(theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF'
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark)
  },
  info: {
    backgroundColor: blue[600],
    color: '#FFFFFF'
  },
  warning: {
    backgroundColor: amber[600],
    color: '#FFFFFF'
  }
}));