import {Grid} from "@material-ui/core";
import {Field} from "formik";
import React from "react";
import {setTheAgentForm} from "../store/agent-form.slice";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {createAgentsStyles} from "../create/create-agents.styles";
import {useTranslation} from "react-i18next";

const ScopesInfo = () => {
  const dispatch = useDispatch();
  const agentForm = useSelector((state: AppState) => state.agents.agentForm);
  const classes = createAgentsStyles();
  const {t} = useTranslation();

  const handleCheckbox = (value: string) => {
    dispatch(setTheAgentForm({
      ...agentForm,
      scopes: agentForm.scopes.includes(value) ? agentForm.scopes.filter(item => item !== value) : [...agentForm.scopes, value]
    }))
  }

  return (
      <div style={{marginBottom: "10px"}}>
        <div style={{
          backgroundColor: "lightgrey",
          padding: "15px",
          marginLeft: "-16px",
          marginRight: "-16px",
        }}>
          <h2 className={classes.title}>{t("app.main.agency.agentsList.agentEdit.sidebar.title2", "Agent privileges")}</h2>
        </div>

        <Grid container spacing={4} className={classes.permissions}>
          <Grid item xs={3}>
            <div className={classes.checkboxGroup} role="group">
              <label>
                <Field type="checkbox" name="FLIGHT_RESERVATION"
                       checked={agentForm?.scopes?.includes('FLIGHT_RESERVATION')}
                       value='FLIGHT_RESERVATION'
                       onChange={() => handleCheckbox('FLIGHT_RESERVATION')}/>
                {t("app.main.agency.create.scopesInfo.label1", "Booking flights")}
              </label>
              <label>
                <Field type="checkbox" name="ADD_TRAVELER"
                       checked={agentForm?.scopes?.includes('ADD_TRAVELER')}
                       value='ADD_TRAVELER'
                       onChange={() => handleCheckbox('ADD_TRAVELER')}/>
                {t("app.main.agency.create.scopesInfo.label2", "Addition of passengers")}
              </label>
              <label>
                <Field type="checkbox" name="DEACTIVATE_TRAVELER"
                       checked={agentForm?.scopes?.includes('DEACTIVATE_TRAVELER')}
                       value='DEACTIVATE_TRAVELER'
                       onChange={() => handleCheckbox('DEACTIVATE_TRAVELER')}/>
                {t("app.main.agency.create.scopesInfo.label3", "Cancellation of passengers")}
              </label>
              <label>
                <Field type="checkbox" name="CANCELLATION_OF_RESERVATION"
                       checked={agentForm.scopes?.includes('CANCELLATION_OF_RESERVATION')}
                       value='CANCELLATION_OF_RESERVATION'
                       onChange={() => handleCheckbox('CANCELLATION_OF_RESERVATION')}/>
                {t("app.main.agency.create.scopesInfo.label15", "Cancellation of reservation")}
              </label>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.checkboxGroup} role="group">
              <label>
                <Field type="checkbox" name="RESERVATION_DISCUSSION"
                       checked={agentForm?.scopes?.includes('RESERVATION_DISCUSSION')}
                       value='RESERVATION_DISCUSSION'
                       onChange={() => handleCheckbox('RESERVATION_DISCUSSION')}/>
                {t("app.main.agency.create.scopesInfo.label5", "Booking note")}
              </label>
              <label>
                <Field type="checkbox" name="UPDATE_TRAVELERS"
                       checked={agentForm?.scopes?.includes('UPDATE_TRAVELERS')}
                       value='UPDATE_TRAVELERS'
                       onChange={() => handleCheckbox('UPDATE_TRAVELERS')}/>
                {t("app.main.agency.create.scopesInfo.label6", "Change of passengers")}
              </label>
              <label>
                <Field type="checkbox" name="CONFIRM_VIBER"
                       checked={agentForm?.scopes?.includes('CONFIRM_VIBER')}
                       value='CONFIRM_VIBER'
                       onChange={() => handleCheckbox('CONFIRM_VIBER')}/>
                {t("app.main.agency.create.scopesInfo.label4", "Viber confirmation")}
              </label>
              <label>
                <Field type="checkbox" name="ACTIVITIES"
                       checked={agentForm?.scopes?.includes('ACTIVITIES')} value='ACTIVITIES'
                       onChange={() => handleCheckbox('ACTIVITIES')}/>
                {t("app.main.agency.create.scopesInfo.label8", "Activities")}
              </label>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.checkboxGroup} role="group">
              <label>
                <Field type="checkbox" name="ADD_DISCUSSION"
                       checked={agentForm?.scopes?.includes('ADD_DISCUSSION')}
                       value='ADD_DISCUSSION'
                       onChange={() => handleCheckbox('ADD_DISCUSSION')}/>
                {t("app.main.agency.create.scopesInfo.label9", "Adding remarks")}
              </label>
              <label>
                <Field type="checkbox" name="UPDATE_TRAVELER_PRICES"
                       checked={agentForm?.scopes?.includes('UPDATE_TRAVELER_PRICES')}
                       value='UPDATE_TRAVELER_PRICES'
                       onChange={() => handleCheckbox('UPDATE_TRAVELER_PRICES')}/>
                {t("app.main.agency.create.scopesInfo.label10", "Passenger price change")}
              </label>
              <label>
                <Field type="checkbox" name="SHOW_TRAVELERS"
                       checked={agentForm?.scopes?.includes('SHOW_TRAVELERS')}
                       value='SHOW_TRAVELERS'
                       onChange={() => handleCheckbox('SHOW_TRAVELERS')}/>
                {t("app.main.agency.create.scopesInfo.label11", "Show/Don't show passengers")}
              </label>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.checkboxGroup} role="group">
              <label>
                <Field type="checkbox" name="UPDATE_CONTACT"
                       checked={agentForm?.scopes?.includes('UPDATE_CONTACT')}
                       value='UPDATE_CONTACT'
                       onChange={() => handleCheckbox('UPDATE_CONTACT')}/>
                {t("app.main.agency.create.scopesInfo.label12", "Changing contactor")}
              </label>
              <label>
                <Field type="checkbox" name="DELETE_TRAVELERS"
                       checked={agentForm?.scopes?.includes('DELETE_TRAVELERS')}
                       value='DELETE_TRAVELERS'
                       onChange={() => handleCheckbox('DELETE_TRAVELERS')}/>
                {t("app.main.agency.create.scopesInfo.label13", "Deleting passengers")}
              </label>
              <label>
                <Field type="checkbox" name="UPDATE_TRAVELER_FLIGHTS"
                       checked={agentForm?.scopes?.includes('UPDATE_TRAVELER_FLIGHTS')}
                       value='UPDATE_TRAVELER_FLIGHTS'
                       onChange={() => handleCheckbox('UPDATE_TRAVELER_FLIGHTS')}/>
                {t("app.main.agency.create.scopesInfo.label14", "Change of passenger flights")}
              </label>
            </div>
          </Grid>
        </Grid>

      </div>
  );
};

export default ScopesInfo;