import {makeStyles} from '@material-ui/core/styles';
import {Theme} from "@material-ui/core";

export const activityPageStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100%'
  }
}));

export const activityItemStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(5, 0)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardFooter: {
    margin: theme.spacing(1, 2)
  },
  pos: {
    marginBottom: 12,
  }
}));