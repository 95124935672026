import axios from "./axios.interceptors";

class DashboardService {
  registeredCustomers = async (): Promise<number> => {
    try {
      const response = await axios.get('/api/dashboard/customers/created/count');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  registeredTravelers = async (): Promise<number> => {
    try {
      const response = await axios.get('/api/dashboard/travelers/created/count');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  reservationsToday = async (): Promise<number> => {
    try {
      const response = await axios.get('/api/dashboard/reservations/created/count');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  revenueToday = async (): Promise<number> => {
    try {
      const response = await axios.get('/api/dashboard/revenue/created/count');
      return response.data[0]?.amount;
    } catch (err) {
      throw err;
    }
  }

  revenueSummary = async (params: any): Promise<any> => {
    try {
      const response = await axios.get('/api/dashboard/revenue/summary', {params});
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  paymentMethods = async (): Promise<number> => {
    try {
      const response = await axios.get('/api/dashboard/paymentMethods/created/count');
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  disabledReservations = async (): Promise<number> => {
    try {
      const response = await axios.get('/api/dashboard/reservations/created/disabled/count');
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new DashboardService();