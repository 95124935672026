import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {CustomersService} from 'app/services';
import {FilterCustomersQueryParams, PagedCustomerList,} from "../../../types/service-types/CustomerTypes";
import {errorMsg} from "../../../utils/MessageUtils";

export const fetchAllCustomers = (queryParams?: FilterCustomersQueryParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (queryParams && Object.keys(queryParams).length > 0) {
      response = await CustomersService.filterCustomers(queryParams);
    } else response = await CustomersService.fetchAll();
    dispatch(setCustomersList(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(errorMsg(err.data.message));
  }
}

interface CustomersListSlice {
  allCustomers: PagedCustomerList;
  loading: boolean;
}

const initialState: CustomersListSlice = {
  allCustomers: {content: []},
  loading: false
}

const stateSlice = createSlice({
  name: 'customer/customers-list',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCustomersList: (state, action) => {
      state.allCustomers = action.payload
    }
  }
});

export const {setLoading, setCustomersList} = stateSlice.actions;

export default stateSlice.reducer;