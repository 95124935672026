import React, {useState} from 'react';
import {Icon, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {NavLink} from 'react-router-dom';
import useStyles from './navbar-list-item.styles';
import {NavbarItemTypes} from "app/types/NavbarTypes";

const NavbarListItem = ({
                          secondaryText, icon, primaryText, ...otherProps
                        }: NavbarItemTypes) => {
  const classes = useStyles();

  const [active, setActive] = useState(false);

  return (
      <NavLink
          isActive={match => {
            if (match?.isExact) {
              setActive(true)
              return true;
            } else {
              setActive(false)
              return false;
            }
          }} {...otherProps} >
        <ListItem
            className={classes.listItem}
            style={active ? {background: '#e7e7e7', fontWeight: 700} : {}} button>
          <ListItemIcon className={classes.icon}>
            <Icon style={{color: '#ffffff !important', fontSize: '18px'}}>{icon}</Icon>&nbsp;&nbsp;
            <ListItemText className={classes.itemText}
                          primary={primaryText}
                          secondary={secondaryText}
            />
          </ListItemIcon>
        </ListItem>
      </NavLink>
  );
};

export default NavbarListItem;