import React, {useEffect, useState} from 'react';
import TableActionButtons from "app/main/shared-components/base-table/TableActionButtons";
import {Column, TableActionsTypes} from "app/types/TableTypes";
import PageHeader from "app/main/shared-components/page-header/PageHeader";
import BaseTable from "app/main/shared-components/base-table/BaseTable";
import history from '@history';
import SearchInput from "../../shared-components/search-input/SearchInput";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {fetchAllAirlines} from "../store/airlines/airline-companies.slice";
import {useRouteMatch} from "react-router-dom";
import {toggleQuickPanel} from "../../shared-components/quick-panel/store";
import {deleteAirline} from "../store/airlines/delete-airline.slice";
import ModalDialog from "../../shared-components/modal-dialog/ModalDialog";
import {useTranslation} from "react-i18next";

const AirlineCompaniesPage = (props: any) => {
  const dispatch = useDispatch();
  let {url} = useRouteMatch();
  const {
    airlineCompanies,
    loading
  } = useSelector((state: AppState) => state.configurations.airlines.airlineCompanies);
  const {t} = useTranslation();
  const [companies, setCompanies] = useState(airlineCompanies || []);

  const [modal, setModal] = useState({
    state: false,
    deleteId: '',
    airlineName: ''
  });

  const tableActions: TableActionsTypes = {
    // onViewRow: (rowId: number) =>  alert('row: ' + rowId),
    onEditRow: (rowId: number) => {
      dispatch(toggleQuickPanel());
      history.push(`${url}/update/${rowId}`);
    },
    onDeleteRow: (rowId: string, name: string) => {
      setModal({state: true, deleteId: rowId, airlineName: name});
    }
  }


  const columns: Column[] = [
    {
      Header: `${t("app.main.configuration.airlineCompanies.table.tableRow6", "Company")}`,
      accessor: 'name'
    },
    {
      Header: `${t("app.main.configuration.airlineCompanies.table.tableRow4", "Other informations")}`,
      accessor: 'additionalInformation'
    },
    {
      Header: `${t("app.main.employees.employeeList.table.actions", "Actions")}`,
      accessor: 'id',
      Cell: ({cell}: any) => (
          <TableActionButtons {...tableActions} rowId={cell.value} name={cell.row.original.name}/>
      )
    }
  ];

  const toString = (obj: any) => {
    if (obj) {
      if (isNaN(obj)) {
        return obj;
      } else return obj.toString();
    } else return "";
  }

  const searchCompanies = (value: string) => {
    if (value) {
      const filteredCompanies = airlineCompanies.filter(company => {
        return toString(company.name).toLowerCase().match(value) ||
            toString(company.additionalInformation).toLowerCase().match(value);
      })
      setCompanies(filteredCompanies)
    } else {
      setCompanies(airlineCompanies)
    }

  }

  useEffect(() => {
    dispatch(fetchAllAirlines());
  }, []);

  useEffect(() => {
    setCompanies(airlineCompanies);
  }, [airlineCompanies])

  const translateModal = () => {
    let title = t("app.main.configuration.airline.module.title", "Are you sure you want to delete airline ");

    return title + modal.airlineName + ' ?';
  }

  return (
      <div>
        <PageHeader
            title={t("app.main.configuration.airlineCompanies.title1", "Airline Companies")}
            desc={t( "app.main.configuration.airlineCompanies.desc1", "If you want to add a new company, click Register")}
            button={t("app.main.agency.agentsList.registerButton", "Register")}
            onClickButton={() => {
              history.push(`${url}/create`)
              dispatch(toggleQuickPanel());
            }}
        />
        <div style={{marginTop: '10px', marginBottom: '10px'}}>
          <SearchInput onChange={searchCompanies}/>
        </div>
        <ModalDialog
            open={modal.state}
            title={translateModal()}
            onOk={() => {
              dispatch(deleteAirline({id: modal.deleteId}));
              setModal({state: false, deleteId: '', airlineName: ''});
            }}
            onCancel={() => setModal({state: false, deleteId: '', airlineName: ''})}
        />
        <BaseTable columns={columns} data={companies} isLoading={loading}/>
      </div>
  );
};

export default AirlineCompaniesPage;