import {AppState} from 'app/store';
import {useSelector} from 'react-redux';

const useAuthority = () => {
  const user = useSelector((state: AppState) => state.auth.user);

  const hasUserAnyAuthority = (authorities: string[]): boolean => {
    if (authorities.length === 0) {
      return true;
    }

    // @ts-ignore
    return authorities.some(item => user?.authorities.includes(item))
  }

  return {hasUserAnyAuthority}
}

export default useAuthority;