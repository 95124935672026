import {makeStyles, Theme} from "@material-ui/core/styles";

export const updatePassengerFlightStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(0, 5)
  },
  formHeader: {
    color: theme.palette.secondary.dark,
    margin: theme.spacing(1.5, 0)
  },
  priceRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    padding: '15px',
    backgroundColor: '#e7e7e7'
  },
  price: {
    marginRight: '5px'
  },
  weight500: {
    fontWeight: 500
  }
}))