import {makeStyles} from "@material-ui/styles";

export const clientWebContentStyle = makeStyles(() => ({
  formWrapper: {
    margin: '2rem',
    width: '30%',
    ['@media (max-width:768px)']: {
      width: '100%',
      margin: '2rem auto'
    },
    '& form': {
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  formHeader: {
    margin: '1rem',
    width: '30%'
  },
  colorBlue: {
    color: '#0D3B66'
  },
  width100: {
    width: '100%'
  },
}));