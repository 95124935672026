import axios from './axios.interceptors';
import {
    AgentTypes,
    CreateAgentBodyTypes,
    CreatePaymentBodyTypes,
    FilterAgentsQueryParams,
    FilterPaymentsQueryParams,
    FilterProvisionsQueryParams,
    UpdateAgentAccessibility,
    UpdateAgentBodyTypes,
    UpdateAgentScopes,
} from '../types/service-types/AgentTypes';

class AgentService {
    fetchAll = async (): Promise<AgentTypes> => {
        try {
            const response = await axios.get('/api/agents');
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    filterAgents = async (queryParams: FilterAgentsQueryParams): Promise<any> => {
        try {
            const response = await axios.get(`/api/agents`, {params: queryParams});
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    filterAgentsWithDebt = async (queryParams?: FilterAgentsQueryParams): Promise<any> => {
        try {
            const response = await axios.get(`/api/agents/with-debt`, {params: queryParams});
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    filterAgentsWithoutDebt = async (queryParams?: FilterAgentsQueryParams): Promise<any> => {
        try {
            const response = await axios.get(`/api/agents/debt-free`, {params: queryParams});
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    getAgentById = async (id: string): Promise<any> => {
        try {
            const response = await axios.get(`/api/agents/${id}`);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    createAgent = async (body: CreateAgentBodyTypes): Promise<any> => {
        try {
            return await axios.post('/api/agents', body);
        } catch (err) {
            throw err;
        }
    }

    createPaymentAtAgent = async (id: string, body: CreatePaymentBodyTypes): Promise<any> => {
        try {
            return await axios.post(`/api/agents/${id}/payments`, body);
        } catch (err) {
            throw err;
        }
    }

    updatePaymentAtAgent = async (id: string, body: any): Promise<any> => {
        try {
            return await axios.put(`api/agents/${id}/payments`, body);
        } catch (err) {
            throw err;
        }
    }

    filterPaymentsAtAgent = async (id: string, queryParams?: FilterPaymentsQueryParams): Promise<any> => {
        try {
            const response = await axios.get(`/api/agents/${id}/payments`, {params: queryParams});
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    filterProvisionsAtAgent = async (id: string, queryParams?: FilterProvisionsQueryParams): Promise<any> => {
        try {
            const response = await axios.get(`/api/agents/${id}/provisions`, {params: queryParams});
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    updateAgent = async (id: string, body: UpdateAgentBodyTypes): Promise<any> => {
        try {
            return await axios.put(`/api/agents/${id}`, body);
        } catch (err) {
            throw err;
        }
    }

    updateAccessibility = async (id: string, body: UpdateAgentAccessibility): Promise<any> => {
        try {
            return await axios.put(`/api/agents/${id}/accessibility`, body);
        } catch (err) {
            throw err;
        }
    }

    updateScopes = async (id: string, body: UpdateAgentScopes): Promise<any> => {
        try {
            return await axios.put(`/api/agents/${id}/scopes`, body);
        } catch (err) {
            throw err;
        }
    }

    deleteAgent = async (id: string): Promise<any> => {
        try {
            return await axios.delete(`/api/agents/${id}`);
        } catch (err) {
            throw err;
        }
    }

    uploadAgentLogo = async (id: string, logoImage: any): Promise<any> => {
        return await axios.put(`/api/agents/${id}/logo-image`, logoImage);
    }

    activateAgent = async (id: string) => {
        try {
            return await axios.patch(`/api/agents/activate/${id}`)
        } catch (err) {
            throw err;
        }
    }

    deactivateAgent = async (id: string) => {
        try {
            return await axios.patch(`/api/agents/deactivate/${id}`)
        } catch (err) {
            throw err;
        }
    }
}

export default new AgentService();
