import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import AppContext from 'app/AppContext';
import {RouteTypes} from 'app/types/PageTypes';
import TopbarLayout from './topbar/TopbarLayout';
import SubTopbar from './sub-topbar/SubTopbar';
import Navbar from "./navbar/Navbar";
import useStyles from './admin-layout.styles';
import QuickPanel from "app/main/shared-components/quick-panel/QuickPanel";
import AppMessage from "app/main/shared-components/app-message/AppMessage";
import useAuthority from 'app/hooks/useAuthority';
import useMutationObserver from 'app/hooks/useMutationObservable';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import ReportPageConfig from "app/main/report/ReportPageConfig";
import {fetchAllPaymentMethods} from "../configurations/store/paymentmethods/paymentmethods-list.slice";
import AgencyEditPageConfig from "../agency/AgencyEditPageConfig";
import AgencyReportsPageConfig from "../agency-reports/AgencyReportsPageConfig";
import {getAccount} from "../../account/store/get-account.slice";
import {ROLES} from "../../constants/roles";

const AdminLayout = () => {
    const dispatch = useDispatch();
    const appContext = useContext(AppContext);
    const {hasUserAnyAuthority} = useAuthority();
    const classes = useStyles();
    const {pagesConfigs, setPagesConfigs} = appContext;
    const paymentMeth = useSelector((state: AppState) => state.configurations.paymentmethods.paymentmethodsList.allPaymentMethods);
    const balances = useSelector((state: AppState) => state.agents.agent.agent?.balances);
    const pagesWrapperRef = useRef<any>();
    const history = useHistory();
    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef<any>();
    const account = useSelector((state: AppState) => state.account.account);
    const balanceResponse = account?.account?.balanceResponse;

    useEffect(() => {
        dispatch(getAccount());
    }, []);

    useEffect(() => {
        setHeaderHeight(headerRef?.current?.clientHeight)
        dispatch(fetchAllPaymentMethods());
    }, [])

    useEffect(() => {
        pagesWrapperRef.current.scrollTo(0, 0);
    }, [history.location.pathname]);

    // Detect when height of the header changes and set the headerHeight
    useMutationObserver({
        target: headerRef,
        callback: useCallback((mutations) => {
            setHeaderHeight(headerRef?.current?.clientHeight)
        }, [])
    });

    useEffect(() => {
        if (paymentMeth.length > 0) {
            setPagesConfigs([ReportPageConfig(paymentMeth), ...pagesConfigs])
        }
    }, [paymentMeth]);

    useEffect(() => {
        if (balances?.length) {
            setPagesConfigs([AgencyEditPageConfig(balances), ...pagesConfigs])
        } else {
            setPagesConfigs([AgencyEditPageConfig([]), ...pagesConfigs])
        }
    }, [balances]);

    useEffect(() => {
        if (hasUserAnyAuthority([ROLES.ROLE_AGENT])) {
            if (balanceResponse?.length) {
                setPagesConfigs([AgencyReportsPageConfig(balanceResponse), ...pagesConfigs])
            } else {
                setPagesConfigs([AgencyReportsPageConfig([]), ...pagesConfigs])
            }
        }
    }, [account, balanceResponse]);

    const pageLayoutWrapperHeight = {height: `calc(100vh - ${headerHeight}px)`}

    return (
        <div className={classes.root}>
            <div ref={headerRef}>
                <TopbarLayout/>
                <SubTopbar/>
            </div>
            <QuickPanel/>
            <div className={classes.pageLayoutWrapper}>
                <Navbar headerHeight={headerHeight}/>
                <div ref={pagesWrapperRef} className={classes.pagesWrapper}
                     style={{...pageLayoutWrapperHeight}}>
                    <Switch>
                        {pagesConfigs.map((config, idx) => {
                            if (hasUserAnyAuthority(config.baseRoute.authorities || [])) {
                                return (
                                    <Route key={idx} path={config.baseRoute.path} render={() => {
                                        return (
                                            <config.baseRoute.component
                                                settings={config.settings}
                                                key={idx + config.baseRoute.path}
                                            >
                                                {config.subRoutes.map((subRoute: RouteTypes, idx: number) => {
                                                    if (hasUserAnyAuthority(subRoute.authorities || [])) {
                                                        return (
                                                            <Route key={idx} render={(otherProps) => (
                                                                <subRoute.component
                                                                    key={idx + subRoute.path}
                                                                    settings={config.settings}
                                                                    {...otherProps}
                                                                />
                                                            )} path={subRoute.path}/>
                                                        )
                                                    }
                                                })}
                                            </config.baseRoute.component>
                                        )
                                    }}/>
                                )
                            }
                        })}
                    </Switch>
                </div>
            </div>
            <AppMessage/>
        </div>
    )
}

export default AdminLayout;
